import { Component, Input, OnInit } from '@angular/core';
import { BookingTotals } from 'app/fixme-inline-types';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { ExtraModel } from 'up-ibe-types';
import { fadeInOnEnterAnimation } from '../animations';
import { BookingService } from '../services/booking.service';
import { LocalStorageService } from '../services/local-storage.service';

@Component({
    selector: 'ibe-total-summary',
    templateUrl: './total-summary.component.html',
    styleUrls: [ './total-summary.component.scss' ],
    animations: [
        fadeInOnEnterAnimation,
    ],
})
export class TotalSummaryComponent implements OnInit {
    @Input() public currency: string;
    @Input() public bookingTotals: BookingTotals;
    @Input() public isOpen = false;
    @Input() public isSuppressed = false;
    @Input() public displayInclusiveExtrasAsTaxes = false;

    constructor(
        public readonly config: IbeConfigService,
        private readonly bookingService: BookingService,
        private readonly localStorageService: LocalStorageService,
    ) { }

    public ngOnInit() { }

    public getDepositTotal(): number {
        return this.getFlexDeposit() + this.getFixDeposit();
    }

    public getFixDeposit(): number {
        const reservations = this.bookingService.getReservations();

        const findDepositFix = (extra: ExtraModel) => extra.id.includes('DEPO_FIX');
        const depositAsIncludedExtra: ExtraModel | undefined = (reservations.length > 0)
            ? reservations[0]?.includedExtras?.find(findDepositFix)
            : undefined;

        return (depositAsIncludedExtra && depositAsIncludedExtra.totalGrossAmount)
            ? depositAsIncludedExtra.totalGrossAmount.amount
            : 0;
    }

    public getFlexDeposit(): number {
        const metaData = this.localStorageService.getStaceyMetaData();
        return (metaData)
            ? Math.ceil((metaData.internal.avgPricePerSliceWithoutExtras.grossAmount * 30) * 2)
            : 0;
    }

    public toggleSlide() {
        this.isOpen = !this.isOpen;
    }
}
