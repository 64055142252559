import { Injectable } from '@angular/core';
import { StaceyMetaData, StayCriteriaModel } from 'app/fixme-inline-types';
import { BookingModel } from 'up-ibe-types';
import { IbeConfigService } from './ibe-config.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private _localStorageKeyPrefix = 'upibe';

    constructor(
        // FIXME: DO NOT use public properties
        public config: IbeConfigService,
    ) {
    }

    // tslint:disable-next-line:no-any
    public setItem(key: string, data: any): void {
        try {
            const storageKey = `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`;
            const jsonData = JSON.stringify(data);

            localStorage.setItem(storageKey, jsonData);
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('LocalStorageService.setItem:', error);
            throw  error;
        }
    }

    // FIXME: replace return type 'any' with 'unknown' and start fixing random types
    // tslint:disable-next-line:no-any
    public getItem(key: string): any {
        const storageKey = `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`;

        try {
            const item = localStorage.getItem(storageKey);
            return item ? JSON.parse(item) : false;
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('LocalStorageService.getItem:', error);
            throw  error;
        }
    }

    public removeItem(key: string) {
        const storageKey = `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`;

        try {
            return localStorage.removeItem(storageKey);
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('LocalStorageService.removeItem:', error);
            throw  error;
        }
    }

    /**
     * @Internal invalid type cast
     * - the correct return type is *unknown*
     * - getItem returns *any*
     * - the returned data is never tested / validated
     */
    public getStaceyMetaData(): StaceyMetaData | false {
        return this.getItem('stacey');
    }

    public getBookingData() {
        return this.getItem('bookingData');
    }

    public getBookingRequestId() {
        return this.getItem('bookingRequestId');
    }

    public getBookingRequestInProgress() {
        return this.getItem('bookingRequestInProgress');
    }

    public setBookingRequestInProgress(data: { success: boolean; id: string }) {
        return this.setItem('bookingRequestInProgress', data);
    }

    public getLastSearchedStayCriteria() {
        return this.getItem('lastSearchedStayCriteria');
    }

    public getBookingLoginCredentials() {
        return this.getItem('bookingLoginCredentials');
    }

    public getBookingRequestIdEventHasBeenFiredFor() {
        return this.getItem('setBookingRequestIdEventHasBeenFiredFor');
    }

    public getSaferpayToken() {
        return this.getItem('saferPayToken');
    }

    public getAdyenTransactionId() {
        return this.getItem('adyenTransactionId');
    }

    public setBookingData(data: BookingModel) {
        return this.setItem('bookingData', data);
    }

    public setBookingRequestId(data: string) {
        return this.setItem('bookingRequestId', data);
    }

    public setBookingLoginCredentials(data: {
        propertyId?: string;
        bookingOrReservationId: string;
        lastName: string;
    }) {
        return this.setItem('bookingLoginCredentials', data);
    }

    public setLastSearchedStayCriteria(data: StayCriteriaModel) {
        return this.setItem('lastSearchedStayCriteria', data);
    }

    public setBookingRequestIdEventHasBeenFiredFor(data: string) {
        return this.setItem('setBookingRequestIdEventHasBeenFiredFor', data);
    }

    public setAdyenTransactionId(data: string) {
        return this.setItem('adyenTransactionId', data);
    }

    public setSaferPayToken(data: string) {
        return this.setItem('saferPayToken', data);
    }

    public removeBookingData() {
        this.removeItem('bookingData');
    }

    public removeBookingRequestId() {
        this.removeItem('bookingRequestId');
    }

    public removeLastSearchedStayCriteria() {
        this.removeItem('lastSearchedStayCriteria');
    }

    public removeBookingRequestInProgress() {
        this.removeItem('bookingRequestInProgress');
    }

    public removeSaferPayToken() {
        this.removeItem('saferPayToken');
    }

    public removeAdyenTransactionId() {
        return this.removeItem('adyenTransactionId');
    }
}
