<div *ngIf="!propertyCalendar">
    <h3 class="ibe-property-availability-calendar" *ngIf="property">
        {{ property.name }}
    </h3>

    <div class="ibe-availability-calendars">
        <div class="ibe-calendar-left">
            <ibe-calendar
                [currentMonth]="leftCalendarCurrentMonth"
                [arrivalDate]="arrivalDate"
                [departureDate]="departureDate"
                [hoveredDepartureDate]="hoveredDepartureDate"
                [property]="property"
                [potentialAvailabilitySpanSubject]="potentialAvailabilitySpanSubject"
                (onArrivalDateSelection)="onArrivalDateSelection($event)"
                (onDepartureDateSelection)="onDepartureDateSelection($event)"
                (onDepartureDateHover)="onDepartureDateHover($event)"
                (onStayDateSelection)="onStayDateSelection($event)"
            ></ibe-calendar>
        </div>
        <div class="ibe-calendar-right">
            <ibe-calendar
                [currentMonth]="rightCalendarCurrentMonth"
                [arrivalDate]="arrivalDate"
                [departureDate]="departureDate"
                [hoveredDepartureDate]="hoveredDepartureDate"
                [property]="property"
                [potentialAvailabilitySpanSubject]="potentialAvailabilitySpanSubject"
                (onArrivalDateSelection)="onArrivalDateSelection($event)"
                (onDepartureDateSelection)="onDepartureDateSelection($event)"
                (onDepartureDateHover)="onDepartureDateHover($event)"
                (onStayDateSelection)="onStayDateSelection($event)"
            ></ibe-calendar>
        </div>
    </div>
</div>
