<div class="ibe-filter-results-header ibe-region-results-header">
    <h2 class="ibe-filter-results-title ibe-region-results-title">
        <span *ngIf="region === 'All'">{{ 'room_results.all_properties' | translate }}</span>
        <span *ngIf="region !== 'All'">{{region}}</span>
        &gt;
        <span>{{ dates }}</span>
        &gt;
        <span>
            {{ persons }}
            <span *ngIf="persons === 1">{{ 'room_results.guest' | translate }}</span>
            <span *ngIf="persons > 1">{{ 'room_results.guests' | translate }}</span>
        </span>
    </h2>
</div>

<div
    *ngIf="(availability$ | async)?.length"
    #regionResultsContainer
    class="ibe-region-results ibe-filter-results"
>
    <div class="ibe-filter-results-list ibe-region-results-list ibe-row">
        <div
            *ngFor="let result of availability$ | async"
            class="ibe-filter-result ibe-region-result ibe-col-4"
            (click)="onResultClick(result)"
        >
            <div class="ibe-room-image-outer">
                <div
                    class="ibe-room-image-inner"
                    [ngStyle]="{
                        'background-image': 'url(' + getPropertyImageUrl(
                            result.property.id,
                            result.unitType.id
                        ) + ')'
                    }"
                ></div>
            </div>
            <div class="ibe-filter-footer ibe-region-footer">
                <p class="ibe-filter-text ibe-region-text">
                    {{result.property.location.city}}
                </p>
                <p class="ibe-filter-from-price-text ibe-region-from-price-text">
                    <span class="ibe-filter-from-price-text ibe-region-from-price">
                        {{ result.fromPrice.amount | currency: result.fromPrice.currency:'symbol':'1.2-2' }} +
                    </span>
                    <br>
                    {{ 'extras.per_night' | translate }}
                </p>
            </div>
            <p class="ibe-property-text" *ngIf="!result.property.location.addressLine2">
                {{result.property.name}}
            </p>
            <p
                class="ibe-property-text"
                *ngIf="result.property.location.addressLine2"
                [innerHTML]="'booking_search.region_results_propertyName_with_address' | translate:{
                    addressLine2: result.property.location.addressLine2,
                    propertyName: result.property.name
                }"
            ></p>
            <p
                *ngIf="result.property.description"
                class="ibe-filter-description ibe-region-description"
            >
                {{ result.property.description }}
            </p>
        </div>
    </div>
</div>

<div class="ibe-no-room-results" *ngIf="noAvailability$ | async">
    <p class="ibe-no-room-results-text">{{ 'room_results.no_availability' | translate }}</p>

    <div
        class="ibe-filter-availability-calendars ibe-region-availability-calendars"
        *ngIf="noAvailabilityShowCalendar$ | async"
    >

        <div *ngIf="propertyCalendar">
            <ibe-react-property-calendar [properties]="getFilterProperties()"></ibe-react-property-calendar>
        </div>

        <div *ngIf="!propertyCalendar">
            <div *ngFor="let property of regionProperties">
                <ibe-availability-calendar [property]="property"></ibe-availability-calendar>
            </div>
        </div>
    </div>
</div>
