import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddExtrasToReservationParams, Invoice } from 'app/fixme-inline-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReservationModel } from 'up-ibe-types';
import { ReservationStatusEnum } from '../../enums';
import { environment } from '../../environments/environment';
import { GuestAuthService } from './guest-auth.service';
import { IbeConfigService } from './ibe-config.service';

@Injectable()
export class ReservationService {
    constructor(
        private readonly http: HttpClient,
        private readonly config: IbeConfigService,
        private readonly guestAuthService: GuestAuthService,
    ) { }

    public getGuestReservations() {
        let httpParams = new HttpParams()
            .set('pmsGuestId', this.guestAuthService.getPmsGuestId());

        for (const property of this.config.properties) {
            httpParams = httpParams.append('propertyId', property.pmsId);
        }

        return this.http.get(`${environment.serverUrl}/api/ibe/guest/reservations`, {
            params: httpParams,
        });
    }

    public filterReservationsByStatuses(
        reservations: ReservationModel[],
        statuses: ReservationStatusEnum[],
    ) {
        return reservations.filter((reservation) => {
            if (reservation.status) {
                return statuses.includes(ReservationStatusEnum[reservation.status]);
            }
            return false;
        });
    }

    public getReservationInvoice(params: {
        id: string,
        propertyId: string
    }) {
        const httpParams = new HttpParams()
            .set('id', params.id)
            .set('propertyId', params.propertyId);

        return this.http.get(`${environment.serverUrl}/api/ibe/guest/reservation/invoice`, {
            params: httpParams,
            responseType: 'blob',
        }).pipe(map((response: Blob) => {
            return new Blob([response], { type: 'application/pdf' });
        }));
    }

    public downloadInvoice(bookingReference: string, invoiceId: string) {
        return this.http
            .get(
                `${environment.serverUrl}/api/ibe/bookings/${bookingReference}/invoices/${invoiceId}/download`,
                { responseType: 'blob' },
            )
            .pipe(
                map(
                    (response: Blob) => {
                        return new Blob([response], { type: 'application/pdf' });
                    },
                ),
            );
    }

    public getReservationInvoices(bookingReference: string) {
        return this.http.get<Invoice[]>(`${environment.serverUrl}/api/ibe/bookings/${bookingReference}/invoices`);
    }

    public addExtrasToReservation(params: AddExtrasToReservationParams): Observable<boolean> {
        return this.http
            .post<boolean>(`${environment.serverUrl}/api/ibe/guest/reservation/extras`, params);
    }
}
