import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { captureException, Scope } from '@sentry/browser';
import { ErrorSource } from 'app/error-dialog/error-mapping';
import {
    After3DsCompleteResult,
    AfterAlternativePaymentCompleteResponse,
    MycheckWallet,
    MycheckWalletConfig,
    PaymentResponseData,
    ProcessPaymentParams,
    SetPaymentParams,
} from 'app/fixme-inline-types';
import { environment } from '../../../../environments/environment';
import { ErrorDialogService } from '../../../services/error-dialog.service';
import { ErrorHandlerService, tryInitializeSentry } from '../../../services/error-handler.service';
import { IbeConfigService } from '../../../services/ibe-config.service';
import { LocalStorageService } from '../../../services/local-storage.service';

declare const mycheckWallet: MycheckWallet;

@Component({
    selector: 'ibe-mycheck-payment',
    templateUrl: './mycheck-payment.component.html',
    styleUrls: ['./mycheck-payment.component.scss'],
})
export class MycheckPaymentComponent implements OnInit {
    @Input('paymentSetupData') public paymentSetupData: {
        refreshToken: string,
        paymentAmount: number,
        publicKey: string,
        merchantAccount: string,
        paymentCurrency: string
    };
    @Input('prePaymentAmount') public prePaymentAmount: number;
    @Input('bookingRequestId') public bookingRequestId: string;
    @Input('isLoading') public isLoading: boolean;
    @Input('paymentRedirected') public paymentRedirected: boolean;
    // tslint:disable-next-line:no-any
    @Output('onComplete') public onComplete: EventEmitter<any> = new EventEmitter();
    @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
    @Output('togglePaymentRedirected') public togglePaymentRedirected: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('confirmPaymentBtn', { static: true }) public confirmPaymentBtnRef: ElementRef;
    @ViewChild('invalidDetailsTxt') public invalidDetailsTxtRef: ElementRef;
    @ViewChild('mywalletWrap', { static: true }) public mywalletWrapRef: ElementRef;

    // FIXME: DO NOT use public properties
    public processPaymentParams: ProcessPaymentParams;
    public isFormValid: boolean;
    public paymentResponse: PaymentResponseData;
    public isAlternativePayment: boolean;

    constructor(
        // FIXME: DO NOT use public properties
        public readonly errorDialogService: ErrorDialogService,
        private readonly http: HttpClient,
        private readonly translate: TranslateService,
        private readonly ibeConfig: IbeConfigService,
        private readonly localStorageService: LocalStorageService,
    ) {
    }

    public ngOnInit() {
        this.setupWidget();
    }

    public setupWidget() {
        if (!mycheckWallet) {
            throw new Error('The Mycheck Wallet Checkout SDK script has not been loaded');
        }

        const config: MycheckWalletConfig = {
            refreshToken: this.paymentSetupData.refreshToken,
            publishableKey: this.paymentSetupData.publicKey,
            acceptedCards: ['visa', 'mastercard', 'diners', 'discover', 'elo'],
            events: {
                onReady: () => {
                    this.toggleIsLoading.emit(false);
                },
                onInitFailure: (error: unknown) => {
                    this.handleError(error, 'http', 'MyCheckPaymentComponent.setupWidget', {});
                },
                afterFormIsReady: (value: { isValid: boolean }) => {
                    if (!value.isValid) {
                        this.isFormValid = false;
                        this._toggleConfirmPaymentDisabled(true);
                    } else {
                        this.isFormValid = true;
                        this._toggleConfirmPaymentDisabled(false);
                    }
                },
                afterSelectCreditCard: () => {
                    this._toggleConfirmPaymentDisabled(false);
                },
                after3DsComplete: (result: After3DsCompleteResult) => {
                    if (result.status === 'SUCCESS') {
                        return this.onPaymentComplete();
                    }
                    return this._onPaymentError('get_card_error');
                },
                alternativePaymentSelected: (value: { serviceName: string }) => {
                    const idealService = 'AdyenIdeal';
                    if (value.serviceName === idealService) {
                        this.isAlternativePayment = true;
                        this._toggleConfirmPaymentDisabled(false);
                    }
                },
                afterAlternativePaymentComplete: async (response: AfterAlternativePaymentCompleteResponse) => {
                    try {
                        if (response && response.status === 'SUCCESS') {
                            this.localStorageService.setAdyenTransactionId(response.transaction_id);
                            this.processPayload();
                        } else {
                            this._onPaymentError('ideal_payment_failed');
                        }
                        return;
                    } catch (err) {
                        this.toggleIsLoading.emit(false);
                        return this._onPaymentError('generic_error');
                    }
                },
            },
        };

        if (!this.prePaymentAmount) {
            this.prePaymentAmount = this.paymentSetupData.paymentAmount;
        }

        if (this.ibeConfig.accountAmexEnabled) {
            config.acceptedCards = config.acceptedCards.concat('amex');
        }

        if (!this.prePaymentAmount) {
            this.prePaymentAmount = this.paymentSetupData.paymentAmount;
        }

        // related: instead of saying "show iDEAL / don't show iDEAL"
        // we have to send [] to hide iDEAL if it's flexible, and undefined
        // if it is prepaid flex, so iDEAL shows
        const nominalPrePayAmount = 0.02;
        if (this.prePaymentAmount <= nominalPrePayAmount) {
            config.acceptedAlternativeWallets = [];
        }

        mycheckWallet.init('mywalletSdk', config);
    }

    public getCard() {
        this._toggleConfirmPaymentDisabled(true);
        if (this.isAlternativePayment) {
            try {
                const params: SetPaymentParams = {
                    currency: this.paymentSetupData.paymentCurrency,
                    amount: String(this.prePaymentAmount),
                    return_url: `${window.location.origin}${window.location.pathname}#/checkout/payment`,
                    callback_url: `${environment.serverUrl}/api/payment/mycheck/adyen/result?bookingRequestId=${this.bookingRequestId}`,
                };
                mycheckWallet.setPayment(params);
            } catch (error) {
                this.handleError(error, 'http', 'MyCheckPaymentComponent.setPayment', {});
            }

            mycheckWallet
                .getCardToken()
                .catch((error) => this.handleError(error, 'http', 'MyCheckPaymentComponent.getCardToken', {}));

            alert(this.translate.instant('checkout_payment.do_not_close_tab'));
        } else {
            mycheckWallet
                .getCard()
                .then(
                    (card) => {
                        this.processPaymentParams = {
                            cardLast4Digits: card.last_4_digits,
                            cardCode: card.credit_type,
                            expiryMonth: card.exp_month,
                            expiryYear: card.exp_year4,
                            token: card.token,
                        };
                        this.isFormValid = true;
                        this.processPayment();
                    },
                    (error: Error) => {
                        this.isFormValid = false;
                        this.logError(error, 'http', 'MyCheckPaymentComponent.getCard', {});
                        this._toggleConfirmPaymentDisabled(false);
                    },
                )
                .catch(error => this.handleError(error, 'http', 'MyCheckPaymentComponent.getCard', {}));
        }
    }

    public processPayment() {
        if (this.isFormValid) {
            // FIXME: share ibe type ... ProcessPaymentRequest !?!?
            const body = {
                token: this.processPaymentParams.token,
                cardCode: this.processPaymentParams.cardCode,
                cardLast4Digits: this.processPaymentParams.cardLast4Digits,
                amount: this.paymentSetupData.paymentAmount,
                prePaymentAmount: this.prePaymentAmount,
                merchantAccount: this.paymentSetupData.merchantAccount,
                paymentCurrency: this.paymentSetupData.paymentCurrency,
                bookingRequestId: this.bookingRequestId,
            };
            this.http.post(`${environment.serverUrl}/api/ibe/process-payment`, body)
                .subscribe(this.handleProcessPaymentResponse, this.handleProcessPaymentError);

            this.toggleIsLoading.emit(true);
        }
    }

    private handleProcessPaymentError = (error: unknown) => {
        console.error('processPayment', error);
        this.toggleIsLoading.emit(false);
        this._toggleConfirmPaymentDisabled(false);
        this.errorDialogService.errorDialog('Payment Process Failed', ErrorSource.MYCHECK);
    };
    private handleProcessPaymentResponse = (response: { success: boolean; data: PaymentResponseData }) => {
        if (response.success) {
            this.paymentResponse = response.data;
            if (response.data.awaitingConfirmation) {
                return; // wait for 3DS redirect
            }
            return this.onPaymentComplete();
        } else {
            // FIXME payment failed ???
        }
    };

    public processPayload(): void {
        const localTransactionId = this.localStorageService.getAdyenTransactionId();

        if (!localTransactionId) {
            return this._onPaymentError('ideal_payment_failed');
        }

        const idealCardCode = 'IDL';
        this.paymentResponse = {
            transactionId: localTransactionId,
            cardCode: idealCardCode,
        };
        this.onPaymentComplete();
    }

    public onPaymentComplete() {
        this.paymentRedirected = true;
        this.togglePaymentRedirected.emit(this.paymentRedirected);
        return this.onComplete.emit({
            ...this.processPaymentParams,
            ...this.paymentResponse, // must overwrite 'token' in this.processPaymentParams, as that one is wrong
            amount: this.paymentSetupData.paymentAmount,
            propertyPaymentProviderSettings: {
                merchantAccount: this.paymentSetupData.merchantAccount,
                paymentCurrency: this.paymentSetupData.paymentCurrency,
            },
        });
    }

    private _toggleConfirmPaymentDisabled(value: boolean) {
        this.confirmPaymentBtnRef.nativeElement.disabled = value;
    }

    private _onPaymentError(errorCode: string) {
        this.isLoading = false;
        this.errorDialogService.errorDialog(errorCode, ErrorSource.MYCHECK, true);
    }

    // tslint:disable-next-line:no-any
    private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
        this.logError(error, type, category, details);

        this.errorDialogService.errorDialog('unknown', ErrorSource.MYCHECK, true);
    }

    // tslint:disable-next-line:no-any
    private logError(error: any, type: string, category: string, details: { [p: string]: any }) {
        const hasSentry = tryInitializeSentry();
        if (hasSentry) {
            const err = ErrorHandlerService.toError(error);
            details.error = error;
            captureException(err, (scope: Scope) =>
                scope.addBreadcrumb({
                    type,
                    level: 'fatal',
                    message: `${err.message}`,
                    category,
                    data: details,
                }),
            );
        } else {
            console.error('handleError', error);
        }
    }
}
