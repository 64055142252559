import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarStayDateSelectionEvent } from 'app/fixme-inline-types';
import { IbeConfigService } from 'app/services/ibe-config.service';
import * as moment from 'moment';
import { bookingSearchPopUpAnimation } from '../../../animations';

@Component({
    selector: 'ibe-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss', '../ibe-form-control.scss'],
    animations: [bookingSearchPopUpAnimation],
})
export class DateInputComponent {
    @Input() public arrivalValue: Date;
    @Input() public departureValue: Date;
    @Input() public startDate: moment.Moment;
    @Output() public onStayDateSelection = new EventEmitter<CalendarStayDateSelectionEvent>();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public showDateRangePicker = false;

    constructor(
        private readonly config: IbeConfigService,
    ) { }

    public toggleDateRangePicker() {
        this.showDateRangePicker = !this.showDateRangePicker;
    }

    public formatDateRange(arrival: Date, departure: Date) {
        const start = moment(arrival).locale(this.config.language).format('ddd DD MMM');
        const end = moment(departure).locale(this.config.language).format('ddd DD MMM');
        return `${start} - ${end}`;
    }

    public stayDatesChanged($event: CalendarStayDateSelectionEvent) {
        this.onStayDateSelection.emit($event);
    }

    public handleEnterKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.toggleDateRangePicker();
        }
    }
}
