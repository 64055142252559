<h2 mat-dialog-title>
    {{ data.title }}
</h2>

<mat-dialog-content>
    <p *ngIf="data.message">
        {{ data.message }}
    </p>
    <p *ngIf="data.description">
        <small>{{ data.description }}</small>
    </p>

    <ng-container *ngIf="data.details && data.details.length">
        <div *ngFor="let error of data.details">
            <h4>{{error.title}}</h4>
            <div class="ibe-error">
                <span class="ibe-error-message" [innerHTML]="error.message"></span>
                <span class="ibe-rate-info-btn" *ngIf="error.description" matTooltip="{{error.description}}">i</span>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
        mat-dialog-close
        *ngIf="!data.allowRetry"
        class="ibe-btn ibe-close-btn"
    >
        {{ 'dialog.close' | translate }}
    </button>
    <button
        mat-dialog-close
        *ngIf="data.allowRetry && data.details"
        class="ibe-btn ibe-cancel-btn"
    >
        {{ 'dialog.cancel_reservation' | translate }}
        <i class="ibe-icon ibe-icon-close-circle"></i>
    </button>
    <button
        *ngIf="data.allowRetry && data.details"
        class="ibe-btn ibe-retry-btn"
        (click)="onRetry()"
    >
        {{ 'dialog.update_reservation' | translate }}
        <i class="ibe-icon ibe-icon-tick-circle"></i>
    </button>

    <button class="ibe-btn ibe-retry-btn" *ngIf="data.allowRetry && !data.details" (click)="onRetry()">
        {{ 'dialog.retry' | translate }}
    </button>
</mat-dialog-actions>
