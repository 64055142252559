<div *ngIf="noError && isAccountAuthenticated">
    <h1>{{ 'guest_management_auth.welcome' | translate }}</h1>
    <p>{{ 'guest_management_auth.account_authorised' | translate }}</p>
    <a href="#/guest/manage">{{ 'guest_management_auth.login_here' | translate }}</a>
</div>

<div *ngIf="!noError">
    <h1>{{ 'guest_management_auth.account_not_found' | translate }}</h1>
    <a href="#" (click)="backToSearch()">
        {{ 'error.go_back' | translate }}
    </a>
</div>
