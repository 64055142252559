import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReservationClassInterface } from 'app/fixme-inline-types';
import { ExtrasDialogService } from 'app/services/extras-dialog.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { ToasterService } from 'app/services/toaster.services';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ExtraModel, MonetaryValueModel, ReservationModel } from 'up-ibe-types';
import { ReservationStatusEnum } from '../../enums';
import { RemovalModalComponent } from '../booking/cart/removal-modal/removal-modal.component';
import { AnalyticsService } from '../services/analytics.service';
import { BookingService } from '../services/booking.service';
import { IbeConfigService } from '../services/ibe-config.service';
import { ImagesService } from '../services/images.service';
import { asNumber, asString } from '../helpers/type.helper';

@Component({
    selector: 'ibe-reservation-card',
    templateUrl: './reservation-card.component.html',
    styleUrls: [ './reservation-card.component.scss' ],
})
export class ReservationCardComponent implements OnInit {
    @Input() public reservationKey: number;
    @Input() public reservation: ReservationModel;
    @Input() public showButtons = true;
    @Input() public isInCart = false;
    @Input() public showCancellationPolicy = true;
    @Input() public showIsCancelled = true;
    @Input() public showAddExtrasButton = true;
    @Input() public displayInclusiveExtrasAsTaxes = false;

    @Output('onRemoveReservation') public onRemoveReservation: EventEmitter<string> = new EventEmitter();
    @Output('onExtrasChange') public onExtrasChange: EventEmitter<string> = new EventEmitter();

    public get totalGrossAmount(): MonetaryValueModel {
        return {
            amount: asNumber(this.reservation?.totalGrossAmount?.amount, 0),
            currency: asString(this.reservation?.totalGrossAmount?.currency, ''),

        };
    }

    public get totalBaseAmount(): MonetaryValueModel {
        return {
            amount: asNumber(this.reservation?.totalBaseAmount?.amount, 0),
            currency: asString(this.reservation?.totalBaseAmount?.currency, ''),
        };
    }

    // FIXME: DO NOT use public properties
    public numberOfNights: number;
    public arrivalDate: string;
    public departureDate: string;

    constructor(
        // FIXME: DO NOT use public properties
        public readonly config: IbeConfigService,
        private readonly bookingService: BookingService,
        private readonly toasterService: ToasterService,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly imagesService: ImagesService,
        private readonly analyticsService: AnalyticsService,
        private readonly dialog: MatDialog,
        private readonly extrasDialogService: ExtrasDialogService,
        private readonly localStorageService: LocalStorageService,
    ) {
    }

    public ngOnInit(): void {
        this.numberOfNights = this.calculateNumberOfNights(this.reservation.arrival, this.reservation.departure);
        this.arrivalDate = this.formatDate(this.reservation.arrival);
        this.departureDate = this.formatDate(this.reservation.departure);
    }

    public getMonthlyRent(): number {
        const metaData = this.localStorageService.getStaceyMetaData();

        return metaData
            ? Math.ceil(metaData.internal.avgPricePerSliceWithoutExtras.grossAmount * 30)
            : 0;
    }

    public removeReservation(): void {
        this.dialog
            .open(RemovalModalComponent)
            .afterClosed()
            .subscribe(
                (closeAction) => {
                    if (closeAction === 'confirm') {
                        if (this.bookingService.removeReservationFromBooking(this.reservationKey)) {
                            this.onRemoveReservation.emit();
                            this.toasterService.showSuccess(
                                this.translate.instant('offer_card.room_removed'),
                                this.translate.instant('offer_card.room_successfully_removed'),
                            );
                            this.analyticsService.createRoomExtrasRemoveFromCartEvent(this.reservation);
                            this.analyticsService.createRoomRemoveFromCartEvent(this.reservation);
                        }

                        const reservations = this.bookingService.getReservations();
                        if (reservations.length === 0) {
                            this.router
                                .navigate([ 'booking/results' ], { queryParamsHandling: 'preserve' })
                                .catch((navError) => console.error('navigation failed: /booking/results', navError));
                        }
                    }
                },
            );
    }

    public addExtras(): void {
        const params = {
            propertyId: this.reservation.property.id,
            ratePlanId: this.reservation.ratePlan.id,
            arrival: moment(this.reservation.arrival).format('YYYY-MM-DD'),
            departure: moment(this.reservation.departure).format('YYYY-MM-DD'),
            adults: this.reservation.adults.toString(),
            childrenAges: this.reservation.childrenAges.toString(),
        };

        this.extrasDialogService
            .open(params, this.reservation.ratePlan.id, this.reservation.property.id, this.reservation.extras)
            .subscribe(
                (data) => {
                    const selected: ExtraModel[] = data[1];
                    if (!_.isEqual(selected, this.reservation.extras)) {
                        if (this.bookingService.saveExtrasToReservation(this.reservationKey, selected)) {
                            this.toasterService.showSuccess(
                                this.translate.instant('offer_card.extras_saved'),
                                this.translate.instant('offer_card.extras_successfully_saved'),
                            );
                            this.onExtrasChange.emit();
                        }
                    }
                },
                (error) => console.error(error),
            );
    }

    public removeExtra(extra: ExtraModel) {
        if (this.bookingService.removeExtraFromReservation(this.reservationKey, extra.id)) {
            this.toasterService.showSuccess(
                this.translate.instant('offer_card.extra_removed'),
                this.translate.instant('offer_card.extra_successfully_removed'),
            );
            this.onExtrasChange.emit();
        }
        this.analyticsService.createExtraRemoveFromCartEvent(this.reservation.property, extra, this.reservation.adults);
    }

    private calculateNumberOfNights = (arrivalDate: string, departureDate: string) => {
        const arrival = moment(arrivalDate);
        const departure = moment(departureDate);
        return departure.diff(arrival, 'days');
    };

    public getUnitTypeImageUrl(unitTypeId: string) {
        return this.imagesService.getUnitTypeImageUrl(this.reservation.property.id, unitTypeId);
    }

    public reservationClass(reservation: ReservationModel) {
        const classes: ReservationClassInterface = {
            'has-extras': Boolean(reservation.extras && reservation.extras.length),
            'show-add-extras-button': this.showAddExtrasButton,
            'single-reservation': reservation.hasOwnProperty('booker'),
        };

        return Object.keys(classes)
            .filter(key => classes[key])
            .join(' ');
    }

    public get isCancelled() {
        return this.reservation.status === ReservationStatusEnum.Canceled;
    }

    public hasCancellationFee() {
        return !(_.isEmpty(_.pick(this.reservation, [ 'cancellationFee.fee.amount' ])));
    }

    public cancellationDescription() {
        return _.get(this.reservation, [ 'cancellationFee', 'description' ]);
    }

    public cancellationAmount() {
        return _.get(this.reservation, [ 'cancellationFee', 'fee', 'amount' ]);
    }

    public cancellationCurrency() {
        return _.get(this.reservation, [ 'cancellationFee', 'fee', 'currency' ]);
    }

    private formatDate(date: string) {
        const momentDate = moment(date);
        momentDate.locale(this.config.language);
        return momentDate.format('DD MMM YYYY');
    }
}
