import { Property } from 'up-ibe-types';

export function getVariationsInCurrentLanguage(
    property: Property,
    filterType: 'regions' | 'categories',
    currentLanguage: string,
): string[] {
    const propertyAttribute = property[filterType] || undefined;

    const variations: string[] = [];
    if (propertyAttribute) {
        propertyAttribute.forEach((element) => {
            const variationInCurrentLanguage = element.variations.find(
                (variation: { language: { value: string } }) => variation.language.value === currentLanguage,
            );
            if (variationInCurrentLanguage) {
                variations.push(variationInCurrentLanguage.name.trim());
            }
        });
    }

    return variations;
}
