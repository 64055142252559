/* tslint:disable:max-file-line-count quotemark */
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyIbeAvailabilityCalendar } from '@detco/my-ibe-availability-calendar/dist/lib/MyIbeAvailabilityCalendar';
import { MyIbeCalendarNav } from '@detco/my-ibe-availability-calendar/dist/lib/MyIbeCalendarNav';
import {
    MyIbeAvailabilityCalendarDayLabel,
    MyIbeAvailabilityCalendarTranslation,
    MyIbeCalenderDay,
    OptionalDate,
    ValidWeekDay,
} from '@detco/my-ibe-availability-calendar/dist/lib/types';
import { TranslateService } from '@ngx-translate/core';
import { StayCriteriaModel } from 'app/types';
import { addDays, getWeekOfMonth, parseISO } from 'date-fns';

import { de, es, fi, fr, nl, ru, sv } from 'date-fns/locale';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { ReplaySubject } from 'rxjs';
import { Property } from 'up-ibe-types';
import { IbeConfigService } from '../../services/ibe-config.service';
import {
    CalendarDay,
    CalendarDayData,
    CalendarStayDateSelectionEvent,
    CheapestAvailabilityData,
} from './calendar/calendar';

const languages = { de, fr, sv, fi, ru, nl, es };
const daysPerWeek = 7;

const isoDate = (date: Date | string): string => {
    return moment(date).format('YYYY-MM-DD');
};

const fixAvailability = (availabilityDay: CalendarDayData): MyIbeCalenderDay => {
    const restrictions = {
        // define default restrictions - fallback for undefined values
        minLengthOfStay: 0,
        maxLengthOfStay: 0,
        closed: false,
        closedOnArrival: false,
        closedOnDeparture: false,
        // override with restrictions in response data
        ...availabilityDay.restrictions,
    };
    const date = new Date(availabilityDay.date);
    const dayOfMonth = date.getDate();
    const weekday = (date.getDay() || daysPerWeek) as ValidWeekDay; // Sunday is 7 not 0
    const week = getWeekOfMonth(date, {
        weekStartsOn: 1, // weeks start on monday
    });

    return {
        week,
        weekday,
        dayOfMonth,
        date: isoDate(date),
        price: availabilityDay.price ?? 0,
        currency: availabilityDay.currency,
        isAvailable: availabilityDay.isAvailable,
        allowArrival: false,
        allowDeparture: false,
        restrictions,
    };
};

@Component({
    selector: 'ibe-react-property-calendar',
    templateUrl: './react-property-calendar.component.html',
    styleUrls: ['./react-property-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReactPropertyCalendarComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('ibeReactPropertyCalendar') public ibeReactCalendar: ElementRef;

    @Input('properties') public properties: Property[];
    @Input('arrivalDate') public arrivalDate: Date | undefined;
    @Input('departureDate') public departureDate: Date | undefined;
    @Input('hoveredDepartureDate') public hoveredDepartureDate: Date | undefined;
    @Input('currentMonth') public currentMonth: moment.Moment;
    @Input('potentialAvailabilitySpanSubject') public potentialAvailabilitySpanSubject: ReplaySubject<CalendarDay[]>;

    @Output('onArrivalDateSelection') public onArrivalDateSelection: EventEmitter<Date> = new EventEmitter();
    @Output('onDepartureDateSelection') public onDepartureDateSelection: EventEmitter<Date> = new EventEmitter();
    @Output('onDepartureDateHover') public onDepartureDateHover: EventEmitter<Date> = new EventEmitter();
    @Output('onStayDateSelection') public onStayDateSelection: EventEmitter<CalendarStayDateSelectionEvent> = new EventEmitter();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public calendarDates: Record<string, MyIbeCalenderDay[]> = {};
    public smallestMinLengthOfStay: number;
    public currentMonthDisplay: string;

    private reactRoot: Root | undefined = undefined;
    private readonly translation: MyIbeAvailabilityCalendarTranslation;
    private numberOfDays = 14;

    private startDate: string;
    private adults: number;
    private childrenAges: number[];

    // never show less than seven days
    private readonly minimumDaysToShow = 7;
    // the minimum width in pixel a calendar day requires
    private readonly minDayWith = 68;
    // fallback width if the calendar container width is unknown
    private readonly defaultWidth = 1024;
    // the width of the property label
    private readonly propertyLabelWidth = 0;

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly currentRoute: ActivatedRoute,
        private readonly translate: TranslateService,
        private readonly config: IbeConfigService,
    ) {
        // This is required to 'force' import React, without being unused
        if (React.version) {
            this.translation = this.initTranslations();
            this.startDate = isoDate(new Date());
            this._checkRequiredQueryParams();
        }
    }

    public ngOnInit() {
        const queryParams = this.currentRoute.snapshot.queryParams as StayCriteriaModel;
        this.startDate = isoDate(queryParams.arrival);
        this.adults = queryParams.adults;
        this.childrenAges = queryParams.childrenAges;
        this.numberOfDays = this.getNumberOfDays();

        this.loadAvailabilities();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    public ngAfterViewInit() {
        this.mountReact();
        this.onResize();
    }

    public ngOnDestroy() {
        this.reactRoot?.unmount();
    }

    @HostListener('window:resize', ['$event'])
    public onResize() {
        const newNumberOfDays = this.getNumberOfDays();
        if (`${this.numberOfDays}` !== `${newNumberOfDays}`) {
            this.numberOfDays = newNumberOfDays;
            this.loadAvailabilities();
        }

        this.render();
    }

    private getNumberOfDays = (): number => {
        const width = this.ibeReactCalendar?.nativeElement?.clientWidth || this.defaultWidth;
        const daysToLoad = ((width - this.propertyLabelWidth) / this.minDayWith);
        return Math.max(this.minimumDaysToShow, Math.floor(daysToLoad));
    };

    private setStartDate = (start: string) => {
        this.startDate = start;
        this.loadAvailabilities();
    };

    private render() {
        const { properties, calendarDates, translation, startDate } = this;
        if (properties) {
            const endDate = isoDate(addDays(parseISO(startDate), this.numberOfDays - 1));
            this.mountReact();

            this.reactRoot?.render(
                <div>
                    <MyIbeCalendarNav
                        dateFrom={startDate}
                        dateEnd={endDate}
                        translation={translation}
                        onChange={this.setStartDate}
                        dateFormat="yyyy-MM-dd"
                    />
                    {properties.map((property) => {
                        const dates = calendarDates[property.pmsId] || [];
                        return (
                            <MyIbeAvailabilityCalendar
                                key={property.pmsId}
                                property={property}
                                availabilities={dates}
                                translation={this.translation}
                                variant="property"
                                imageBaseUrl={environment.imagesUrl}
                                onChange={(checkIn: OptionalDate, checkOut: OptionalDate) => {
                                    this.onRangeChanged(
                                        property.pmsId,
                                        this.cleanDate(checkIn),
                                        this.cleanDate(checkOut),
                                    );
                                }}
                            />
                        );
                    })}
                </div>,
            );
        }
    }

    private onRangeChanged = (propertyPmsId: string, checkIn: OptionalDate, checkOut: OptionalDate) => {
        if (checkIn !== null && checkOut !== null) {
            const queryParams: StayCriteriaModel = {
                propertyId: propertyPmsId,
                arrival: checkIn,
                departure: checkOut,
                adults: this.adults,
                childrenAges: this.childrenAges,
            };

            this.router
                .navigate(['booking/results'], { queryParams })
                .then(/* ignored */);
        }
    };

    private mountReact() {
        if (this.ibeReactCalendar && this.ibeReactCalendar.nativeElement && !this.reactRoot) {
            this.reactRoot = createRoot(this.ibeReactCalendar.nativeElement);
        }
    }

    private _fetchAvailabilityData(pmsPropertyId: string, date: string) {
        const queryParams = this.currentRoute.snapshot.queryParams;
        const children = queryParams.childrenAges ? queryParams.childrenAges.length : 0;

        const start = moment(date);
        const now = moment();
        const fromDate = start.isBefore(now) ? now : start;

        let params = new HttpParams()
            .set('from', fromDate.format('YYYY-MM-DD'))
            .set('to', moment(fromDate).add(this.numberOfDays, 'days').format('YYYY-MM-DD'))
            .set('propertyId', pmsPropertyId)
            .set('adults', queryParams.adults)
            .set('children', children.toString());

        if (queryParams.promoCode) {
            params = params.set('promoCode', queryParams.promoCode);
        }

        return this.http.get(`${environment.serverUrl}/api/ibe/cheapest-availability-per-day`, {
            params,
        });
    }

    private _generateCalendarDates(propertyId: string, calendarDayData: CalendarDayData[], numberOfDays: number): void {
        let sort = calendarDayData
            .map(dayData => fixAvailability(dayData))
            .sort((a, b) => (a.date <= b.date ? -1 : 1));

        if (sort.length > numberOfDays) {
            sort = sort.splice(0, numberOfDays);
        }

        this.calendarDates[propertyId] = sort;

        this.render();
    }

    private _checkRequiredQueryParams = (): void => {
        const queryParams = this.currentRoute.snapshot.queryParams;
        if (!queryParams.propertyId || !queryParams.adults || !queryParams.arrival || !queryParams.departure) {
            // noinspection JSIgnoredPromiseFromCall
            this.router.navigateByUrl('/booking/search');
        }
    };

    private loadAvailabilities() {
        const { properties } = this;
        if (properties) {
            properties.forEach(property => {
                const propertyId = property.pmsId;
                this._fetchAvailabilityData(propertyId, this.startDate)
                    .subscribe(
                        (cheapestAvailability: CheapestAvailabilityData) => {
                            this.smallestMinLengthOfStay = cheapestAvailability.smallestMinLos || 0;
                            this._generateCalendarDates(
                                propertyId,
                                cheapestAvailability.dayData,
                                this.numberOfDays,
                            );
                        },
                        (error) => console.error('av-calendar:', error),
                    );
            });
        }
    }

    private cleanDate(date: OptionalDate): null | string {
        return (date === null || date === undefined) ? null : isoDate(date);
    }

    private initTranslations(): MyIbeAvailabilityCalendarTranslation {
        const dayLabel: MyIbeAvailabilityCalendarDayLabel = {
            monday: this.translate.instant('date_format.short.monday'),
            tuesday: this.translate.instant('date_format.short.tuesday'),
            wednesday: this.translate.instant('date_format.short.wednesday'),
            thursday: this.translate.instant('date_format.short.thursday'),
            friday: this.translate.instant('date_format.short.friday'),
            saturday: this.translate.instant('date_format.short.saturday'),
            sunday: this.translate.instant('date_format.short.sunday'),
        };

        // @ts-ignore
        const locale: Locale | undefined = languages[this.config.language] ?? undefined;

        return {
            dayLabel,
            closedForArrival: this.translate.instant('property_calendar.closed_on_arrival'),
            closedForDeparture: this.translate.instant('property_calendar.closed_on_departure'),
            closed: this.translate.instant('property_calendar.closed'),
            minStay: this.translate.instant('property_calendar.min_length_of_stay'),
            maxStay: this.translate.instant('property_calendar.max_length_of_stay'),
            locale,
            displayDateFormat: this.translate.instant('date_format.display_date'),
        };
    }
}
