<div class="" #mywalletWrap [hidden]="isLoading">
    <div class="ibe-wallet-wrap">
        <div id="mywalletSdk"></div>
    </div>
    <div class="ibe-mycheck-footer">
        <button
            class="ibe-btn ibe-primary-btn ibe-mycheck-complete-btn"
            (click)="getCard()"
            #confirmPaymentBtn
            disabled
        >
            {{ 'checkout_payment.confirm_payment' | translate }}
        </button>
    </div>
</div>
