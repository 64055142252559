import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogProps } from 'app/fixme-inline-types';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ErrorSource, mapErrorCodes } from '../error-dialog/error-mapping';

@Injectable()
export class ErrorDialogService {
    constructor(
        private readonly translate: TranslateService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
    ) {}

    public errorDialog(errorCode: string, errorSource: ErrorSource, reloadPage?: boolean): void {
        const error = mapErrorCodes(errorCode, errorSource);
        const config: ErrorDialogProps = {
            title: this.translate.instant(`dialog_error_codes.${error}.title`),
            message: this.translate.instant(`dialog_error_codes.${error}.message`),
            allowRetry: false,
        };

        this.showErrorDialog(config, (reloadPage === true));
    }

    public showErrorDialog(config: ErrorDialogProps, reloadPage: boolean): void {
        this.dialog
            .open(ErrorDialogComponent, { data: config })
            .afterClosed()
            .subscribe((): void => {
                    if (!this.router.url.includes('manage') || reloadPage) {
                        this._reloadPageOnError();
                    }
                },
            );
    }

    private _reloadPageOnError() {
        /* We include a conditional because clients often use weird path names
          that affects the angular route hashing and may redirect them to a non ibe page.
          It is safer to use window.location.href = paymentPath, to redirect clients in that case
        */
        const paymentPath = `${window.location.origin}${window.location.pathname}#/checkout/payment`;
        if (window.location.href === paymentPath) {
            window.location.reload();
        } else {
            window.location.href = paymentPath;
        }
    }
}
