import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { RegionAndPropertyId } from 'app/fixme-inline-types';
import { Property } from 'up-ibe-types';

@Component({
    selector: 'ibe-city-selector',
    templateUrl: './city-selector.component.html',
    styleUrls: ['./city-selector.component.scss'],
})
export class CitySelectorComponent implements OnInit {
    @Input('properties') public properties: Property[];
    @Output('onCityAndPropertySelection') public onCityAndPropertySelection: EventEmitter<RegionAndPropertyId> = new EventEmitter();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public cities: string[] = [];
    public currentProperties: Property[] = [];
    public form: FormGroup;

    // wording is inaccurate - this is a city filter
    // we keep 'region' as url.query and formField.name (to stay compatible with existing deep links!)
    public readonly cityFieldName = 'region';

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
    ) {
        // tslint:disable-next-line:no-any
        const controls: any = {};
        controls[this.cityFieldName] = ['All', [Validators.required]];
        controls['propertyId'] = ['All', [Validators.required]];

        this.form = this.formBuilder.group(controls);
    }

    public ngOnInit() {
        this.currentProperties = this.properties;
        this.initCitySelectorValues();
        this.setCityAndProperty();
    }

    private selectedCityValue() {
        return this.form.controls[this.cityFieldName].value;
    }

    private selectedPropertyValue() {
        return this.form.controls['propertyId'].value;
    }

    public setCityAndProperty() {
        this.route.queryParams.subscribe((params: Params) => {
            const city = this.cities.find((value) => (value === params.region));
            if (city) {
                this.form.controls[this.cityFieldName].setValue(params.region);
                this._filterPropertiesByCity();
            }
            if (params.propertyId) {
                this.form.controls['propertyId'].setValue(params.propertyId);
            }
        });
    }

    public onPropertySelection() {
        this._emitValues();
    }

    public onCitySelection() {
        if (this.selectedCityValue() === 'All') {
            this.currentProperties = this.properties;
        } else {
            this._filterPropertiesByCity();
        }
        this._emitValues();
    }

    private _emitValues() {
        this.onCityAndPropertySelection.emit({
            region: this.selectedCityValue(),
            propertyId: this.selectedPropertyValue(),
        });
    }

    private initCitySelectorValues() {
        this.cities = this.properties
            .map(this.propertyToCityName)
            .filter(this.removeEmpty)
            .filter(this.filterUnique)
            .sort();
    }

    public _filterPropertiesByCity() {
        const selectedCity = this.selectedCityValue();
        if (selectedCity !== 'All') {
            this.currentProperties = this.properties.filter(property => {
                return property.location?.city?.trim() === selectedCity?.trim();
            });
        }

        // if the region is changed we need to make sure the property is in the region
        // else change to all properties
        const selectedProperty = this.currentProperties.find(property => {
            return this.selectedPropertyValue() === property.id;
        });
        if (!selectedProperty) {
            this.form.controls['propertyId'].setValue('All');
        }
    }

    private removeEmpty = <T>(value: T) => !!value;
    private filterUnique = <T>(value: T, index: number, array: T[]): boolean => (array.indexOf(value) === index);
    private propertyToCityName = (property: Property): string => (property.location?.city ? property.location.city.trim() : '');
}
