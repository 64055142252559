<label (click)="toggleDateRangePicker()">
    {{ 'booking_search.arrival_date' | translate }} - {{ 'booking_search.departure_date' | translate }}
</label>

<span
    class="ibe-form-control ibe-date-input"
    #ibeDateInputRef
    [ngClass]="{'ibe-date-input-active': showDateRangePicker}"
    (click)="toggleDateRangePicker()"
    (keypress)="handleEnterKeyboardEvent($event)"
    tabindex="0"
>
    {{ formatDateRange(arrivalValue, departureValue) }}
</span>

<ibe-date-range-picker
    *ngIf="showDateRangePicker"
    [arrival]="arrivalValue"
    [departure]="departureValue"
    [startDate]="startDate"
    (onStayDateSelection)="stayDatesChanged($event)"
    (closePopup)="toggleDateRangePicker()"
    [@bookingSearchPopUpAnimation]
></ibe-date-range-picker>
