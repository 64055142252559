import { Injectable } from '@angular/core';
import { ToasterConfig, ToasterService as Toaster } from 'angular2-toaster';

@Injectable()
export class ToasterService {
    private _toasterConfig: ToasterConfig = new ToasterConfig(
        {
            showCloseButton: false,
            tapToDismiss: true,
            timeout: 5000,
            animation: 'flyRight',
        },
    );

    constructor(
        private readonly toasterService: Toaster,
    ) {}

    public showSuccess = (title: string, body: string): void => {
        this.toasterService.pop('success', title, body);
    };

    public showError = (title: string, body: string): void => {
        this.toasterService.pop('error', title, body);
    };

    get toasterConfig(): ToasterConfig {
        return this._toasterConfig;
    }
}
