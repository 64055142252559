/* tslint:disable:max-file-line-count max-line-length */
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import { FormControl } from '@angular/forms';
import { BookingOrReservationEnum } from 'enums';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import {
    AmountModel,
    AvailabilityResultModel,
    AvailabilityResultRateModel,
    BookerModel,
    CreateBookingResponseModel,
    ExtraModel,
    GuestTitle,
    Property,
    ReservationModel,
    Settings,
    TaxModel,
    TermsAndConditions,
} from 'up-ibe-types';

// todo: shouldn't need this
export const NOT_FOUND_TEXT = 'Not Found';

// FIXME
export type Language = keyof TermsAndConditions;

export type SelectedRateId = {
    rateId: string
}

export type CompleteBookingResponseModel = CreateBookingResponseModel & {
    pdf?: string
}

export type SaferpaySetupData = {
    ResponseHeader: {
        SpecVersion: string,
        RequestId: string
    },
    Token: string,
    Expiration: string,
    RedirectUrl: string
}

export type AdyenDropinSetupData = {
    payload: string
    paymentMethods: PaymentMethod[]
    clientKey: string
    merchantAccount: string
    paymentCurrency: string
    amount: number
    countryCode: string
    currency: string
    bookingOrReservationId?: string
}

export type Step = {
    handle: string
    title: string
    number: number
    route: string
}

export interface StepClass {
    [key: string]: boolean;
}

// DO NOT extend shared types
export type FixmeIbeSettings = Settings & {
    forceCommercialPurpose: boolean
}

export interface ComponentCanDeactivate {
    canDeactivate(): boolean | Observable<boolean>;
}

export interface AddExtrasToReservationParams {
    propertyId: string;
    reservationId: string;
    reservation: ReservationModel;
}

export interface Invoice {
    id: string;
    type: string;
    created: string;
}

export interface ReservationDetailParams {
    id: string;
    propertyId: string;
}

export interface ReservationClassInterface {
    'has-extras': boolean;
    'show-add-extras-button': boolean;
    'single-reservation': boolean;

    [key: string]: boolean;
}

export interface PersonsQtyData {
    adults: number,
    childrenAges: number[]
}

export interface RegionAndPropertyId {
    region: string;
    propertyId: string;
}

export interface PropertiesByCity {
    city: string;
    properties: Property[];
}

export interface OnAddToCartEvent {
    availabilityResult: AvailabilityResultModel,
    availabilityResultRate: AvailabilityResultRateModel,
    selectedUnitQty: number;
}

export interface StayCriteriaModel {
    propertyId: string;
    arrival: string;
    departure: string;
    adults: number;
    childrenAges: number[];
    region?: string;
    category?: string;
}

export interface ErrorDialogData {
    price?: string;
    ratePlan?: string;
    description?: string;
}

export interface ExcludedRoutes {
    [key: string]: boolean;
}

export enum BookingError {
    BookingFailed = 'booking_failed',
    NoAvailability = 'no_availability',
    PaymentError = 'payment_error',
    BookingProblem = 'booking_problem',
    InvalidPmsData = 'invalid_pms_data'
}

export interface ExtraQueryParams {
    params: {
        propertyId: string;
        ratePlanId: string;
        arrival: string;
        departure: string;
        adults: string;
        // tslint:disable-next-line:no-any
        childrenAges?: any;
    };
}

export interface BookingErrorDetails {
    error: {
        success: boolean;
        errorCodes: BookingError[];
    };
}

// FIXME: remove maybe description
export type ErrorDetails = {
    title: string;
    message: string;
    description?: string;
}
export type ErrorDialogProps = {
    title: string
    message?: string
    description?: string
    allowRetry: boolean
    details?: ErrorDetails[]
};

export interface CountriesResponse {
    [key: string]: string;
}

export interface DialogData {
    bookingOrReservation: string;
    bookingOrReservationId: string;
    guestDetails: BookerModel;
    guestTitleFieldEnabled: boolean;
    propertyId?: string;
}

export interface GuestDetailsDialogResponse {
    updateSuccess: boolean;
    guestDetails: BookerModel;
}

export interface BookingTotals {
    netTotal: number;
    cityTaxTotal: number;
    touristTaxTotal: number;
    salesTaxTotal: number;
    taxTotal: number;
    grossTotal: number;
    serviceChargeTotal: number;
    breakdown: TaxModel[];
    additionalIncludedExtrasBreakdown: ExtraModel[];
}

export interface StatesResponse {
    US: {
        [key: string]: string;
    };
    CA: {
        [key: string]: string;
    };
}

export interface AddressField {
    name: string;
    controlArgs: [ string, FormControl ];
}

export interface DateRestrictions {
    closed: boolean;
    closedOnArrival: boolean;
    closedOnDeparture: boolean;
    minLengthOfStay: number;
    maxLengthOfStay: number;
}

export interface BookingManagementParams {
    id: string | null;
    lastName: string | null;
    propertyId: string | null;
    showExtras: string | null;
}

// ---------------------------------------------------------------------------------------------------------------------

/** @deprecated Type is useless and also invalid */
export interface AdyenDropInState {
    data: { [key: string]: string },
    isValid: boolean
}

export interface WebhookResponseAmount {
    value: number;
    currency: string;
}

export interface WebhookResponseJournal {
    pspReference: string;
    amount: WebhookResponseAmount;
    eventCode: string;
    dateTime: string;
    isSuccess: boolean;
    reason: string;
}

export interface WebhookResponse {
    pspReference: string;
    amount: WebhookResponseAmount;
    merchantReference: string;
    merchantAccountCode: string;
    originalFolioId: string;
    propertyId: string;
    journal: WebhookResponseJournal[];
}

export interface AdyenDropinComponent {
    props: {
        type: string,
        [key: string]: unknown
    };

    handleAction(action: { [key: string]: string }): void;
}

export interface AdyenPaymentPayload {
    propertyPaymentProviderSettings?: {
        merchantAccount: string;
        paymentCurrency: string;
    };
    PaRes?: string;
    MD?: string;
    redirectResult?: string;
    bookingReference?: string;
}

/** @deprecated Type is almost useless and also invalid */
export interface AdyenResponse {
    resultCode: string,
    reason: string,
    action?: { [key: string]: string },
    pspReference?: string
}

export interface InitiatePaymentPayload {
    data: { [key: string]: string },
    bookingRequestId: string,
    merchantAccount: string
}

export interface PaymentSetupRequestData {
    bookingOrReservation: string;
    bookingOrReservationId: string;
    ibeLanguage: string;
    ibeUrl: string;
    propertyId?: string;
}

/** @deprecated remove maybe 'something' 'if this than that' types */
export type PaymentSetupData = {
    propertyId: string
    // TODO combine this to something {identifier, pmsBookingId | pmsReservationId | ibeBookingRequestId}
    bookingOrReservation: BookingOrReservationEnum
    bookingOrReservationId: string
    bookingRequestId: string
}

export type  PaymentDialogData = PaymentSetupData & {
    paymentRedirected: boolean
    hideDirectPaymentMethods: boolean
}

// ---------------------------------------------------------------------------------------------------------------------
export interface GoogleAnalyticsProductObject {
    name: string | undefined;
    id: string;
    // extras do not have variants
    variant?: string;
    price: number;
    quantity: number;
    brand: string;
}

// ---------------------------------------------------------------------------------------------------------------------
export interface CalendarDay {
    date: Moment;
    data: CalendarDayData;
    hasRestrictions: boolean;
    hasAvailability?: boolean;
}

export interface CalendarDayData {
    date: Date;
    price: number;
    restrictions: CalendarDayRestrictions | undefined;
    currency: string;
    isAvailable: boolean;

    // FIXME: hack for broken calendar
    allowDeparture: boolean;
}

export interface CalendarDayRestrictions {
    closed: boolean;
    closedOnArrival: boolean;
    closedOnDeparture: boolean;
    minLengthOfStay?: number;
    maxLengthOfStay?: number;
}

export interface CheapestAvailabilityData {
    dayData: CalendarDayData[];
    smallestMinLos?: number;
}

export interface CalendarStayDateSelectionEvent {
    arrivalDate: Date;
    departureDate: Date;
}

export interface DayClass {
    [key: string]: boolean;
}

export interface ProcessPaymentParams {
    token: string;
    expiryMonth: string;
    expiryYear: string;
    cardCode: string;
    cardLast4Digits: string;
}

export interface WalletCard {
    last_4_digits: string;
    first_6_digits: string;
    credit_type: string;
    exp_month: string;
    exp_year4: string;
    source: string;
    token: string;
}

export interface PaymentResponseData {
    maskedCardNumber?: string;
    token?: string;
    orderId?: string;
    transactionId?: string;
    awaitingConfirmation?: boolean;
    cardCode?: string;
}

export interface After3DsCompleteResult {
    status: string;
    method?: string;
    reason?: string;
    transaction_id?: string;
}

// see https://developer.mycheckapp.com/Wallet/Implementing%20the%20wallet.html
export interface MycheckWalletConfig {
    // The refresh token been created at the backend with the secret key that been granted at the onboarding process
    // and users identifier
    refreshToken: string;
    // The publishable key for this environment, been granted at the onboarding process
    publishableKey: string;
    // The accepted credit cards on your site.
    // Options: 'amazonpay', 'amex', 'applepay', 'cirrus', 'diners-club', 'diners', 'discover', 'elo', 'jcb',
    //          'maestro', 'mastercard', 'paypal', 'solo', 'switch', 'unionpay', 'visa'
    acceptedCards: string[];
    events: {
        // will be triggered when the wallet first initiates (the init function starts)
        onInit?: () => void,
        // This event will occur in case the user deleted any payment method */
        afterDeleteCreditCard?: () => void,
        // will be triggered when an alternative payment has been selected
        alternativePaymentSelected?: (data: unknown) => void,
        // Will return user data received from alternative payment methods. supported for MasterPass and Visa Checkout
        userData?: (data: unknown) => void,
        // This event will occur when the user closed the "manage payment methods" section / modal */
        exitedManagePaymentMethod?: Function,
        // will be triggered when the gift card checkbox is marked	*/
        giftCardTrigger?: Function,
        // will be triggered when the wallet is been fully loaded, onReady: () => console.log('wallet is loaded') */
        onReady?(): void,
        // Returns users credit card token, use this token with the billing system to make payments on the card,
        // this callback fires when user adds a new credit card, selecting card from the list,
        // or using an alternative payment method
        afterSelectCreditCard: (token: string) => void,
        // will return { isValid: true } if the credit card form been filled but the card hasn't been added yet,
        // Also may return { isValid: false } In case the user deletes characters
        afterFormIsReady?: (value: { isValid: boolean }) => void,
        // will be triggered when the wallet is not loaded */
        onInitFailure: (error: unknown) => void,
        // Used for 3DS transactions, will return {status: "SUCCESS", transaction_id: "d86ca.....6520df06c2"},
        // status FAILURE also possible
        after3DsComplete?: (value: After3DsCompleteResult) => void,
        // Will return the transaction information after complete,
        // example: {method: "SALE", status: "SUCCESS", transaction_id: "ALIPAY-Ir5JEqV0mcQiVg-1561985422"} */
        afterAlternativePaymentComplete?: (value: { method: string, status: string, transaction_id: string }) => void
    };
    // The alternative payment methods to support, needs to be pre configured by MyCheck
    // Options: 'masterpass','visacheckout','amexcheckout', 'applepay', 'amazonpay', 'googlepay', 'paypal', 'alipay',
    // 'wechat', 'adyen'
    acceptedAlternativeWallets?: string[];
    // Only if the alternative payment methods section should be render on different id on the page
    alternativeAtId?: string;
    // Whether to focus on the first input of the add card form when the wallet is loaded: (Boolean) - default:1
    autoFocus?: boolean;
    // Sets the wallet's language. default: en
    // For other languages, please contact MyCheck with a request to add your choice of language.
    local?: string;
}

export interface AfterAlternativePaymentCompleteResponse {
    method: string;
    status: string;
    transaction_id: string;
}

export interface SetPaymentParams {
    currency: string;
    amount: string;
    return_url?: string;
    callback_url?: string;
}

export interface MycheckWallet {
    init(id: string, config: MycheckWalletConfig): void;

    getCard(): Promise<WalletCard>;

    getCardToken(): Promise<string>;

    setPayment(params: SetPaymentParams): void;
}

export interface DetailsData {
    title?: GuestTitle;
    firstName?: string;
    lastName: string;
    email?: string;
    phone?: string;
    travelPurpose?: string;
    guestComment?: string[] | string;
    company?: {
        companyName?: string;
        companyTaxId?: string;
    };
}

export interface AddressData {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    postalCode?: string;
    countryCode?: string;
    termsConditions?: boolean;
    marketingConsent?: boolean;
}

export interface SearchFormValues {
    arrival: Date;
    departure: Date;
    adults: number;
    promoCode: string;
    propertyId: string;
    childrenAges: number[];
    region: string;
}

export type StaceyInternalMeta = {
    avgPricePerSliceWithoutExtras: AmountModel
}

export type StaceyMetaData = {
    internal: StaceyInternalMeta
}
