import { Component, Input } from '@angular/core';
import { asString, hasProperty, isObject } from '../../helpers/type.helper';

export type GuestCreditCard = {
    paymentMethod: string;
    cardNumber: string;
    cardName?: string;
    expiryMonth?: string;
    expiryYear?: string;
}

@Component({
    selector: 'ibe-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent {
    @Input('card') public card: GuestCreditCard;

    get getCardLastFourDigits() {
        const cardNumber = isObject(this.card) && hasProperty(this.card, 'cardNumber')
            ? asString(this.card.cardNumber, '')
            : '';

        return cardNumber ? cardNumber.slice(-4) : '****';
    }
}
