<div class="ibe-guest-password-reset">
    <div class="ibe-login-link">
        <svg id="Icons" class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <g id="arrow-right">
                <line
                    id="arrow-stem"
                    x1="8.036"
                    y1="23.964"
                    x2="40.036"
                    y2="23.964"
                    stroke-width="3"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="none"
                />
                <polyline
                    id="arrowhead"
                    points="20 36 7.964 23.964 20 11.929"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                />
            </g>
        </svg>
        <a (click)="goToGuestManagement()">{{ 'guest_management_auth.login' | translate }}</a>
    </div>
    <h1 class="ibe-password-reset-title">
        {{ 'guest_management_auth.guest_password_reset' | translate }}
    </h1>
    <form #passwordResetFormDirective="ngForm" [formGroup]="passwordResetForm" *ngIf="!resetToken">
        <div class="ibe-form-group">
            <label for="emailInput">{{ 'guest_management_auth.email_address' | translate }}</label>
            <input
                type="text"
                formControlName="email"
                id="emailInput"
                class="ibe-form-control"
                [ngClass]="{'is-invalid': isFormControlInvalid(passwordResetForm.controls.email, passwordResetFormDirective)}"
                placeholder="{{ 'guest_management_auth.email_address' | translate }}"
            >
            <ibe-form-validation-message
                [form]="passwordResetFormDirective"
                [control]="passwordResetForm.controls.email"
            ></ibe-form-validation-message>
        </div>
        <button
            class="ibe-btn ibe-primary-btn"
            (click)="resetPassword()"
        >{{ 'guest_management_auth.reset_password' | translate }}</button>
    </form>

    <form
        class="ibe-guest-complete-password-reset"
        #completePasswordResetFormDirective="ngForm"
        [formGroup]="completePasswordResetForm"
        *ngIf="resetToken"
    >
        <div class="ibe-form-group">
            <label for="passwordInput">{{ 'guest_management_auth.password' | translate }}</label>
            <input
                type="password"
                formControlName="password"
                id="passwordInput"
                class="ibe-form-control"
                [ngClass]="{'is-invalid': isFormControlInvalid(completePasswordResetForm.controls.password, passwordResetFormDirective)}"
                placeholder="{{ 'guest_management_auth.password' | translate }}"
            >
            <ibe-form-validation-message
                [form]="completePasswordResetFormDirective"
                [control]="completePasswordResetForm.controls.password"
            ></ibe-form-validation-message>
        </div>
        <div class="ibe-form-group">
            <label for="confirmPasswordInput">{{ 'guest_management_auth.password' | translate }}</label>
            <input
                type="password"
                formControlName="confirmPassword"
                id="confirmPasswordInput"
                class="ibe-form-control"
                [ngClass]="{'is-invalid': isFormControlInvalid(completePasswordResetForm.controls.confirmPassword, completePasswordResetFormDirective)}"
                placeholder="{{ 'guest_management_auth.confirm_password' | translate }}"
            >
            <ibe-form-validation-message
                [form]="completePasswordResetFormDirective"
                [control]="completePasswordResetForm.controls.confirmPassword"
            ></ibe-form-validation-message>
        </div>
        <button
            class="ibe-btn ibe-primary-btn"
            (click)="completeResetPassword()"
        >{{ 'guest_management_auth.reset_password' | translate }}</button>
    </form>
</div>
