import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDialogComponent } from 'app/error-dialog/error-dialog.component';
import { ToasterService } from 'app/services/toaster.services';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { FormValidationService } from '../../services/form-validation.service';
import { GuestAuthService } from '../../services/guest-auth.service';

export const accountNotAuthedMessage = 'Account created before email authentication was required';

@Component({
    selector: 'ibe-guest-login',
    templateUrl: './guest-login.component.html',
    styleUrls: ['./guest-login.component.scss'],
})
export class GuestLoginComponent implements OnInit {
    @ViewChild('guestLoginFormDirective', { static: true }) public guestLoginFormDirective: FormGroupDirective;

    // FIXME: DO NOT use public properties
    public guestLoginForm: FormGroup;
    public loading = false;

    constructor(
        // FIXME: DO NOT use public properties
        public readonly guestAuthService: GuestAuthService,
        public readonly dialogRef: MatDialogRef<GuestLoginComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
    ) {
    }

    public ngOnInit() {
        this.guestLoginForm = this.formBuilder.group({
            'username': ['', [Validators.required, Validators.email, FormValidationService.emailValidator]],
            'password': ['', [Validators.required]],
        });
    }

    public loginGuest() {
        if (this.guestLoginForm.valid) {
            this.loading = true;
            this.guestAuthService.loginGuest(this.guestLoginForm.value)
                .subscribe((response) => {
                    this.toasterService.showSuccess(
                        this.translate.instant('guest_management_auth.login_successful'),
                        '',
                    );

                    this.dialogRef.close(true);
                    this.loading = false;
                }, (error) => {
                    if (error.error.message === accountNotAuthedMessage) {
                        return this.dialog
                            .open(
                                ErrorDialogComponent,
                                {
                                    data: {
                                        title: this.translate.instant('guest_management_auth.authentication_required'),
                                        message: this.translate.instant('guest_management_auth.check_email'),
                                        allowRetry: false,
                                    },
                                },
                            )
                            .afterClosed()
                            .subscribe(
                                () => {
                                    this.router
                                        .navigate(['/guest/manage'])
                                        .catch((navErr) => console.error('navigation failed: /guest/manage', navErr));
                                },
                            );
                    }

                    this.toasterService.showError(
                        this.translate.instant('guest_management_auth.login_error'),
                        error.error.message,
                    );
                    this.loading = false;
                    return;
                });
        }
    }

    public isFormControlInvalid(formControl: AbstractControl | null) {
        return isFormControlInvalid(formControl, this.guestLoginFormDirective);
    }

    public createAccount() {
        this.dialogRef.close(false);
        this.router.navigate(['/account/create'])
            .catch((error) => console.error('navigation failed: /account/create', error));
    }

    public resetPassword() {
        this.dialogRef.close(false);
        this.router.navigate(['/guest/account/reset-password'])
            .catch((error) => console.error('navigation failed: /guest/account/reset-password', error));
    }

    public manageBooking() {
        try {
            this.dialogRef.close(false);
            this.router.navigate(['/booking/manage'])
                .catch((error) => console.error('navigation failed: /booking/manage', error));
        } catch (error) {
            console.error('manageBooking', error);
        }
    }
}
