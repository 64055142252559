import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatus } from 'up-ibe-types';

@Component({
    selector: 'ibe-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
    private errorCode: number = 0;
    get errorType(): string {
        return (this.errorCode === HttpStatus.INTERNAL_SERVER_ERROR) ? 'server_error' : 'not_found';
    }

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) { }

    public ngOnInit() {
        this.getErrorCode();
    }

    private getErrorCode() {
        this.route
            .queryParams
            .subscribe({
                next: (params) => { this.errorCode = parseInt(params.errorCode, 10); },
            });
    }

    public backToSearch() {
        this.router
            .navigate(['booking/search'])
            .catch((navError) => console.error('navigation failed: /booking/search', navError));
    }
}
