import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IbeConfigService } from '../ibe-config.service';

@Pipe({ name: 'currency' })
export class CustomCurrencyPipe implements PipeTransform {
    constructor(
        private readonly config: IbeConfigService,
    ) {
    }

    // FIXME: this tries to parse `EUR100` and then ?!? return `EUR 100`
    // - figure out what this code is supposed to do, or if it is just pure up-bullshit - again
    private formatPrice = (price: string): string => {
        const firstDigit = price.match(/\d/) as RegExpMatchArray;
        let symbol = price.slice(0, firstDigit.index);
        const amount = price.slice(firstDigit.index);
        if (symbol.length > 1) {
            symbol = symbol.concat(' ');
        }
        return symbol + amount;
    };

    public transform(
        value: number | undefined,
        currencyCode?: string,
        symbolDisplay?: string,
        digits?: string,
    ): string {
        const locale = this.getLocale(this.config.language);
        const currencyPipe = new CurrencyPipe(locale, 'GBP');
        const price = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);

        return (price)
            ? this.formatPrice(price)
            : '';
    }

    public getLocale(language: string): string {
        // This is based on the locales that have been imported at the top of app.module.ts.
        // Look in the top of app.module.ts
        // FIXME: if it is a GLOBAL ... use a GLOBAL ...  something like myIBE.defines.allowedLocales
        const allowedLocales = [
            'en', 'de', 'fi', 'se', 'ru', 'fr',
        ];

        return (allowedLocales.includes(language))
            ? language
            : 'en';
    }
}
