import { IbeEnvironmentConfig } from './typed-env';

export const environment: IbeEnvironmentConfig = {
    production: true,
    serverUrl: 'https://ibe-server.uphotel.agency',
    imagesUrl: 'https://s3-eu-west-1.amazonaws.com/images.ibe-production',
    translationFilesUrl: 'https://ibe.uphotel.agency/assets/i18n',
    paymentProviderScripts: {
        adyen: {
            test: 'https://checkoutshopper-test.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.1.6.3.min.js',
            live: 'https://checkoutshopper-live.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.1.6.3.min.js',
        },
        mycheck: {
            test: 'https://wallet-sdk-sandbox.mycheckapp.com/sdk.js',
            live: 'https://wallet-sdk.mycheckapp.com/sdk.js',
        },
    },
    subDomain: 'ibe',
    version: '0cd8b6c557c304c14e3f30d854cc40ad61f99fc7',
    sentry: {
        enabled: true,
        endpointUrl: 'https://c920ebd5fbcd46b7a13705cfe44ad1cc@o108473.ingest.sentry.io/4504083309068288',
        release: '0cd8b6c557c304c14e3f30d854cc40ad61f99fc7',
    },
    languages: ['en', 'de', 'fr', 'sv', 'fi', 'ru', 'nl', 'es', 'it'],
};
