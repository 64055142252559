<h1>
    {{ 'error.' + errorType | translate }}
</h1>

<p>
    {{ 'error.message.' + errorType | translate }}
</p>

<a href="#" (click)="backToSearch()">
    {{ 'error.go_back' | translate }}
</a>
