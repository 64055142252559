import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'app/services/local-storage.service';
import { ToasterService } from 'app/services/toaster.services';
import { BookingConfirmationResponseModel, BookingModel, ReservationModel } from 'up-ibe-types';
import { environment } from '../../../environments/environment';
import { BookingTotals } from '../../fixme-inline-types';
import { AnalyticsService } from '../../services/analytics.service';
import { BookingService } from '../../services/booking.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { ImagesService } from '../../services/images.service';

@Component({
    selector: 'ibe-booking-confirmation',
    templateUrl: './booking-confirmation.component.html',
    styleUrls: ['./booking-confirmation.component.scss'],
})
export class BookingConfirmationComponent implements OnInit, OnDestroy {
    // FIXME DO NOT USE PUBLIC PROPERTIES
    public isLoading = true;
    public bookings: BookingModel[];
    public bookingCurrency: string;
    public propertyId: string;
    public isLoggedIn: boolean;
    public areRatesSuppressed: boolean;

    get isGuestEmailsEnabled(): boolean {
        return this.config.settings.sendGuestEmailsEnabled;
    }

    get showGuestManageLink(): boolean {
        return this.config.settings.guestManagementEnabled && this.isLoggedIn;
    };

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly bookingService: BookingService,
        public readonly localStorageService: LocalStorageService,
        public readonly analyticsService: AnalyticsService,
        private readonly config: IbeConfigService,
        private readonly guestAuthService: GuestAuthService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly toasterService: ToasterService,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly imagesService: ImagesService,
        private readonly http: HttpClient,
    ) {
    }

    public ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const httpParams = new HttpParams()
                .set('bookingRequestId', params.bookingRequestId);

            return this.http.get(`${environment.serverUrl}/api/ibe/booking-confirmation`, {
                    params: httpParams,
                })
                .subscribe(
                    (response: BookingConfirmationResponseModel) => {
                        if (
                            'useRentalAgreement' in this.config.accountFeatureWhitelist
                            && this.config.accountFeatureWhitelist.useRentalAgreement
                        ) {
                            this.bookingService.clearBookingDataFromLocalStorage(true);
                        }
                        if (response.bookings) {
                            this.isLoading = false;
                            this.bookings = response.bookings;
                            this.areRatesSuppressed = !!(this.bookings.find(booking => {
                                return booking.reservations.find(reservation => reservation.suppressedRate);
                            }));
                            this.propertyId = response.propertyId;
                            this._setBookingCurrency();
                            this._fireCompletedReservationAnalyticsEvent(params.bookingRequestId);
                        }
                    },
                    (error) => {
                        console.error(error);
                        this.isLoading = false;
                        this.toasterService.showError(
                            this.translate.instant('booking_confirmation.booking_not_found'),
                            this.translate.instant('booking_confirmation.booking_not_found_for_booking_id'),
                        );
                        this.router.navigate(['/'])
                            .catch((navError) => console.error('navigation failed: /', navError));
                    });
        });
        this.isLoggedIn = this.guestAuthService.isLoggedIn();
    }

    public ngOnDestroy() {
        this.bookingService.clearBookingDataFromLocalStorage();
        this.bookingService.clearSearchCriteriaFromLocalStorage();
    }

    private _setBookingCurrency() {
        // we determine the currency code for the currency pipe from the booking reservation data
        if (this.bookings[0].reservations && this.bookings[0].reservations.length) {
            this.bookingCurrency = this.bookings[0].reservations[0].totalGrossAmount.currency;
        }
    }

    private _fireCompletedReservationAnalyticsEvent(bookingRequestId: string) {
        const firedBookingRequestId = this.localStorageService.getBookingRequestIdEventHasBeenFiredFor();

        if (!firedBookingRequestId || firedBookingRequestId !== bookingRequestId) {
            this.bookings.forEach((booking: BookingModel) => {
                this.analyticsService.createCompletedReservationsEvent(bookingRequestId, booking.reservations);
            });
            this.localStorageService.setBookingRequestIdEventHasBeenFiredFor(bookingRequestId);
        }
    }

    public getUnitTypeImageUrl(unitTypeId: string) {
        return this.imagesService.getUnitTypeImageUrl(this.propertyId, unitTypeId);
    }

    public calculateBookingTotals(reservations: ReservationModel[]): BookingTotals {
        return this.bookingService.calculateBookingTotals(reservations);
    }
}
