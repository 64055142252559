import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { ExtrasComponent } from 'app/extras/extras.component';
import { ExtraQueryParams } from 'app/fixme-inline-types';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ExtraModel } from 'up-ibe-types';
import { ExtrasStoreService } from './extras-store.service';
import { arrayIfEmpty } from './store/common';

@Injectable({
    providedIn: 'root',
})
export class ExtrasDialogService {
    private extras: Subject<ExtraModel[]> = new Subject<ExtraModel[]>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly extrasStore: ExtrasStoreService,
    ) { }

    public open(queryParams: Params, rateId: string, propertyId: string, selectedExtras: ExtraModel[] = [], skipOnBackdropClick = false) {
        const params: ExtraQueryParams = this._queryToExtraParams(queryParams, rateId, propertyId);

        const extras$: Observable<ExtraModel[]> = this.extrasStore.load(params);

        const dialog = this.dialog.open<ExtrasComponent>(ExtrasComponent, {
            data: {
                selectedExtras,
                propertyId,
                skipOnBackdropClick,
                extras: this.extras,
            },
            panelClass: 'up-ibe-extras',
            disableClose: true,
        });

        const hasSomeExtras$: Observable<boolean> = extras$.pipe(
            map((extras) => !!extras.length),
        );

        const selectedExtras$ = extras$
            .pipe(
                switchMap((extras: ExtraModel[]) => {
                    if (extras.length) {
                        this.extras.next(extras);

                        return dialog.afterClosed().pipe(arrayIfEmpty());
                    } else {
                        this.extras.next([]);
                        return of([]);
                    }
                }),
                take(1), // finalise as soon as we have 1 result, no need to unsubscribe
            );

        return combineLatest<[boolean, ExtraModel[]]>(hasSomeExtras$, selectedExtras$);
    }

    // tslint:disable-next-line:no-any
    private _queryToExtraParams(queryParams: Params, rateId: string, propertyId: any): ExtraQueryParams {
        return {
            params: {
                propertyId,
                ratePlanId: rateId,
                arrival: queryParams.arrival,
                departure: queryParams.departure,
                adults: queryParams.adults,
                childrenAges: (queryParams.childrenAges || []),
            },
        };
    }
}
