<div class="ibe-persons-qty-popup">
    <ibe-persons-qty-selector
        [initialValue]="personsQtyData.adults"
        [minimumValue]="1"
        [maximumValue]="maxNumberOfAdults"
        (onValueChange)="onAdultQtyChange($event)"
    >
        <span *ngIf="!isChildrenEnabled">
            {{ 'booking_search.persons' | translate }}
        </span>
        <span *ngIf="isChildrenEnabled">
            {{ 'booking_search.adults' | translate }}
        </span>
    </ibe-persons-qty-selector>

    <ibe-persons-qty-selector
        [initialValue]="personsQtyData.childrenAges.length"
        [minimumValue]="0"
        [maximumValue]="maxNumberOfChildren"
        (onValueChange)="onChildrenQtyChange($event)"
        *ngIf="isChildrenEnabled"
    >
        {{ 'booking_search.children' | translate }}
    </ibe-persons-qty-selector>

    <select
        *ngFor="let select of getChildAges, let i = index"
        [ngModel]="personsQtyData.childrenAges[i]"
        (change)="onChildrenAgeChange(i, $event.target.value)"
        class="ibe-custom-select ibe-child-age-select"
    >
        <option value="0" disabled>
            {{ 'booking_search.select_age_of_child' | translate }}
            {{ i + 1 }}
        </option>

        <option [value]="option" *ngFor="let option of generateChildrenAgeOptions()">
            {{ option }}
            {{ 'booking_search.years_old' | translate }}
        </option>
    </select>
</div>
