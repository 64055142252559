import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOnEnterAnimation = trigger('fadeInOnEnterAnimation', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('.5s', style({ opacity: 1 })),
    ]),
]);

export const flyRightOnLeaveAnimation = trigger('flyRightOnLeaveAnimation', [
    transition(':leave', [
        animate('.5s ease-out', style({ opacity: 0, transform: 'translateX(50px)' })),
    ]),
]);

export const scaleDownOnLeaveAnimation = trigger('scaleDownOnLeaveAnimation', [
    transition(':leave', [
        animate('.5s ease-out', style({ opacity: 0, transform: 'scale(0.2)' })),
    ]),
]);

export const scaleUpAnimation = trigger('scaleUpAnimation', [
    transition('* => completed', [
        animate('.5s ease-out', style({ opacity: 0, transform: 'scale(1.1)' })),
    ]),
]);

export const slideDownAnimation = trigger('slideDownAnimation', [
    state('active', style({ maxHeight: '200px' })),
    state('inactive', style({ maxHeight: 0, padding: 0, display: 'none' })),
    transition('inactive => active', animate('.5s ease-in-out')),
    transition('active => inactive', animate('.5s ease-in-out')),
]);

export const bookingSearchPopUpAnimation = trigger('bookingSearchPopUpAnimation', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10%)' }),
        animate('.2s ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('.1s ease-out', style({ opacity: 0, transform: 'translateY(-10%)' })),
    ]),
]);
