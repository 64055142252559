/* tslint:disable:max-file-line-count */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuestDetailsDialogResponse, Invoice, ReservationDetailParams } from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { GuestModel, IbeServerApiResponseModel, ReservationModel } from 'up-ibe-types';
import { BookingOrReservationEnum, GuaranteeTypeEnum, ReservationStatusEnum } from '../../../../enums';
import { environment } from '../../../../environments/environment';
import {
    BookingCancellationDialogComponent,
} from '../../../booking-management/booking-cancellation-dialog/booking-cancellation-dialog.component';
import {
    BookingPaymentDialogComponent,
} from '../../../booking-management/booking-payment-dialog/booking-payment-dialog.component';
import { ErrorDialogComponent } from '../../../error-dialog/error-dialog.component';
import { GuestDetailsDialogComponent } from '../../../guest-details-dialog/guest-details-dialog.component';
import { isGuestDataValid } from '../../../helpers/payment.helper';
import { BookingService } from '../../../services/booking.service';
import { GuestAuthService } from '../../../services/guest-auth.service';
import { IbeConfigService } from '../../../services/ibe-config.service';
import { ImagesService } from '../../../services/images.service';
import { ReservationService } from '../../../services/reservation.service';
import { AddExtrasDialogComponent } from '../../modify-reservation/add-extras-dialog/add-extras-dialog.component';

@Component({
    selector: 'ibe-reservation-detail',
    templateUrl: './reservation-detail.component.html',
    styleUrls: ['./reservation-detail.component.scss'],
})
export class ReservationDetailComponent implements OnInit {
    private reservationDetailParams: ReservationDetailParams;

    // FIXME: DO NOT USE public properties
    public reservation: ReservationModel;
    public isLoading = true;
    public numberOfNights: number;
    public isReservationUnModifiable = false;
    public isReservationFlexible = false;
    public paymentDetailsCanBeUpdated = false;
    public paymentDetailsCanBeAdded = false;
    public isTaxBreakdownOpen = false;
    public bookingTotals: { netTotal: number; taxTotal: number; grossTotal: number; };
    public invoices: Invoice[] = [];
    public paymentRedirected = false;

    constructor(
        // FIXME: DO NOT USE public properties
        public readonly dialog: MatDialog,
        private readonly guestAuthService: GuestAuthService,
        private readonly bookingService: BookingService,
        private readonly route: ActivatedRoute,
        private readonly http: HttpClient,
        private readonly reservationService: ReservationService,
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
        private readonly imagesService: ImagesService,
        private readonly router: Router,
    ) {
    }

    public ngOnInit() {
        this.route
            .queryParamMap
            .subscribe(
                (queryParams: ParamMap) => {
                    const id = queryParams.get('id');
                    const propertyId = queryParams.get('propertyId');
                    if (!id || !propertyId) {
                        this.router
                            .navigate(['/guest/reservations/history'])
                            .catch((navError) => console.error('navigation failed: /guest/reservations/history', navError));
                        return;
                    }
                    this.reservationDetailParams = { id, propertyId };
                    this._fetchReservationData();
                },
            );
    }

    public getUnitTypeImageUrl(unitTypeId: string) {
        return this.imagesService.getUnitTypeImageUrl(this.reservation.property.id, unitTypeId);
    }

    public formatDate(date: string) {
        const momentDate = moment(date);
        momentDate.locale(this.config.language);
        return `${momentDate.format('DD MMM YYYY')}`;
    }

    public onInvoicesFetched(invoices: Invoice[]) {
        this.invoices = invoices;
    }

    public openGuestDetailsDialog() {
        this.dialog.open(GuestDetailsDialogComponent, {
                data: {
                    bookingOrReservation: BookingOrReservationEnum.Reservation,
                    bookingOrReservationId: this.reservation.bookingReference,
                    guestDetails: this.reservation.primaryGuest,
                    propertyId: this.reservation.property.id,
                    guestTitleFieldEnabled: this.config.settings.checkoutFields.details.title,
                },
                panelClass: 'up-ibe-guest-details',
            })
            .afterClosed().subscribe((response: GuestDetailsDialogResponse) => {
            if (response.updateSuccess) {
                this.toasterService.showSuccess(
                    this.translate.instant('global.success'),
                    this.translate.instant('manage_booking.guest_updated_successfully'),
                );

                this.reservation.primaryGuest = {
                    ...response.guestDetails,
                };
            } else {
                this.toasterService.showError(
                    this.translate.instant('manage_booking.guest_update'),
                    this.translate.instant('manage_booking.guest_update_failed'),
                );
            }
        });
    }

    private _isReservationUnModifiable() {
        return (this.reservation.status !== ReservationStatusEnum.OnWaitingList)
            && (this.reservation.status !== ReservationStatusEnum.Confirmed);
    }

    private _isReservationFlexible() {
        return !!(
            this.reservation.guaranteeType
            && (this.reservation.guaranteeType !== GuaranteeTypeEnum.Prepayment)
        );
    }

    public openCancellationDialog() {
        let reservationId = this.reservation.id;
        if (this.config.isPmsStayntouch()) {
            reservationId = this.reservation.bookingReference;
        }
        this.dialog.open(BookingCancellationDialogComponent, {
            data: this.reservation,
        }).afterClosed()
            .subscribe((dialogResponse: boolean) => {
                if (dialogResponse) {
                    return this.http
                        .post(
                            `${environment.serverUrl}/api/ibe/cancel-reservation`,
                            {
                                propertyId: this.reservation.property.id,
                                bookingReference: this.reservation.bookingReference,
                                reservationId,
                            },
                        )
                        .subscribe(
                            (apiResponse: IbeServerApiResponseModel) => {
                                if (apiResponse.success) {
                                    this._fetchReservationData();
                                    this.toasterService.showSuccess(
                                        this.translate.instant('manage_booking.reservation_cancelled'),
                                        this.translate.instant('manage_booking.reservation_successfully_cancelled'),
                                    );
                                }
                            },
                            (error) => {
                                console.error('openCancellationDialog.subscribe.subscribe', error);
                                this.dialog.open(ErrorDialogComponent, {
                                    data: {
                                        title: this.translate.instant('dialog_error_codes.booking_cancel_error.title'),
                                        message: this.translate.instant('dialog_error_codes.booking_cancel_error.message'),
                                    },
                                });
                            },
                        );
                }
                return;
            });
    }

    public _fetchReservationData() {
        const httpParams = new HttpParams()
            .set('id', this.reservationDetailParams.id)
            .set('email', this.guestAuthService.getUsername())
            .set('propertyId', this.reservationDetailParams.propertyId);

        return this.http
            .get(`${environment.serverUrl}/api/ibe/guest/reservation`, { params: httpParams })
            .subscribe(
                (reservation: ReservationModel) => {
                    this.reservation = reservation;
                    this.isLoading = false;
                    this.bookingTotals = this.bookingService.calculateBookingTotals([this.reservation]);
                    this.numberOfNights = this.bookingService.calculateNumberOfNights(this.reservation.arrival, this.reservation.departure);
                    this.isReservationUnModifiable = this._isReservationUnModifiable();
                    this.isReservationFlexible = this._isReservationFlexible();
                    this.paymentDetailsCanBeUpdated = !this.isReservationUnModifiable && !!this.reservation.paymentAccount;
                    this.paymentDetailsCanBeAdded =
                        this.config.settings.paymentStepEnabled &&
                        !this.reservation.paymentAccount &&
                        !this.isReservationUnModifiable;

                    if (this.route.snapshot.queryParamMap.has('payload')) {
                        this.paymentRedirected = true;
                        this.openPaymentDialog();
                    }
                },
                (error) => {
                    console.error('_fetchReservationData.subscribe', error);
                    this.isLoading = false;
                    this.dialog
                        .open(
                            ErrorDialogComponent,
                            {
                                data: {
                                    title: this.translate.instant('dialog_error_codes.booking_not_found.title'),
                                    message: this.translate.instant('dialog_error_codes.booking_not_found.message'),
                                },
                            },
                        )
                        .afterClosed()
                        .subscribe(
                            () => { this.router.navigateByUrl('/guest/reservations/history').catch(console.error); },
                        );
                },
            );
    }

    public toggleTaxBreakdown() {
        this.isTaxBreakdownOpen = !this.isTaxBreakdownOpen;
    }

    public openPaymentDialog() {
        const isValid = isGuestDataValid(this.reservation.primaryGuest as GuestModel);
        if (!isValid) {
            this.openGuestDetailsDialog();
        } else {
            this.dialog
                .open(
                    BookingPaymentDialogComponent,
                    {
                        data: {
                            bookingOrReservation: BookingOrReservationEnum.Reservation,
                            bookingOrReservationId: this.reservation.bookingReference,
                            propertyId: this.reservation.property.id,
                            paymentRedirected: this.paymentRedirected,
                        },
                    },
                )
                .afterClosed()
                .subscribe(
                    (response) => {
                        if (response && response.paymentSuccess) {
                            this._fetchReservationData();
                        }
                        if (this.paymentRedirected) {
                            this.paymentRedirected = false;
                            this.router
                                .navigate([], { relativeTo: this.route, queryParams: this.reservationDetailParams })
                                .catch((navError) => console.error('navigation failed: []', navError));
                        }
                    },
                );
        }
    }

    public downloadInvoice() {
        this.reservationService.getReservationInvoice(this.reservationDetailParams)
            .subscribe((response: Blob) => {
                saveAs(response, 'invoice.pdf');
            }, (error: HttpErrorResponse) => {
                const httpForbiddenError = 403;
                const httpNotFoundError = 404;

                if (error.status === httpForbiddenError) {
                    this.openInvoiceErrorDialog('coming_soon');
                    return;
                }
                if (error.status === httpNotFoundError) {
                    this.openInvoiceErrorDialog('no_invoice');
                    return;
                }
                this.openInvoiceErrorDialog('get_invoice_error');
            });
    }

    public openAddExtrasDialog() {
        this.dialog.open(AddExtrasDialogComponent, {
            data: this.reservation,
        }).afterClosed().subscribe(success => {
            if (success) {
                this._fetchReservationData();
            }
        });
    }

    public goBack() {
        if (this.isReservationUnModifiable) {
            return this.router.navigateByUrl('/guest/reservations/history');
        }
        return this.router.navigateByUrl('/guest/reservations/modify');
    }

    private openInvoiceErrorDialog(type: string) {
        this.dialog.open(ErrorDialogComponent, {
            data: {
                title: this.translate.instant(`dialog_error_codes.${type}.title`),
                message: this.translate.instant(`dialog_error_codes.${type}.message`),
            },
        });
    }
}
