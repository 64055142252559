import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export type SaferpayPaymentDialogData = {
    url: string
}

@Component({
    selector: 'ibe-saferpay-payment-dialog',
    template: `<div></div>`,
})
export class SaferpayPaymentDialogComponent {
    public url: string | null;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: SaferpayPaymentDialogData,
        private sanitizer: DomSanitizer,
    ) {
        this.url = this.sanitizer.sanitize(SecurityContext.URL, this.data.url);
        location.href = this.url as string;
    }
}
