import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/services/toaster.services';
import { GuestAuthService } from '../../services/guest-auth.service';

@Component({
    selector: 'ibe-guest-management-header',
    templateUrl: './guest-management-header.component.html',
    styleUrls: ['./guest-management-header.component.scss'],
})
export class GuestManagementHeaderComponent implements OnInit {
    @Input('type') public type: string;
    @Input('isReservationUnModifiable') public isReservationUnModifiable: boolean;

    private guestUsername: string = '';
    get username(): string { return this.guestUsername; }

    constructor(
        // FIXME: DO NOT USE public properties
        public guestAuthService: GuestAuthService,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
    ) {
    }

    public ngOnInit() {
        this.guestUsername = this.guestAuthService.getUsername();
    }

    public logout() {
        this.guestAuthService.logout();
        this.router
            .navigate(['/'])
            .catch((navError) => console.error('navigation failed: /', navError));

        const title = this.translate.instant('guest_management_auth.logout_successful');
        this.toasterService.showSuccess(title, '');
    }

    public onReservationDetailsBackClick(): Promise<boolean> {
        const targetUrl = (this.isReservationUnModifiable)
            ? '/guest/reservations/history'
            : '/guest/reservations/modify';

        return this.router.navigateByUrl(targetUrl);
    }
}
