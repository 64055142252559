import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { BookingService } from './booking.service';

@Injectable()
export class RoomResultsGuardService implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly bookingService: BookingService,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        if (isEmpty(route.queryParams)) {
            const stayCriteriaParams = this.bookingService.getLastSearchedStayCriteria();

            if (stayCriteriaParams) {
                this.router
                    .navigate(['booking/results'], { queryParams: stayCriteriaParams })
                    .catch((navError) => console.error('navigation failed: /booking/results', navError));
            } else {
                this.router
                    .navigate(['booking/search'])
                    .catch((navError) => console.error('navigation failed: /booking/search', navError));

                return false;
            }
        }

        return true;
    }
}
