import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isFieldRequired } from '../helpers/form.helper';

@Component({
    selector: 'ibe-form-label',
    templateUrl: './form-label.component.html',
    styleUrls: ['./form-label.component.scss'],
})
export class FormLabelComponent implements OnInit {
    @Input() public labelText: string;
    @Input() public labelControl: AbstractControl;
    @Input() public labelControlName: string;

    public isRequired = false;

    public ngOnInit() {
        if (this.labelControl) {
            this.isRequired = isFieldRequired(this.labelControl);
        }
    }
}
