<h2 mat-dialog-title class="ibe-modal-title">{{ 'offer_removal_confirmation.confirm_removal' | translate }}</h2>
<mat-dialog-content>
    {{ 'offer_removal_confirmation.are_you_sure' | translate }}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="ibe-btn ibe-cancel-btn" (click)="close('cancel')">
        {{ 'offer_removal_confirmation.cancel' | translate }}
    </button>
    <button class="ibe-btn ibe-primary-btn ibe-confirm-btn" (click)="close('confirm')">
        {{ 'offer_removal_confirmation.continue' | translate }}
    </button>
</mat-dialog-actions>
