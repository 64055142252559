import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { ExcludedRoutes } from 'app/fixme-inline-types';

const excludedRoutes: ExcludedRoutes = {
    booking: true,
    search: true,
    results: true,
    checkout: true,
    details: true,
    address: true,
    payment: true,
    error: true,
    confirmation: true,
    guest: true,
    manage: true,
    edit: true,
    reservations: true,
    history: true,
    modify: true,
    account: true,
    create: true,
    'reset-password': true,
    'complete-reset-password': true,
    reservation: true,
};

@Injectable()
export class AnchorGuard implements CanActivateChild {
    public canActivateChild(route: ActivatedRouteSnapshot) {
        const routeUlrPath = route.url[0].path;
        const isExcluded = excludedRoutes[routeUlrPath];
        const elementFound = document.getElementById(routeUlrPath);

        if (isExcluded && elementFound) {
            console.error(`Conflicting Anchor found: ${routeUlrPath}`);
        }

        return (isExcluded || !elementFound);
    }
}
