import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Invoice } from 'app/fixme-inline-types';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { saveAs } from 'file-saver';
import { ReservationService } from '../../../services/reservation.service';

@Component({
    selector: 'ibe-invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit {
    @Input('bookingReference') public bookingReference: string;

    @Output() public invoicesFetched: EventEmitter<Invoice[]> = new EventEmitter();

    // FIXME: DO NOT use public properties
    public invoices: Invoice[] = [];
    public locale = 'en';

    constructor(
        private readonly reservationService: ReservationService,
        private readonly config: IbeConfigService,
    ) { }

    public async ngOnInit() {
        this.locale = this.config.language;

        // If the booking reference ends with -1 (apaleo) remove it.
        if (this.bookingReference && this.bookingReference.endsWith('-1')) {
            // To be safe, don't replace it only remove it from the end.
            const referenceLength = 2;
            this.bookingReference = this.bookingReference.substring(0, this.bookingReference.length - referenceLength);
        }

        this.reservationService.getReservationInvoices(this.bookingReference).subscribe((invoices: Invoice[]) => {
            // We emit the invoices so the parent can have access to them for logic.
            this.invoicesFetched.emit(invoices);
            this.invoices = invoices;
        });
    }

    public downloadInvoice(invoiceId: string) {
        this.reservationService.downloadInvoice(this.bookingReference, invoiceId).subscribe((response: Blob) => {
            saveAs(response, `${invoiceId}.pdf`);
        });
    }
}
