import { Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogComponent } from 'app/alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from 'app/error-dialog/error-dialog.component';
import { OnAddToCartEvent } from 'app/fixme-inline-types';
import { scrollToElement } from 'app/helpers/scroll.helper';
import { asNumber } from 'app/helpers/type.helper';
import { AnalyticsService } from 'app/services/analytics.service';
import { AvailabilityStoreService } from 'app/services/availability-store.service';
import { BookingService, Cart } from 'app/services/booking.service';
import { ExtrasDialogService } from 'app/services/extras-dialog.service';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { ImagesService } from 'app/services/images.service';
import {
    AvailabilitySearchParams,
    INVALID_PARAMS,
    SearchParamsService,
} from 'app/services/room-results/search-params.service';
import { ToasterService } from 'app/services/toaster.services';
import { WindowLocationService } from 'app/services/window-location.service';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, map, mapTo, startWith, tap } from 'rxjs/operators';
import { AvailabilityResultModel, EmbeddedItemModel, ExtraModel, Property, UnitTypeModel } from 'up-ibe-types';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';

type ExtraDialogEventHandler = ([ hasSomeExtras, selectedExtras ]: readonly [ boolean, ExtraModel[] ]) => void;

@Component({
    selector: 'ibe-room-results',
    templateUrl: './room-results.component.html',
    styleUrls: [ './room-results.component.scss' ],
})
export class RoomResultsComponent implements OnInit, OnDestroy {
    @ViewChild('roomResultsContainer') set roomResultsContainer(elementRef: ElementRef) {
        if (elementRef && this.config.settings.autoscrollEnabled) {
            scrollToElement(elementRef);
        }
    }

    @Output('onLoadingEmit') public onLoadingEmit: EventEmitter<Observable<boolean>> = new EventEmitter();
    @HostBinding('class.no-availability') public noAvailabilityClass = false;

    private searchParams: AvailabilitySearchParams | null;

    get propertyId(): string { return this.searchParams?.params.propertyId ?? ''; }
    get promoCode(): string { return this.searchParams?.params.promoCode ?? ''; }

    private loading$: Observable<boolean>;
    private selectedUnitQty: number;

    // FIXME: DO NOT USE public properties
    public property: EmbeddedItemModel;
    public descriptionId: string;
    public availability$: Observable<AvailabilityResultModel[]>;
    public noAvailability$: Observable<boolean>;
    public noAvailabilityShowCalendar$: Observable<boolean>;
    public subscription: Subscription;
    public showAvailabilityCalendar = false;
    public displayInclusiveExtrasAsTaxes = false;

    public get isAvailabilityCalendarEnabled(): boolean { return this.config.settings.availabilityCalendarEnabled; }

    constructor(
        // FIXME: DO NOT USE public properties
        public readonly availabilityStoreService: AvailabilityStoreService,
        public readonly config: IbeConfigService,
        public readonly windowLocationService: WindowLocationService,
        private readonly analyticsService: AnalyticsService,
        private readonly bookingService: BookingService,
        private readonly dialog: MatDialog,
        private readonly extrasDialogService: ExtrasDialogService,
        private readonly imagesService: ImagesService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly searchParamsService: SearchParamsService,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
    ) {
    }

    private onError = (error: Error): void => {
        console.error('RoomResultsComponent', error);
        throw error;
    };

    public ngOnInit() {
        const validParams$: Observable<AvailabilitySearchParams> = this.searchParamsService.validParams(this.route.queryParams);

        this.config.getCurrentPropertySubscribable().subscribe(this.onPropertyChanged);

        this.availability$ = this.availabilityStoreService.availability$(validParams$);
        this.loading$ = merge<boolean, boolean>(
            validParams$.pipe(mapTo(true)),
            this.availability$.pipe(mapTo(false)),
        ).pipe(
            startWith(true),
            catchError(_ => of(false)),
        );
        this.onLoadingEmit.emit(this.loading$);

        this.noAvailability$ = this.availability$.pipe(
            map((availability) => availability.length < 1),
            tap(
                (noAvailability: boolean): void => {
                    this.noAvailabilityClass = noAvailability;
                },
            ),
        );

        this.noAvailabilityShowCalendar$ = this.noAvailability$.pipe(
            map((noAvailability) => noAvailability && this.config.settings.availabilityCalendarEnabled),
            startWith(false),
        );

        this.subscription = combineLatest(validParams$, this.availability$).subscribe({
            next: this.onSearchParamsOrAvailabilityChanged,
            error: this.onSearchOrAvailabilityError,
        });
    }

    private onSearchParamsOrAvailabilityChanged: (
        [ searchParams, availability ]: readonly [ AvailabilitySearchParams, AvailabilityResultModel[] ],
    ) => void = ([ searchParams, availability ]) => {
        this.updateSearchParams(searchParams);
        this.updateAvailabilityResult(availability);
    };

    private updateSearchParams = (search: AvailabilitySearchParams): void => {
        if (search.params.propertyId !== undefined) {
            // FIXME why does a component set GLOBAL state --> random components should not set 'config' values
            this.config.setCurrentProperty(search.params.propertyId);
        }
        this.searchParams = search;
    };

    private updateAvailabilityResult = (availability: AvailabilityResultModel[]): void => {
        if (this.searchParams) {
            const httpParams = this.searchParams.toHttpParams();
            if (availability.length) {
                this.property = availability[0].property;
                this.analyticsService.createRoomImpressionsEvent(this.property, availability, httpParams);
            } else {
                const property = this.config.findPropertyById(this.propertyId);
                this.analyticsService.createNoResultsEvent(httpParams, property);
            }
        }
    };

    private showInvalidSearchParamsDialog = () => {
        const title = this.translate.instant('room_results.invalid_search_params_error_title');
        const message = this.translate.instant('room_results.invalid_search_params_error_message');

        this.dialog
            .open(AlertDialogComponent, { data: { title, message } })
            .afterClosed()
            .subscribe(() => {
                this.bookingService.clearSearchCriteriaFromLocalStorage();
                this.router
                    .navigate([ 'booking/search' ])
                    .catch(console.error);
            });
    };

    private onSearchOrAvailabilityError = (err: unknown): void => {
        if (err === INVALID_PARAMS) {
            this.showInvalidSearchParamsDialog();
        } else {
            console.error('RoomResultsComponent.onSearchOrAvailabilityError', err);
            const title = this.translate.instant('room_results.availability_error_title');
            const message = this.translate.instant('room_results.availability_error_message');

            this.dialog
                .open(ErrorDialogComponent, { data: { title, message, allowRetry: true } })
                .afterClosed()
                .subscribe((retry: boolean) => {
                    if (retry) {
                        this.windowLocationService.reload();
                    }
                });
        }
    };

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public openGalleryDialog(unitType: UnitTypeModel) {
        this.analyticsService.createRoomClickEvent(this.property.name, unitType);
        this.analyticsService.createRoomViewEvent(this.property.name, unitType);
        this.dialog.open(GalleryDialogComponent, {
            data: {
                propertyId: this.propertyId,
                unitType,
            },
        });
    }

    public getUnitTypeImageUrl(unitTypeId: string) {
        return this.imagesService.getUnitTypeImageUrl(this.propertyId, unitTypeId);
    }

    public openDescription(id: string, element: Element) {
        // setTimeout is included to drag this to the back of the event loop so the height of the element is set properly.
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 0);

        if (id === this.descriptionId) {
            this.descriptionId = '';
            return;
        }
        this.descriptionId = id;
    }

    public toggleAvailabilityCalendar() {
        this.showAvailabilityCalendar = !this.showAvailabilityCalendar;
    }

    public onAddToCart($event: OnAddToCartEvent) {
        const reservations = this.bookingService.getReservations();
        const numberOfReservations = reservations.length;
        const totalNumberOfUnits = asNumber($event.selectedUnitQty, 0) + numberOfReservations;

        if (totalNumberOfUnits > this.config.settings.roomQtySelectorLimit) {
            this.toasterService.showError(
                this.translate.instant('room_results.addtocart_failed'),
                this.translate.instant('room_results.max_rooms_exceeded'),
            );
        } else {
            this.selectedUnitQty = asNumber($event.selectedUnitQty, 0);

            this.analyticsService.createRoomClickEvent(this.property.name, $event.availabilityResultRate);
            this.analyticsService.createRoomViewEvent(this.property.name, $event.availabilityResultRate);

            this.extrasDialogService
                .open(this.route.snapshot.queryParams, $event.availabilityResultRate.id, this.property.id)
                .subscribe(this.onExtraDialogEvent($event), this.onError);
        }
    }

    private onExtraDialogEvent = (event: OnAddToCartEvent): ExtraDialogEventHandler => ([ hasSomeExtras, selectedExtras ]) => {
        this.analyticsService.createExtrasImpressionsEvent(this.property, selectedExtras);

        const cart = Cart.FromParams(
                this.route.snapshot.queryParams,
                event.availabilityResult,
                event.availabilityResultRate,
            )
            .withSelectedExtras(selectedExtras)
            .withExtrasAvailable(hasSomeExtras);

        this._saveCart(cart);
    };

    private _saveCart(cart: Cart) {
        const addedOk = this.bookingService.addReservationToBooking(
            cart,
            this.selectedUnitQty,
        );

        if (addedOk) {
            this.analyticsService.createRoomAddToCartEvent(this.selectedUnitQty, cart.getReservation());
            this.analyticsService.createExtrasAddToCartEvent(cart.getReservation());
            this.toasterService.showSuccess(
                this.translate.instant('room_results.room_added'),
                this.translate.instant('room_results.room_added_to_cart'),
            );
            this.router
                .navigate([ 'checkout' ])
                .catch((navError) => console.error('navigation failed: /checkout', navError));
        }
    }

    private onPropertyChanged = (property: Property | undefined) => {
        this.displayInclusiveExtrasAsTaxes = (property?.config?.displayInclusiveExtrasAsTaxes === true);
    };
}
