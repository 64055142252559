// tslint:disable:max-file-line-count
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorSource } from 'app/error-dialog/error-mapping';
import { AddressData, CompleteBookingResponseModel, DetailsData, StayCriteriaModel } from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    BookerModel,
    BookingModel,
    CreateBookingRequestResponseModel,
    CreateBookingResponseModel,
    ExtraModel,
    ReservationDiffModel,
    ReservationModel,
    TravelPurpose,
} from 'up-ibe-types';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { calculateBookingTotals, calculateExtrasTotals } from '../helpers/booking.helper';
import { asString, isArray } from '../helpers/type.helper';
import { Cart } from './booking-service/cart';
import { ErrorDialogService } from './error-dialog.service';
import { IbeConfigService } from './ibe-config.service';
import { JourneyService } from './journey.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class BookingService {
    private booking: BookingModel;

    constructor(
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
        private readonly http: HttpClient,
        private readonly config: IbeConfigService,
        private readonly dialog: MatDialog,
        private readonly errorDialogService: ErrorDialogService,
        private readonly localStorageService: LocalStorageService,
        private readonly journeyService: JourneyService,
    ) {
        this.loadBookingData();
    }

    public getBooking(): Readonly<BookingModel> { return this.booking; }

    public getBookerComment(): string { return asString(this.booking.bookerComment, ''); }

    public hasValidBooker(): boolean {
        const booker: BookerModel = this.booking.booker;

        return (booker.lastName !== '');
        // TODO: title, middleInitial, firstName, email, phone - check required fields
        // @see config.settings.checkoutFields.detail
    }

    public getBooker(): Readonly<BookerModel> {
        return this.booking.booker;
    }

    private loadBookingData() {
        const data = this.localStorageService.getBookingData();

        if (data) {
            this.booking = data;
            this.modifyReservationDataIfInvalid();
        } else {
            this.booking = this.getBlankBooking();
        }
    }

    private saveBookingToLocalStorage() {
        this.localStorageService.setBookingData(this.booking);
    }

    public bookingCurrency(): string {
        const currency: string = this.booking?.reservations[0]?.totalGrossAmount?.currency ?? '';
        return currency ? `${currency}` : '';
    }

    // Not technically a reservation model, but it's the closest thing to what it actually is without
    // making a new type that's essentially identical like in the backend
    public mergeReservationsWithPmsData(pmsData: ReservationDiffModel | undefined) {
        const replaceRatePlanId = pmsData?.ratePlanToChange?.id ?? '';

        if (replaceRatePlanId) {
            const newReservationData = { ...pmsData, ratePlanToChange: undefined };
            const updateReservationDetails = (reservation: ReservationModel): ReservationModel => {
                return (reservation.ratePlan.id === replaceRatePlanId)
                    ? { ...reservation, ...newReservationData }
                    : reservation;
            };

            this.booking.reservations = this.booking.reservations.map(updateReservationDetails);
        }
        return this.localStorageService.setBookingData(this.booking);
    }

    public saveLastSearchedStayCriteria(stayCriteria: StayCriteriaModel) {
        return this.localStorageService.setLastSearchedStayCriteria(stayCriteria);
    }

    public getLastSearchedStayCriteria() {
        return this.localStorageService.getLastSearchedStayCriteria();
    }

    public addReservationToBooking(cart: Cart, selectedQty: number): boolean {
        const reservation = cart.getReservation();
        const availableUnits = cart.availableUnits();

        if (!this._canReservationBeAdded(reservation)) {
            return false;
        }

        for (let i = 0 ; i < selectedQty ; i += 1) {
            const sameUnitTypeInBasketCount = this.booking.reservations
                .filter(item => {
                    return (item.ratePlan.id === reservation.ratePlan.id)
                        && (item.unitType.id === reservation.unitType.id);
                }).length;

            if (availableUnits > sameUnitTypeInBasketCount) {
                this.booking.reservations.push({ ...reservation });
                this.saveBookingToLocalStorage();
            } else {
                const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
                this.errorDialogService.errorDialog('Unable to add', errorSource);

                return false;
            }
        }

        return true;
    }

    public removeReservationFromBooking(reservationKey: number) {
        this.booking.reservations.splice(reservationKey, 1);
        this.saveBookingToLocalStorage();
        return true;
    }

    public removeAllReservations() {
        this.booking.reservations = [];
        this.saveBookingToLocalStorage();
    }

    public saveExtrasToReservation(reservationKey: number, extras: ExtraModel[]) {
        this.booking.reservations[reservationKey].extras = extras;
        this.saveBookingToLocalStorage();
        return true;
    }

    public removeExtraFromReservation(reservationKey: number, extraId: string) {
        this.booking.reservations[reservationKey].extras = this.booking.reservations[reservationKey].extras
            .filter((element) => {
                return element.id !== extraId;
            });
        this.saveBookingToLocalStorage();
        return true;
    }

    public addDetailsToReservation(data: DetailsData) {
        this.booking.booker.title = data.title;
        this.booking.booker.firstName = data.firstName;
        this.booking.booker.lastName = data.lastName;
        this.booking.booker.email = data.email;
        this.booking.booker.phone = data.phone;
        this.booking.booker.preferredLanguage = this.config.language;

        if (data.guestComment) {
            this.booking.bookerComment = isArray(data.guestComment)
                ? data.guestComment.join(', ')
                : data.guestComment;
        }

        if (data.travelPurpose) {
            this.booking.reservations = this.booking.reservations.map((reservation) => {
                return {
                    ...reservation,
                    travelPurpose: data.travelPurpose as TravelPurpose,
                };
            });

            if (data.company) {
                let taxId = '';
                if (data.company.companyTaxId) {
                    taxId = data.company.companyTaxId;
                }
                this.booking.booker.company = {
                    name: data.company.companyName,
                    taxId,
                };
            }
        }

        this.saveBookingToLocalStorage();
        return true;
    }

    public addAddressToReservation(data: AddressData) {
        this.booking.booker.address = data;
        this.saveBookingToLocalStorage();
        return true;
    }

    public addPmsGuestIdToReservation(pmsGuestId: string) {
        this.booking.booker.pmsGuestId = pmsGuestId;
        this.saveBookingToLocalStorage();
        return true;
    }

    public removePmsGuestIdFromReservation() {
        delete this.booking.booker.pmsGuestId;
        this.saveBookingToLocalStorage();
        return true;
    }

    public modifyReservationDataIfInvalid() {
        // remove reservations if arrival date is in the past
        const lengthBeforeFilter = this.booking.reservations.length;
        this.booking.reservations = this.booking.reservations
            .filter(reservation => !moment().isAfter(reservation.arrival, 'day'));
        const lengthAfterFilter = this.booking.reservations.length;
        this.saveBookingToLocalStorage();

        this.translate
            .get('checkout_payment.offers_expired_title')
            .subscribe((translated: string) => {
                if (lengthBeforeFilter > lengthAfterFilter) {

                    this.dialog
                        .open(AlertDialogComponent, {
                            data: {
                                title: translated,
                                message: this.translate.instant('checkout_payment.offers_expired_message'),
                            },
                        });
                    if (!lengthAfterFilter) {
                        this.clearBookingDataFromLocalStorage();
                    }
                }
            });
    }

    public setBookingRequestInProgress(data: { success: boolean; id: string }) {
        return this.localStorageService.setBookingRequestInProgress(data);
    }

    public getBookingRequestInProgress() {
        return this.localStorageService.getBookingRequestInProgress();
    }

    public clearBookingRequestInProgress() {
        this.localStorageService.removeSaferPayToken();
        this.localStorageService.removeBookingRequestInProgress();
    }

    public clearBookingDataFromLocalStorage(isBookingConfirmationStep: boolean = false) {
        this.localStorageService.removeSaferPayToken();
        this.localStorageService.removeBookingRequestId();
        this.localStorageService.removeBookingRequestInProgress();
        this.localStorageService.removeAdyenTransactionId();
        const hasUseRentalAgreementSetToTrue: boolean = this.config?.accountFeatureWhitelist?.useRentalAgreement === true;
        if (!hasUseRentalAgreementSetToTrue || (hasUseRentalAgreementSetToTrue && isBookingConfirmationStep)) {
            this.localStorageService.removeBookingData();
        }
        if (!hasUseRentalAgreementSetToTrue) {
            this.booking = this.getBlankBooking();
        }
    }

    public clearSearchCriteriaFromLocalStorage() {
        return this.localStorageService.removeLastSearchedStayCriteria();
    };

    /**
     * Throughout the booking process some data is attached to the booking in localstorage for easy
     * access and to avoid a lot of processing in the IBE. This method removes that data. This is
     * necessary when the booking data in localstorage is going to be passed as request data.
     * Although PMS' currently ignore the additional data attached to the booking, they may not in
     * the future so this is beneficial as a precaution.
     */
    private removeUnrequiredRequestDataFromBooking(booking: BookingModel): BookingModel {
        const updatedBooking = booking;

        updatedBooking.reservations = updatedBooking.reservations.map((reservation: ReservationModel) => {
            const { totalBaseAmount, ...reservationWithoutTotalBaseAmount } = reservation;
            return reservationWithoutTotalBaseAmount;
        });

        return updatedBooking;
    }

    public createBookingRequest(): Observable<Object> {
        return this.http.post(`${environment.serverUrl}/api/ibe/create-booking-request`, {
            booking: this.removeUnrequiredRequestDataFromBooking(this.booking),
            journeyToken: this.journeyService.getJourneyToken(),
            ibeLanguage: this.config.language,
            ibeUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        });
    }

    public populateBookingErrorMessage(pmsData: ReservationDiffModel | undefined) {
        const reservation = (this.booking.reservations.find(
            item => pmsData?.ratePlanToChange && pmsData?.ratePlanToChange.id === item?.ratePlan.id,
        ));

        const priceToDisplay = pmsData?.totalGrossAmount
            ? pmsData?.totalGrossAmount.amount
            : reservation?.totalGrossAmount.amount;

        const ratePlanToDisplay = pmsData?.ratePlan || reservation?.ratePlan;

        return {
            price: `${priceToDisplay} ${reservation?.totalGrossAmount.currency}`,
            ratePlan: ratePlanToDisplay?.name,
            description: ratePlanToDisplay?.pmsDescription,
        };
    }

    public saveBookingRequestIdToLocalStorage(response: CreateBookingRequestResponseModel) {
        return this.localStorageService.setBookingRequestId(response.bookingRequestId);
    }

    public getBookingRequestId() {
        return this.localStorageService.getBookingRequestId();
    }

    /**
     * Be careful: Only leave paymentData undefined if you want
     * to make a booking with no payment.
     */
    // tslint:disable-next-line:no-any
    public completeBooking(paymentData: any | undefined, bookingRequestId: string) {
        return this.http
            .post(`${environment.serverUrl}/api/ibe/complete-booking`, {
                bookingRequestId,
                paymentData,
            })
            .pipe(
                tap((response: CreateBookingResponseModel) => {
                    const useRentalAgreement = (this.config?.accountFeatureWhitelist?.useRentalAgreement === true);
                    const isLongStayProperty = this.config.isLongStayProperty();

                    if (useRentalAgreement && isLongStayProperty) {
                        const newResponse: CompleteBookingResponseModel = response;
                        if (newResponse.pdf) {
                            const link: HTMLAnchorElement = document.createElement('a');
                            link.download = `rental_agreement_contract_${moment(new Date(), 'DD_MM_YYYY')}`;
                            link.href = newResponse.pdf;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                }),
            );
    }

    public calculateBookingTotals(reservations: ReservationModel[], displayInclusiveExtrasAsTaxes: boolean = false) {
        return calculateBookingTotals(reservations, displayInclusiveExtrasAsTaxes);
    }

    public calculateExtrasTotals(extras: ExtraModel[]) {
        return calculateExtrasTotals(extras);
    }

    public translateReservationStatus(status: string) {
        switch (status) {
            case 'Confirmed':
                return this.translate.instant('manage_booking.reservation_statuses.confirmed');
            case 'Canceled':
                return this.translate.instant('manage_booking.reservation_statuses.canceled');
            case 'CheckedOut':
                return this.translate.instant('manage_booking.reservation_statuses.checked_out');
            case 'InHouse':
                return this.translate.instant('manage_booking.reservation_statuses.in_house');
            case 'NoShow':
                return this.translate.instant('manage_booking.reservation_statuses.no_show');
            default:
                return status;
        }
    }

    public calculateNumberOfNights(arrivalDate: Date | string, departureDate: Date | string): number {
        const arrival = moment(arrivalDate);
        const departure = moment(departureDate);
        return departure.diff(arrival, 'days');
    }

    private _canReservationBeAdded(reservation: ReservationModel): boolean {
        const hasDifferentPropertyId = (item: ReservationModel) => {
            return item.property.id !== reservation.property.id;
        };

        if (this.booking.reservations.some(hasDifferentPropertyId)) {
            this.toasterService.showError(
                this.translate.instant('room_results.cannot_add_reservation'),
                this.translate.instant('room_results.reservations_in_cart_are_for_different_property'),
            );

            return false;
        }

        const bookingCurrency = this.bookingCurrency();

        if (this.booking.reservations.length && (bookingCurrency !== reservation.totalGrossAmount.currency)) {
            const errorMessage = this.translate.instant('room_results.reservations_in_cart_are_for_different_currency');
            this.toasterService.showError(
                this.translate.instant('room_results.cannot_add_reservation'),
                `${errorMessage} (${bookingCurrency})`,
            );
            return false;
        }

        return true;
    }

    private getBlankBooking(): BookingModel {
        return {
            booker: {
                title: undefined,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                preferredLanguage: 'en',
                address: {
                    addressLine1: '',
                    city: '',
                    postalCode: '',
                    countryCode: this.config.settings.defaultAddressCountryCode,
                },
            },
            reservations: [],
            bookerComment: '',
        };
    }

    public getReservations(): ReservationModel[] {
        return isArray(this.booking.reservations)
            ? this.booking.reservations
            : [];
    }
}

export { Cart };
