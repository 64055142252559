<h2 class="ibe-checkout-step-title" *ngIf="paymentRequired">{{ 'checkout_payment.enter_card_details' | translate }}</h2>
<h2
    class="ibe-checkout-step-title"
    *ngIf="!paymentRequired"
>{{ 'checkout_payment.confirm_booking_title' | translate }}</h2>
<div class="ibe-checkout-step-box">

    <div class="ibe-flexible-guarantee-message" *ngIf="paymentRequired && showFlexibleGuaranteeMessage()">
        <div>
            <i class="ibe-icon ibe-icon-warning-circle"></i>
        </div>
        <div class="message-text">
            {{ 'checkout_payment.credit_card_details_needed_to_guarantee_stay' | translate }}
            <br>
            {{ 'checkout_payment.your_card_will_not_be_charged' | translate }}
        </div>
    </div>

    <div class="ibe-pre-payment-message" *ngIf="paymentRequired && prePaymentAmount > 1">
        {{ 'checkout_payment.payable_now' | translate }}
        <span>{{ prePaymentAmount | currency:bookingCurrency }}</span>
    </div>

    <div class="ibe-3ds-message" *ngIf="paymentRedirected">
        <div>
            <i class="ibe-icon ibe-icon-warning-circle"></i>
        </div>
        <div class="message-text">
            {{ 'checkout_payment.do_not_close_tab' | translate }}
            <br>
            {{ 'checkout_payment.waiting_for_booking_completion' | translate }}
        </div>
    </div>

    <ibe-adyen-dropin-payment
        *ngIf="showPaymentComponent('adyen-dropin')"
        [paymentSetupData]="paymentSetupData"
        [bookingRequestId]="bookingRequestId"
        [isLoading]="isLoading"
        [paymentRedirected]="paymentRedirected"
        [paymentButtonLabel]="paymentButtonLabel()"
        (toggleIsLoading)="toggleIsLoading($event)"
        (togglePaymentRedirected)="togglePaymentRedirected($event)"
        (toggleInPaymentFlow)="toggleInPaymentFlow($event)"
        (onComplete)="completeBooking($event)"
    ></ibe-adyen-dropin-payment>

    <ibe-adyen-payment
        *ngIf="hasPaymentScriptLoaded && showPaymentComponent('adyen')"
        [paymentSetupData]="paymentSetupData"
        [isLoading]="isLoading"
        [paymentRedirected]="paymentRedirected"
        (toggleIsLoading)="toggleIsLoading($event)"
        (toggleInPaymentFlow)="toggleInPaymentFlow($event)"
        (onComplete)="completeBooking($event)"
    >
    </ibe-adyen-payment>

    <ibe-saferpay-payment
        *ngIf="showPaymentComponent('saferpay')"
        [bookingRequestId]="bookingRequestId"
        [paymentSetupData]="paymentSetupData"
        [paymentRedirected]="paymentRedirected"
        (toggleInPaymentFlow)="toggleInPaymentFlow($event)"
        (onComplete)="completeBooking($event)"
        (togglePaymentRedirected)="togglePaymentRedirected($event)"
        (onRedirectComplete)="onRedirectComplete($event)"
    >
    </ibe-saferpay-payment>

    <ibe-mycheck-payment
        *ngIf="hasPaymentScriptLoaded && showPaymentComponent('mycheck')"
        [paymentSetupData]="paymentSetupData"
        [prePaymentAmount]="prePaymentAmount"
        [paymentRedirected]="paymentRedirected"
        [bookingRequestId]="bookingRequestId"
        [isLoading]="isLoading"
        (toggleIsLoading)="toggleIsLoading($event)"
        (togglePaymentRedirected)="togglePaymentRedirected($event)"
        (onComplete)="completeBooking($event)"
    >
    </ibe-mycheck-payment>

    <div
        class="ibe-confirm-booking-option"
        *ngIf="bookingRequestId && !paymentRequired && !paymentRedirected && !isLoading"
    >
        <p>{{ 'checkout_payment.no_payment_for_booking' | translate }}</p>
        <button class="ibe-btn ibe-primary-btn ibe-confirm-booking-btn" (click)="completeBooking()">
            <span class="ibe-confirm-booking-btn-text">{{ 'checkout_payment.confirm_booking' | translate }}</span>
        </button>
    </div>
    <div class="ibe-confirm-booking-option" *ngIf="(paymentRedirected && !paymentRequired) || isLoading">
        <ibe-loading-circle></ibe-loading-circle>
    </div>
</div>
<ul class="ibe-checkout-step-checklist">
    <li routerLink="/checkout/details">
        <i class="ibe-icon ibe-icon-tick"></i>
        <span class="ibe-checkout-step-checklist-title">{{ 'checkout_payment.personal_details' | translate }}</span>
        <i class="ibe-icon ibe-icon-edit"></i>
    </li>
    <li routerLink="/checkout/address">
        <i class="ibe-icon ibe-icon-tick"></i>
        <span class="ibe-checkout-step-checklist-title">{{ 'checkout_payment.address' | translate }}</span>
        <i class="ibe-icon ibe-icon-edit"></i>
    </li>
</ul>
