import { Params } from '@angular/router';
import {
    AvailabilityResultModel,
    AvailabilityResultRateModel,
    ChannelCode,
    ExtraModel,
    ReservationModel,
} from 'up-ibe-types';
import { isArray } from '../../helpers/type.helper';

export class Cart {
    private readonly reservation: ReservationModel;
    private readonly rate: AvailabilityResultRateModel;

    public static FromParams(
        queryParams: Params,
        availabilityResult: AvailabilityResultModel,
        rate: AvailabilityResultRateModel,
    ) {
        let promoCode;
        let corporateCode;
        if (rate.isCorporateRate) {
            corporateCode = queryParams.promoCode;
        }
        if (rate.isPromoRate) {
            promoCode = queryParams.promoCode;
        }

        const reservation: ReservationModel = {
            property: availabilityResult.property,
            unitType: availabilityResult.unitType,
            ratePlan: {
                id: rate.id,
                name: rate.name,
                pmsDescription: rate.description,
            },
            totalBaseAmount: rate.totalBaseAmount,
            totalGrossAmount: rate.totalGrossAmount,
            includedExtras: rate.includedExtras,
            prePaymentAmount: rate.prePaymentAmount,
            arrival: availabilityResult.arrival,
            departure: availabilityResult.departure,
            adults: queryParams.adults,
            childrenAges: (queryParams.childrenAges || []),
            extras: [], // extras,
            taxes: rate.taxes,
            guaranteeType: rate.guaranteeType,
            promoCode,
            corporateCode,
            extrasAvailable: false,
            estimatedCityTax: rate.estimatedCityTax,
            estimatedServiceCharge: rate.estimatedServiceCharge,
            suppressedRate: !!(rate.suppressed),
            depositPolicy: rate.depositPolicy,
            channel: 'Ibe' as ChannelCode,
        };

        return new Cart(reservation, rate);
    }

    public constructor(
        reservation: ReservationModel,
        rate: AvailabilityResultRateModel,
    ) {
        this.reservation = reservation;
        this.rate = rate;
    }

    public withSelectedExtras(extras: ExtraModel[]) {
        if (extras && isArray(extras) && (extras.length > 0)) {
            const newParams = { ...this.reservation, ...{ extras } };
            return new Cart(newParams, this.rate);
        }
        return this;
    }

    public withExtrasAvailable(extrasAvailable: boolean) {
        if ((extrasAvailable === true) || (extrasAvailable === false)) {
            const newParams = { ...this.reservation, ...{ extrasAvailable } };
            return new Cart(newParams, this.rate);
        }
        return this;
    }

    public getReservation(): ReservationModel {
        return this.reservation;
    }

    public availableUnits() {
        return this.rate.availableUnits;
    }

    public getRate(): AvailabilityResultRateModel {
        return this.rate;
    }
}
