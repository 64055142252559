import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Step, StepClass } from 'app/fixme-inline-types';
import { GuestAuthService } from '../services/guest-auth.service';
import { IbeConfigService } from '../services/ibe-config.service';

@Component({
    selector: 'ibe-steps-bar',
    templateUrl: './steps-bar.component.html',
    styleUrls: ['./steps-bar.component.scss'],
})
export class StepsBarComponent implements OnInit {
    // FIXME DO NOT USE public properties
    public steps: Step[] = [
        {
            handle: 'offerSearch',
            title: this.translate.instant('steps_bar.search_accommodation'),
            number: 1,
            route: 'booking/search',
        },
        {
            handle: 'offerResults',
            title: this.translate.instant('steps_bar.select_room_rate'),
            number: 2,
            route: 'booking/results',
        },
        {
            handle: 'checkout',
            title: this.translate.instant('steps_bar.checkout'),
            number: 3,
            route: 'checkout/details',
        },
    ];
    public currentStep: Step | undefined;

    constructor(
        // FIXME DO NOT USE public properties
        public readonly guestAuthService: GuestAuthService,
        public readonly config: IbeConfigService,
        private readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
        private readonly router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.route
            .data
            .subscribe(
                (data) => {
                    this.currentStep = this.steps.find((step) => step.handle === data['step']);
                },
                // onError
            );
    }

    public isStepActive(stepHandle: string): boolean {
        return (this.currentStep !== undefined)
            && (stepHandle === this.currentStep.handle);
    }

    public goToStep(step: Step): void {
        this.router
            .navigate([step.route])
            .catch((navError) => console.error('navigation failed: goToStep', navError));
    }

    public goToGuestManagement(e: Event) {
        if (e) {
            e.preventDefault();
        }
        this.router
            .navigate(['guest/manage'])
            .catch((navError) => console.error('navigation failed: /guest/manage', navError));
    }

    public ibeStepsClass(step: Step): StepClass {
        const classes: StepClass = {};
        classes[step.handle] = true;

        return classes;
    }
}
