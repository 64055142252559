import { AnimationEvent } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/services/toaster.services';
import { forkJoin, ReplaySubject } from 'rxjs';
import { ReservationModel } from 'up-ibe-types';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { scrollToTop } from '../../helpers/scroll.helper';
import { asString } from '../../helpers/type.helper';
import { AnalyticsService } from '../../services/analytics.service';
import { BookingService } from '../../services/booking.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { NewsletterSubscriptionService } from '../../services/newsletter-subscription.service';

@Component({
    selector: 'ibe-checkout-address',
    templateUrl: './checkout-address.component.html',
    styleUrls: ['./checkout-address.component.scss'],
    animations: [
        fadeInOnEnterAnimation,
        scaleUpAnimation,
    ],
})
export class CheckoutAddressComponent implements OnInit {
    @ViewChild('addressFormDirective', { static: true }) public addressFormDirective: FormGroupDirective;

    // FIXME DO NOT USE public properties
    public addressForm: FormGroup;
    public animationState: string;
    public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    public isFormReady: boolean = false;

    constructor(
        private readonly config: IbeConfigService,
        private readonly bookingService: BookingService,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
        private readonly analyticsService: AnalyticsService,
        private readonly newsletterSubscriptionService: NewsletterSubscriptionService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
    ) {
    }

    public ngOnInit() {
        const isValid = this.bookingService.hasValidBooker();
        if (isValid) {
            this.addressForm = this.formBuilder.group({});
            this.createCheckoutAddressEvent();
            scrollToTop();
        } else {
            this.redirectTo('checkout/details');
        }
    }

    public addFormControl(name: string, formGroup: FormGroup) {
        if (Object.keys(formGroup.controls).length === 0) {
            this.skipAddressStep();
        }
        this.addressForm.addControl(name, formGroup);
        this.isFormReady = true;
    }

    public subscribeToNewsletter() {
        const booker = this.bookingService.getBooker();
        const email = asString(booker.email, '');
        if (email !== '') {
            const firstName = asString(booker.firstName, '');
            const lastName = booker.lastName;

            const reservations = this.bookingService.getReservations();

            forkJoin(
                reservations.map((reservation: ReservationModel) => {
                    return this.newsletterSubscriptionService
                        .subscribeToNewsletter(
                            { firstName, lastName, email },
                            reservation.property.id,
                        );
                }),
            ).subscribe();
        }
    }

    public onFormSubmit(event: Event) {
        this.submit.next(true);
        event.preventDefault();

        if (this.addressForm.valid) {
            if (this.addressForm.controls['address'].value.marketingConsent) {
                this.subscribeToNewsletter();
            }

            if (this.bookingService.addAddressToReservation(this.addressForm.controls['address'].value)) {
                const title = this.translate.instant('checkout_address.address_added');
                const body = this.translate.instant('checkout_address.address_has_been_added');

                this.toasterService.showSuccess(title, body);
                this.animationState = 'completed';
            }
        }
    }

    public isFormControlInvalid(formControl: AbstractControl): boolean {
        return isFormControlInvalid(formControl, this.addressFormDirective);
    }

    public scaleUpAnimationComplete(event: AnimationEvent): void {
        if (event.toState === 'completed') {
            const useRentalAgreement = (this.config?.accountFeatureWhitelist?.useRentalAgreement === true);
            const isLongStayProperty = this.config.isLongStayProperty();

            if (useRentalAgreement && isLongStayProperty) {
                window.location.href = '/rental-agreement';
            } else {
                this.redirectTo('checkout/payment');
            }
        }
    }

    private skipAddressStep = (): void => {
        this.redirectTo('checkout/payment');
    };

    private createCheckoutAddressEvent(): void {
        const step = 2;
        const reservations = this.bookingService.getReservations();
        this.analyticsService.createCheckoutEvent(reservations, step);
    }

    public showBillingAddressMessage(): boolean {
        const paymentSettings = this.config.getPropertyPaymentSettings();
        return (paymentSettings.additionalAdyenShopperDetailsEnabled === true);
    }

    private redirectTo = (ibePath: string): void => {
        // tslint:disable-next-line:no-any
        const onNavigationError = (navError: any) => console.error(`navigation failed: ${ibePath}`, navError);
        this.router
            .navigate([ibePath])
            .catch(onNavigationError);
    };
}
