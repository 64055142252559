import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from './services/booking.service';

@Pipe({
    name: 'translateReservationStatus',
})
export class TranslateReservationStatusPipe implements PipeTransform {
    constructor(private readonly bookingService: BookingService) {}

    public transform(status: string | undefined): string {
        return (status)
            ? this.bookingService.translateReservationStatus(status)
            : 'Unknown';
    }
}
