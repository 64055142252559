import { AnimationEvent } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from 'app/services/booking.service';
import { GuestAuthService } from 'app/services/guest-auth.service';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { ToasterService } from 'app/services/toaster.services';
import { ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { scrollToTop } from '../../helpers/scroll.helper';

@Component({
    selector: 'ibe-checkout-details',
    templateUrl: './checkout-details.component.html',
    styleUrls: ['./checkout-details.component.scss'],
    animations: [fadeInOnEnterAnimation, scaleUpAnimation],
})
export class CheckoutDetailsComponent implements OnInit {
    // FIXME: DO NOT use public properties
    public detailsForm: FormGroup;
    public animationState: string;
    public isCreateAccountLoading = false;
    public isSubmitting = false;
    public showCreateAccountButton = false;
    public showCreateAccountBox = this.config.settings.guestManagementEnabled && this.config.settings.checkoutFields.details.password;
    public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    private licensePlate: string;

    constructor(
        // FIXME: DO NOT use public properties
        public config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly bookingService: BookingService,
        private readonly toasterService: ToasterService,
        private readonly guestAuthService: GuestAuthService,
    ) {
    }

    public ngOnInit() {
        this.detailsForm = this.formBuilder.group({});
        scrollToTop();
    }

    public addFormControl(name: string, formGroup: FormGroup) {
        this.detailsForm.addControl(name, formGroup);
    }

    public onFormSubmit(event: Event) {
        this.submit.next(true);
        event.preventDefault();
        if (this.detailsForm.valid) {
            if (this.config?.accountFeatureWhitelist?.licensePlateField) {
                const fieldComment = this.detailsForm.get('details.guestComment');
                if (fieldComment) {
                    fieldComment.setValue(`${this.detailsForm.get('details.guestComment')?.value} /PI ${this.licensePlate}.`);
                } else {
                    // form with license plates and no comment field === problem: license plates do not work
                }
            }

            const formValues = (this.detailsForm.get('details') as FormGroup).getRawValue();
            if (this.bookingService.addDetailsToReservation(formValues)) {
                const title = this.translate.instant('checkout_details.details_added');
                const body = this.translate.instant('checkout_details.details_has_been_added');
                this.toasterService.showSuccess(title, body);
                this.animationState = 'completed';
            }
        }
    }

    public toggleSubmit() {
        this.isSubmitting = !this.isSubmitting;
    }

    public createGuestAccount(event: Event) {
        event.preventDefault();
        this.isCreateAccountLoading = true;
        this.toggleSubmit();
        return this.guestAuthService.createGuest({
                username: this.detailsForm.controls['details'].value.email,
                password: this.detailsForm.controls['details'].value.password,
                firstName: this.detailsForm.controls['details'].value.firstName,
                lastName: this.detailsForm.controls['details'].value.lastName,
                title: this.detailsForm.controls['details'].value.title,
                phone: this.detailsForm.controls['details'].value.phone,
            })
            .pipe(finalize(() => this.toggleSubmit()))
            .subscribe(
                (response) => {
                    // FIXME: why is 'the success' response ignored !?
                    this.toasterService.showSuccess(
                        this.translate.instant('global.success'),
                        this.translate.instant('guest_management_auth.create_successful'),
                    );
                    this.isCreateAccountLoading = false;
                    this.showCreateAccountButton = false;
                    this.showCreateAccountBox = false;
                },
                (response) => {
                    this.isCreateAccountLoading = false;
                    this.toasterService.showError(
                        this.translate.instant('guest_management_auth.create_failed'),
                        response.error.message,
                    );
                },
            );
    }

    public scaleUpAnimationComplete(event: AnimationEvent) {
        if (event.toState === 'completed') {
            this.router
                .navigate(['checkout/address'])
                .catch((navError) => console.error('navigation failed: /checkout/address', navError));
        }
    }

    public toggleShowCreateAccountButton(value: boolean) {
        this.showCreateAccountButton = value;
    }

    public updateLicensePlateNumber(value: string) {
        this.licensePlate = value;
    }
}
