<ibe-booking-search
    [isLoading]="loading$ | async"
></ibe-booking-search>

<ibe-cart></ibe-cart>

<ibe-room-results
    *ngIf="!isRegionSearch"
    (onLoadingEmit)="onLoadingEmit($event)"
></ibe-room-results>

<ibe-region-results
    *ngIf="isRegionSearch"
    (onLoadingEmit)="onLoadingEmit($event)"
>
</ibe-region-results>
