<h2 mat-dialog-title>
    {{ title }}
</h2>

<mat-dialog-content>
    {{ message }}
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="ibe-btn ibe-primary-btn" mat-dialog-close>
        OK
    </button>
</mat-dialog-actions>
