import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type AlertDialogData = {
    title: string,
    message: string
}

@Component({
    selector: 'ibe-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: AlertDialogData,
    ) { }

    get title(): string { return this.data.title; }
    get message(): string { return this.data.message; }
}
