import { HttpStatus } from 'up-ibe-types';

export enum Errors {

    // WIP: add invalid data and invalid types as inline validation
    //    - as a helping tool to fix the types and interfaces
    InvalidParam = 'InvalidParam',
}

export class ApplicationError extends Error {
    public readonly name: string;
    public readonly message: string;
    public readonly status: number;
    public readonly context: object;

    constructor(name: string, message: string, status: number, context: object) {
        super();
        this.name = name;
        this.message = message || 'Something went wrong. Please try again.';
        this.status = status || HttpStatus.INTERNAL_SERVER_ERROR;
        this.context = context || {};
    }
}
