/* tslint:disable:max-file-line-count */
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';

// LOCALE DATA - USED IN CURRENCIES
import localeDe from '@angular/common/locales/de';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeSe from '@angular/common/locales/se';

import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToasterModule } from 'angular2-toaster';
import { AuthInterceptor } from 'app/interceptors/auth.interceptor';
import { IbeKeyInterceptorService } from 'app/interceptors/ibe-key-interceptor.service';
import { JourneyTokenInterceptor } from 'app/interceptors/journey-token.interceptor';
import { LanguageInterceptorService } from 'app/interceptors/language-interceptor.service';
import { ToasterService } from 'app/services/toaster.services';
import { environment } from '../environments/environment';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookerDetailsComponent } from './booking-management/booker-details/booker-details.component';
import {
    BookingCancellationDialogComponent,
} from './booking-management/booking-cancellation-dialog/booking-cancellation-dialog.component';
import { BookingManagementComponent } from './booking-management/booking-management.component';
import {
    BookingPaymentDialogComponent,
} from './booking-management/booking-payment-dialog/booking-payment-dialog.component';
import { CreditCardComponent } from './booking-management/credit-card/credit-card.component';
import { AvailabilityCalendarComponent } from './booking/availability-calendar/availability-calendar.component';
import {
    AvailabilityRestrictionsDialogComponent,
} from './booking/availability-calendar/availability-restrictions-dialog/availability-restrictions-dialog.component';
import { CalendarComponent } from './booking/availability-calendar/calendar/calendar.component';
import { ReactPropertyCalendarComponent } from './booking/availability-calendar/react-property-calendar.component';
import { BookingConfirmationComponent } from './booking/booking-confirmation/booking-confirmation.component';
import { BookingSearchComponent } from './booking/booking-search/booking-search.component';
import { CitySelectorComponent } from './booking/booking-search/city-selector/city-selector.component';
import { DateInputComponent } from './booking/booking-search/date-input/date-input.component';
import { PersonsQtyPopupComponent } from './booking/booking-search/persons-qty-popup/persons-qty-popup.component';
import {
    PersonsQtySelectorComponent,
} from './booking/booking-search/persons-qty-popup/persons-qty-selector/persons-qty-selector.component';
import { PropertySelectorComponent } from './booking/booking-search/property-selector/property-selector.component';
import { CartComponent } from './booking/cart/cart.component';
import { RemovalModalComponent } from './booking/cart/removal-modal/removal-modal.component';
import { RegionResultsComponent } from './booking/results-container/region-results/region-results.component';
import { ResultsContainerComponent } from './booking/results-container/results-container.component';
import {
    GalleryDialogComponent,
} from './booking/results-container/room-results/gallery-dialog/gallery-dialog.component';
import {
    RateInfoModalComponent,
} from './booking/results-container/room-results/rate-info-modal/rate-info-modal.component';
import { RateSelectorComponent } from './booking/results-container/room-results/rate-selector/rate-selector.component';
import { RoomResultsComponent } from './booking/results-container/room-results/room-results.component';
import { CheckoutAddressComponent } from './checkout/checkout-address/checkout-address.component';
import { CheckoutDetailsComponent } from './checkout/checkout-details/checkout-details.component';
import { CheckoutPaymentErrorComponent } from './checkout/checkout-payment-error/checkout-payment-error.component';
import {
    AdyenDropinPaymentComponent,
} from './checkout/checkout-payment/adyen-dropin-payment/adyen-dropin-payment.component';
import { AdyenPaymentComponent } from './checkout/checkout-payment/adyen-payment/adyen-payment.component';
import { CheckoutPaymentComponent } from './checkout/checkout-payment/checkout-payment.component';
import { MycheckPaymentComponent } from './checkout/checkout-payment/mycheck-payment/mycheck-payment.component';
import {
    SaferpayPaymentDialogComponent,
} from './checkout/checkout-payment/saferpay-payment/saferpay-payment-dialog.component';
import { SaferpayPaymentComponent } from './checkout/checkout-payment/saferpay-payment/saferpay-payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DatePickerComponent } from './date-range-picker/date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { NativeDatepickerComponent } from './date-range-picker/native-datepicker/native-datepicker.component';
import { DisableAutofillDirective } from './directives/disable-autofill.directive';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExtrasTemplateComponent } from './extras-template/extras-template.component';
import { ExtrasComponent } from './extras/extras.component';
import { AdyenFactory } from './factories/adyen.factory';
import { FormLabelComponent } from './form-label/form-label.component';
import { FormValidationMessageComponent } from './form-validation-message/form-validation-message.component';
import { AnchorGuard } from './guards/anchor-guard';
import { LogGuestMovementGuard } from './guards/log-guest-movement-guard';
import { GuestDetailsDialogComponent } from './guest-details-dialog/guest-details-dialog.component';
import { AddressFormComponent } from './guest-form/address-form/address-form.component';
import { DetailsFormComponent } from './guest-form/details-form/details-form.component';
import { EditAccountComponent } from './guest-management/edit-account/edit-account.component';
import {
    GuestAccountAuthComponent,
} from './guest-management/guest-create-account/guest-account-auth/guest-account-auth.component';
import { GuestCreateAccountComponent } from './guest-management/guest-create-account/guest-create-account.component';
import { GuestLoginComponent } from './guest-management/guest-login/guest-login.component';
import {
    GuestManagementHeaderComponent,
} from './guest-management/guest-management-header/guest-management-header.component';
import {
    GuestManagementOptionsComponent,
} from './guest-management/guest-management-options/guest-management-options.component';
import {
    GuestManagementSidebarComponent,
} from './guest-management/guest-management-sidebar/guest-management-sidebar.component';
import { GuestManagementComponent } from './guest-management/guest-management.component';
import { GuestPasswordResetComponent } from './guest-management/guest-password-reset/guest-password-reset.component';
import {
    AddExtrasDialogComponent,
} from './guest-management/modify-reservation/add-extras-dialog/add-extras-dialog.component';
import {
    EditReservationDatesDialogComponent,
} from './guest-management/modify-reservation/edit-reservation-dates-dialog/edit-reservation-dates-dialog.component';
import { ModifyReservationComponent } from './guest-management/modify-reservation/modify-reservation.component';
import { InvoiceListComponent } from './guest-management/reservations-history/invoice-list/invoice-list.component';
import {
    ReservationDetailComponent,
} from './guest-management/reservations-history/reservation-detail/reservation-detail.component';
import { ReservationHistoryComponent } from './guest-management/reservations-history/reservations-history.component';
import {
    ReservationListComponent,
} from './guest-management/reservations-history/reservations-list/reservations-list.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ReservationCardComponent } from './reservation-card/reservation-card.component';
import { AnalyticsService } from './services/analytics.service';
import { BookingService } from './services/booking.service';
import { ErrorDialogService } from './services/error-dialog.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { FormValidationService } from './services/form-validation.service';
import { GlobalWindowService } from './services/global-window.service';
import { GuestAuthService, jwtTokenGetter } from './services/guest-auth.service';
import { GuestManagementGuardService } from './services/guest-management-guard.service';
import { IbeConfigService } from './services/ibe-config.service';
import { ImagesService } from './services/images.service';
import { CustomCurrencyPipe } from './services/pipes/custom-currency.pipe';
import { ReservationService } from './services/reservation.service';
import { RoomResultsGuardService } from './services/room-results-guard.service';
import { SearchParamsService } from './services/room-results/search-params.service';
import { ScriptLoaderService } from './services/script-loader.service';
import { ThemeService } from './services/theme.service';
import { StepsBarComponent } from './steps-bar/steps-bar.component';
import { TotalSummaryComponent } from './total-summary/total-summary.component';
import { TranslateReservationStatusPipe } from './translate-reservation-status.pipe';

[localeDe, localeFi, localeSe, localeRu, localeFr].forEach(locale => registerLocaleData(locale));

export function HttpLoaderFactory(backend: HttpBackend) {
    const http = new HttpClient(backend);
    return new TranslateHttpLoader(http, `${environment.translationFilesUrl}/`, '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        CheckoutComponent,
        StepsBarComponent,
        BookingSearchComponent,
        RoomResultsComponent,
        LoadingBarComponent,
        GalleryDialogComponent,
        RateInfoModalComponent,
        CheckoutDetailsComponent,
        CheckoutAddressComponent,
        CheckoutPaymentComponent,
        CheckoutPaymentErrorComponent,
        BookingConfirmationComponent,
        ErrorPageComponent,
        ReservationCardComponent,
        ExtrasComponent,
        RemovalModalComponent,
        CartComponent,
        FormValidationMessageComponent,
        TotalSummaryComponent,
        AdyenPaymentComponent,
        AdyenDropinPaymentComponent,
        MycheckPaymentComponent,
        ErrorDialogComponent,
        AlertDialogComponent,
        GuestDetailsDialogComponent,
        BookingManagementComponent,
        AvailabilityCalendarComponent,
        ReactPropertyCalendarComponent,
        CalendarComponent,
        LoadingCircleComponent,
        CreditCardComponent,
        RateSelectorComponent,
        BookingCancellationDialogComponent,
        BookingPaymentDialogComponent,
        PersonsQtySelectorComponent,
        PersonsQtyPopupComponent,
        PropertySelectorComponent,
        AvailabilityRestrictionsDialogComponent,
        DateRangePickerComponent,
        DatePickerComponent,
        NativeDatepickerComponent,
        FormLabelComponent,
        GuestManagementComponent,
        GuestLoginComponent,
        GuestCreateAccountComponent,
        ReservationHistoryComponent,
        ModifyReservationComponent,
        EditAccountComponent,
        ReservationListComponent,
        GuestManagementOptionsComponent,
        GuestManagementSidebarComponent,
        ReservationDetailComponent,
        BookerDetailsComponent,
        GuestPasswordResetComponent,
        EditReservationDatesDialogComponent,
        GuestManagementHeaderComponent,
        AddExtrasDialogComponent,
        ExtrasTemplateComponent,
        TranslateReservationStatusPipe,
        CustomCurrencyPipe,
        CitySelectorComponent,
        ResultsContainerComponent,
        RegionResultsComponent,
        DateInputComponent,
        SaferpayPaymentComponent,
        SaferpayPaymentDialogComponent,
        DisableAutofillDirective,
        InvoiceListComponent,
        DateFormatPipe,
        DetailsFormComponent,
        AddressFormComponent,
        GuestAccountAuthComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToasterModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        MatDialogModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSelectModule,
        NguCarouselModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                whitelistedDomains: [
                    'ibe-server.uphotel.local:3000',
                    'ibe-server.staging.uphotel.agency',
                    'ibe-server.uphotel.agency',
                ],
            },
        }),
        MatInputModule,
    ],
    providers: [
        IbeConfigService,
        ThemeService,
        BookingService,
        AnalyticsService,
        FormValidationService,
        RoomResultsGuardService,
        GuestManagementGuardService,
        ImagesService,
        GlobalWindowService,
        ErrorDialogService,
        LogGuestMovementGuard,
        AnchorGuard,
        GuestAuthService,
        ReservationService,
        ScriptLoaderService,
        SearchParamsService,
        ToasterService,
        AdyenFactory,
        ErrorHandlerService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: IbeKeyInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JourneyTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    entryComponents: [
        GalleryDialogComponent,
        RateInfoModalComponent,
        ExtrasComponent,
        RemovalModalComponent,
        ErrorDialogComponent,
        AlertDialogComponent,
        GuestDetailsDialogComponent,
        BookingCancellationDialogComponent,
        BookingPaymentDialogComponent,
        AvailabilityRestrictionsDialogComponent,
        EditReservationDatesDialogComponent,
        GuestLoginComponent,
        AddExtrasDialogComponent,
        SaferpayPaymentDialogComponent,
    ],
    bootstrap: [AppComponent],
})
// tslint:disable-next-line: max-file-line-count
export class AppModule {
}
