<input
    matInput
    type="text"
    class="ibe-custom-select"
    placeholder="{{ placeHolderOrSelectedProperty() }}"
    (click)="onPropertyIdInputClick()"
    [formControl]="propertyId"
    [matAutocomplete]="auto"
    [ngClass]="invalidClass"
>
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayName">
    <mat-optgroup
        *ngFor="let propertyByCity of propertySelectOptions"
        [label]="propertyByCity.city"
    >
        <mat-option
            *ngFor="let property of propertyByCity.properties"
            (onSelectionChange)="onSearchSelection(property)"
            [value]="property"
        >
            {{property.name}}
        </mat-option>
    </mat-optgroup>
</mat-autocomplete>
