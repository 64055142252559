import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'app/services/analytics.service';
import { AvailabilityStoreService } from 'app/services/availability-store.service';
import { BookingService } from 'app/services/booking.service';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { ImagesService } from 'app/services/images.service';
import {
    AvailabilitySearchParams,
    INVALID_PARAMS,
    SearchParamsService,
} from 'app/services/room-results/search-params.service';
import * as moment from 'moment';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { catchError, map, mapTo, startWith } from 'rxjs/operators';
import { AvailabilityResultByRegionModel, Property } from 'up-ibe-types';
import { AlertDialogComponent } from '../../../alert-dialog/alert-dialog.component';
import { scrollToElement } from '../../../helpers/scroll.helper';
import { FilterPropertiesService } from '../../../services/filter-properties.service';

type SearchOrAvailabilityHandler = (
    [searchParams, availability]: readonly [AvailabilitySearchParams, AvailabilityResultByRegionModel[]]) => void;

@Component({
    selector: 'ibe-region-results',
    templateUrl: './region-results.component.html',
    styleUrls: ['./region-results.component.scss'],
})
export class RegionResultsComponent implements OnInit, OnDestroy {
    @ViewChild('regionResultsContainer') set regionResultsContainer(elementRef: ElementRef) {
        if (elementRef && this.config.settings.autoscrollEnabled) {
            scrollToElement(elementRef);
        }
    }

    @Output('onLoadingEmit') public onLoadingEmit: EventEmitter<Observable<boolean>> = new EventEmitter();

    private loading$: Observable<boolean>;

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public availability$: Observable<AvailabilityResultByRegionModel[]>;
    public noAvailability$: Observable<boolean>;
    public noAvailabilityShowCalendar$: Observable<boolean>;
    public searchParams: AvailabilitySearchParams;
    public dates: string;
    public persons: number;
    public region: string;
    public subscription: Subscription;
    public regionProperties: Property[];
    private cityFilter: string;

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly bookingService: BookingService,
        private readonly config: IbeConfigService,
        private readonly availabilityStoreService: AvailabilityStoreService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly searchParamsService: SearchParamsService,
        private readonly imagesService: ImagesService,
        private readonly analyticsService: AnalyticsService,
        private readonly translate: TranslateService,
        private readonly dialog: MatDialog,
        private readonly propertyFilterService: FilterPropertiesService,
    ) { }

    get propertyCalendar(): boolean {
        return (this.config.settings?.usePropertyAvailabilityCalendar === true);
    }

    public getFilterProperties = (): Property[] => {
        const properties = this.propertyFilterService.getFilteredProperties();
        const filtered = (properties && this.cityFilter)
            ? properties.filter((property) => (property?.location?.city === this.cityFilter))
            : properties;

        return filtered || [];
    };

    private onError = (error: Error): void => console.error('region-result-component', error);

    private onParamsChanged = (params: Params) => {
        this.cityFilter = params.region && this.config.properties.find((property) => (property?.location?.city === params.region))
            ? `${params.region}`
            : '';
    };

    public ngOnInit(): void {
        this.route.queryParams.subscribe(this.onParamsChanged, this.onError);

        const validParams$ = this.searchParamsService.validParams(this.route.queryParams);
        this.availability$ = this.availabilityStoreService.availabilityForRegion$(validParams$);

        this.loading$ = merge<boolean, boolean>(
            validParams$.pipe(mapTo(true)),
            this.availability$.pipe(mapTo(false)),
        )
            .pipe(
                startWith(true),
                catchError(_ => of(false)),
            );
        this.onLoadingEmit.emit(this.loading$);

        this.noAvailability$ = this.availability$.pipe(map((availability) => availability.length < 1));

        this.noAvailabilityShowCalendar$ = this.noAvailability$
            .pipe(
                map((noAvailability) => noAvailability && this.config.settings.availabilityCalendarEnabled),
                startWith(false),
            );

        this.subscription = combineLatest(validParams$, this.availability$)
            .subscribe({
                next: this.onSearchOrAvailabilityChanged,
                error: this.onSearchOrAvailabilityError,
            });
    }

    private onSearchOrAvailabilityChanged: SearchOrAvailabilityHandler = ([search, availability]) => {
        this.searchParams = search;
        this.getParamsForTitle(search);

        const noFilter = (search.params.region === 'All');
        const regionFilter = search.params.region.trim().toLowerCase();

        this.regionProperties = this.config.properties.filter((property: Property) => {
            const propertyRegion = property.location?.city?.trim().toLowerCase();
            return noFilter || (propertyRegion === regionFilter);
        });

        if (availability.length) {
            this.analyticsService.createRegionSearchImpressionEvent(this.region, availability);
        } else {
            this.analyticsService.createNoRegionResultsEvent(search.toHttpParams(), this.region);
        }
    };

    private showInvalidSearchParamsDialog = () => {
        const title = this.translate.instant('room_results.invalid_search_params_error_title');
        const message = this.translate.instant('room_results.invalid_search_params_error_message');

        this.dialog
            .open(AlertDialogComponent, { data: { title, message } })
            .afterClosed()
            .subscribe(() => {
                this.bookingService.clearSearchCriteriaFromLocalStorage();
                return this.router
                    .navigate(['booking/search'])
                    .catch((navError) => console.error('navigation failed: /booking/results', navError));
            });
    };

    private onSearchOrAvailabilityError = (err: unknown): void => {
        if (err === INVALID_PARAMS) {
            this.showInvalidSearchParamsDialog();
        } else {
            throw err;
        }
    };

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getPropertyImageUrl(propertyId: string, unitTypeId: string) {
        return this.imagesService.getPropertyImageUrl(propertyId, unitTypeId);
    }

    private getParamsForTitle(search: AvailabilitySearchParams): void {
        this.region = search.params.region;
        this.dates = this.formatDates(search.params.arrival, search.params.departure);
        this.persons = Number(search.params.adults);
        if (search.params.childrenAges) {
            this.persons = this.persons + search.params.childrenAges.length;
        }
    }

    public onResultClick(result: AvailabilityResultByRegionModel): void {
        const stayCriteriaParams = {
            ...this.searchParams.params,
            propertyId: result.property.id,
        };

        this.router
            .navigate(['/booking/results'], { queryParams: stayCriteriaParams })
            .catch((navError) => console.error('navigation failed: /booking/results', navError));

        this.bookingService.saveLastSearchedStayCriteria(stayCriteriaParams);
    }

    public formatDates(arrival: string, departure: string): string {
        const arrivalDate = moment(arrival);
        const departureDate = moment(departure);
        arrivalDate.locale(this.config.language);
        departureDate.locale(this.config.language);
        const arrivalFormatted = arrivalDate.format('Do MMM');
        const departureFormatted = departureDate.format('Do MMM');
        return `${arrivalFormatted} - ${departureFormatted}`;
    }
}
