import { Component, Input, OnInit } from '@angular/core';
import { ReservationModel } from 'up-ibe-types';
import { ReservationStatusEnum } from '../../../enums';
import { ReservationService } from '../../services/reservation.service';

@Component({
    selector: 'ibe-reservations-history',
    templateUrl: './reservations-history.component.html',
    styleUrls: ['./reservations-history.component.scss'],
})
export class ReservationHistoryComponent implements OnInit {
    @Input('reservations') public reservations: ReservationModel[] = [];

    // FIXME: DO NOT use public properties
    public isLoading = false;

    constructor(
        private readonly reservationService: ReservationService,
    ) { }

    public ngOnInit() {
        this.getReservationHistory();
    }

    public getReservationHistory() {
        this.isLoading = true;
        this.reservationService.getGuestReservations()
            .subscribe((reservations: ReservationModel[]) => {
                if (reservations.length) {
                    this.reservations = this.reservationService.filterReservationsByStatuses(reservations, [
                        ReservationStatusEnum.Canceled,
                        ReservationStatusEnum.CheckedOut,
                        ReservationStatusEnum.NoShow,
                    ]);
                } else {
                    this.reservations = [];
                }
                this.isLoading = false;
            });
    }
}
