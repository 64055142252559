import { Language, NOT_FOUND_TEXT } from 'app/fixme-inline-types';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReservationCancellationFeeModel, ReservationModel } from 'up-ibe-types';

export class CancellationFeeViewModel {
    constructor(
        private readonly model: ReservationCancellationFeeModel | undefined,
    ) {}

    public description() {
        return (this.model) ? this.model.description : NOT_FOUND_TEXT;
    }

    public amount() {
        return _.get(this.model, 'fee.amount');
    }

    public currency() {
        return _.get(this.model, 'fee.currency');
    }

    public dueDateTime() {
        if (this.model && this.model.dueDateTime) {
            return moment(this.model.dueDateTime).toDate();
        } else {
            return undefined;
        }
    }

    public isPayable() {
        return Boolean(
            this.model &&
            this.model.dueDateTime &&
            this.model.fee &&
            moment().isAfter(this.model.dueDateTime) &&
            this.model.fee.amount > 0,
        );
    }

    public amountDue() {
        return _.get(this.model, 'cancellationFee.fee.amount') || 0;
    }

    public formattedDueDate(language: Language) {
        if (this.model) {
            const momentDate = moment(this.model.dueDateTime);
            momentDate.locale(language);
            return `${momentDate.format('DD MMM. YYYY')} at ${momentDate.format('H:mm a')}`;
        } else {
            return undefined;
        }
    }

    public isNotEmpty(): boolean {
        return !Boolean(this.model);
    }
}

/**
 * more convenient access to deeply nested properties
 **/
export class ReservationViewModel {
    public readonly cancellationFee: CancellationFeeViewModel;

    constructor(
        private readonly reservation: ReservationModel,
    ) {
        this.cancellationFee = new CancellationFeeViewModel(reservation.cancellationFee);
    }

    public bookingReference() {
        return (this.reservation) ? this.reservation.bookingReference : NOT_FOUND_TEXT;
    }

    public primaryGuest() {
        return (this.reservation) ? this.reservation.primaryGuest : NOT_FOUND_TEXT;
    }

    public isNotEmpty() {
        return !Boolean(this.reservation);
    }
}
