import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormValidationService } from '../services/form-validation.service';

@Component({
    selector: 'ibe-form-validation-message',
    templateUrl: './form-validation-message.component.html',
    styleUrls: ['./form-validation-message.component.scss'],
})
export class FormValidationMessageComponent implements OnInit {
    @Input() public form: FormGroupDirective;
    @Input() public control: AbstractControl;
    @Input() public submitObservable: Observable<boolean> | undefined;

    private formError: string = '';
    public get errorMessage(): string { return this.formError; }

    constructor(private readonly validation: FormValidationService) { }

    public ngOnInit() {
        this.control?.valueChanges?.subscribe(this.setErrorMessage);
        this.form?.ngSubmit?.subscribe(this.setErrorMessage);
        this.submitObservable?.subscribe(this.setErrorMessage);
    }

    private setErrorMessage = () => {
        if (this.control?.errors) {
            // errorName: name of the validation rule which failed ... required | invalidEmail | ...
            for (const errorName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(errorName)) {
                    this.formError = this.validation.getValidatorErrorMessage(
                        this.control.errors[errorName],
                        errorName,
                    );
                }
            }
        } else {
            this.formError = '';
        }
    };
}
