import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExtraModel, PricingUnit } from 'up-ibe-types';
import { ImagesService } from '../services/images.service';

@Component({
    selector: 'ibe-extras-template',
    templateUrl: './extras-template.component.html',
    styleUrls: ['./extras-template.component.scss'],
})
export class ExtrasTemplateComponent implements OnChanges {
    @Input('extras') public extras: ExtraModel[] = [];
    @Input('selectedExtras') public selectedExtras: ExtraModel[] = [];
    @Input('isLoading') public isLoading = false;

    @Output('onSelectedExtrasUpdate') public onSelectedExtrasUpdate: EventEmitter<ExtraModel[]> = new EventEmitter();

    private extraImageFound: boolean = false;
    public get haveImages(): boolean { return this.extraImageFound; };

    constructor(
        private readonly imagesService: ImagesService,
        private readonly translate: TranslateService,
    ) {}

    public ngOnChanges() {
        if (this.extras) {
            this.extras.forEach((extra) => {
                if (extra.image) {
                    this.extraImageFound = true;
                }
                if (extra.isInclusiveInRate && !this.isSelected(extra.id)) {
                    this.selectedExtras.push(extra);
                }
            });
        }
    }

    public toggleExtra(extra: ExtraModel) {
        if (this.isExtraSelected(extra.id)) {
            this.removeExtraFromSelectedExtras(extra);
        } else {
            this.addExtraToSelectedExtras(extra);
        }
        this.onSelectedExtrasUpdate.emit(this.selectedExtras);
    }

    private isSelected = (extraId: string): boolean => {
        return this.selectedExtras.some((selectedExtra) => (selectedExtra.id === extraId));
    };
    public isExtraSelected(extraId: string): boolean {
        return (this.selectedExtras) && this.isSelected(extraId);
    }

    public getExtraImageUrl(extra: ExtraModel) {
        return this.imagesService.getExtraImageUrl(extra);
    }

    public translatePricingUnit(pricingUnit?: PricingUnit) {
        const stringToTranslate = pricingUnit ? `extras.${pricingUnit.toString().toLowerCase()}` : '';
        return this.translate.instant(stringToTranslate);
    }

    private addExtraToSelectedExtras(extra: ExtraModel) {
        this.selectedExtras = this.selectedExtras.concat(extra);
        return;
    }

    private removeExtraFromSelectedExtras(extra: ExtraModel) {
        this.selectedExtras = this.selectedExtras.filter(
            (element: ExtraModel) => { return extra.id !== element.id; },
        );
    }
}
