import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BookingTotals } from 'app/fixme-inline-types';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Property, ReservationModel } from 'up-ibe-types';
import { flyRightOnLeaveAnimation } from '../animations';
import { calculateCityTaxEstimate } from '../helpers/booking.helper';
import { BookingService } from '../services/booking.service';
import { GuestAuthService } from '../services/guest-auth.service';
import { IbeConfigService } from '../services/ibe-config.service';

@Component({
    selector: 'ibe-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    animations: [
        flyRightOnLeaveAnimation,
    ],
})
export class CheckoutComponent implements OnInit {
    // FIXME DO NOT USE PUBLIC PROPERTIES
    public isReservationsEditable = false;
    public bookingTotals: BookingTotals;
    public showCityTaxEstimateEnabled = false;
    public showServiceChargeEnabled = true;
    public displayInclusiveExtrasAsTaxes = false;
    public cityTaxEstimate = 0;
    public isSuppressed = false;

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly currentRoute: ActivatedRoute,
        private readonly bookingService: BookingService,
        private readonly config: IbeConfigService,
        private readonly router: Router,
        private readonly guestAuthService: GuestAuthService,
    ) {
        this.isSuppressed = !!(this.reservations.find(reservation => reservation.suppressedRate));
    }

    public get useRentalAgreement(): boolean { return this.config?.accountFeatureWhitelist?.useRentalAgreement; }
    public get reservations(): ReservationModel[] { return this.bookingService.getReservations(); }
    public get bookingCurrency(): string { return this.bookingService.bookingCurrency(); }

    public ngOnInit() {
        // slight leaky abstraction, this means they were redirected back here
        // after a myCheck iDEAL transaction. Can't set in deeper components
        // as they can't load, because localStorage might already be cleared.
        if (this.currentRoute.snapshot.queryParamMap.has('mycheck-transaction-id')) {
            return window.close();
        }

        if (this.reservations.length < 1) {
            return this.router.navigate(['booking/results']);
        }

        this._setIsReservationEditable();

        // FIXME: WTF ... WHY? This is a booker details form and has nothing to do with Properties
        this.config.setCurrentProperty(this.reservations[0].property.id);
        this.config
            .getCurrentPropertySubscribable()
            .subscribe(
                this.onCurrentPropertyChanged,
                // FIXME: onError
            );

        this.showCityTaxEstimateEnabled = this.config.settings.showCityTaxEstimateEnabled;
        if (this.showCityTaxEstimateEnabled) {
            this.cityTaxEstimate = calculateCityTaxEstimate(this.reservations);
        }
        this._removePmsGuestIdIfLoggedOut();
    }

    public allowAddExtra = (): boolean => {
        // changing the reservation (extras) is not allowed when the payment step is active:
        // 1. the reservation is locked and a 'ibe-booking-request' was created on the server
        // 2. the amount is set and send to the payment provider
        return (this.router.url !== '/checkout/payment');
    };

    public addAnotherRoom() {
        this.router.navigate(['booking/results'])
            .catch((error) => console.error(error));
    }

    public onRemoveReservation() {
        this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
        this.cityTaxEstimate = calculateCityTaxEstimate(this.reservations);
    }

    public onExtrasChange() {
        this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
    }

    private _setIsReservationEditable() {
        if (this.currentRoute.snapshot.firstChild && this.currentRoute.snapshot.firstChild.data.isReservationsEditable) {
            this.isReservationsEditable = this.currentRoute.snapshot.firstChild.data.isReservationsEditable;
        }

        this.router
            .events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.currentRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
            )
            .subscribe(
                (event) => {
                    this.isReservationsEditable = event.isReservationsEditable;
                },
            );
    }

    private _removePmsGuestIdIfLoggedOut() {
        if (!this.guestAuthService.isLoggedIn()) {
            this.bookingService.removePmsGuestIdFromReservation();
        }
    }

    private onCurrentPropertyChanged = (property: Property): void => {
        if (!property) {
            this.displayInclusiveExtrasAsTaxes = false;
        }

        if (property.config.displayInclusiveExtrasAsTaxes !== undefined) {
            this.displayInclusiveExtrasAsTaxes = property.config.displayInclusiveExtrasAsTaxes;
        }

        this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
    };
}
