/* tslint:disable:max-file-line-count */
// FIXME: this component is to large - split into sub-components / services
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddressData, DetailsData, Language } from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { GuaranteeTypeEnum, TravelPurposeEnum } from 'enums';
import { Observable } from 'rxjs';
import {
    BookerModel,
    CheckoutFields,
    PredefinedCommentBubbles,
    ReservationModel,
    SelectOption,
    Settings,
    TermsAndConditions,
    TravelPurpose,
} from 'up-ibe-types';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { GuestLoginComponent } from '../../guest-management/guest-login/guest-login.component';
import { generateGuestTitleOptions, isFormControlInvalid } from '../../helpers/form.helper';
import { AnalyticsService } from '../../services/analytics.service';
import { BookingService } from '../../services/booking.service';
import { FormValidationService } from '../../services/form-validation.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { IbeConfigService } from '../../services/ibe-config.service';

type GuestCommentType = string | string[];

@Component({
    selector: 'ibe-details-form',
    templateUrl: './details-form.component.html',
    styleUrls: ['./details-form.component.scss'],
    animations: [fadeInOnEnterAnimation, scaleUpAnimation],
})
export class DetailsFormComponent implements OnInit {
    @ViewChild('detailsFormDirective', { static: true }) public detailsFormDirective: FormGroupDirective;

    @Input() public showCreateAccountBox: boolean;
    @Input() public submitObservable: Observable<boolean>;

    @Output() public formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() public toggleShowCreateAccountButton: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public updateLicensePlateNumber: EventEmitter<string> = new EventEmitter<string>();

    private commentBubblesOptions: SelectOption<string>[] = [];

    // FIXME: DO NOT use public properties
    public detailsForm: FormGroup;
    public companyForm: FormGroup;
    public readonly guestTitleOptions = generateGuestTitleOptions(this.translate, this.config.pmsProvider);
    public readonly travelPurposeOptions = this.generateTravelPurposeOptions();
    public ibeSettings: Settings;
    public isTravelPurposeBusiness = false;
    public guest: BookerModel;
    public companyInfoRequired: boolean = false;
    public isCreateAccountLoading = false;
    public termsConditionsUrl: string;
    public isChrome: boolean = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    public isOnCheckout = this.router.url.includes('checkout');
    public licensePlate: string;

    constructor(
        // FIXME: DO NOT use public properties
        public readonly guestAuthService: GuestAuthService,
        public readonly config: IbeConfigService,
        private readonly dialog: MatDialog,
        private readonly bookingService: BookingService,
        private readonly toasterService: ToasterService,
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        private readonly analyticsService: AnalyticsService,
        private readonly router: Router,
    ) {
        this.ibeSettings = this.config.settings;

        // TODO: extreme long constructor
        //     - reduce number of lines
        //     - use functions 'really they are fancy crazy stuff'
        //     - downside: they might help with code readability & maintainability

        this.companyForm = this.formBuilder.group({
            'companyName': ['', [Validators.required]],
            'companyTaxId': ['', [Validators.required]],
        });

        this.detailsForm = this.formBuilder.group({
            'title': ['', [Validators.required]],
            'firstName': ['', [Validators.required]],
            'lastName': ['', [Validators.required]],
            'email': [
                { value: '', disabled: true },
                [Validators.required, FormValidationService.emailValidator],
            ],
            'travelPurpose': ['', Validators.required],
            'password': ['', [FormValidationService.passwordStrengthValidator]],
            'phone': ['', [Validators.required, FormValidationService.phoneValidator]],
            'guestComment': [''],
            'termsConditions': [false, [Validators.requiredTrue]],
            'company': this.companyForm,
        });

        if (!this.isOnCheckout) {
            // FIXME this.ibeSettings is a reference NOT a copy >>> this code change global 'DO NOT change' configs
            this.ibeSettings.checkoutFields.details.guestComment = false;
            this.ibeSettings.checkoutFields.details.companyName = false;
            this.ibeSettings.checkoutFields.details.companyTaxId = false;
            this.ibeSettings.checkoutFields.address.marketingConsent = false;
        }

        if (!this.isOnCheckout || this.config.isLongStayProperty()) {
            this.ibeSettings.checkoutFields.details.travelPurpose = false;
            this.detailsForm.removeControl('termsConditions');
        }

        for (const field in this.ibeSettings.checkoutFields.details) {
            if (!this.ibeSettings.checkoutFields.details[field as keyof CheckoutFields['details']]) {
                if (field.includes('company')) {
                    this.companyForm.removeControl(field);
                }
                this.detailsForm.removeControl(field);
            }
        }
        if (this.config?.accountFeatureWhitelist?.licensePlateField) {
            const fieldGuestComment = new FormControl('');
            this.detailsForm.addControl('guestComment', fieldGuestComment);
        }
        this.showCreateAccountBox = this.isOnCheckout;
        this.termsConditionsUrl = this.getTermsConditionsUrl();
    }

    public ngOnInit() {
        this.initPredefinedCommentBubbleOptions();
        this.updateForm();
        this.createCheckoutDetailsEvent();
        this.activateCompanyField();

        this.handleLoggedInGuest();

        if (this.isForceCommercialPurposeEnabled) {
            this.detailsForm.patchValue({ travelPurpose: TravelPurposeEnum.Business });
            this.onTravelPurposeFieldChange(TravelPurposeEnum.Business);
        }

        this.formReady.emit(this.detailsForm);
    }

    get isForceCommercialPurposeEnabled(): boolean {
        return (this.config?.settings?.forceCommercialPurpose === true);
    }

    get isGuestCommentEnabledAndAvailable(): boolean {
        const enabled = (this.ibeSettings?.checkoutFields?.details?.guestComment === true);
        const useTextBox = !this.isPredefinedCommentBubblesEnabled;
        const available = useTextBox || (this.predefinedCommentBubblesOptions.length > 0);

        return enabled && available;
    }

    get isPredefinedCommentBubblesEnabled(): boolean {
        return (this.config?.accountFeatureWhitelist?.predefinedCommentBubbles === true);
    }

    get predefinedCommentBubblesOptions(): SelectOption<string>[] {
        return this.commentBubblesOptions;
    }

    public onTravelPurposeFieldChange(value: TravelPurpose | undefined) {
        this.isTravelPurposeBusiness = false;
        if (value === 'Business') {
            if (this.ibeSettings.checkoutFields.details.companyName) {
                const fieldCompanyName = new FormControl('', Validators.required);
                this.companyForm.addControl('companyName', fieldCompanyName);

                if (this.ibeSettings.checkoutFields.details.companyTaxId) {
                    const fieldCompanyTaxId = new FormControl('', Validators.required);
                    this.companyForm.addControl('companyTaxId', fieldCompanyTaxId);
                }
            }
            this.isTravelPurposeBusiness = true;
        } else {
            if (this.companyForm.contains('companyName')) {
                this.companyForm.removeControl('companyName');
            }
            if (this.companyForm.contains('companyTaxId')) {
                this.companyForm.removeControl('companyTaxId');
            }
        }
    }

    private activateCompanyField() {
        if (this.config.settings.checkoutFields.details.companyTaxId) {
            const reservations = this.bookingService.getReservations();

            const isCompanyRequired = (reservation: ReservationModel) => (
                (reservation.guaranteeType === GuaranteeTypeEnum.Company)
                || reservation.corporateCode
            );
            this.companyInfoRequired = reservations.some(isCompanyRequired);

            if (this.companyInfoRequired) {
                this.companyInfoRequired = true;

                const fieldCompanyName = new FormControl('', Validators.required);
                this.companyForm.addControl('companyName', fieldCompanyName);

                const fieldCompanyTaxId = new FormControl('', Validators.required);
                this.companyForm.addControl('companyTaxId', fieldCompanyTaxId);
            }
        }
    }

    public isFormControlInvalid(formControl: AbstractControl): boolean {
        return isFormControlInvalid(formControl, this.detailsFormDirective);
    }

    public openLoginDialog(): void {
        this.dialog.closeAll();

        const dialogOptions = { height: '550px', width: '500px' };
        this.dialog
            .open(GuestLoginComponent, dialogOptions)
            .afterClosed()
            .subscribe(
                this.onGuestLoginClosed,
                // FIXME: onError
            );
    }

    private onGuestLoginClosed = (loggedIn: boolean): void => {
        if (loggedIn) {
            this.showCreateAccountBox = false;
            this.toggleShowCreateAccountButton.emit(false);
        }
    };

    private updateForm(): void {
        // TODO: verify booker is set / complete and of type BookerModel
        const booker: BookerModel = this.bookingService.getBooker();
        const bookerComment = this.bookingService.getBookerComment();

        if (booker.company) {
            this.companyForm.patchValue({
                companyName: booker.company.name,
                companyTaxId: booker.company.taxId,
            });
        }

        // guest comment is either a string or a string[] - depends on feature and checkout field settings
        const guestComment = this.convertBookerComment(bookerComment);
        const travelPurpose = this.getTravelPurpose();
        this.detailsForm.patchValue({
            ...booker,
            guestComment,
            travelPurpose,
            company: this.companyForm,
        });

        this.parseAndReplaceLicensePlatesFromComments();
        this.onTravelPurposeFieldChange(travelPurpose);
    }

    private parseAndReplaceLicensePlatesFromComments(): void {
        // FIXME: license plates should not use comments as 'storage', this makes other features incompatible.
        // TODO: store license plates in a separate value, join them to the comment string in 'createBookingRequest'
        // @see: IbeConfigService.fixInvalidSettings
        const commentField = this.detailsForm.get('guestComment');
        if (commentField) {
            const comment: string | string[] = commentField?.value;
            if (comment && (typeof comment === 'string')) {
                this.licensePlate = comment.substring(comment.indexOf('/PI'));
                commentField.setValue(comment.replace(` ${this.licensePlate}`, ''));
            }
        }
    }

    private generateTravelPurposeOptions(): SelectOption<TravelPurposeEnum>[] {
        const values = [TravelPurposeEnum.Business, TravelPurposeEnum.Leisure];
        const asSelectOption = (value: TravelPurposeEnum) => {
            const label = this.translate.instant(`checkout_details.travel_purposes.${value}`);
            return { label: `${label}`, value };
        };

        return values.map(asSelectOption);
    }

    private createCheckoutDetailsEvent(): void {
        const step = 1;
        const reservations = this.bookingService.getReservations();
        this.analyticsService.createCheckoutEvent(reservations, step);
    }

    private getTravelPurpose(): TravelPurpose | undefined {
        const fieldTravelPurpose = this.detailsForm.get('travelPurpose');

        const reservations = this.bookingService.getReservations();
        // storedValue is used in case no travel purpose is select and the guest returns from any other view
        const storedTravelPurpose = reservations[0]?.travelPurpose;

        return fieldTravelPurpose ? fieldTravelPurpose.value : storedTravelPurpose;
    }

    public onLicensePlateChange = (event: string) => {
        this.updateLicensePlateNumber.emit(event);
    };

    private getTermsConditionsUrl() {
        const language: Language = this.config.language;
        const value: unknown = this.config?.settings?.termsAndConditionsUrls[language];
        const termsUrl = value && (typeof value === 'string') ? `${value}` : '';
        const defaultKey: keyof TermsAndConditions = this.config.settings.defaultLanguage as keyof TermsAndConditions;

        return termsUrl.length ? termsUrl : this.config.settings.termsAndConditionsUrls[defaultKey];
    }

    private compilePredefinedCommentOptions = (
        predefinedCommentBubbles: PredefinedCommentBubbles,
        language: keyof PredefinedCommentBubbles,
    ): SelectOption<string>[] => {
        const asSelectOption = (comment: string) => ({ label: comment, value: comment });

        return predefinedCommentBubbles[language]?.map(asSelectOption);
    };

    private initPredefinedCommentBubbleOptions(): void {
        const { language } = this.config;
        const { predefinedCommentBubbles } = this.config.settings;

        this.commentBubblesOptions = (predefinedCommentBubbles[language]?.length > 0)
            ? this.compilePredefinedCommentOptions(predefinedCommentBubbles, language)
            : [];
    }

    private convertBookerComment = (bookerComment: string): GuestCommentType => {
        return (this.isPredefinedCommentBubblesEnabled)
            ? bookerComment.split(', ')
            : bookerComment;
    };

    private handleLoggedInGuest = (): void => {
        const isGuestLoggedIn = this.guestAuthService.isLoggedIn();
        if (isGuestLoggedIn && this.isOnCheckout) {
            const guestId = this.guestAuthService.getPmsGuestId();
            this.guestAuthService.fetchGuestByPmsId({ guestId })
                .subscribe(
                    this.onGuestLoaded,
                    this.onGuestLoadError,
                );

            this.showCreateAccountBox = false;
        } else {
            if (this.detailsForm.controls.email) {
                this.detailsForm.controls['email'].enable();
            }
            if (this.detailsForm.controls.password) {
                this.detailsForm.controls.password.valueChanges.subscribe((value) => {
                    if (value) {
                        this.toggleShowCreateAccountButton.emit(true);
                    } else {
                        this.toggleShowCreateAccountButton.emit(false);
                    }
                });
            }
        }
    };

    // FIXME: this is an API call !!! response is untyped, really ??? - DO NOT use inline types - NEVER
    private onGuestLoaded = (response: { success: boolean, user: BookerModel }): void => {
        if (response.success) {
            this.guest = response.user;
            this.guest.email = this.guestAuthService.getUsername();

            const guestId = this.guestAuthService.getPmsGuestId();

            this.bookingService.addPmsGuestIdToReservation(guestId);
            this.bookingService.addDetailsToReservation(this.guest as DetailsData);
            if (this.guest.address) {
                // FIXME: unverified data: DO NOT lie to others / DO NOT type-cast random data
                this.bookingService.addAddressToReservation(this.guest.address as AddressData);
            }
            this.updateForm();
        } else {
            // FIXME: damn every if has an else - wish we could ignore them
        }
    };

    // tslint:disable-next-line:no-any
    private onGuestLoadError = (response: any) => {
        const title = this.translate.instant('guest_management_auth.fetch_failed');
        const body = response.error.message;

        this.toasterService.showError(title, body);
    };
}
