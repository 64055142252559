import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { asNumber, asString, isArray } from 'app/helpers/type.helper';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AngularRouterParams, SearchParams } from '../../types';

export const INVALID_PARAMS = 'Invalid Params';

export class AvailabilitySearchParams {
    private searchParams: SearchParams = {
        propertyId: '',
        arrival: moment().format('YYYY-MM-DD'),
        departure: moment().add(1, 'day').format('YYYY-MM-DD'),
        adults: 1,
        childrenAges: [],
        promoCode: '',
        ratePlanId: '',
        region: '',
    };

    get params(): SearchParams {
        return this.searchParams;
    }

    constructor(queryParams: AngularRouterParams) {
        this.parseQueryParams(queryParams);
    }

    public isValid(): boolean {
        const params = this.searchParams;
        const now = moment();
        const arrival = moment(params.arrival, 'YYYY-MM-DD', true);
        const departure = moment(params.departure, 'YYYY-MM-DD', true);

        // required values
        const required = (params.propertyId !== '') && (params.adults > 0);

        // date range validation [ now <= arrival < departure ]
        const validArrival = arrival.isValid() && arrival.isSameOrAfter(now, 'day');
        const validDeparture = departure.isValid() && departure.isAfter(arrival, 'day');

        return required && validArrival && validDeparture;
    }

    // filters out empty values
    public toHttpParams(): HttpParams {
        const values: Record<string, string> = {
            // copy string values
            ...this.searchParams,
            // convert numbers
            adults: asString(this.searchParams.adults, ''),
            childrenAges: this.searchParams.childrenAges.join(','),
        };

        // return only non empty values
        const params: Record<string, string> = {};
        Object.keys(values).forEach((key: string) => {
            if (values[key] !== '') {
                params[key] = values[key];
            }
        });

        return new HttpParams({ fromObject: params });
    }

    private parseChildrenAges = (value: unknown): number[] => {
        const ages: string[] = isArray(value)
            ? value.map((val: unknown) => asString(val, ''))
            : asString(value, '').split(',');

        // convert to numbers / remove invalid values (allow 'greater than or equal to' zero)
        return ages
            .map((age) => asNumber(age, -1))
            .filter((age) => (age >= 0));
    };

    private parseQueryParams = (queryParams: AngularRouterParams): void => {
        this.searchParams.propertyId = asString(queryParams?.propertyId, this.searchParams.propertyId);
        this.searchParams.arrival = asString(queryParams?.arrival, this.searchParams.arrival);
        this.searchParams.departure = asString(queryParams?.departure, this.searchParams.departure);
        this.searchParams.adults = asNumber(queryParams?.adults, this.searchParams.adults);
        this.searchParams.childrenAges = this.parseChildrenAges(queryParams?.childrenAges);

        // optional or feature
        this.searchParams.promoCode = asString(queryParams?.promoCode, this.searchParams.promoCode);
        this.searchParams.ratePlanId = asString(queryParams?.ratePlanId, this.searchParams.ratePlanId);
        this.searchParams.region = asString(queryParams?.region, this.searchParams.region);
    };
}

@Injectable({
    providedIn: 'root',
})
export class SearchParamsService {
    /*
      Gives an observable that resolves when you get some valid params
      Will never resolve if invalid params are provided
    */
    public validParams(queryParams: Observable<Params>): Observable<AvailabilitySearchParams> {
        return queryParams.pipe(
            map((params) => new AvailabilitySearchParams(params)),
            mergeMap((searchParams: AvailabilitySearchParams) => searchParams.isValid()
                ? of(searchParams)
                : throwError(INVALID_PARAMS),
            ),
        );
    }
}
