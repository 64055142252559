<section class="ibe-guest-management ibe-guest-management-section">
    <ibe-guest-management-header
        type="detail"
        [isReservationUnModifiable]="isReservationUnModifiable"
    ></ibe-guest-management-header>
    <div *ngIf="!reservation && isLoading">
        <ibe-loading-circle></ibe-loading-circle>
    </div>
    <div *ngIf="!reservation && !isLoading">
        <h2>{{ 'guest_management.no_reservation_found' | translate }}</h2>
    </div>
    <div class="ibe-reservation-guest-management-detail" *ngIf="reservation && !isLoading">
        <div class="ibe-row ibe-reservation-detail-header">
            <div>
                <span class="ibe-reservation-detail-id">
                    {{ 'manage_booking.reservation_id' | translate }}:
                    #{{reservation.bookingReference}}
                </span>
                <br>
            </div>
            <div class="ibe-reservation-detail-status ibe-mobile">
                <span>
                    <strong>{{ 'guest_management.status' | translate }}:</strong>
                    {{ reservation.status|translateReservationStatus }}
                </span>
            </div>
            <div
                *ngIf="invoices.length === 0"
                class="ibe-reservation-detail-action-buttons"
                [ngClass]="{'ibe-hide-reservation-actions': (reservation.status !== 'CheckedOut' && isReservationUnModifiable)}"
            >
                <a
                    *ngIf="reservation.status === 'CheckedOut'"
                    class="ibe-btn ibe-download-invoice-btn"
                    (click)="downloadInvoice()"
                >
                    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <title>file</title>
                        <g id="file">
                            <g id="lines">
                                <line
                                    id="line-3"
                                    x1="15.549"
                                    y1="33.676"
                                    x2="32.451"
                                    y2="33.676"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    fill="none"
                                />
                                <line
                                    id="line-2"
                                    x1="15.549"
                                    y1="26.538"
                                    x2="32.451"
                                    y2="26.538"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    fill="none"
                                />
                                <line
                                    id="line-1"
                                    x1="15.549"
                                    y1="19.4"
                                    x2="32.451"
                                    y2="19.4"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    fill="none"
                                />
                            </g>
                            <path
                                id="page"
                                d="M39,41.9V11.863L31.451,5H10.211A1.16,1.16,0,0,0,9,6.1V41.9A1.16,1.16,0,0,0,10.211,43H37.789A1.16,1.16,0,0,0,39,41.9Z"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <polyline
                                id="fold"
                                points="30.287 5 30.287 12.92 39 12.92"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </g>
                    </svg>
                    {{ 'guest_management.download_invoice' | translate }}
                </a>
                <a
                    *ngIf="!isReservationUnModifiable"
                    (click)="openCancellationDialog()"
                    class="ibe-btn ibe-cancel-rerservation-btn"
                >
                    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <title>cross</title>
                        <line
                            id="tick"
                            x1="9.511"
                            y1="38.489"
                            x2="38.489"
                            y2="9.511"
                            stroke-width="2"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill="none"
                        />
                        <line
                            id="tick-2"
                            data-name="tick"
                            x1="38.489"
                            y1="38.489"
                            x2="9.511"
                            y2="9.511"
                            stroke-width="2"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill="none"
                        />
                    </svg>
                    {{ 'manage_booking.cancel_reservation' | translate }}
                </a>
            </div>
        </div>

        <div class="ibe-row ibe-reservation-detail-card" *ngIf="reservation && !isLoading">
            <div
                class="ibe-col-lg-4 ibe-reservation-detail-unit-image"
                [ngClass]="{'ibe-col-lg-6': (reservation.extras.length === 0)}"
            >
                <div [ngStyle]="{'background-image': 'url(' + getUnitTypeImageUrl(reservation.unitType.id) + ')'}"></div>
            </div>
            <div
                class="ibe-col-lg-4 ibe-reservation-detail-stay-info"
                [ngClass]="{'ibe-reservation-has-total-summary ibe-col-lg-6': (reservation.extras.length === 0)}"
            >
                <div class="ibe-reservation-detail-unit-title">
                    <div>
                        <h2>{{ reservation.unitType.name }}</h2>
                        <h5>{{ reservation.property.name }}</h5>
                    </div>
                    <div class="ibe-reservation-detail-status ibe-desktop">
                        <span>
                            <strong>{{ 'guest_management.status' | translate }}:</strong>
                            {{ reservation.status|translateReservationStatus }}</span>
                    </div>
                </div>
                <div class="ibe-reservation-detail-stay-price">
                    <p>
                        <span class="ibe-reservation-detail-price"> {{ reservation.totalGrossAmount.amount | currency: reservation.totalGrossAmount.currency:'symbol':'1.2-2' }} </span>
                        <span
                            class="ibe-reservation-detail-rate-plan"
                            *ngIf="reservation.ratePlan.pmsDescription"
                            matTooltip="{{ reservation.ratePlan.pmsDescription }}"
                            matTooltipClass="ibe-reservation-pay-now-tooltip"
                        >
                            {{ reservation.ratePlan.name }}
                            <svg
                                id="Icons"
                                class="ibe-reservation-detail-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="info">
                                    <circle
                                        id="circle"
                                        cx="24"
                                        cy="24"
                                        r="19"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <g id="letter-i">
                                        <line
                                            id="stem"
                                            x1="24"
                                            y1="19.713"
                                            x2="24"
                                            y2="34.171"
                                            stroke-width="2"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                        <line
                                            id="tittle"
                                            x1="24"
                                            y1="13.829"
                                            x2="24"
                                            y2="14.829"
                                            stroke-width="2"
                                            stroke="#fff"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <span
                            class="ibe-reservation-detail-rate-plan"
                            *ngIf="!reservation.ratePlan.pmsDescription"
                        >{{ reservation.ratePlan.name }}</span>
                        <br>
                        <br>
                        <a
                            *ngIf="!isReservationUnModifiable && reservation.cancellationFee"
                            class="ibe-reservation-detail-cancellation-btn"
                            (click)="openCancellationDialog()"
                        >{{ 'guest_management.view_cancellation_policy' | translate }}</a>
                    </p>
                </div>
                <div class="ibe-reservation-detail-stay">
                    <p class="ibe-reservation-detail-stay-persons">
                        <svg
                            id="Icons"
                            class="ibe-reservation-detail-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                        >
                            <g id="guest">
                                <circle
                                    id="head"
                                    cx="24"
                                    cy="14.75"
                                    r="9.25"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                                <path
                                    id="body"
                                    d="M8.691,39.309a15.309,15.309,0,0,1,30.618,0"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                        <span *ngIf="reservation.adults === 1">{{ reservation.adults }} {{ 'booking_search.adult' | translate }}</span>
                        <span *ngIf="reservation.adults > 1">{{ reservation.adults }} {{ 'booking_search.adults' | translate }}</span>
                        <svg
                            *ngIf="reservation.childrenAges.length"
                            id="Icons"
                            class="ibe-reservation-detail-icon ibe-reservation-detail-children-count"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                        >
                            <g id="guest">
                                <circle
                                    id="head"
                                    cx="24"
                                    cy="14.75"
                                    r="9.25"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                                <path
                                    id="body"
                                    d="M8.691,39.309a15.309,15.309,0,0,1,30.618,0"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                        <span
                            *ngIf="reservation.childrenAges.length === 1"
                            class="ibe-reservation-detail-children-count"
                        >{{ reservation.childrenAges.length }} {{ 'offer_card.child' | translate }}</span>
                        <span
                            *ngIf="reservation.childrenAges.length > 1"
                            class="ibe-reservation-detail-children-count"
                        >{{ reservation.childrenAges.length }} {{ 'offer_card.children' | translate }}</span>
                    </p>
                    <p class="ibe-resevation-detail-nights">
                        <svg
                            id="Icons"
                            class="ibe-reservation-detail-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                        >
                            <g id="calendar">
                                <rect
                                    id="frame"
                                    x="7.787"
                                    y="9.003"
                                    width="32.427"
                                    height="30.335"
                                    rx="1"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    fill="none"
                                />
                                <line
                                    id="accent-line"
                                    x1="7.812"
                                    y1="16.123"
                                    x2="40.213"
                                    y2="16.123"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    fill="none"
                                />
                                <g id="hooks">
                                    <line
                                        id="right-hook"
                                        x1="33.69"
                                        y1="6.663"
                                        x2="33.69"
                                        y2="11.141"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <line
                                        id="left-hook"
                                        x1="14.862"
                                        y1="6.663"
                                        x2="14.862"
                                        y2="11.141"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                </g>
                                <g id="dates">
                                    <line
                                        x1="24.594"
                                        y1="21.807"
                                        x2="23.594"
                                        y2="21.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="32.594"
                                        y1="21.807"
                                        x2="31.594"
                                        y2="21.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="24.594"
                                        y1="27.807"
                                        x2="23.594"
                                        y2="27.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="32.594"
                                        y1="27.807"
                                        x2="31.594"
                                        y2="27.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="24.594"
                                        y1="33.807"
                                        x2="23.594"
                                        y2="33.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="32.594"
                                        y1="33.807"
                                        x2="31.594"
                                        y2="33.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="16.594"
                                        y1="27.807"
                                        x2="15.594"
                                        y2="27.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="16.594"
                                        y1="33.807"
                                        x2="15.594"
                                        y2="33.807"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </g>
                        </svg>
                        <span *ngIf="numberOfNights == 1">{{ numberOfNights }} {{ 'offer_card.night' | translate }}</span>
                        <span *ngIf="numberOfNights > 1">{{ numberOfNights }} {{ 'offer_card.nights' | translate }}</span>
                        <span class="ibe-reservation-detail-stay-dates">{{ formatDate(reservation.arrival) }}
                            - {{ formatDate(reservation.departure) }}</span>
                    </p>
                    <a
                        *ngIf="isReservationFlexible &&
          !isReservationUnModifiable &&
          !reservation.extras.length" class="ibe-reservation-add-extra-btn" (click)="openAddExtrasDialog()"
                    >{{ 'extras.add_an_extra' | translate }}
                        <span>+</span>
                    </a>
                </div>
                <div
                    class="ibe-reservation-detail-total-area"
                    (click)="toggleTaxBreakdown()"
                    *ngIf="!reservation.extras.length"
                >
                    <ibe-total-summary
                        [currency]="reservation.totalGrossAmount.currency"
                        [bookingTotals]="bookingTotals"
                        [isOpen]="isTaxBreakdownOpen"
                    ></ibe-total-summary>
                </div>
            </div>

            <div class="ibe-col-lg-4 ibe-reservation-detail-total-summary" *ngIf="reservation.extras.length">
                <div class="ibe-reservation-detail-extras">
                    <h4>{{ 'extras.extras' | translate }}</h4>
                    <p
                        *ngIf="!reservation.extras.length"
                        class="ibe-reservation-no-extras"
                    >{{ 'guest_management.no_extras' | translate }}</p>
                    <ul *ngIf="reservation.extras.length">
                        <li *ngFor="let extra of reservation.extras">
                            <span class="ibe-reservation-extras-name">{{ extra.name }}</span>
                            <span
                                class="ibe-reservation-extras-price"
                                *ngIf="!extra.isInclusiveInRate && extra.pricingMode !== 'Inclusive'"
                            >{{ extra.totalGrossAmount.amount | currency: extra.totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
                        </li>
                    </ul>
                    <a
                        class="ibe-reservation-add-extra-btn"
                        (click)="openAddExtrasDialog()"
                    >{{ 'extras.add_an_extra' | translate }}
                        <span>+</span>
                    </a>
                </div>

                <div class="ibe-reservation-detail-total-area" (click)="toggleTaxBreakdown()">
                    <ibe-total-summary
                        [currency]="reservation.totalGrossAmount.currency"
                        [bookingTotals]="bookingTotals"
                        [isOpen]="isTaxBreakdownOpen"
                    ></ibe-total-summary>
                </div>
            </div>
        </div>

        <div
            class="ibe-row ibe-reservation-booker"
            *ngIf="reservation && !isLoading"
            [ngClass]="{'ibe-reservation-tax-open': isTaxBreakdownOpen}"
        >
            <div class="ibe-section ibe-reservation-guest ibe-col-lg-6">
                <div class="ibe-section-heading">
                    <h4>{{ 'guest_management.guest_details' | translate }}</h4>
                    <span>
                        <a *ngIf="!isReservationUnModifiable" (click)="openGuestDetailsDialog()">
                            <svg
                                class="ibe-reservation-detail-icon"
                                id="Icons"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <title>edit</title>
                                <g id="edit">
                                    <path
                                        id="pencil"
                                        d="M42.373,11.047l1.761-1.761a2.278,2.278,0,0,0-.07-3.219L41.933,3.936a2.278,2.278,0,0,0-3.219-.07L36.953,5.627,21.141,21.439a1.183,1.183,0,0,0-.326.616l-1.194,6.324,6.324-1.194a1.183,1.183,0,0,0,.616-.326Z"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <polyline
                                        id="paper"
                                        points="40.16 24 40.16 43 5 43 5 7.84 24 7.84"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                            <span class="ibe-desktop">{{ 'manage_booking.update_details' | translate }}</span>
                            <span class="ibe-mobile">{{ 'guest_management.update' | translate }}</span>
                        </a>
                    </span>
                </div>
                <div class="ibe-reservation-booker-detail">
                    <ibe-booker-details [guest]="reservation.primaryGuest"></ibe-booker-details>
                </div>
            </div>
            <div
                class="ibe-section ibe-reservation-payment ibe-col-lg-6"
                *ngIf="reservation.paymentAccount || paymentDetailsCanBeAdded || paymentDetailsCanBeUpdated"
            >
                <div class="ibe-section-heading">
                    <h4>{{ 'manage_booking.payment_method' | translate }}</h4>
                    <span>
                        <a (click)="openPaymentDialog()" *ngIf="paymentDetailsCanBeUpdated">
                            <svg
                                class="ibe-reservation-detail-icon"
                                id="Icons"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <title>edit</title>
                                <g id="edit">
                                    <path
                                        id="pencil"
                                        d="M42.373,11.047l1.761-1.761a2.278,2.278,0,0,0-.07-3.219L41.933,3.936a2.278,2.278,0,0,0-3.219-.07L36.953,5.627,21.141,21.439a1.183,1.183,0,0,0-.326.616l-1.194,6.324,6.324-1.194a1.183,1.183,0,0,0,.616-.326Z"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <polyline
                                        id="paper"
                                        points="40.16 24 40.16 43 5 43 5 7.84 24 7.84"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                            <span class="ibe-desktop">{{ 'guest_management.update_payment_details' | translate }}</span>
                            <span class="ibe-mobile">{{ 'guest_management.update' | translate }}</span>
                        </a>
                    </span>
                </div>
                <div class="ibe-reservation-payment-card">
                    <button
                        class="ibe-btn ibe-pay-btn"
                        *ngIf="paymentDetailsCanBeAdded"
                        (click)="openPaymentDialog()"
                    >{{ 'manage_booking.add_payment_method' | translate }}</button>
                    <ibe-credit-card
                        [card]="reservation.paymentAccount"
                        *ngIf="reservation.paymentAccount"
                    ></ibe-credit-card>
                </div>
            </div>
        </div>
        <div class="ibe-row ibe-reservation-detail-header ibe-mobile">
            <div class="ibe-reservation-detail-action-buttons">
                <a (click)="goBack()" class="ibe-btn ibe-cancel-rerservation-btn">
                    <svg
                        class="ibe-reservation-detail-icon"
                        id="Icons"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                    >
                        <g id="arrow-left">
                            <line
                                id="arrow-stem"
                                x1="8.036"
                                y1="23.964"
                                x2="40.036"
                                y2="23.964"
                                stroke-width="2"
                                stroke="currentcolor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill="none"
                            />
                            <polyline
                                id="arrowhead"
                                points="20 36 7.964 23.964 20 11.929"
                                fill="none"
                                stroke="currentcolor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </g>
                    </svg>
                    {{ 'guest_management.view_reservations' | translate }}
                </a>
            </div>
        </div>
        <ibe-invoice-list
            *ngIf="reservation.status === 'CheckedOut'"
            (invoicesFetched)="onInvoicesFetched($event)"
            [bookingReference]="reservation.bookingReference"
        ></ibe-invoice-list>
    </div>

</section>
