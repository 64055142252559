import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SearchFormValues } from 'app/fixme-inline-types';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { asNumber, isArray } from '../../helpers/type.helper';
import { IbeConfigService } from '../ibe-config.service';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsToSearchValuesService {

    constructor(
        private readonly currentRoute: ActivatedRoute,
        private readonly config: IbeConfigService,
    ) { }

    /**
     * When the querystring changes, processes the results
     * to give you params you can put straight into a booking search form.
     *
     * Updates whenever the querystring changes.
     */
    public params$(startDate: Moment): Observable<SearchFormValues> {
        const numberOfAdultsDefault = this.config.settings.numberOfAdultsDefault || 1;

        return this.currentRoute.queryParams
            .pipe(
                map(
                    (params: Params): SearchFormValues => {
                        const arrival = params.arrival ? moment(params.arrival) : startDate;
                        const departure = params.departure
                            ? moment(params.departure)
                            : moment(startDate).add(1, 'days');

                        return {
                            arrival: arrival.toDate(),
                            departure: departure.toDate(),
                            adults: params.adults ? Number(params.adults) : numberOfAdultsDefault,
                            promoCode: params.promoCode,
                            propertyId: params.propertyId || '',
                            childrenAges: this.toChildrenAges(params.childrenAges),
                            region: params.region,
                        };
                    },
                ),
                map((params) => {
                    const departure = moment(params.departure).isSameOrBefore(params.arrival, 'day') ?
                        moment(params.arrival).add(1, 'day').toDate() : params.departure;
                    return { ...params, departure };
                }),
            );
    }

    /**
     * Angular HttpParams#get might pass in:
     * a) a string, e.g. '16',
     * b) an array of strings e.g. ['4', '5']
     * c) strings that aren't numbers e.g. 'beavis'
     * d) undefined if the key is missing
     **/
    private toChildrenAges(ages: string | string[] | undefined): number[] {
        const array = isArray(ages) ? ages : [ages];

        return array
            // convert all items to numbers or 0 if the value is invalid
            .map((age: unknown) => asNumber(age, 0))
            // remove invalid values: 0's
            .filter((age) => (age > 0));
    }
}
