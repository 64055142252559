import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as passwordSherrif from 'password-sheriff';
import { isFormControlInvalid } from '../helpers/form.helper';

@Injectable()
export class FormValidationService {
    public static phoneValidator(control: FormControl) {
        // tslint:disable-next-line:max-line-length
        const regex = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
        if (control.value) {
            if (control.value.match(regex)) {
                return undefined;
            } else {
                return {
                    'invalidPhone': true,
                };
            }
        }
        return undefined;
    }

    public static emailValidator(control: FormControl) {
        const regex = new RegExp(
            '^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)'
            + '*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$', 'i',
        );
        if (control.value) {
            if (control.value.match(regex)) {
                return undefined;
            } else {
                return {
                    'invalidEmail': true,
                };
            }
        }
        return undefined;
    }

    public static passwordStrengthValidator(control: FormControl) {
        const lengthPolicy = new passwordSherrif.PasswordPolicy({
            length: {
                minLength: 10,
                maxLength: 128,
            },
        });

        if (control.value) {
            if (lengthPolicy.check(control.value)) {
                return undefined;
            } else {
                return {
                    'badPasswordStrength': true,
                };
            }
        }
        return undefined;
    }

    constructor(
        private readonly translate: TranslateService,
    ) {}

    public getValidatorErrorMessage(error: ValidationErrors, validatorName: string) {
        const messages: { [index: string]: string } = {
            'required': this.translate.instant('form_validation.required'),
            'invalidPhone': this.translate.instant('form_validation.valid_phone_number_required'),
            'invalidEmail': this.translate.instant('form_validation.valid_email_required'),
            'passwordMatches': this.translate.instant('form_validation.unmatching_passwords'),
            'badPasswordStrength': this.translate.instant('form_validation.bad_password_strength'),
            'maxlength': this.translate.instant('form_validation.bad_postcode_length', { length: error.requiredLength }),
        };

        return messages[validatorName];
    }

    public passwordMatchValidator(group: FormGroup) {
        const password = group.get('password');
        const confirmPassword = group.get('confirmPassword');
        if ((password && confirmPassword) && (password.value !== confirmPassword.value)) {
            confirmPassword.setErrors({ passwordMatches: false });
            return false;
        }
        return;
    }

    public isFormControlInvalid(formControl: AbstractControl, directive: FormGroupDirective) {
        const isInvalid = isFormControlInvalid(formControl, directive);
        if (isInvalid) {
            directive.ngSubmit.emit(true);
        }
        return isFormControlInvalid(formControl, directive);
    }
}
