export enum ErrorSource {
    APALEO = 'Apaleo',
    STAYNTOUCH = 'StayNTouch',
    ADYEN = 'Adyen',
    ADYENDROPIN = 'Adyen Drop-in',
    MYCHECK = 'MyCheck',
    SAFERPAY = 'Saferpay'
}

export function mapErrorCodes(errorCode: string, errorSource: ErrorSource) {
    switch (errorSource) {
        // PMS
        case ErrorSource.APALEO:
            return mapApaleoCodes(errorCode);
        case ErrorSource.STAYNTOUCH:
            return mapSntCodes(errorCode);
        // Payment
        case ErrorSource.ADYEN:
            return mapAdyenCodes(errorCode);
        case ErrorSource.ADYENDROPIN:
            return mapAdyenDropinCodes(errorCode);
        case ErrorSource.MYCHECK:
            return mapMycheckCodes(errorCode);
        case ErrorSource.SAFERPAY:
            return mapSaferpayCodes(errorCode);
    }
}

function mapSharedBookingCodes(errorCode: string): string {
    switch (errorCode) {
        case 'Unable to add':
            return 'unable_to_add';
        default:
            return 'booking_failed';
    }
}

function mapApaleoCodes(errorCode: string): string {
    switch (errorCode) {
        default:
            return mapSharedBookingCodes(errorCode);
    }
}

function mapSntCodes(errorCode: string): string {
    switch (errorCode) {
        default:
            return mapSharedBookingCodes(errorCode);
    }
}

function mapAdyenCodes(errorCode: string): string {
    switch (errorCode) {
        default:
            return 'payment_error';
    }
}

// tslint:disable-next-line: cyclomatic-complexity
function mapAdyenDropinCodes(errorCode: string): string {
    switch (errorCode) {
        case 'CVC Declined':
            return 'cvc_declined';
        case 'Invalid Card Number':
            return 'card_number_invalid';
        case 'Not enough balance':
            return 'insufficient_funds';
        case 'DECLINED Expiry Incorrect':
            return 'expiry_invalid';
        case 'Refused':
            return 'declined';
        case 'Referral':
            return 'referral';
        case 'Blocked Card':
            return 'blocked_card';
        case 'Expired Card':
            return 'expired_card';
        case 'Invalid Amount':
            return 'invalid_amount';
        case 'Issuer Unavailable':
            return 'issuer_unavailable';
        case 'Not supported':
            return 'not_supported';
        case '3D Not Authenticated':
            return 'not_3d_authenticated';
        case 'Acquirer Fraud':
            return 'acquirer_fraud';
        case 'Cancelled':
            return 'cancelled';
        case 'Shopper Cancelled':
            return 'shopper_cancelled';
        case 'Fraud':
            return 'fraud';
        case 'Not Submitted':
            return 'not_submitted';
        case 'FRAUD-CANCELLED':
            return 'fraud_cancelled';
        case 'Transaction Not Permitted':
            return 'transaction_not_permitted';
        case 'Restricted Card':
            return 'restricted_card';
        case 'Revocation Of Auth':
            return 'revocation_of_auth';
        case 'Declined Non Generic':
            return 'declined_non_generic';
        case 'Withdrawal amount exceeded':
            return 'withdrawal_amount_exceeded';
        case 'Withdrawal count exceeded':
            return 'withdrawal_count_exceeded';
        case 'Issuer Suspected Fraud':
            return 'issuer_suspected_fraud';
        case 'AVS Declined':
            return 'avs_declined';
        case 'No checking account available on Card':
            return 'no_checking_account';
        case 'No savings account available on Card':
            return 'no_savings_account';
        case 'Authentication required':
            return 'authentication_required';
        case 'RReq not received from DS':
            return 'rreq_not_received';
        default:
            return 'payment_error';
    }
}

function mapMycheckCodes(errorCode: string): string {
    switch (errorCode) {
        case 'get_card_error':
            return 'get_card_error';
        case 'ideal_payment_error':
            return 'ideal_payment_error';
        case 'generic_error':
            return 'generic_error';
        default:
            return 'payment_error';
    }
}

function mapSaferpayCodes(errorCode: string): string {
    switch (errorCode) {
        default:
            return 'payment_error';
    }
}
