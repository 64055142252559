<div class="up-ibe" [@fadeInOnEnterAnimation] *ngIf="configHasLoaded && translationFileHasLoaded && !ibeLoadError">
    <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
    <div class="ibe-container">
        <ibe-steps-bar></ibe-steps-bar>
        <router-outlet></router-outlet>
    </div>
    <div [innerHtml]="getThemeCss"></div>
</div>
<div class="ibe-load-error" *ngIf="ibeLoadError">
    <svg class="up-ibe-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 64">
        <defs>
            <style>.cls-1 {
                fill: #555857;
            }

            .cls-2 {
                fill: #34b4e2;
            }

            .cls-3 {
                fill: #fcb933;
            }

            .cls-4 {
                fill: #ec297b;
            }

            .cls-5 {
                fill: #ee4748;
            }

            .cls-6 {
                fill: #8bc53f;
            }

            .cls-7 {
                fill: #929497;
            }</style>
        </defs>
        <title>up-booking-engine</title>
        <path
            class="cls-1"
            d="M33.44,62.08V52.23h3c6.38,0,9.52-3.54,9.52-10V36.91c0-6.5-3.14-10-9.52-10H27V62.08Zm0-29.41h3c2,0,3.13.93,3.13,3.83v6.1c0,2.9-1.1,3.83-3.13,3.83h-3Z"
        />
        <path
            class="cls-1"
            d="M15.22,26.87v25.4c0,2.9-1.28,3.94-3.31,3.94s-3.31-1-3.31-3.94V26.87H2.21v25c0,6.5,3.26,10.22,9.52,10.22s9.52-3.72,9.52-10.22v-25Z"
        />
        <polygon
            class="cls-2"
            points="24.21 1.93 24.2 1.92 10.34 15.78 10.34 15.78 24.2 15.78 24.2 15.78 24.2 15.78 24.21 15.78 24.21 1.93"
        />
        <polygon class="cls-3" points="24.21 1.93 24.21 15.78 24.21 15.78 32.17 23.74 39.09 16.81 24.21 1.93" />
        <polygon
            class="cls-4"
            points="41.03 18.75 39.09 16.81 32.17 23.74 32.18 23.75 36.02 23.75 46.04 23.75 41.03 18.75"
        />
        <polygon class="cls-5" points="16.22 23.75 24.2 15.78 10.34 15.78 16.22 23.75" />
        <polygon
            class="cls-6"
            points="10.34 15.78 10.34 15.78 7.46 18.66 2.37 23.75 12.55 23.75 16.22 23.75 10.34 15.78"
        />
        <path
            class="cls-7"
            d="M69.35,40v.66c0,1.89-.58,3.09-1.86,3.7,1.55.6,2.15,2,2.15,3.93v1.5c0,2.83-1.5,4.36-4.38,4.36H60.72V35.75h4.35C68.06,35.75,69.35,37.14,69.35,40Zm-5.75-1.6v4.86h1.13c1.08,0,1.73-.48,1.73-1.95v-1c0-1.31-.44-1.89-1.47-1.89Zm0,7.48v5.64h1.66c1,0,1.49-.44,1.49-1.81v-1.6c0-1.7-.55-2.23-1.86-2.23Z"
        />
        <path
            class="cls-7"
            d="M73.89,40.15c0-2.94,1.55-4.61,4.38-4.61s4.39,1.67,4.39,4.61v9.56c0,2.94-1.55,4.62-4.39,4.62s-4.38-1.68-4.38-4.62Zm2.89,9.74c0,1.31.58,1.81,1.49,1.81s1.5-.5,1.5-1.81V40c0-1.31-.58-1.81-1.5-1.81s-1.49.5-1.49,1.81Z"
        />
        <path
            class="cls-7"
            d="M87,40.15c0-2.94,1.55-4.61,4.38-4.61s4.39,1.67,4.39,4.61v9.56c0,2.94-1.55,4.62-4.39,4.62S87,52.65,87,49.71Zm2.89,9.74c0,1.31.57,1.81,1.49,1.81s1.5-.5,1.5-1.81V40c0-1.31-.58-1.81-1.5-1.81s-1.49.5-1.49,1.81Z"
        />
        <path class="cls-7" d="M104.2,46.79l-.89,1.68v5.65h-2.89V35.75h2.89v8l3.78-8H110l-4,8.18,4,10.19h-3Z" />
        <path class="cls-7" d="M114.05,35.75h2.88V54.12h-2.88Z" />
        <path class="cls-7" d="M124.36,40.81V54.12h-2.6V35.75h3.62l3,11v-11h2.57V54.12h-3Z" />
        <path
            class="cls-7"
            d="M140.08,43.88h4v5.83c0,2.94-1.47,4.62-4.3,4.62s-4.31-1.68-4.31-4.62V40.15c0-2.94,1.47-4.61,4.31-4.61s4.3,1.67,4.3,4.61v1.79h-2.73V40c0-1.31-.57-1.81-1.49-1.81s-1.5.5-1.5,1.81v9.92c0,1.31.58,1.79,1.5,1.79s1.49-.48,1.49-1.79V46.51h-1.31Z"
        />
        <path class="cls-7" d="M158.45,43.49h4v2.62h-4v5.38h5v2.63h-7.87V35.75h7.87v2.62h-5Z" />
        <path class="cls-7" d="M170.55,40.81V54.12H168V35.75h3.63l3,11v-11h2.57V54.12h-3Z" />
        <path
            class="cls-7"
            d="M186.27,43.88h4v5.83c0,2.94-1.47,4.62-4.3,4.62s-4.3-1.68-4.3-4.62V40.15c0-2.94,1.46-4.61,4.3-4.61s4.3,1.67,4.3,4.61v1.79h-2.73V40c0-1.31-.57-1.81-1.49-1.81s-1.5.5-1.5,1.81v9.92c0,1.31.58,1.79,1.5,1.79s1.49-.48,1.49-1.79V46.51h-1.31Z"
        />
        <path class="cls-7" d="M194.93,35.75h2.89V54.12h-2.89Z" />
        <path class="cls-7" d="M205.25,40.81V54.12h-2.6V35.75h3.62l3,11v-11h2.57V54.12h-3Z" />
        <path class="cls-7" d="M219.52,43.49h4v2.62h-4v5.38h5v2.63h-7.87V35.75h7.87v2.62h-5Z" />
    </svg>
    <span [innerHTML]="ibeLoadError"></span>
</div>
