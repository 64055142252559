<div class="ibe-gallery-header">
    <h2 mat-dialog-title>
        {{ unitType.name }}
    </h2>

    <button class="ibe-gallery-close" mat-dialog-close>
        <i class="ibe-icon ibe-icon-close"></i>
        <span class="sr-only">
            Close
        </span>
    </button>
</div>

<mat-dialog-content>
    <div *ngIf="imagesUrls">
        <ngu-carousel
            id="carousel"
            [inputs]="imageCarousel"
            [dataSource]="imagesUrls"
            (onMove)="onImageChange($event)"
        >

            <div *nguCarouselDef="let image;" class="item">
                <img src="{{ image }}" class="carousel-image">
            </div>
            <button NguCarouselPrev class="ibe-carousel-prev" *ngIf="imagesUrls.length > 1">
                <i class="ibe-icon ibe-icon-chevron-left"></i>
            </button>
            <button NguCarouselNext class="ibe-carousel-next" *ngIf="imagesUrls.length > 1">
                <i class="ibe-icon ibe-icon-chevron-right"></i>
            </button>

        </ngu-carousel>
    </div>
</mat-dialog-content>
