<div
    *ngIf="reservation"
    class="ibe-reservation"
    [ngClass]="reservationClass(reservation)"
>
    <div
        class="ibe-image"
        [ngStyle]="{'background-image': 'url(' + getUnitTypeImageUrl(reservation.unitType.id) + ')'}"
    >
    </div>

    <div class="ibe-image ibe-print-only">
        <img src="{{ getUnitTypeImageUrl(reservation.unitType.id) }}" alt=""/>
    </div>

    <div style="display: flex; width: 100%;">
        <div class="ibe-detail">
            <div class="ibe-title">
                {{ reservation.unitType.name }}
            </div>
            <div
                *ngIf="!config.isLongStayProperty()"
                class="ibe-rate-plan-title"
            >
                {{ reservation.ratePlan.name }}
            </div>
            <div
                class="ibe-rate-plan-title"
                *ngIf="config?.accountFeatureWhitelist?.useRentalAgreement && config.isLongStayProperty()"
            >
                <span>Monthly Rent</span>
            </div>

            <div class="ibe-property-title">
                {{ reservation.property.name }}
            </div>

            <div class="ibe-stay-details">
                <div *ngIf="!config.isLongStayProperty()">
                    <i class="ibe-icon ibe-icon-calendar"></i>
                    <span *ngIf="numberOfNights === 1">
                        {{ numberOfNights }} {{ 'offer_card.night' | translate }}
                    </span>
                    <span *ngIf="numberOfNights > 1">
                        {{ numberOfNights }} {{ 'offer_card.nights' | translate }}
                    </span>
                    &gt;
                    <span class="ibe-dates">
                        {{ arrivalDate }} - {{ departureDate }}
                    </span>
                </div>

                <div *ngIf="config?.accountFeatureWhitelist?.useRentalAgreement && config.isLongStayProperty()">
                    <span>Notice Period: 3 Months</span>
                </div>
                <div class="ibe-persons-qty">
                    <span class="ibe-adults">
                        <i class="ibe-icon ibe-icon-user"></i>
                        {{ reservation.adults }}
                        <span *ngIf="config.isChildrenEnabled">
                            {{ 'offer_card.adults' | translate }}
                        </span>
                        <span *ngIf="!config.isChildrenEnabled">
                            {{ 'offer_card.persons' | translate }}
                        </span>
                    </span>
                    <span class="ibe-children" *ngIf="reservation.childrenAges.length">
                        <i class="ibe-icon ibe-icon-user"></i>
                        {{ reservation.childrenAges.length }} {{ 'offer_card.children' | translate }}
                    </span>
                </div>

                <div *ngIf="isCancelled && showIsCancelled" class="ibe-cancelled-label">
                    {{ reservation.status }}
                </div>
            </div>
            <button
                class="ibe-btn ibe-cancellation-policy-btn"
                *ngIf="!isInCart && showCancellationPolicy && hasCancellationFee()"
                matTooltip="{{ cancellationDescription() }} {{'manage_booking.fee' | translate}}: {{ cancellationAmount() | currency:cancellationCurrency():'symbol':'1.2-2' }}"
                matTooltipClass="ibe-cancellation-policy-tooltip"
            >
                {{ 'offer_card.cancellation_policy' | translate }}
            </button>
        </div>

        <div class="ibe-price" style="width: 40%; justify-content: end;">

            <ng-container *ngIf="!config?.accountFeatureWhitelist?.useRentalAgreement">
                <span class="ibe-price-inner" *ngIf="!reservation.suppressedRate && displayInclusiveExtrasAsTaxes">
                    {{ totalBaseAmount.amount | currency:totalBaseAmount.currency:'symbol':'1.2-2' }}
                </span>
                <span class="ibe-price-inner" *ngIf="!reservation.suppressedRate && !displayInclusiveExtrasAsTaxes">
                    {{ totalGrossAmount.amount | currency:totalGrossAmount.currency:'symbol':'1.2-2' }}
                </span>
            </ng-container>

            <ng-container *ngIf="config?.accountFeatureWhitelist?.useRentalAgreement && config.isLongStayProperty()">
                <div style="display: flex; flex-direction: column;">
                    <span style="text-align: right;" class="ibe-price-inner">
                        {{ getMonthlyRent() | currency:totalGrossAmount.currency:'symbol':'1.0-0' }} /
                        Month
                    </span>
                    <span style="font-size: 0.8rem; text-align: right;">
                        Deposit:
                        {{ getMonthlyRent() * 2 | currency:totalGrossAmount.currency:'symbol':'1.0-0'  }}
                        (2x Months rent)
                    </span>
                </div>
            </ng-container>
            <button class="ibe-remove-btn" *ngIf="showButtons" (click)="removeReservation()">
                <i class="ibe-icon ibe-icon-close"></i>
                <span class="sr-only">Remove</span>
            </button>
        </div>
    </div>
</div>

<div class="ibe-reservation-extras" *ngIf="reservation.extras && reservation.extras.length">
    <div class="ibe-reservation-extra" *ngFor="let extra of reservation.extras">
        <div class="ibe-reservation-extra-name">
            <i class="ibe-icon ibe-icon-tick"></i>
            {{ extra.name }}
            <span *ngIf="extra.quantity">({{ extra.quantity }})</span>
        </div>
        <div
            *ngIf="extra.totalGrossAmount && (!extra.isInclusiveInRate && (extra.pricingMode !== 'Inclusive'))"
            class="ibe-reservation-extra-price"
        >
            <ng-container *ngIf="!config.accountFeatureWhitelist.useRentalAgreement">
                {{ extra.totalGrossAmount.amount | currency:extra.totalGrossAmount.currency:'symbol':'1.2-2' }}
            </ng-container>
            <ng-container *ngIf="config.accountFeatureWhitelist.useRentalAgreement && config.isLongStayProperty()">
                {{ extra.totalGrossAmount.amount | currency:extra.totalGrossAmount.currency:'symbol':'1.0-0' }}
            </ng-container>
            <button
                class="ibe-remove-btn"
                *ngIf="showButtons && (!extra.isInclusiveInRate && (extra.pricingMode !== 'Inclusive'))"
                (click)="removeExtra(extra)"
            >
                <i class="ibe-icon ibe-icon-close"></i>
                <span class="sr-only">Remove</span>
            </button>
        </div>
    </div>
</div>

<button class="ibe-btn ibe-add-extras-btn" *ngIf="showAddExtrasButton && !isInCart" (click)="addExtras()">
    + {{ 'offer_card.add_extras' | translate }}
</button>
<ng-content select=".ibe-reservation-action-btns"></ng-content>
