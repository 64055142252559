import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarDay, CalendarStayDateSelectionEvent } from 'app/fixme-inline-types';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ReplaySubject } from 'rxjs';
import { Property } from 'up-ibe-types';

@Component({
    selector: 'ibe-availability-calendar',
    templateUrl: './availability-calendar.component.html',
    styleUrls: ['./availability-calendar.component.scss'],
})
export class AvailabilityCalendarComponent implements OnInit {
    @Input('property') public property: Property | undefined;

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public leftCalendarCurrentMonth: Moment;
    public rightCalendarCurrentMonth: Moment;
    public arrivalDate: Date;
    public departureDate: Date;
    public hoveredDepartureDate: Date;
    public potentialAvailabilitySpanSubject: ReplaySubject<CalendarDay[]> = new ReplaySubject<CalendarDay[]>();
    public propertyCalendar: boolean;

    constructor(
        private readonly currentRoute: ActivatedRoute,
        private readonly router: Router,
    ) {
        // FIXME - feature-flag required
        this.propertyCalendar = false;
    }

    public ngOnInit() {
        this._checkRequiredQueryParams();
        this._initData();
    }

    public onStayDateSelection($event: CalendarStayDateSelectionEvent) {
        this.arrivalDate = $event.arrivalDate;
        this.departureDate = $event.departureDate;
        this.router
            .navigate(['booking/results'], {
                    queryParams: {
                        arrival: moment(this.arrivalDate).format('YYYY-MM-DD'),
                        departure: moment(this.departureDate).format('YYYY-MM-DD'),
                    },
                    queryParamsHandling: 'merge',
                },
            )
            .catch((navError) => console.error('navigation failed: /booking/results', navError));
    }

    public onArrivalDateSelection($event: Date) {
        this.arrivalDate = $event;
    }

    public onDepartureDateSelection($event: Date) {
        this.departureDate = $event;
    }

    public onDepartureDateHover($event: Date) {
        this.hoveredDepartureDate = $event;
    }

    private _checkRequiredQueryParams() {
        const queryParams = this.currentRoute.snapshot.queryParams;

        const hasAllRequiredParams = queryParams
            && queryParams.propertyId
            && queryParams.adults
            && queryParams.arrival
            && queryParams.departure;

        if (!hasAllRequiredParams) {
            this.router
                .navigateByUrl('/booking/search')
                .catch((navError) => console.error('navigation failed: /booking/search', navError));
        }
    }

    private _initData() {
        const queryParams = this.currentRoute.snapshot.queryParams;
        this.arrivalDate = moment(queryParams.arrival).toDate();
        this.departureDate = moment(queryParams.departure).toDate();
        this.leftCalendarCurrentMonth = moment(queryParams.arrival);
        this.rightCalendarCurrentMonth = moment(queryParams.arrival).add(1, 'month');
    }
}
