<div class="ibe-guest-availability-dialog">
    <div class="ibe-form-group ibe-stay-date-selection" id="ibeDateInput">
        <ibe-date-range-picker
            [arrival]="arrival"
            [departure]="departure"
            (onStayDateSelection)="onStayDateSelection($event)"
        ></ibe-date-range-picker>
        <ibe-native-datepicker
            [arrival]="arrival"
            [departure]="departure"
            (onStayDateSelection)="onStayDateSelection($event)"
        >
        </ibe-native-datepicker>
    </div>
    <ibe-loading-bar [isLoading]="isLoading"></ibe-loading-bar>

    <div class="ibe-guest-availability-dialog-footer">
        <h2>Edit Dates</h2>
        <div class="ibe-guest-availability-dialog-stay">
            <span class="ibe-guest-availability-dialog-nights">
                <svg id="Icons" class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <g id="calendar">
                        <rect
                            id="frame"
                            x="7.787"
                            y="9.003"
                            width="32.427"
                            height="30.335"
                            rx="1"
                            stroke-width="2"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill="none"
                        />
                        <line
                            id="accent-line"
                            x1="7.812"
                            y1="16.123"
                            x2="40.213"
                            y2="16.123"
                            stroke-width="2"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            fill="none"
                        />
                        <g id="hooks">
                            <line
                                id="right-hook"
                                x1="33.69"
                                y1="6.663"
                                x2="33.69"
                                y2="11.141"
                                stroke-width="2"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill="none"
                            />
                            <line
                                id="left-hook"
                                x1="14.862"
                                y1="6.663"
                                x2="14.862"
                                y2="11.141"
                                stroke-width="2"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill="none"
                            />
                        </g>
                        <g id="dates">
                            <line
                                x1="24.594"
                                y1="21.807"
                                x2="23.594"
                                y2="21.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="32.594"
                                y1="21.807"
                                x2="31.594"
                                y2="21.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="24.594"
                                y1="27.807"
                                x2="23.594"
                                y2="27.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="32.594"
                                y1="27.807"
                                x2="31.594"
                                y2="27.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="24.594"
                                y1="33.807"
                                x2="23.594"
                                y2="33.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="32.594"
                                y1="33.807"
                                x2="31.594"
                                y2="33.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="16.594"
                                y1="27.807"
                                x2="15.594"
                                y2="27.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                            <line
                                x1="16.594"
                                y1="33.807"
                                x2="15.594"
                                y2="33.807"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            />
                        </g>
                    </g>
                </svg>
                <span *ngIf="numberOfNights == 1">{{ numberOfNights }} {{ 'offer_card.night' | translate }}</span>
                <span *ngIf="numberOfNights > 1">{{ numberOfNights }} {{ 'offer_card.nights' | translate }}</span>
            </span>
            <span #ibeDateRef class="ibe-guest-availability-dialog-dates"></span>
        </div>
        <div *ngIf="hasAvailability" class="ibe-guest-availability-dialog-price">
            <span class="ibe-guest-availability-dialog-total-text">{{ 'checkout.total_cost' | translate }}</span>
            <span class="ibe-guest-availability-dialog-gross">{{ totalGrossAmount.amount | currency: totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
        </div>
        <p *ngIf="!hasAvailability">{{ 'room_results.no_rooms' | translate }}</p>
        <button (click)="confirmDateChanges()" [disabled]="isDateSameAsReservationDate()">Confirm Changes</button>
    </div>
</div>
