import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from 'app/fixme-inline-types';
import { BookingService } from 'app/services/booking.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

enum LogType {
    return = 'RETURN',
    redirect = 'REDIRECT',
    exit = 'EXIT'
}

@Injectable()
export class LogGuestMovementGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        private readonly http: HttpClient,
        private readonly bookingService: BookingService,
    ) { }

    public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate()
            ? true
            : this.logAndConfirmExit();
    }

    public logGuestRedirect(paymentProvider: string) {
        const notification = {
            bookingRequestId: this.bookingService.getBookingRequestId(),
            paymentProvider,
            logType: LogType.redirect,
        };
        this.http
            .post(`${environment.serverUrl}/api/ibe/notify`, notification)
            .subscribe(
                () => { /* don't care */ },
                (error => console.error('logGuestRedirect', error)),
            );
    }

    public logAndConfirmExit() {
        const notification = {
            bookingRequestId: this.bookingService.getBookingRequestId(),
            logType: LogType.exit,
        };
        this.http.post(`${environment.serverUrl}/api/ibe/notify`, notification)
            .subscribe(
                () => { /* don't care */ },
                (error => console.error('logAndConfirmExit', error)),
            );

        return confirm('Are you sure you want to leave? If you quit now, your booking may still be completed');
    }

    // Use this so we can see if Guests do actually come back from 3DS when they complain of failures
    public logGuestReturn() {
        const notification = {
            bookingRequestId: this.bookingService.getBookingRequestId(),
            logType: LogType.return,
        };
        this.http.post(`${environment.serverUrl}/api/ibe/notify`, notification)
            .subscribe(
                () => { /* don't care */ },
                (error => console.error('logGuestReturn', error)),
            );
    }
}
