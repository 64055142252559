<div class="ibe-persons-qty-selector">
    <div class="ibe-persons-qty-selector-title">
        <ng-content></ng-content>
    </div>
    <div class="ibe-persons-qty-selector-controls">
        <button type="button" class="ibe-persons-qty-selector-button" (click)="decrement()">-</button>
        <div class="ibe-persons-qty-selector-value">{{ value }}</div>
        <button type="button" class="ibe-persons-qty-selector-button" (click)="increment()">+</button>
    </div>
</div>
