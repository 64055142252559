<dl class="ibe-guest-booker-details">
    <dt>{{ 'manage_booking.name' | translate }}</dt>
    <dd>
        <span *ngIf="guest.title">
            {{ 'checkout_details.titles.' + guest.title | translate }}
        </span>

        {{ guest.firstName }} {{ guest.lastName }}
    </dd>
</dl>

<dl class="ibe-guest-booker-details">
    <dt>{{ 'manage_booking.booking_email' | translate }}</dt>
    <dd class="ibe-lowercase">{{ guest.email }}</dd>
</dl>

<dl class="ibe-guest-booker-details">
    <dt>{{ 'manage_booking.phone' | translate }}</dt>
    <dd>{{ guest.phone }}</dd>
</dl>

<dl class="ibe-guest-booker-details" *ngIf="guest.address">
    <dt>{{ 'manage_booking.address' | translate }}</dt>
    <dd>{{ guest.address.addressLine1 }}</dd>
    <dd>{{ guest.address.addressLine2 }}</dd>
</dl>

<dl class="ibe-guest-booker-details" *ngIf="guest.address">
    <dt>{{ 'manage_booking.city' | translate }}</dt>
    <dd>{{ guest.address.city }}</dd>
</dl>

<dl class="ibe-guest-booker-details" *ngIf="guest.address">
    <dt>{{ 'manage_booking.postal_code' | translate }}</dt>
    <dd class="ibe-uppercase">{{ guest.address.postalCode }}</dd>
</dl>
