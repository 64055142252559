import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'app/services/local-storage.service';
import { ToasterService } from 'app/services/toaster.services';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Property } from 'up-ibe-types';
import { HttpStatus } from '../../../enums';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { FormValidationService } from '../../services/form-validation.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { NewsletterSubscriptionService } from '../../services/newsletter-subscription.service';

@Component({
    selector: 'ibe-guest-create-account',
    templateUrl: './guest-create-account.component.html',
    styleUrls: ['./guest-create-account.component.scss'],
})
export class GuestCreateAccountComponent implements OnInit {
    @ViewChild('guestCreateFormDirective', { static: true }) public guestCreateFormDirective: FormGroupDirective;

    public get marketingConsentEnabled(): boolean { return this.config.settings.checkoutFields.address.marketingConsent; };

    // FIXME DO NOT USE public properties
    public guestCreateForm: FormGroup;
    public showForm = false;
    public isPmsApaleo = false;
    public saving = false;

    constructor(
        // FIXME DO NOT USE public properties
        public readonly guestAuthService: GuestAuthService,
        private readonly config: IbeConfigService,
        private readonly toasterService: ToasterService,
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        private readonly formValidationService: FormValidationService,
        private readonly newsletterSubscriptionService: NewsletterSubscriptionService,
        private readonly localStorageService: LocalStorageService,
        private readonly router: Router,
    ) {
    }

    public ngOnInit() {
        this.isPmsApaleo = this.config.isPmsApaleo();

        if (this.guestAuthService.getToken()) {
            this.router.navigate(['guest/manage']).catch(console.error);
        }

        this.guestCreateForm = this.formBuilder.group({
            'firstName': ['', [Validators.required]],
            'lastName': ['', [Validators.required]],
            'username': ['', [Validators.required, Validators.email, FormValidationService.emailValidator]],
            'password': ['', [Validators.required]],
            'confirmPassword': ['', [Validators.required]],
            'marketingConsent': [false, [Validators.required]],
        }, { validator: this.formValidationService.passwordMatchValidator });

        if (!this.isPmsApaleo && this.config.settings.checkoutFields.address.marketingConsent) {
            this.guestCreateForm.addControl('marketingConsent', new FormControl('true'));
        }
    }

    public subscribeToNewsletter(): Observable<object> {
        if (this.localStorageService.getLastSearchedStayCriteria()) {
            return this.newsletterSubscriptionService.subscribeToNewsletter({
                firstName: this.guestCreateForm.value.firstName,
                lastName: this.guestCreateForm.value.lastName,
                email: this.guestCreateForm.value.username,
            }, this.localStorageService.getLastSearchedStayCriteria().propertyId);
        }

        return this.subscribeToAllNewsletters();
    }

    public subscribeToAllNewsletters(): Observable<object> {
        if (!this.config.properties?.length) {
            return of({});
        }

        return forkJoin(this.config.properties.map((property: Property) => {
                return this.newsletterSubscriptionService.subscribeToNewsletter({
                    firstName: this.guestCreateForm.value.firstName,
                    lastName: this.guestCreateForm.value.lastName,
                    email: this.guestCreateForm.value.username,
                }, property.pmsId);
            }),
        );
    }

    public createGuest() {
        this.saving = true;

        if (this.guestCreateForm.valid) {
            this.guestAuthService.createGuest(this.guestCreateForm.value).pipe(
                mergeMap(() => this.guestCreateForm.value.marketingConsent ? this.subscribeToNewsletter() : of({})),
            ).subscribe(() => {
                this.saving = false;

                if (this.config?.accountFeatureWhitelist?.authenticateGuestEmails) {
                    this.goToGuestManagement();
                    this.toasterService.showSuccess(
                        this.translate.instant('guest_management_auth.create_successful'),
                        '',
                    );
                    this.toasterService.showSuccess(
                        this.translate.instant('guest_management_auth.check_email'),
                        '',
                    );
                } else {
                    this.guestAuthService.loginGuest(this.guestCreateForm.value).subscribe(response => {
                        this.goToGuestManagement();
                    });
                }
            }, (error) => {
                this.saving = false;

                this.toasterService.showError(
                    this.translate.instant('guest_management_auth.create_failed'),
                    error.error.message,
                );

                if (error.status === HttpStatus.Conflict) {
                    this.goToGuestManagement();
                }
            });
        }
    }

    public toggleForm() {
        this.showForm = !this.showForm;
    }

    public goToGuestManagement() {
        this.router.navigate(['guest/manage']).catch(console.error);
    }

    public isFormControlInvalid(formControl: AbstractControl | null) {
        return isFormControlInvalid(formControl, this.guestCreateFormDirective);
    }
}
