import { Component, Input } from '@angular/core';
import { BookerModel } from 'up-ibe-types';

@Component({
    selector: 'ibe-booker-details',
    templateUrl: './booker-details.component.html',
    styleUrls: ['./booker-details.component.scss'],
})
export class BookerDetailsComponent {
    @Input('guest') public guest: BookerModel;
}
