import { GuestModel } from 'up-ibe-types';
import * as validate from 'validate.js';
import { environment } from '../../environments/environment';
import { IbeEnvironmentPaymentConfigList } from '../../environments/typed-env';

const isKnownProvider = (
    name: PropertyKey,
    list: IbeEnvironmentPaymentConfigList,
): name is keyof IbeEnvironmentPaymentConfigList => {
    return name in list;
};

export function getPaymentProviderScriptUrl(providerName: string, testApiMode: boolean): string {
    const provider = isKnownProvider(providerName, environment.paymentProviderScripts)
        ? environment.paymentProviderScripts[providerName]
        : undefined;

    return (provider) ? (testApiMode ? provider.test : provider.live) : '';
}

export function isGuestDataValid(guestDetails: GuestModel): boolean {
    const guestDetailConstraints = {
        'lastName': { presence: { allowEmpty: false } },
        'email': { presence: { allowEmpty: false } },
        'address.countryCode': { presence: { allowEmpty: false } },
    };

    // TODO: remove validate and replace with yup - validate.js is outdated and horrible typed (any, any, any)
    const hasErrors = validate(guestDetails, guestDetailConstraints);

    return !hasErrors;
}
