<div class="ibe-reservation-status">
    <div class="ibe-form-group ibe-reservation-filter-form-group" *ngIf="reservations.length">
        <label for="reservationStatusSelect">{{ 'guest_management.filter_reservation_status' | translate }}</label>
        <select
            id="reservationStatusSelect"
            class="ibe-custom-select ibe-reservation-status-select"
            (change)="filterReservations($event.target.value)"
            name="reservationStatus"
            [formControl]="reservationStatus"
        >
            <option value="All">
                {{ 'manage_booking.reservation_statuses.all' | translate }}
                ({{ allReservationsCount }})
            </option>
            <option value="Confirmed" *ngIf="confirmedReservationsCount">
                {{ 'manage_booking.reservation_statuses.confirmed' | translate }}
                ({{ confirmedReservationsCount }})
            </option>
            <option value="Canceled" *ngIf="cancelledReservationsCount">
                {{ 'manage_booking.reservation_statuses.canceled' | translate }}
                ({{ cancelledReservationsCount }})
            </option>
            <option value="CheckedOut" *ngIf="checkedOutReservationsCount">
                {{ 'manage_booking.reservation_statuses.checked_out' | translate }}
                ({{ checkedOutReservationsCount }})
            </option>
            <option value="InHouse" *ngIf="inHouseReservationsCount">
                {{ 'manage_booking.reservation_statuses.in_house' | translate }}
                ({{ inHouseReservationsCount }})
            </option>
            <option value="NoShow" *ngIf="noShowReservationsCount">
                {{ 'manage_booking.reservation_statuses.no_show' | translate }}
                ({{ noShowReservationsCount }})
            </option>
        </select>
    </div>
</div>

<div class="ibe-guest-management-bookings-table" [ngClass]="{'ibe-reservation-actions-open': isOpen}">
    <ng-container *ngIf="reservations.length">
        <div
            class="ibe-guest-reservations"
            *ngFor="let reservation of filteredReservations; index as idx"
            [ngClass]="{'ibe-reservation-actions-open': isReservationBelowToggledReservation(idx), 'ibe-reservation-isFlexible': isReservationBelowFlexibleToggledReservation(idx)}"
        >
            <p
                class="ibe-guest-reservation-status"
                [ngClass]="{'ibe-reservation-cancelled-status': reservation.status == 'Canceled'}"
            >
                {{ 'guest_management.status' | translate }}: {{ reservation.status|translateReservationStatus }}
            </p>
            <div class="ibe-row ibe-reservation-details">
                <div
                    class="ibe-reservation-unit-image ibe-col-lg-4 ibe-col-md-12"
                    [ngStyle]="{'background-image': 'url(' + getUnitTypeImageUrl(reservation.property.id, reservation.unitType.id) + ')'}"
                ></div>
                <div class="ibe-reservation-info ibe-col-lg-8 ibe-col-md-12">
                    <div class="ibe-reservation-info-row">
                        <div class="ibe-reservation-unit-title">
                            <h2>{{ reservation.unitType.name }}</h2>
                            <h5>{{ reservation.property.name }}</h5>
                            <p>
                                {{ 'manage_booking.reservation_id' | translate }}:
                                #{{ reservation.bookingReference }}
                            </p>
                        </div>
                        <div class="ibe-reservation-stay-price">
                            <span class="ibe-reservation-price-rate-wrap">
                                <span
                                    class="ibe-reservation-price"
                                    *ngIf="hasValidRate(reservation) && !reservation.suppressedRate"
                                >
                                    {{ reservationTotalAmount(reservation) | currency: reservation.totalGrossAmount.currency:'symbol':'1.2-2' }}
                                </span>
                                <span
                                    class="ibe-reservation-rate-plan"
                                    *ngIf="hasValidRate(reservation) && reservation.ratePlan.pmsDescription"
                                    matTooltip="{{ reservation.ratePlan.pmsDescription }}"
                                    matTooltipClass="ibe-reservation-pay-now-tooltip"
                                >
                                    {{ reservation.ratePlan.name }}
                                    <svg
                                        id="Icons"
                                        class="ibe-reservation-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 48 48"
                                    >
                                        <g id="info">
                                            <circle
                                                id="circle"
                                                cx="24"
                                                cy="24"
                                                r="19"
                                                fill="currentColor"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <g id="letter-i">
                                                <line
                                                    id="stem"
                                                    x1="24"
                                                    y1="19.713"
                                                    x2="24"
                                                    y2="34.171"
                                                    stroke-width="2"
                                                    stroke="#fff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    fill="none"
                                                />
                                                <line
                                                    id="tittle"
                                                    x1="24"
                                                    y1="13.829"
                                                    x2="24"
                                                    y2="14.829"
                                                    stroke-width="2"
                                                    stroke="#fff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    fill="none"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                <span
                                    class="ibe-reservation-rate-plan"
                                    *ngIf="hasValidRate(reservation) && !reservation.ratePlan.pmsDescription"
                                >
                                    {{ reservation.ratePlan.name }}
                                </span>
                            </span>
                            <a
                                class="ibe-reservation-cancellation-btn"
                                *ngIf="!isReservationUnModifiable(reservation) && reservation.cancellationFee"
                                (click)="openCancellationDialog(reservation)"
                            >
                                {{ 'guest_management.view_cancellation_policy' | translate }}
                            </a>
                        </div>
                    </div>
                    <div class="ibe-reservation-stay">
                        <p class="ibe-reservation-stay-persons">
                            <svg
                                id="Icons"
                                class="ibe-reservation-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="guest">
                                    <circle
                                        id="head"
                                        cx="24"
                                        cy="14.75"
                                        r="9.25"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <path
                                        id="body"
                                        d="M8.691,39.309a15.309,15.309,0,0,1,30.618,0"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                            <span *ngIf="reservation.adults === 1">{{ reservation.adults }} {{ 'booking_search.adult' | translate }}</span>
                            <span *ngIf="reservation.adults > 1">{{ reservation.adults }} {{ 'booking_search.adults' | translate }}</span>
                            <svg
                                *ngIf="reservation.childrenAges.length"
                                id="Icons"
                                class="ibe-reservation-icon ibe-reservation-children-count"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="guest">
                                    <circle
                                        id="head"
                                        cx="24"
                                        cy="14.75"
                                        r="9.25"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                    <path
                                        id="body"
                                        d="M8.691,39.309a15.309,15.309,0,0,1,30.618,0"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                            <span
                                *ngIf="reservation.childrenAges.length === 1"
                                class="ibe-reservation-children-count"
                            >
                                {{ reservation.childrenAges.length }} {{ 'offer_card.child' | translate }}
                            </span>
                            <span
                                *ngIf="reservation.childrenAges.length > 1"
                                class="ibe-reservation-children-count"
                            >
                                {{ reservation.childrenAges.length }} {{ 'offer_card.children' | translate }}
                            </span>
                        </p>
                        <p class="ibe-resevation-nights">
                            <span class="ibe-reservation-nights-details">
                                <svg
                                    id="Icons"
                                    class="ibe-reservation-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                >
                                    <g id="calendar">
                                        <rect
                                            id="frame"
                                            x="7.787"
                                            y="9.003"
                                            width="32.427"
                                            height="30.335"
                                            rx="1"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                        <line
                                            id="accent-line"
                                            x1="7.812"
                                            y1="16.123"
                                            x2="40.213"
                                            y2="16.123"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                        <g id="hooks">
                                            <line
                                                id="right-hook"
                                                x1="33.69"
                                                y1="6.663"
                                                x2="33.69"
                                                y2="11.141"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                fill="none"
                                            />
                                            <line
                                                id="left-hook"
                                                x1="14.862"
                                                y1="6.663"
                                                x2="14.862"
                                                y2="11.141"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                fill="none"
                                            />
                                        </g>
                                        <g id="dates">
                                            <line
                                                x1="24.594"
                                                y1="21.807"
                                                x2="23.594"
                                                y2="21.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="21.807"
                                                x2="31.594"
                                                y2="21.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="24.594"
                                                y1="27.807"
                                                x2="23.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="27.807"
                                                x2="31.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="24.594"
                                                y1="33.807"
                                                x2="23.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="33.807"
                                                x2="31.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="16.594"
                                                y1="27.807"
                                                x2="15.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="16.594"
                                                y1="33.807"
                                                x2="15.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </g>
                                </svg>
                                <span *ngIf="getNumberOfNights(reservation) == 1">
                                    {{ getNumberOfNights(reservation) }} {{ 'offer_card.night' | translate }}
                                </span>
                                <span *ngIf="getNumberOfNights(reservation) > 1">
                                    {{ getNumberOfNights(reservation) }} {{ 'offer_card.nights' | translate }}
                                </span>
                            </span>
                            <span class="ibe-reservation-stay-dates">
                                {{ formatDate(reservation.arrival) }} - {{ formatDate(reservation.departure) }}
                            </span>
                            <a
                                class="ibe-reservation-dates-edit-btn"
                                *ngIf="!isReservationUnModifiable(reservation) && (isReservationFlexible(reservation))"
                                (click)="openEditReservationDatesDialog(reservation)"
                            >
                                {{ 'guest_management.edit' | translate }}
                                <svg
                                    class="ibe-reservation-icon"
                                    id="Icons"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                >
                                    <g id="edit">
                                        <path
                                            id="pencil"
                                            d="M42.373,11.047l1.761-1.761a2.278,2.278,0,0,0-.07-3.219L41.933,3.936a2.278,2.278,0,0,0-3.219-.07L36.953,5.627,21.141,21.439a1.183,1.183,0,0,0-.326.616l-1.194,6.324,6.324-1.194a1.183,1.183,0,0,0,.616-.326Z"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                        <polyline
                                            id="paper"
                                            points="40.16 24 40.16 43 5 43 5 7.84 24 7.84"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="ibe-row ibe-reservation-extras ibe-desktop">
                <div class="ibe-reservation-extras-title ibe-col-md-4">
                    <h3>{{ 'extras.extras' | translate }}</h3>
                    <p *ngIf="reservation.extras.length && hasValidRate(reservation) && calculateExtrasTotals(reservation.extras) !== 0">
                        <span class="ibe-total-cost-label">{{ 'checkout.total_cost' | translate }}</span>
                        <span class="ibe-total-cost-value">{{ calculateExtrasTotals(reservation.extras) | currency: reservation.totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
                    </p>
                </div>
                <div
                    class="ibe-reservation-extras-wrap ibe-col-md-8"
                    [ngClass]="{'ibe-reservation-has-extras': reservation.extras.length}"
                >
                    <ul class="ibe-reservation-extras-list">
                        <li *ngFor="let extra of reservation.extras">
                            <span class="ibe-reservation-extras-name">{{ extra.name }}</span>
                            <span
                                class="ibe-reservation-extras-price"
                                *ngIf="hasValidRate(reservation) && !extra.isInclusiveInRate && extra.pricingMode !== 'Inclusive'"
                            >
                                {{ extra.totalGrossAmount.amount | currency: extra.totalGrossAmount.currency:'symbol':'1.2-2' }}
                            </span>
                        </li>
                    </ul>
                    <a
                        *ngIf="!isReservationUnModifiable(reservation) && isReservationFlexible(reservation)"
                        class="ibe-reservation-add-extra-btn" (click)="openAddExtrasDialog(reservation)"
                    >
                        {{ 'extras.add_an_extra' | translate }}
                        <span>+</span>
                    </a>
                </div>
            </div>

            <div class="ibe-row ibe-reservation-extras ibe-mobile" *ngIf="reservation.extras.length">
                <div class="ibe-reservation-extras-title ibe-col-md-12">
                    <h3>{{ 'extras.extras' | translate }}</h3>
                    <span
                        class="ibe-reservation-cost"
                        *ngIf="reservation.extras.length && hasValidRate(reservation) && calculateExtrasTotals(reservation.extras) !== 0"
                    >
                        <span class="ibe-total-cost-label">{{ 'checkout.total_cost' | translate }}</span>
                        <span class="ibe-total-cost-value">{{ calculateExtrasTotals(reservation.extras) | currency: reservation.totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
                    </span>
                </div>
                <div
                    class="ibe-reservation-extras-wrap ibe-col-md-12"
                    [ngClass]="{'ibe-reservation-has-extras': reservation.extras.length}"
                >
                    <ul class="ibe-reservation-extras-list">
                        <li *ngFor="let extra of reservation.extras">
                            <span class="ibe-reservation-extras-name">{{ extra.name }}</span>
                            <span
                                class="ibe-reservation-extras-price"
                                *ngIf="hasValidRate(reservation) && !extra.isInclusiveInRate"
                            >
                                {{ extra.totalGrossAmount.amount | currency: extra.totalGrossAmount.currency:'symbol':'1.2-2' }}
                            </span>
                        </li>
                    </ul>
                    <a
                        *ngIf="!isReservationUnModifiable(reservation) && isReservationFlexible(reservation)"
                        (click)="openAddExtrasDialog(reservation)"
                        class="ibe-reservation-add-extra-btn"
                    >
                        {{ 'extras.add_an_extra' | translate }}
                        <span>+</span>
                    </a>
                </div>
            </div>

            <div
                class="ibe-row ibe-reservation-footer ibe-desktop"
                [ngClass]="{'ibe-reservation-invalid-rate': !hasValidRate(reservation)}"
            >
                <p class="ibe-reservation-contact-hotel-text">
                    {{ 'guest_management.contact_hotel_for_resrevation_details_text' | translate }}
                </p>
                <a (click)="viewReservationDetails(reservation)">
                    {{ 'guest_management.view_reservation_details' | translate }}
                </a>
                <a
                    *ngIf="!isReservationUnModifiable(reservation)"
                    class="ibe-reservation-footer-btn"
                    (click)="openGuestDetailsDialog(reservation)"
                >
                    {{ 'guest_management.edit_guest_details' | translate }}
                </a>
                <a
                    *ngIf="!isReservationUnModifiable(reservation)"
                    class="ibe-reservation-footer-btn ibe-reservation-footer-cancellation-btn"
                    (click)="openCancellationDialog(reservation)"
                >
                    {{ 'manage_booking.cancel_reservation' | translate }}
                </a>
            </div>

            <div
                class="ibe-row ibe-reservation-footer ibe-mobile"
                [ngClass]="{'ibe-reservation-isCancelled': reservation.status === 'Canceled', 'ibe-reservation-invalid-rate': !hasValidRate(reservation)}"
            >
                <p class="ibe-reservation-contact-hotel-text">
                    {{ 'guest_management.contact_hotel_for_resrevation_details_text' | translate }}
                </p>
                <a
                    class="ibe-reservation-footer-links ibe-reservation-view-link"
                    (click)="viewReservationDetails(reservation)"
                >
                    {{ 'guest_management.view_details' | translate }}
                    <svg
                        id="Icons"
                        class="ibe-reservation-icon ibe-view-details-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                    >
                        <g id="arrow-right">
                            <line
                                id="arrow-stem"
                                x1="39.964"
                                y1="23.964"
                                x2="7.964"
                                y2="23.964"
                                stroke-width="3"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                fill="none"
                            />
                            <polyline
                                id="arrowhead"
                                points="28 11.929 40.036 23.964 28 36"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                    </svg>
                </a>
                <a
                    *ngIf="!isReservationUnModifiable(reservation)"
                    class="ibe-reservation-footer-links ibe-reservation-action-btn"
                    (click)="toggleSlide(idx, reservation)"
                    [ngClass]="{'ibe-reservation-actions-open': isToggledReservationOpen(idx)}"
                >
                    {{ 'guest_management.actions' | translate }}
                    <svg id="Icons" class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <g id="caret-down">
                            <polyline
                                id="arrowhead"
                                points="36.036 18.982 24 31.018 11.964 18.982"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                    </svg>
                </a>
                <ul
                    *ngIf="!isReservationUnModifiable(reservation)"
                    class="ibe-reservation-actions"
                    [ngClass]="{'ibe-reservation-actions-open': isToggledReservationOpen(idx), 'ibe-reservation-isFlexible': isOpen && isToggledReservationFlexible}"
                >
                    <li class="ibe-reservation-action" *ngIf="isReservationFlexible(reservation)">
                        <a (click)="openEditReservationDatesDialog(reservation)">
                            <span>
                                <svg
                                    id="Icons"
                                    class="ibe-reservation-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                >
                                    <g id="calendar">
                                        <rect
                                            id="frame"
                                            x="7.787"
                                            y="9.003"
                                            width="32.427"
                                            height="30.335"
                                            rx="1"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                        <line
                                            id="accent-line"
                                            x1="7.812"
                                            y1="16.123"
                                            x2="40.213"
                                            y2="16.123"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            fill="none"
                                        />
                                        <g id="hooks">
                                            <line
                                                id="right-hook"
                                                x1="33.69"
                                                y1="6.663"
                                                x2="33.69"
                                                y2="11.141"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                fill="none"
                                            />
                                            <line
                                                id="left-hook"
                                                x1="14.862"
                                                y1="6.663"
                                                x2="14.862"
                                                y2="11.141"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                fill="none"
                                            />
                                        </g>
                                        <g id="dates">
                                            <line
                                                x1="24.594"
                                                y1="21.807"
                                                x2="23.594"
                                                y2="21.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="21.807"
                                                x2="31.594"
                                                y2="21.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="24.594"
                                                y1="27.807"
                                                x2="23.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="27.807"
                                                x2="31.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="24.594"
                                                y1="33.807"
                                                x2="23.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="32.594"
                                                y1="33.807"
                                                x2="31.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="16.594"
                                                y1="27.807"
                                                x2="15.594"
                                                y2="27.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <line
                                                x1="16.594"
                                                y1="33.807"
                                                x2="15.594"
                                                y2="33.807"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </g>
                                </svg>
                                {{ 'guest_management.edit_stay_dates' | translate }}
                            </span>
                            <svg
                                id="Icons"
                                class="ibe-reservation-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="arrow-right">
                                    <line
                                        id="arrow-stem"
                                        x1="39.964"
                                        y1="23.964"
                                        x2="7.964"
                                        y2="23.964"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <polyline
                                        id="arrowhead"
                                        points="28 11.929 40.036 23.964 28 36"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="3"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li class="ibe-reservation-action">
                        <a (click)="openGuestDetailsDialog(reservation)">
                            <span>
                                <svg
                                    id="Icons"
                                    class="ibe-reservation-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                >
                                    <g id="guest">
                                        <circle
                                            id="head"
                                            cx="24"
                                            cy="14.75"
                                            r="9.25"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                        <path
                                            id="body"
                                            d="M8.691,39.309a15.309,15.309,0,0,1,30.618,0"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                                {{ 'guest_management.edit_guest_details' | translate }}
                            </span>
                            <svg
                                id="Icons"
                                class="ibe-reservation-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="arrow-right">
                                    <line
                                        id="arrow-stem"
                                        x1="39.964"
                                        y1="23.964"
                                        x2="7.964"
                                        y2="23.964"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <polyline
                                        id="arrowhead"
                                        points="28 11.929 40.036 23.964 28 36"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="3"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li class="ibe-reservation-action">
                        <a
                            *ngIf="!isReservationUnModifiable(reservation) && isReservationFlexible(reservation)"
                            (click)="openAddExtrasDialog(reservation)"
                        >
                            <span>
                                <svg
                                    class="ibe-reservation-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <g>
                                        <line
                                            x1="12"
                                            y1="20"
                                            x2="12"
                                            y2="4"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                        />
                                        <line
                                            x1="4"
                                            y1="12"
                                            x2="20"
                                            y2="12"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                        />
                                    </g>
                                </svg>
                                {{ 'extras.add_an_extra' | translate }}
                            </span>
                            <svg
                                id="Icons"
                                class="ibe-reservation-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="arrow-right">
                                    <line
                                        id="arrow-stem"
                                        x1="39.964"
                                        y1="23.964"
                                        x2="7.964"
                                        y2="23.964"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <polyline
                                        id="arrowhead"
                                        points="28 11.929 40.036 23.964 28 36"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="3"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li class="ibe-reservation-action">
                        <a (click)="openCancellationDialog(reservation)">
                            <span>
                                <svg
                                    id="Icons"
                                    class="ibe-reservation-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                >
                                    <line
                                        id="tick"
                                        x1="9.511"
                                        y1="38.489"
                                        x2="38.489"
                                        y2="9.511"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <line
                                        id="tick-2"
                                        data-name="tick"
                                        x1="38.489"
                                        y1="38.489"
                                        x2="9.511"
                                        y2="9.511"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                </svg>
                                {{ 'manage_booking.cancel_reservation' | translate }}
                            </span>
                            <svg
                                id="Icons"
                                class="ibe-reservation-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                            >
                                <g id="arrow-right">
                                    <line
                                        id="arrow-stem"
                                        x1="39.964"
                                        y1="23.964"
                                        x2="7.964"
                                        y2="23.964"
                                        stroke-width="3"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        fill="none"
                                    />
                                    <polyline
                                        id="arrowhead"
                                        points="28 11.929 40.036 23.964 28 36"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="3"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <div *ngIf="!reservations.length">
        <h2>{{ 'guest_management.no_reservations_found' | translate }}</h2>
    </div>
</div>
