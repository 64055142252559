import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { asString } from '../../helpers/type.helper';
import { IbeConfigService } from '../../services/ibe-config.service';

@Component({
    selector: 'ibe-results-container',
    templateUrl: './results-container.component.html',
    styleUrls: ['./results-container.component.scss'],
})
export class ResultsContainerComponent implements OnInit {
    // FIXME: DO NOT USE public properties
    public loading$: Observable<boolean>;
    public isRegionSearch = false;

    constructor(
        // FIXME: DO NOT USE public properties
        public readonly config: IbeConfigService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
    }

    public ngOnInit() {
        this.route
            .queryParams
            .subscribe(this.onQueryParamsChanged);
    }

    public onLoadingEmit($event: Observable<boolean>) {
        // Added setTimeout to fix:
        //   ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        //   Previous value: 'null'. Current value: 'true'.
        // Maybe something like ObservableInput would be better: https://stackoverflow.com/a/59116890/3486297
        setTimeout(() => this.loading$ = $event, 0);
    }

    private onQueryParamsChanged = (params: Params) => {
        const regionFilterEnabled = (this.config.settings?.displayPropertiesByRegionEnabled === true);

        this.isRegionSearch = !!(
            regionFilterEnabled
            && params.region
            && params.propertyId
            && params.propertyId === 'All'
        );

        this.validateParams(params);
    };

    // HOT-FIX for deep links into /booking/results without all required search params
    //         like [ #/booking/results?propertyId=BER ] without adults
    // TODO: build ONE central search params service / validator / replacer / upgrade ... ONE - really JUST ONE
    private validateParams(params: Params): void {
        const arrival = params.arrival ? moment(params.arrival) : moment();
        const departure = params.departure ? moment(params.departure) : moment(arrival.toDate()).add(1, 'days');

        const fixed = {
            arrival: arrival.format('YYYY-MM-DD'),
            departure: departure.format('YYYY-MM-DD'),
            adults: params.adults ? Number(params.adults) : 1,
            promoCode: params.promoCode,
            propertyId: params.propertyId || '',
            region: params.region,
        };

        const isValid = (asString(fixed.propertyId, '') === params.propertyId)
            && (asString(fixed.adults, '') === params.adults)
            && (fixed.arrival === params.arrival)
            && (fixed.departure === params.departure);

        if (!isValid) {
            this.router.navigate(
                ['booking/results'],
                { queryParams: fixed, queryParamsHandling: 'merge' },
            );
        }
    }
}
