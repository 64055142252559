import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookingErrorDetails, SaferpaySetupData } from 'app/fixme-inline-types';
import { interval, of } from 'rxjs';
import { catchError, concatMap, filter, take } from 'rxjs/operators';
import { CreateBookingResponseModel } from 'up-ibe-types';
import { environment } from '../../../../environments/environment';
import { BookingService } from '../../../services/booking.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { SaferpayPaymentDialogComponent } from './saferpay-payment-dialog.component';

@Component({
    selector: 'ibe-saferpay-payment',
    template: `<ibe-loading-bar [isLoading]="true"></ibe-loading-bar>`,
    styleUrls: ['./saferpay-payment.component.scss'],
})
export class SaferpayPaymentComponent implements OnInit {
    @Input('paymentSetupData') public paymentSetupData: SaferpaySetupData;
    @Input('bookingRequestId') public bookingRequestId: string;
    @Input('paymentRedirected') public paymentRedirected = false;

    @Output('onComplete') public onComplete: EventEmitter<CreateBookingResponseModel | BookingErrorDetails> = new EventEmitter();
    @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
    @Output('onRedirectComplete') public onRedirectComplete: EventEmitter<string> = new EventEmitter();
    @Output('toggleInPaymentFlow') public toggleInPaymentFlow: EventEmitter<boolean> = new EventEmitter();
    @Output('togglePaymentRedirected') public togglePaymentRedirected: EventEmitter<boolean> = new EventEmitter();

    constructor(
        // FIXME: DO NOT use public properties
        public readonly dialog: MatDialog,
        private readonly localStorageService: LocalStorageService,
        private readonly bookingService: BookingService,
        private readonly http: HttpClient,
    ) {}

    public ngOnInit(): void {
        if (!this.paymentSetupData && !this.paymentRedirected) {
            console.error('No Saferpay redirect URL');
        } else {
            this.checkForPaymentCompletion();

            if (!(this.paymentRedirected && this._processPayload())) {
                this._openPaymentDialog();
            }
        }
    }

    private _processPayload() {
        return this.localStorageService.getSaferpayToken();
    }

    private _openPaymentDialog() {
        this.toggleInPaymentFlow.emit(false);
        this.dialog.open(SaferpayPaymentDialogComponent, {
            data: {
                url: this.paymentSetupData.RedirectUrl,
            },
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-dialog',
            disableClose: false,
        });
        this.localStorageService.setSaferPayToken(this.paymentSetupData.Token);
        this.toggleIsLoading.emit(false);
    }

    private pollIsBookingComplete = () => {
        const httpParams = new HttpParams()
            .set('bookingRequestId', this.bookingRequestId);

        return this.http.get(
            `${environment.serverUrl}/api/payment/saferpay/is-booking-complete`, {
                params: httpParams,
            },
        );
    };

    private checkForPaymentCompletion(): void {
        const inProgress = this.bookingService.getBookingRequestInProgress();
        if (inProgress && inProgress.success) {
            this.onRedirectComplete.emit(this.bookingRequestId);
            return;
        }

        const waitTime = 2500;
        interval(waitTime)
            .pipe(
                concatMap(this.pollIsBookingComplete),
                filter((response: CreateBookingResponseModel) => !!(response)),
                take(1),
                catchError((error: BookingErrorDetails) => of(error)),
            ).subscribe(this.onIsBookingCompleteResponse);
    }

    private onIsBookingCompleteResponse = (response: CreateBookingResponseModel): void => {
        if (this.isErrorResponse(response)) {
            this.dialog.closeAll();
            this.onComplete.emit(response);
        } else {
            this.handlePaymentCompletionResponse(response);
        }
    };

    private handlePaymentCompletionResponse(response: CreateBookingResponseModel) {
        let success = false;
        if (response) {
            success = response.success;
            this.dialog.closeAll();
            this.toggleInPaymentFlow.emit(true);
            this.onComplete.emit({
                success,
                bookingRequestId: response.bookingRequestId,
                pmsBookingIds: response.pmsBookingIds,
                message: response.message,
            });
        }
        this.bookingService.setBookingRequestInProgress({ success, id: this.bookingRequestId });
    }

    private isErrorResponse(response: CreateBookingResponseModel | BookingErrorDetails): response is BookingErrorDetails {
        return 'error' in response;
    }
}
