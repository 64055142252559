import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Language } from 'app/fixme-inline-types';
import { CancellationFeeViewModel, ReservationViewModel } from 'app/services/booking/reservation.viewmodels';
import { ReservationModel } from 'up-ibe-types';
import { IbeConfigService } from '../../services/ibe-config.service';

@Component({
    selector: 'ibe-booking-cancellation-dialog',
    templateUrl: './booking-cancellation-dialog.component.html',
    styleUrls: ['./booking-cancellation-dialog.component.scss'],
})
export class BookingCancellationDialogComponent {
    // FIXME DO NOT USE PUBLIC PROPERTIES
    public reservation: ReservationViewModel;
    public cancellationFee: CancellationFeeViewModel;
    public language: Language;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: ReservationModel,
        // FIXME DO NOT INJECT complex Objects for just one simple value (>>> language)
        private readonly config: IbeConfigService,
    ) {
        this.reservation = new ReservationViewModel(data);
        this.cancellationFee = this.reservation.cancellationFee;
        this.language = this.config.language;
    }

    public get isCancellationFeePayable() {
        return this.cancellationFee.isPayable();
    }
}
