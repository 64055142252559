import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/services/toaster.services';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { GuestAuthService } from '../../services/guest-auth.service';
import { IbeConfigService } from '../../services/ibe-config.service';

@Component({
    selector: 'ibe-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss'],
})
export class EditAccountComponent implements OnInit {
    @ViewChild('guestFormDirective', { static: true }) public guestFormDirective: FormGroupDirective;

    // FIXME: DO NOT use public properties
    public guestForm: FormGroup;
    public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        private readonly config: IbeConfigService,
        private readonly http: HttpClient,
        private readonly guestAuthService: GuestAuthService,
        private readonly toasterService: ToasterService,
    ) {
    }

    public ngOnInit() {
        this.guestForm = this.formBuilder.group({});
    }

    public addFormControl(name: string, formGroup: FormGroup) {
        this.guestForm.addControl(name, formGroup);
        const formControlsLength = 2;
        if (Object.keys(this.guestForm.controls).length === formControlsLength) {
            this.loadGuestDetailsData();
        }
    }

    private loadGuestDetailsData(): void {
        // Have to encode email: https://github.com/angular/angular/issues/18261
        const pmsGuestId = this.guestAuthService.getPmsGuestId();
        const params = new HttpParams()
            .set('pmsGuestId', pmsGuestId);

        this.http
            .get(`${environment.serverUrl}/api/ibe/guest`, { params })
            .subscribe({
                // tslint:disable-next-line:no-any
                next: (response: any) => {
                    if (response.user) {
                        this.guestForm.patchValue({
                            details: response.user,
                        });
                        this.guestForm.patchValue({
                            address: response.user.address,
                        });
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.toasterService.showError(
                        this.translate.instant('guest_management.error_getting_details'),
                        '',
                    );
                },
            });
    }

    public isFormControlInvalid(formControl: AbstractControl) {
        return isFormControlInvalid(formControl, this.guestFormDirective);
    }

    public onFormSubmit(event: Event) {
        this.submit.next(true);
        event.preventDefault();
        if (this.guestForm.valid) {
            // Have to encode email: https://github.com/angular/angular/issues/18261
            const pmsGuestId = this.guestAuthService.getPmsGuestId();
            const params = new HttpParams()
                .set('pmsGuestId', pmsGuestId)
                .set('language', this.config.language);

            this.http
                .put(
                    `${environment.serverUrl}/api/ibe/guest`,
                    { ...this.guestForm.value },
                    { params },
                )
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next: (response: any) => {
                        // FIXME: why do we ignore this response
                        this.toasterService.showSuccess(
                            this.translate.instant('guest_management.details_updated_successfully'),
                            '',
                        );
                    },
                    error: (error) => {
                        console.error(error);
                        this.toasterService.showError(
                            this.translate.instant('guest_management.error_updating_details'),
                            '',
                        );
                    },
                });
        }
    }
}
