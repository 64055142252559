import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CalendarDay,
    CalendarDayData,
    CalendarDayRestrictions,
    CalendarStayDateSelectionEvent,
    CheapestAvailabilityData,
    DayClass,
} from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { environment } from 'environments/environment';
import { compact, flatten, uniqBy } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Property } from 'up-ibe-types';
import * as validate from 'validate.js';
import { IbeConfigService } from '../../../services/ibe-config.service';
import {
    AvailabilityRestrictionsDialogComponent,
} from '../availability-restrictions-dialog/availability-restrictions-dialog.component';

const dateRestrictionConstraints = {
    minLengthOfStay: { numericality: { lessThanOrEqualTo: 0 } },
    maxLengthOfStay: { numericality: { greaterThanOrEqualTo: 365 } },
};

@Component({
    selector: 'ibe-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
    @Input('arrivalDate') public arrivalDate: Date | undefined;
    @Input('departureDate') public departureDate: Date | undefined;
    @Input('hoveredDepartureDate') public hoveredDepartureDate: Date | undefined;
    @Input('currentMonth') public currentMonth: moment.Moment;
    @Input('property') public property: Property | undefined;
    @Input('potentialAvailabilitySpanSubject') public potentialAvailabilitySpanSubject: ReplaySubject<CalendarDay[]>;
    @Output('onArrivalDateSelection') public onArrivalDateSelection: EventEmitter<Date> = new EventEmitter();
    @Output('onDepartureDateSelection') public onDepartureDateSelection: EventEmitter<Date> = new EventEmitter();
    @Output('onDepartureDateHover') public onDepartureDateHover: EventEmitter<Date> = new EventEmitter();
    @Output('onStayDateSelection') public onStayDateSelection: EventEmitter<CalendarStayDateSelectionEvent> = new EventEmitter();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public calendarDates: (CalendarDay | undefined)[] = [[moment]] as unknown as CalendarDay[];
    public isLoading = false;
    public fetchAvailability = new Subject<Date>();
    public smallestMinLengthOfStay: number;
    public restrictionSet: CalendarDayRestrictions[] = [];
    public currentMonthDisplay: string;
    public numOfDaysWithRestrictions = 0;
    public numOfDaysLeftInMonth = 0;

    private potentialAvailabilitySpan: CalendarDay[] = [];
    private biggestMaxLengthOfStay = 364;
    private _request: Subscription;

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly currentRoute: ActivatedRoute,
        public readonly dialog: MatDialog,
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
        private readonly http: HttpClient,
    ) {
    }

    private onAvailabilityLoaded = (cheapestAvailability: CheapestAvailabilityData): void => {
        this.smallestMinLengthOfStay = cheapestAvailability?.smallestMinLos || 0;
        this._generateCalendarDates(cheapestAvailability.dayData);
    };

    // tslint:disable-next-line:no-any
    private onAvailabilityError = (error: any): void => {
        // FIXME - a single calendar component should kill the whole app, therefor we catch the error
        // TODO: either show an error message and a retry button, or show an info and retry after a timeout
        // INFO: the cheapest availability call is expensive in server calculation and server side PMS API calls
        //     - be careful and respectful !!!
        console.error('CalendarComponent.onAvailabilityError', error);
    };

    public ngOnInit() {
        if (this.currentMonth) {
            this.currentMonth = moment(this.currentMonth, 'YYYY-MM-DD');
        } else {
            this.currentMonth = moment();
        }

        this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');

        this._request = this._fetchAvailabilityData(this.currentMonth.toDate())
            .subscribe(this.onAvailabilityLoaded, this.onAvailabilityError);

        const debounceTimeDuration = 1000;
        this.fetchAvailability
            .pipe(debounceTime(debounceTimeDuration))
            .subscribe(() => {
                this._request.unsubscribe();
                this._request = this._fetchAvailabilityData(this.currentMonth.toDate())
                    .subscribe(this.onAvailabilityLoaded, this.onAvailabilityError);
            });
    }

    public selectPreviousMonth() {
        if (this.currentMonth.month() !== moment().month()) {
            this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
            this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');
            this.isLoading = true;
            this.fetchAvailability.next();
        }
    }

    public selectNextMonth() {
        this.currentMonth = moment(this.currentMonth).add(1, 'month');
        this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');
        this.isLoading = true;
        this.fetchAvailability.next();
    }

    public isFeatureMaintenanceDaysEnabled(): boolean {
        // FIXME: [TSC] This expression is unnecessarily compared to a boolean. Just use it directly
        //      - >>> accountFeatureWhitelist is unknown and therefore maintenanceDays - the TSC rule is WRONG
        return this.config?.accountFeatureWhitelist?.maintenanceDays as unknown === true;
    }

    public onDaySelect(event: Event, day: CalendarDay) {
        if (this._isDaySelectable(day)) {
            if (this.config.settings.availabilityCalendarRestrictionsModalEnabled && day.hasRestrictions && this.departureDate) {
                return this.dialog.open(AvailabilityRestrictionsDialogComponent, {
                    data: day.data.restrictions,
                }).afterClosed()
                    .subscribe((dialogResponse: boolean) => {
                        return (dialogResponse) ? this._selectDay(day) : false;
                    });
            } else {
                return this._selectDay(day);
            }
        } else {
            return false;
        }
    }

    private _isDaySelectable(day: CalendarDay) {
        const isValidDay = moment(day.date).isSameOrAfter(moment(), 'day');
        if (!isValidDay) {
            return false;
        }
        const selectDeparture = (this.arrivalDate && !this.departureDate);
        if (!day.data.isAvailable && !selectDeparture && !day.data.allowDeparture) {
            return false;
        }
        if (selectDeparture && day.data.restrictions?.closedOnDeparture) {
            return false;
        }
        return !((this.departureDate || !this.arrivalDate) && day.data.restrictions?.closedOnArrival);
    }

    private _selectDay(day: CalendarDay) {
        let daysArray = [];
        this.potentialAvailabilitySpanSubject.subscribe(response => {
            this.potentialAvailabilitySpan = response;
        });
        if (this.arrivalDate && this.departureDate) {
            this.smallestMinLengthOfStay = day.data.restrictions?.minLengthOfStay || 0;
            this.arrivalDate = day.date.toDate();
            this.departureDate = undefined;
        } else if (!this.arrivalDate || this._isDepartureSameOrBeforeArrival(day)) {
            this.arrivalDate = day.date.toDate();
        } else {
            if (moment(day.date).isBefore(moment(this.arrivalDate).add(this.smallestMinLengthOfStay, 'days'))) {
                return false;
            }

            daysArray = this.potentialAvailabilitySpan.concat(flatten(compact(this.calendarDates)));
            const arrivalIndex = daysArray.findIndex((date) => date?.date.isSame(moment(this.arrivalDate), 'day'));
            const departureIndex = daysArray.findIndex((date) => date?.date.isSame(day.date, 'day'));

            if (daysArray.slice(arrivalIndex, departureIndex).find(searchedDay => !searchedDay?.data.isAvailable)) {
                this.toasterService.showError(
                    this.translate.instant('availability_calendar.calendar_error'),
                    this.translate.instant('availability_calendar.unavailable_booking_period'),
                );
                this.arrivalDate = undefined;
                return false;
            }

            this.departureDate = day.date.toDate();
        }

        daysArray = flatten(this.calendarDates);
        const arrival = daysArray.findIndex((date) => date?.date.isSame(moment(this.arrivalDate), 'day'));
        const lastDayOfMonth = daysArray.findIndex(
            (date) => date?.date.isSame(
                moment(this.arrivalDate).endOf('month'),
                'day',
            ),
        );

        this.onArrivalDateSelection.emit(this.arrivalDate);
        this.potentialAvailabilitySpanSubject.next(daysArray.slice(arrival, lastDayOfMonth + 1) as CalendarDay[]);
        this.onDepartureDateSelection.emit(this.departureDate);

        if (this.arrivalDate && this.departureDate) {
            this.onStayDateSelection.emit({
                arrivalDate: this.arrivalDate,
                departureDate: this.departureDate,
            });
        }
        return true;
    }

    public onDayHover(event: Event, day: CalendarDay) {
        if (this.arrivalDate && !this.departureDate) {
            this.hoveredDepartureDate = day.date.toDate();
            this.onDepartureDateHover.emit(this.hoveredDepartureDate);
        }
    }

    public isArrivalDay(day: CalendarDay) {
        return (this.arrivalDate && moment(day.date).isSame(moment(this.arrivalDate), 'day'));
    }

    public isDepartureDay(day: CalendarDay) {
        return (this.departureDate && moment(day.date).isSame(moment(this.departureDate), 'day'));
    }

    public calendarDayClass(day: CalendarDay) {
        const classes: DayClass = {
            'ibe-calendar-day-active': moment(day.date).isSameOrAfter(moment(), 'day'),
            'ibe-no-availability': day.data && !day.data.isAvailable && !day.data.allowDeparture,
            'ibe-calendar-day-arrival-date': !!(
                this.arrivalDate
                && moment(day.date).isSame(moment(this.arrivalDate), 'day')
            ),
            'ibe-calendar-day-departure-date': !!(
                this.departureDate
                && moment(day.date).isSame(moment(this.departureDate), 'day')
            ),
            'ibe-calendar-day-stay-date': false,
            'ibe-calendar-day-invalid-date': moment(day.date).isBetween(
                moment(this.arrivalDate),
                moment(this.arrivalDate).add(this.smallestMinLengthOfStay, 'days'),
                'days',
            ),
            'ibe-calendar-maintenance-day': !!(
                day.data.restrictions?.closedOnArrival && day.data.restrictions?.closedOnDeparture
            ),
        };
        classes[`${moment(day.date).format('ddd')}-${moment(day.date).format('YYYY-MM-DD')}`] = true;

        if ((this.departureDate && moment(day.date).isBetween(moment(this.arrivalDate), moment(this.departureDate), 'day'))
            || (moment(day.date).isBetween(moment(this.arrivalDate), moment(this.hoveredDepartureDate), 'day'))) {
            classes['ibe-calendar-day-stay-date'] = true;
        }

        return classes;
    }

    private _isDepartureSameOrBeforeArrival(day: CalendarDay): boolean {
        return moment(day.date.toDate())
            .isSameOrBefore(this.arrivalDate, 'day');
    }

    // FIXME: why does a calendar component - load external data - use a service
    private _fetchAvailabilityData(date: Date) {
        this.isLoading = true;
        this.numOfDaysWithRestrictions = 0;
        this.numOfDaysLeftInMonth = 0;
        const queryParams = this.currentRoute.snapshot.queryParams;
        const propertyId = this.property ? this.property.pmsId : queryParams.propertyId;
        const children = queryParams.childrenAges ? queryParams.childrenAges.length : 0;

        const fromDate = moment(date).startOf('month').isBefore(moment()) ? moment() : moment(date).startOf('month');

        let params = new HttpParams()
            .set('from', fromDate.format('YYYY-MM-DD'))
            .set('to', moment(date).endOf('month').format('YYYY-MM-DD'))
            .set('propertyId', propertyId)
            .set('adults', queryParams.adults)
            .set('children', children.toString());

        if (queryParams.promoCode) {
            params = params.set('promoCode', queryParams.promoCode);
        }

        // FIXME: api access in a component
        return this.http.get(
            `${environment.serverUrl}/api/ibe/cheapest-availability-per-day`,
            { params },
        );
    }

    private _generateCalendarDates(calendarDayData: CalendarDayData[]) {
        this.numOfDaysLeftInMonth = calendarDayData.length;
        const localCurrentMonth: moment.Moment[] = Array(this.currentMonth.daysInMonth())
            .fill(undefined)
            .map((day, index) => {
                return moment(this.currentMonth).date(index + 1);
            });

        const paddedMonth = [];
        // add empty entries to fill up everything before the first monday in the month
        let firstOfMonth = moment(localCurrentMonth[0]);
        while (firstOfMonth.day() !== 1) {
            paddedMonth.push(undefined);
            firstOfMonth = firstOfMonth.subtract(1, 'day');
        }

        // FIXME: this is a hack until the updated calendar goes live
        //        this is not tested
        //        this is not intended to be fast nor correct
        //   it is - we do need 'something' NOW
        let lastDayWasAvailable = false;

        // add the dates in the month with some 'invalid' supporting data
        paddedMonth.push(...localCurrentMonth.map(date => {
            const dayData = this.findCalendarDayByDate(calendarDayData, date);
            const dayDate = moment(date.toISOString());
            if (dayData) {
                const calendarDay = this.calendarDay(dayData, dayDate);

                // FIXME hack broken calendar
                if (!calendarDay.data.isAvailable && lastDayWasAvailable) {
                    calendarDay.data.allowDeparture = true;
                }
                lastDayWasAvailable = calendarDay.data.isAvailable && !calendarDay.data.restrictions?.closed;

                return calendarDay;
            } else {
                return this.emptyDay(dayDate);
            }

        }));

        // split into 7's
        // tslint:disable-next-line:no-any
        const evenlySplitWeeks: any = [];
        const daysInWeekNumber = 7;
        paddedMonth.forEach((date, index) => {
            if (index % daysInWeekNumber === 0) {
                evenlySplitWeeks.push([]);
            }
            evenlySplitWeeks[evenlySplitWeeks.length - 1].push(date);
        });

        // pad the other way now
        const lastWeek = evenlySplitWeeks[evenlySplitWeeks.length - 1];
        while (lastWeek.length < daysInWeekNumber) {
            lastWeek.push(undefined);
        }
        this.restrictionSet = uniqBy(this.restrictionSet, (item) => {
            return item.maxLengthOfStay ? item.maxLengthOfStay : item.minLengthOfStay;
        });
        this.calendarDates = evenlySplitWeeks;
        this.isLoading = false;
    }

    private findCalendarDayByDate(calendarDayData: CalendarDayData[], date: Moment) {
        return calendarDayData.find((data) => {
            return moment(date).isSame(moment(data.date), 'day');
        });
    }

    private calendarDay(dayData: CalendarDayData, dayDate: moment.Moment) {
        let dateHasRestrictions = false;
        if (
            dayData.restrictions
            // TODO: remove validate and replace with yup - validate.js is outdated and horrible typed (any, any, any)
            && !validate.isEmpty(dayData.restrictions)
            && validate(dayData.restrictions, dateRestrictionConstraints)
        ) {
            if (this._dayHasLengthOfStayRestriction(dayData.restrictions)) {
                this.numOfDaysWithRestrictions++;
                this.restrictionSet.push(dayData.restrictions);
                dateHasRestrictions = true;
            }
            if (dayData.restrictions.maxLengthOfStay && dayData.restrictions.maxLengthOfStay > this.biggestMaxLengthOfStay) {
                dayData.restrictions.maxLengthOfStay = 0;
            }
        }
        if ((!dayData.isAvailable || !dayDate.isSameOrAfter(moment(), 'day'))) {
            dateHasRestrictions = false;
        }

        return {
            date: dayDate,
            data: dayData,
            hasRestrictions: dateHasRestrictions,
        };
    }

    private emptyDay(dayDate: moment.Moment) {
        return {
            date: dayDate,
            data: {},
            hasRestrictions: false,
        };
    }

    public getRestrictionText(key: keyof CalendarDayRestrictions) {
        let restrictionText = '';
        if (key === 'minLengthOfStay') {
            restrictionText = restrictionText + this.translate.instant(
                `availability_calendar.restrictions.min_length_of_stay`,
                { minLengthOfStay: this.restrictionSet[0].minLengthOfStay },
            );
        }
        if (key === 'maxLengthOfStay') {
            restrictionText = restrictionText + this.translate.instant(
                `availability_calendar.restrictions.max_length_of_stay`,
                { maxLengthOfStay: this.restrictionSet[0].maxLengthOfStay },
            );
        }
        return restrictionText;
    }

    public someDayHasRestriction(key: keyof CalendarDayRestrictions) {
        return flatten(this.calendarDates).find(day => day?.data?.restrictions?.[key]);
    }

    private _dayHasLengthOfStayRestriction(restrictions: CalendarDayRestrictions): boolean {
        return !!(restrictions.minLengthOfStay && restrictions.minLengthOfStay > 1)
            || !!(restrictions.maxLengthOfStay && restrictions.maxLengthOfStay > 0);
    }

// tslint:disable-next-line: max-file-line-count
}
