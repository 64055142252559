import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AvailabilityResultRateModel } from 'up-ibe-types';

export type RateInfoModalData = {
    availabilityResultRate: AvailabilityResultRateModel
}

@Component({
    selector: 'ibe-rate-info-modal',
    templateUrl: './rate-info-modal.component.html',
    styleUrls: ['./rate-info-modal.component.scss'],
})
export class RateInfoModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: RateInfoModalData) {}
}
