import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/services/toaster.services';
import { ExtraModel, ReservationModel } from 'up-ibe-types';
import { ErrorDialogComponent } from '../../../error-dialog/error-dialog.component';
import { ExtrasStoreService } from '../../../services/extras-store.service';
import { ReservationService } from '../../../services/reservation.service';

@Component({
    selector: 'ibe-add-extras-dialog',
    templateUrl: './add-extras-dialog.component.html',
    styleUrls: ['./add-extras-dialog.component.scss'],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: 900 } },
    ],
})
export class AddExtrasDialogComponent implements OnInit {
    // FIXME: DO NOT use public properties
    public isLoading = true;
    public selectedExtras: ExtraModel[] = [];
    public extras: ExtraModel[];

    constructor(
        // FIXME: DO NOT use public properties
        @Inject(MAT_DIALOG_DATA) public data: ReservationModel,
        public dialogRef: MatDialogRef<AddExtrasDialogComponent, boolean>,
        public dialog: MatDialog,
        private readonly extrasStore: ExtrasStoreService,
        private readonly reservationService: ReservationService,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._fetchExtras();
    }

    private _fetchExtras() {
        const params = {
            propertyId: this.data.property.id,
            ratePlanId: this.data.ratePlan.id,
            arrival: this.data.arrival,
            departure: this.data.departure,
            adults: String(this.data.adults),
            childrenAges: this.data.childrenAges,
        };
        this.extrasStore.load({ params })
            .subscribe(response => {
                this.extras = this._filterOutReservationExtras(response);
                this.isLoading = false;
            });
    }

    public completeExtrasStep() {
        const updatedExtras = this.data.extras.concat(this.selectedExtras);
        this.isLoading = true;
        const params = {
            propertyId: this.data.property.id,
            reservationId: this.data.id as string,
            reservation: {
                ...this.data,
                extras: updatedExtras,
            },
        };
        this.reservationService
            .addExtrasToReservation(params)
            .subscribe({
                next: this.onExtrasAdded,
                error: this.onExtraError,
            });
    }

    private onExtrasAdded = (success: boolean) => {
        this.isLoading = false;
        if (success) {
            this.data.extras = this.data.extras.concat(this.selectedExtras);

            const title = this.translate.instant('extras.extras_updated');
            const body = this.translate.instant('extras.extras_updated_successfully');

            this.toasterService.showSuccess(title, body);
        }
        this.dialogRef.close(success);
    };

    private onExtraError = (error: unknown) => {
        console.error('AddExtrasDialog', error);

        const title = this.translate.instant('dialog_error_codes.extras_update_error.title');
        const message = this.translate.instant('dialog_error_codes.extras_update_error.message');

        this.dialog.open(ErrorDialogComponent, { data: { title, message } });
        this.dialogRef.close();
    };

    public skipExtrasStep() {
        this.dialogRef.close();
    }

    // This is done so guests can't unselect already selected extras
    private _filterOutReservationExtras(extras: ExtraModel[]) {
        const reservationExtrasId = this.data.extras.map(extra => extra.id);
        return extras.filter(extra => !reservationExtrasId.includes(extra.id));
    }

    public onSelectedExtrasUpdate($event: ExtraModel[]) {
        this.selectedExtras = $event;
    }
}
