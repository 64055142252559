import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtraModel } from 'up-ibe-types';

export type ExtrasComponentData = {
    selectedExtras: ExtraModel[]
    extras: Subject<ExtraModel[]>,
    propertyId: string,
    skipOnBackdropClick: boolean
}

@Component({
    selector: 'ibe-extras',
    templateUrl: './extras.component.html',
    styleUrls: ['./extras.component.scss'],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: 900 } },
    ],
})
export class ExtrasComponent implements OnInit, OnDestroy {
    private selectedExtrasCache: ExtraModel[] = [];

    // FIXME: DO NOT use public properties
    public extras: ExtraModel[] = [];
    public selectedExtras: ExtraModel[] = [];
    public loading = true;

    private _destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(
        // FIXME: DO NOT use public properties
        @Inject(MAT_DIALOG_DATA) public data: ExtrasComponentData,
        public dialogRef: MatDialogRef<ExtrasComponent, ExtraModel[]>,
    ) {
        // FIXME: why does a dialog needs to listen to external data changes!?!?!?
        //      - when
        this.data
            .extras
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                (extras: ExtraModel[]) => {
                    this.loading = false;
                    this.extras = extras;

                    if (!this.extras.length) {
                        this.dialogRef?.close([]);
                    }
                },
            );
    }

    @HostListener('window:keyup.esc')
    public onKeyUp(): void {
        this.skipExtrasStep();
    }

    public ngOnInit(): void {
        if (this.data?.selectedExtras) {
            this.selectedExtras = (this.selectedExtrasCache = this.data.selectedExtras);
        }

        this.onBackdropClick();
    }

    public completeExtrasStep(): void {
        this.dialogRef?.close(this.selectedExtras);
    }

    public skipExtrasStep(): void {
        this.filterOutNonInclusiveExtrasOnDialogSkip();

        this.dialogRef?.close(this.selectedExtras);
    }

    public onSelectedExtrasUpdate(event: ExtraModel[]): void {
        this.selectedExtras = event;
    }

    private filterOutNonInclusiveExtrasOnDialogSkip(): void {
        const isInclusive = (extra: ExtraModel) => extra.isInclusiveInRate;

        this.selectedExtras = this.selectedExtras.filter(isInclusive);
    }

    public onClose(): void {
        if (this.data?.skipOnBackdropClick) {
            this.skipExtrasStep();
        } else {
            this.dialogRef?.close(this.selectedExtrasCache);
        }
    }

    private onBackdropClick(): void {

        this.dialogRef
            .backdropClick()
            .subscribe({
                next: this.onClose,
            });
    }

    public ngOnDestroy(): void {
        this._destroyed$.next(true);
        this._destroyed$.unsubscribe();
    }
}
