import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CalendarStayDateSelectionEvent } from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import * as moment from 'moment';
import { AvailabilityRateForReservationModel, MonetaryValueModel, ReservationModel } from 'up-ibe-types';
import { environment } from '../../../../environments/environment';
import { BookingService } from '../../../services/booking.service';
import { IbeConfigService } from '../../../services/ibe-config.service';

@Component({
    selector: 'ibe-edit-reservation-dates-dialog',
    templateUrl: './edit-reservation-dates-dialog.component.html',
    styleUrls: ['./edit-reservation-dates-dialog.component.scss'],
})
export class EditReservationDatesDialogComponent implements OnInit {
    @ViewChild('ibeDateRef', { static: true }) public ibeDateRef: ElementRef;

    // FIXME: DO NOT use public properties
    public isLoading = false;
    public hasAvailability = true;
    public arrival: Date;
    public departure: Date;
    public numberOfNights: number;
    public totalGrossAmount: MonetaryValueModel;
    public ratePlanId: string;
    public roomTypeId: string;

    constructor(
        // FIXME: DO NOT use public properties
        @Inject(MAT_DIALOG_DATA) public data: ReservationModel,
        private readonly config: IbeConfigService,
        private readonly bookingService: BookingService,
        private readonly http: HttpClient,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
        private readonly dialogRef: MatDialogRef<EditReservationDatesDialogComponent>,
    ) { }

    public ngOnInit() {
        this.arrival = moment(this.data.arrival).toDate();
        this.departure = moment(this.data.departure).toDate();
        this.numberOfNights = this.bookingService.calculateNumberOfNights(this.arrival, this.departure);
        this.totalGrossAmount = this.data.totalGrossAmount;
        this._setDateText();
    }

    public onStayDateSelection($event: CalendarStayDateSelectionEvent) {
        this.arrival = $event.arrivalDate;
        this.departure = $event.departureDate;
        this._setDateText();
        this.numberOfNights = this.bookingService.calculateNumberOfNights(this.arrival, this.departure);
        if (!this.isDateSameAsReservationDate()) {
            this._getAvailability();
        }
    }

    private _getAvailability() {
        let params = new HttpParams()
            .set('propertyId', this.data.property.id)
            .set('arrival', moment(this.arrival).format('YYYY-MM-DD'))
            .set('departure', moment(this.departure).format('YYYY-MM-DD'))
            .set('adults', this.data.adults.toString())
            .set('ratePlanId', this.data.ratePlan.id)
            .set('roomTypeId', this.data.unitType.id);

        if (this.data.promoCode) {
            params = params.append('promoCode', this.data.promoCode);
        }

        if (this.data.corporateCode) {
            params = params.append('corporateCode', this.data.corporateCode);
        }

        if (this.data.childrenAges && this.data.childrenAges.length) {
            params = params.append('childrenAges', this.data.childrenAges.join(','));
        }

        this.isLoading = true;
        this.hasAvailability = true;

        this.http.get(`${environment.serverUrl}/api/ibe/guest/reservation/availability-by-rate`, { params })
            .subscribe((response: AvailabilityRateForReservationModel) => {
                if (response) {
                    this.totalGrossAmount = response.totalGrossAmount;
                    this.roomTypeId = response.roomTypeId;
                    this.ratePlanId = response.id;
                } else {
                    this.hasAvailability = false;
                }
                this.isLoading = false;
            });
    }

    public confirmDateChanges() {
        this.isLoading = true;
        this.http
            .post(
                `${environment.serverUrl}/api/ibe/guest/change-dates`,
                {
                    propertyId: this.data.property.id,
                    reservationId: this.data.id,
                    arrival: this._getArrivalAsString(),
                    departure: this._getDepartureAsString(),
                    ratePlanId: this.ratePlanId,
                    roomTypeId: this.roomTypeId,
                },
            )
            .subscribe(
                (response: { success: boolean, message: string }) => {
                    if (response && response.success) {
                        this.toasterService.showSuccess(
                            this.translate.instant('guest_management.date_change_successful'),
                            '',
                        );
                        this.dialogRef.close(true);
                    } else {
                        this.toasterService.showError(
                            this.translate.instant('guest_management.date_change_error'),
                            response.message,
                        );
                        this.dialogRef.close();
                    }
                },
                (error) => {
                    this.toasterService.showError(
                        this.translate.instant('guest_management.date_change_error'),
                        error.error.message,
                    );
                    this.dialogRef.close();
                },
            );
        this.isLoading = false;
    }

    public isDateSameAsReservationDate() {
        if (this.data.arrival === this._getArrivalAsString() && this.data.departure === this._getDepartureAsString()) {
            this.totalGrossAmount = this.data.totalGrossAmount;
            return true;
        }
        return false;
    }

    private _setDateText() {
        const formattedArrival = moment(this.arrival).locale(this.config.language).format('MMM DD, YYYY');
        const formattedDeparture = moment(this.departure).locale(this.config.language).format('MMM DD, YYYY');
        this.ibeDateRef.nativeElement.innerHTML = `${formattedArrival} - ${formattedDeparture}`;
    }

    private _getArrivalAsString() {
        return moment(this.arrival).format('YYYY-MM-DD');
    }

    private _getDepartureAsString() {
        return moment(this.departure).format('YYYY-MM-DD');
    }
}
