import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { includes } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { Config, HttpStatus } from 'up-ibe-types';
import { fadeInOnEnterAnimation } from './animations';
import { Language } from './fixme-inline-types';
import { asNumber, hasProperty, isObject, isString } from './helpers/type.helper';
import { AnalyticsService } from './services/analytics.service';
import { IbeConfigService } from './services/ibe-config.service';
import { JourneyService } from './services/journey.service';
import { ThemeService } from './services/theme.service';
import { ToasterService } from './services/toaster.services';

@Component({
    selector: 'ibe-up',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeInOnEnterAnimation],
})
export class AppComponent implements OnInit {
    // FIXME: DO NOT USE public properties
    public configHasLoaded = false;
    public translationFileHasLoaded = false;
    public ibeLoadError: string;

    constructor(
        private readonly themeService: ThemeService,
        private readonly analyticsService: AnalyticsService,
        private readonly httpClient: HttpClient,
        private readonly element: ElementRef,
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly journeyService: JourneyService,
        private readonly toaster: ToasterService,
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }

    get toasterConfig() {
        return this.toaster.toasterConfig;
    }
    get getThemeCss() {
        return this.themeService.generateThemeCss();
    }

    public ngOnInit(): void {
        this.setIbeKey();
        this.journeyService.initiateJourney();

        this.loadConfig();
        this._setupPageViewAnalytics();
        this._setupGlobalRouterDispatch();

        this._initIbeExternalApi();
    }

    private setIbeKey(): void {
        const ibeKey = this.element.nativeElement.getAttribute('ibe-key');

        if (!ibeKey) {
            console.error('Missing ibe-key attribute. Did you forget to set it?');
        }

        this.config.ibeKey = ibeKey;
    }

    private loadConfig(): void {
        this.httpClient
            .get(`${environment.serverUrl}/api/ibe/config`)
            .subscribe(this.onConfigLoaded, this.onConfigError);
    }

    // tslint:disable-next-line:no-any
    private onConfigError = (response: unknown): void => {
        const error = (isObject(response) && hasProperty(response, 'error'))
            ? response.error
            : response;

        const status = (isObject(error) && hasProperty(error, 'status'))
            ? asNumber(error.status, HttpStatus.INTERNAL_SERVER_ERROR)
            : HttpStatus.INTERNAL_SERVER_ERROR;

        const message = (isObject(error) && hasProperty(error, 'message'))
            ? error.message
            : error;

        if (isString(message) && (status < HttpStatus.INTERNAL_SERVER_ERROR)) {
            this.ibeLoadError = message;
        } else {
            console.error('ConfigError', response);
        }
    };

    private onConfigLoaded = (config: Config): void => {
        this.config.setConfig(config);
        this.configHasLoaded = true;
        this._setupIbeLanguage();
        this._setupRedirect();
        this._setupDefaultPropertyId();
        this.config.initializeCurrentProperty();

        if (config.properties.length === 0) {
            this.ibeLoadError = `
                No properties have been enabled for this IBE yet.
                Please enable properties in the UP IBE Admin and try again.
            `;
        }
    };

    private _setupIbeLanguage(): void {
        // get language from language attribute on ibe widget code
        const languageAttributeValue = this.element.nativeElement.getAttribute('language');
        let language;

        // check if a language has been set and if its supported
        if (languageAttributeValue && includes(environment.languages, languageAttributeValue)) {
            language = languageAttributeValue;
        } else {
            // otherwise use default language setting from ibe config
            language = this.config.settings.defaultLanguage;
        }

        this.translate.use(language).subscribe((/* translationFile */) => this.translationFileHasLoaded = true);
        this.config.language = language;
    }

    private _setupRedirect(): void {
        const redirectTo = this.element.nativeElement.getAttribute('mask-redirect-path');
        if (redirectTo) {
            const origin = window.location.origin;
            this.config.redirectPath = `${origin}${redirectTo}`;
        }
    }

    private _setupDefaultPropertyId(): void {
        const defaultPropertyId: string | null = this.element.nativeElement.getAttribute('default-property-id');
        if (defaultPropertyId) {
            this.config.setDefaultPropertyId(defaultPropertyId);
        }
    }

    private _setupPageViewAnalytics(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlWithRemovedQueryParams = event.urlAfterRedirects.split('?')[0];

                this.analyticsService.defineOrGetDatalayer().push({
                    'event': 'UP IBE Page View',
                    'pageUrl': urlWithRemovedQueryParams,
                });
            }
        });
    }

    // Globally dispatch route change events on
    // the window object. This is so webdevs can
    // change the rest of the page outside the IBE
    // depending on which step the visitor is viewing
    //
    // Required because it looks like angular hijacks
    // the html5 hashchange event and makes it unusable
    // outside of angular
    private _setupGlobalRouterDispatch(): void {
        const windowTime = 3000;
        window.ibeHashChange = new ReplaySubject(1, windowTime);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                window.ibeHashChange.next(event.url);
            }
        });
    }

    private _initIbeExternalApi(): void {
        // Attach the possibility to change the language externally
        window.upIbe = window.upIbe || {};
        window.upIbe.setLanguage = (language: Language): void => {
            if (environment.languages.includes(language)) {
                this.translate.use(language).subscribe(() => this.translationFileHasLoaded = true);
                this.config.language = language;
                this.element.nativeElement.setAttribute('language', language);
                window.upIbe.bootstrapEngine();
            } else {
                console.error('Provided language is not supported');
            }
        };
    }
}
