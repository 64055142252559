import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IbeConfigService } from '../services/ibe-config.service';

@Injectable()
export class IbeKeyInterceptorService implements HttpInterceptor {
    constructor(
        private readonly config: IbeConfigService,
    ) {}

    public intercept(
        request: HttpRequest<{}>, next: HttpHandler,
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<{}> | HttpUserEvent<{}>> {
        const injectIbeKey = (this.config.ibeKey && !request.headers.has('ibe-key'));

        const newRequest = (injectIbeKey)
            ? request.clone({ headers: request.headers.set('ibe-key', this.config.ibeKey) })
            : request;

        return next.handle(newRequest);
    }
}
