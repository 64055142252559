import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const localStorageTokenKey = 'upibe_guest_jwt';
const jwtHelper = new JwtHelperService();

@Injectable({
    providedIn: 'root',
})
export class GuestAuthService {
    constructor(
        private readonly http: HttpClient,
    ) {}

    public getToken = () => jwtTokenGetter();

    public isTokenValid() {
        const token = this.getToken();

        if (token) {
            return !jwtHelper.isTokenExpired(token);
        }

        return false;
    }

    // This method is just some syntactic sugar to make code more readable outside of this service
    public isLoggedIn() {
        return this.isTokenValid();
    }

    public getUsername() {
        const token = this.getToken();

        if (!token) {
            return;
        }

        const decodedToken = jwtHelper.decodeToken(token);

        return decodedToken.username;
    }

    public getPmsGuestId() {
        const token = this.getToken();

        if (!token) {
            return;
        }

        const decodedToken = jwtHelper.decodeToken(token);
        return decodedToken.pmsGuestId;
    }

    public logout() {
        try {
            // FIXME: use LocalStorageService
            return localStorage.removeItem(localStorageTokenKey);
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('GuestAuthService.logout:', error);
            throw  error;
        }
    }

    public createGuest(data: {
        username: string;
        password: string;
        firstName: string;
        lastName: string;
        title: string;
        phone: string;
    }) {
        const url = window.location.href.split('#')[0];
        return this.http.post(`${environment.serverUrl}/api/ibe/guest`, {
            ...data,
            url,
        }).pipe(tap((response: { token: string; username: string; }) => {
            // FIXME: response is ignored !?!?
            this.loginGuest({ username: data.username, password: data.password }).subscribe(() => {});
        }));
    }

    public loginGuest(data: { username: string; password: string; }) {
        const url = window.location.href.split('#')[0];
        return this.http.post(`${environment.serverUrl}/api/ibe/guest/login`, { ...data, url })
            .pipe(
                tap((response: { token: string; username: string; }) => {
                    this._setToken(response.token);
                }),
            );
    }

    public fetchGuestByPmsId(data: { guestId: string }) {
        const httpParams = new HttpParams()
            .set('pmsGuestId', encodeURIComponent(data.guestId));

        return this.http
            .get(`${environment.serverUrl}/api/ibe/guest`, { params: httpParams });
    }

    private _setToken(token: string) {
        try {
            // FIXME: use LocalStorageService
            localStorage.setItem(localStorageTokenKey, token);
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('GuestAuthService._setToken:', error);
            throw  error;
        }
    }
}

export function jwtTokenGetter() {
    try {
        // FIXME: use LocalStorageService
        return localStorage.getItem(localStorageTokenKey);
    } catch (error) {
        // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
        console.error('GuestAuthService.jwtTokenGetter:', error);
        throw  error;
    }
}
