import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import {
    Breadcrumb,
    BreadcrumbHint,
    CaptureContext,
    ErrorEvent,
    Event,
    EventHint,
    TransactionEvent,
} from '@sentry/types';
import { environment } from 'environments/environment';
import { HttpStatus } from 'up-ibe-types';
import { hasProperty, isObject } from '../helpers/type.helper';
import { JourneyService } from './journey.service';
import { INVALID_PARAMS } from './room-results/search-params.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private readonly reportToSentry = true as boolean;

    // remove public static converter func
    public static toError(error: unknown): Error {
        if (error instanceof Error) {
            return error;
        } else if (error instanceof Response || error instanceof HttpErrorResponse) {
            return ErrorHandlerService.formatHttpResponseError(error);
        } else {
            return ErrorHandlerService.formatError(error);
        }
    }

    private static formatHttpResponseError(responseError: Response | HttpErrorResponse): Error {
        const err = new Error(`HTTP Error | ${responseError.status} | ${responseError.statusText}`);
        err.stack = tryGetJson(responseError);

        return err;
    }

    // tslint:disable-next-line:no-any
    private static formatError(error: any) {
        const e = new Error(ErrorHandlerService.getNameFromError(error));
        e.stack = tryGetJson(error);

        return e;
    }

    // tslint:disable-next-line:no-any
    private static getNameFromError(error: any) {
        const maybeMsg = error.name || error.message;
        if (typeof (maybeMsg) === 'string') {
            // definitely OK to use this as an error name
            return maybeMsg;
        } else {
            // probably not safe, just call it an "Error"
            return 'Error';
        }
    }

    constructor(
        private injector: Injector,
        private journey: JourneyService,
    ) {
        this.reportToSentry = tryInitializeSentry();
    }

    // tslint:disable-next-line:no-any
    public logErrorWithContext = (error: any, captureContext: CaptureContext) => {
        const err = ErrorHandlerService.toError(error);

        Sentry.captureException(err, captureContext);
    };

    // tslint:disable-next-line:no-any
    public handleError(error: any): void {
        // We're seeing a lot of errors in sentry like:
        // Non-Error exception captured with keys: message, name, stack

        if (!error) {
            console.warn('handle error with no error called');
            return;
        } else if (error === INVALID_PARAMS) {
            // INVALID_PARAMS is used to cancel invalid search parameter ('missing values' or 'arrival in the past', etc.)
            console.warn('Invalid search params');
            // It is an internal hack to 'navigate' - this does not belong into sentry
            return;
        }

        // Having our own ErrorHandler silences errors, so we have to show them again here
        console.error('handleError', error);

        this.sendToSentry(error);

        if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                const router = this.injector.get(Router);
                // FIXME: this is a 'dead end' the whole user process is killed here
                //        the only way out is 'restart' the booking process
                router
                    .navigate(['/error'], { queryParams: { errorCode: error.status } })
                    .catch(console.error);
                // FIXME: current test-cases (spyOn) do not return a promise and die to 'catch not found on undefined'
                // .catch((navError) => console.error('navigate', navError));
            }
        }
    }

    // tslint:disable-next-line:no-any

    // tslint:disable-next-line:no-any
    private sendToSentry(error: any) {
        if (this.reportToSentry) {
            const realError = ErrorHandlerService.toError(error);
            Sentry.withScope((scope) => {
                scope.setExtra('journeyToken', this.journey.getJourneyToken());

                Sentry.captureException(realError);
            });
        }
    }
}

function tryGetJson(error: object) {
    try {
        return JSON.stringify(error, undefined, 2);
    } catch (e) {
        return `Could not stringify error object. Best I can do is typeof: ${typeof (error)}`;
    }
}

let sentryInitialized = false;

export function tryInitializeSentry(): boolean {
    if (environment.sentry.enabled && !sentryInitialized) {
        // https://docs.sentry.io/platforms/javascript/guides/gatsby/configuration/integrations/default/#inboundfilters
        const ignoreErrors = [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            'Can\'t find variable: ZiteReader',
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
        ];
        const allowUrls = [
            'ibe.uphotel.agency/ibe.min.js',
            'ibe.staging.uphotel.agency/runtime.js',
            'ibe.staging.uphotel.agency/polyfills-es5.js',
            'ibe.staging.uphotel.agency/polyfills.js',
            'ibe.staging.uphotel.agency/scripts.js',
            'ibe.staging.uphotel.agency/vendor.js',
            'ibe.staging.uphotel.agency/main.js',
        ];

        const beforeSend = (event: ErrorEvent, hint: EventHint): Event | null => {
            if (event.message === 'Non-Error exception captured with keys: error, headers, message, name, ok') {
                if (isObject(hint.data.error) && hasProperty(hint.data.error, 'message')) {
                    event.message = hint.data.error.message;
                }
            }

            return event;
        };

        const beforeSendTransaction = (event: TransactionEvent, hint: EventHint): Event | null => {
            // TODO
            return event;
        };

        const beforeBreadcrumb = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null => {
            // TODO: filter other frame work
            return breadcrumb;
        };

        const sentryOptions: Sentry.BrowserOptions = {
            dsn: environment.sentry.endpointUrl,
            release: environment.sentry.release,
            environment: 'app',

            attachStacktrace: true,

            ignoreErrors,
            allowUrls,

            beforeSend,
            beforeSendTransaction,
            beforeBreadcrumb,
        };

        Sentry.init(sentryOptions);
        sentryInitialized = true;
    }

    return sentryInitialized;
}
