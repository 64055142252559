import { Component, Input, OnInit } from '@angular/core';
import { ReservationStatusEnum } from 'enums';
import { ReservationModel } from 'up-ibe-types';
import { ReservationService } from '../../services/reservation.service';

@Component({
    selector: 'ibe-modify-reservation',
    templateUrl: './modify-reservation.component.html',
    styleUrls: ['./modify-reservation.component.scss'],
})
export class ModifyReservationComponent implements OnInit {
    @Input('reservations') public reservations: ReservationModel[] = [];

    // FIXME: DO NOT use public properties
    public isLoading = false;

    constructor(
        private readonly reservationService: ReservationService,
    ) { }

    public ngOnInit() {
        this.getReservations();
    }

    public getReservations() {
        this.isLoading = true;
        this.reservationService
            .getGuestReservations()
            .subscribe({
                next: this.onReservationLoaded,
                // error: () => {},
                complete: () => { this.isLoading = false; },
            });
    }

    private onReservationLoaded = (reservations: ReservationModel[]) => {
        this.reservations = [];
        if (reservations.length) {
            this.reservations = this.reservationService.filterReservationsByStatuses(
                reservations,
                [ReservationStatusEnum.Confirmed],
            );
        }
    };
}
