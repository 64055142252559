import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AddressField, CountriesResponse } from 'app/fixme-inline-types';
import { Observable } from 'rxjs';
import { CheckoutFields, SelectOption, Settings } from 'up-ibe-types';
import { environment } from '../../../environments/environment';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { isFormControlValid } from '../../helpers/form.helper';
import { asString } from '../../helpers/type.helper';
import { BookingService } from '../../services/booking.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { ToasterService } from '../../services/toaster.services';
import { CountrySelectOption, countrySelectOption } from '../../static/countries';
import { stateOptionsList } from '../../static/states';

type DefaultCountry = {
    countryCode: string
};

@Component({
    selector: 'ibe-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    animations: [
        fadeInOnEnterAnimation,
        scaleUpAnimation,
    ],
})
export class AddressFormComponent implements OnInit {
    @ViewChild('addressFormDirective', { static: true }) public addressFormDirective: FormGroupDirective | null;

    @Input() public submitObservable: Observable<boolean>;

    @Output() public formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    private maxPostcodeLength: number = 20;
    private maxAddressLength: number = 200;

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public addressForm: FormGroup;
    public countriesList: {}[];
    public ibeSettings: Settings; // FIXME useless double of this.config.settings PUBLIC!!!!!
    public defaultCountries: CountriesResponse[] = [];
    public statesList: {
        US: SelectOption<string>[],
        CA: SelectOption<string>[]
    };

    private isCountriesReady: boolean = false;
    public get isReady(): boolean {
        return this.isCountriesReady;
    }

    public readonly countryCode = (): string => asString(this.addressForm?.controls['countryCode']?.value, '');
    public readonly stateCode = (): string => asString(this.addressForm?.controls['state']?.value, '');

    constructor(
        private readonly config: IbeConfigService,
        private readonly bookingService: BookingService,
        private readonly http: HttpClient,
        private readonly formBuilder: FormBuilder,
        private readonly toasterService: ToasterService,
        // FIXME: router is a fast hack for 'can not load useless data' - replace external data with internal const
        private readonly router: Router,
    ) {
        this.ibeSettings = this.config.settings;
        this.statesList = stateOptionsList;
        this.countriesList = countrySelectOption;

        this.buildAddressForm();
    }

    public ngOnInit(): void {
        this.fixBrowserAutofill();
        this.loadCountriesData();
    }

    private fixBrowserAutofill = () => {
        // some browser 'autofill' form without user interaction, BUT form inputs only trigger event on user interaction
        // we need the 'autofilled' US country to trigger, to show/add the states select (if enabled)
        const select = document.querySelector('#ibeCountryCodeSelect') as HTMLSelectElement;
        if (select) {
            select.dispatchEvent(new Event('change'));
        }
    };

    private onFormStateChanged = () => {
        if (this.isCountriesReady) {
            this.fixBrowserAutofill();

            const booker = this.bookingService.getBooker();
            this.addressForm?.patchValue({ ...booker.address });

            this.onCountrySelect();
            this.formReady.emit(this.addressForm);
        }
    };

    private loadCountriesData(): void {
        // tslint:disable-next-line:no-any
        const onCountryLoadError = (error: any) => {
            console.error('onCountryLoadError', error);
            // FIXME: this is a hack, because this form does not handle errors, when critical data could not be loaded.
            this.toasterService.showError('Network Error', 'Please try again...');
            // FIXME: REMOVE the stupid extra call and add the 'required' for all Bookings data to the ibe.config
            this.redirectTo('checkout/details');
        };
        this.http
            .get(`${environment.serverUrl}/api/ibe/${this.config.ibeKey}/default-address-countries`)
            .subscribe(this.onLoadDefaultCountries, onCountryLoadError);
    }

    public onLoadDefaultCountries = (defaults: DefaultCountry[]): void => {
        const countries: CountrySelectOption[] = countrySelectOption;
        const defaultCountryCodes: string[] = defaults.map((defaultCountry) => defaultCountry.countryCode);

        const isDefaultCountry = (country: CountriesResponse) => defaultCountryCodes.includes(country.value);
        const defaultCountries = countries.filter((country: CountrySelectOption) => isDefaultCountry(country));
        const countriesList = countries.filter((country: CountriesResponse) => !isDefaultCountry(country));

        const sortCountriesByName = (a: CountrySelectOption, b: CountrySelectOption) => ((a.label < b.label) ? -1 : 1);
        this.defaultCountries = defaultCountries.sort(sortCountriesByName);
        this.countriesList = countriesList.sort(sortCountriesByName);

        this.isCountriesReady = true;
        this.onFormStateChanged();
    };

    public isFormControlInvalid(formControl: AbstractControl): boolean {
        return (!!this.addressFormDirective)
            && !isFormControlValid(formControl, this.addressFormDirective);
    }

    public onCountrySelect(): void {
        if (this.showStatesField()) {
            const stateControl = new FormControl('', [Validators.required]);
            this.addressForm.addControl('state', stateControl);
        } else {
            this.addressForm.removeControl('state');
        }
    }

    public showStatesField(): boolean {
        const formCountryCode = this.countryCode();

        return this.config.settings.checkoutFields.address.state
            && (formCountryCode === 'US' || formCountryCode === 'CA');
    }

    private buildAddressForm(): void {
        this.addressForm = this.formBuilder.group({});
        const addressFields: AddressField[] = [
            {
                name: 'street',
                controlArgs: ['addressLine1', new FormControl('', [Validators.required, Validators.maxLength(this.maxAddressLength)])],
            },
            {
                name: 'city',
                controlArgs: ['city', new FormControl('', [Validators.required, Validators.maxLength(this.maxAddressLength)])],
            },
            {
                name: 'postalCode',
                controlArgs: [
                    'postalCode',
                    new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(this.maxPostcodeLength)]),
                ],
            },
            {
                name: 'countryCode',
                controlArgs: ['countryCode', new FormControl('', [Validators.required])],
            },
            {
                name: 'state',
                controlArgs: ['state', new FormControl('', [Validators.required])],
            },
            {
                name: 'marketingConsent',
                controlArgs: ['marketingConsent', new FormControl(false, [Validators.required])],
            },
        ];

        addressFields.forEach((field) => {
            const fieldName = field.name as keyof CheckoutFields['address'];
            if (this.ibeSettings.checkoutFields.address[fieldName]) {
                this.addressForm.addControl(...field.controlArgs);
            }
        });
    }

    private redirectTo = (ibePath: string): void => {
        const onNavigationError = (navError: unknown) => console.error(`navigation failed: ${ibePath}`, navError);
        this.router
            .navigate([ibePath])
            .catch(onNavigationError);
    };
}
