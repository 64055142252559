import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'ibe-guest-management-options',
    templateUrl: './guest-management-options.component.html',
    styleUrls: ['./guest-management-options.component.scss'],
})
export class GuestManagementOptionsComponent {
    constructor(
        private router: Router,
        private bookingService: BookingService,
    ) { }

    get isReservationInProgress(): boolean {
        return (this.bookingService.getReservations().length > 0);
    }

    public navigateToGuestUrl(path: string): void {
        this.router
            .navigateByUrl(path)
            .catch((navError) => console.error(`navigation failed: ${path}`, navError));
    }
}
