import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GuestTitle } from 'up-ibe-types';
import { GuestTitleEnum } from '../../enums';

export function generateGuestTitleOptions(translateService: TranslateService, pmsProvider: string) {
    const guestTitleValues: GuestTitle[] = [
        GuestTitleEnum.Mr,
        GuestTitleEnum.Ms,
        GuestTitleEnum.Dr,
        GuestTitleEnum.Prof,
        GuestTitleEnum.Other,
    ];

    return guestTitleValues.map((value) => {
        return {
            value,
            label: translateService.instant(`checkout_details.titles.${value}`),
        };
    });
}

export function isFieldRequired(formControl: AbstractControl): boolean {
    if (formControl.validator) {
        const validator = formControl.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
    }
    return false;
}

export const isFormControlValid = (formControl: AbstractControl, formDirective: FormGroupDirective): boolean => {
    const hasErrors = (formControl.errors !== null);
    const isChanged = formControl.dirty || formControl.touched;
    const submitting = formDirective.submitted;

    return !(hasErrors && (isChanged || submitting));
};

// This is an example of HIGH-LEVEL BULLSHIT
// FIXME - this function requires three return's !?!? for a boolean result.
// FIXME - this function claims that a non existing field or a field without formDirective is valid
// TODO  - a rule of thumb is: check for positive result 'isValid', not 'isInvalid'
export function isFormControlInvalid(formControl: AbstractControl | null, formDirective: FormGroupDirective): boolean {
    if (!formControl || !formDirective) {
        return false;
    }

    if (formControl.errors && (formControl.dirty || formControl.touched || formDirective.submitted)) {
        return true;
    }

    return false;
}
