import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GuestLoginComponent } from '../guest-management/guest-login/guest-login.component';
import { GuestAuthService } from './guest-auth.service';

@Injectable()
export class GuestManagementGuardService implements CanActivate {
    constructor(
        private readonly guestAuthService: GuestAuthService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
    ) { }

    private onDialogClosed = (dialogResponse: boolean) => {
        if (dialogResponse) {
            this.router
                .navigate(['guest/manage'])
                .catch((navError) => console.error('navigation failed: /guest/manage', navError));
        }
    };

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        if (this.guestAuthService.isLoggedIn()) {
            return true;
        } else {
            this.dialog.closeAll();
            this.dialog
                .open(GuestLoginComponent, { height: '550px', width: '500px' })
                .afterClosed()
                .subscribe(this.onDialogClosed, console.error);

            return false;
        }
    }
}
