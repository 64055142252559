import { HttpStatus } from '../../enums';
import { ApplicationError, Errors } from '../errors';

/**
 * this is a helper toolkit to remove the invalid interfaces and bullshit-types
 */

export const isObject = (variable: unknown): variable is object => {
    return (typeof variable === 'object')
        && !isArray(variable)
        && (variable !== null);
};

export const hasProperty = <O extends {}, P extends PropertyKey>(
    object: O,
    property: P,
): object is O & Record<P, unknown> => {
    return object.hasOwnProperty(property);
};

export const isString = (value: unknown): value is string => {
    return (typeof value === 'string');
};

export const isNumber = (value: unknown): value is number => {
    return (typeof value === 'number') && !isNaN(value);
};

export const isArray = (value: unknown): value is unknown[] => {
    return Array.isArray(value);
};

/** @internal remove this helper, when types are fixed */
export const requireBoolean = (value: unknown): boolean => {
    return (value === true);
};

/** @internal remove this helper, when types are fixed */
export const requireStringOrUndefined = (value: unknown): string | undefined => {
    const type = typeof value;
    return (type === 'string') ? `${value}` : undefined;
};

/** @internal remove this helper, when types are fixed */
export const requireString = (value: unknown): string => {
    const str = requireStringOrUndefined(value);
    if (str === undefined) {
        throw new ApplicationError(Errors.InvalidParam, 'invalid value - string expected', HttpStatus.Bad_Request, { value });
    }
    return str;
};

/** @internal remove this helper, when types are fixed */
export const requireNumberOrUndefined = (value: unknown): number | undefined => {
    const temp = (typeof value === 'string') ? parseFloat(value) : value;

    return (typeof temp === 'number' && !isNaN(temp)) ? temp : undefined;
};

/** @internal remove this helper, when types are fixed */
export const requireNumber = (value: unknown): number => {
    const number = requireNumberOrUndefined(value);
    if (number === undefined) {
        throw new ApplicationError(Errors.InvalidParam, 'invalid value - number expected', HttpStatus.Bad_Request, { value });
    }

    return number;
};

// replace is used to 'replace' invalid values with a default value
export const asNumber = (value: unknown, replace: number): number => {
    const num = isString(value) ? parseFloat(value) : value;

    return isNumber(num) ? num : replace;
};

// replace is used to 'replace' invalid values with a default value
export const asString = (value: unknown, replace: string): string => {
    return (isString(value) || isNumber(value))
        ? `${value}`
        : replace;
};
