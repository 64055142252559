import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IbeConfigService } from '../../../services/ibe-config.service';

// tslint:disable-next-line:no-any
declare const chckt: any;

@Component({
    selector: 'ibe-adyen-payment',
    templateUrl: './adyen-payment.component.html',
    styleUrls: ['./adyen-payment.component.scss'],
})
@Injectable()
export class AdyenPaymentComponent implements OnInit {
    @Input('paymentSetupData') public paymentSetupData: { payload: string };
    @Input('isLoading') public isLoading: boolean;
    @Input('paymentRedirected') public paymentRedirected = false;
    // tslint:disable-next-line:no-any
    @Output('onComplete') public onComplete: EventEmitter<any> = new EventEmitter();
    @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
    @Output('toggleInPaymentFlow') public toggleInPaymentFlow: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly route: ActivatedRoute,
    ) { }

    public ngOnInit() {
        if (this.paymentRedirected) {
            this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
                if (queryParams.has('payload')) {
                    const paymentData = { payload: queryParams.get('payload') };
                    this.onComplete.emit(paymentData);
                }
            });
        } else {
            this.setupWidget();
        }
    }

    private setupWidget() {
        const locale = this.mapLanguageCode(this.config.language);
        const translationObject = {
            'payButton': {
                [locale]: this.translate.instant('checkout_payment.confirm'),
            },
            'holderName': {
                [locale]: this.translate.instant('checkout_payment.holder_name'),
            },
            'creditCard.numberField.title': {
                [locale]: this.translate.instant('checkout_payment.card_number'),
            },
            'creditCard.expiryDateField.title': {
                [locale]: this.translate.instant('checkout_payment.expiry_date'),
            },
            'payment.processing': {
                [locale]: this.translate.instant('checkout_payment.processing'),
            },
        };

        const sdkConfig = {
            context: 'live',
            translations: translationObject,
            shopperLocale: locale,
            allowAddedLocales: true,
        };

        if (this.config.isPaymentInTestMode()) {
            sdkConfig.context = 'test';
        }

        if (!chckt) {
            throw new Error('The Adyen Checkout SDK script has not been loaded');
        }

        chckt.checkout(this.paymentSetupData, '#adyen-payment', sdkConfig);
        this.toggleIsLoading.emit(false);
        // tslint:disable-next-line:no-any
        chckt.hooks.beforeComplete = (_: any, paymentData: any) => {
            if (paymentData && paymentData.payload) {
                this.onComplete.emit(paymentData);
            }
            return false;
        };
        chckt.hooks.beforePendingRedirect = () => {
            this.toggleInPaymentFlow.emit(false);
            return false;
        };
    }

    public mapLanguageCode(languageCode: string) {
        if (languageCode === 'en') {
            return 'en_GB';
        } else if (languageCode === 'de') {
            return 'de_DE';
        } else if (languageCode === 'fi') {
            return 'fi_FI';
        } else if (languageCode === 'se') {
            return 'se_SE';
        } else if (languageCode === 'ru') {
            return 'ru_RU';
        } else if (languageCode === 'fr') {
            return 'fr_FR';
        } else {
            return 'en_GB';
        }
    }
}
