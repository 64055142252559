import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { accountNotAuthedMessage } from 'app/guest-management/guest-login/guest-login.component';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/*
  Why is this AuthInterceptor needed?
  ===================================
  If a users JWT token expires while they are already on a protected guest management route then
  any http request made will fail with a 401 FailedLogin error.
  This interceptor intercepts those errors and then re-routes the user to the guest management
  home route which will trigger the login dialog, allowing the user to re-authenticate.
*/
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly router: Router,
    ) {}

    public intercept(request: HttpRequest<{}>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<{}> | HttpUserEvent<{}>> {
        return next.handle(request).pipe(
            tap((event) => {}),
            // tslint:disable-next-line:no-any
            catchError((error: any) => {
                if (error.error && error.error.name === 'FailedLogin' && error.error.message !== accountNotAuthedMessage) {
                    this.router
                        .navigate(['guest/manage'])
                        .catch((navError) => console.error('navigation failed: /guest/manage', navError));
                }

                return throwError(error);
            }));
    }
}
