<ibe-steps-bar></ibe-steps-bar>

<div class="ibe-row ibe-checkout-row">

    <div class="ibe-col-md-6">
        <router-outlet></router-outlet>
    </div>

    <div class="ibe-col-md-6">
        <h2 class="ibe-checkout-step-title" *ngIf="!useRentalAgreement">
            {{ 'checkout.your_selection' | translate }}
        </h2>
        <h2 class="ibe-checkout-step-title" *ngIf="useRentalAgreement">
            Your Suite
        </h2>

        <ibe-reservation-card
            *ngFor="let reservation of reservations; index as idx;"
            [reservationKey]="idx"
            [reservation]="reservation"
            [showButtons]="isReservationsEditable"
            (onRemoveReservation)="onRemoveReservation()"
            (onExtrasChange)="onExtrasChange()"
            [showAddExtrasButton]="(reservation.extrasAvailable && allowAddExtra())"
            [displayInclusiveExtrasAsTaxes]="displayInclusiveExtrasAsTaxes"
            [@flyRightOnLeaveAnimation]
        ></ibe-reservation-card>

        <button class="ibe-add-another-room-btn" (click)="addAnotherRoom()">
            <i class="ibe-icon ibe-icon-bed-plus"></i>
            <span>{{ 'checkout.add_another_room' | translate }}</span>
        </button>

        <ibe-total-summary
            *ngIf="bookingCurrency"
            [bookingTotals]="bookingTotals"
            [currency]="bookingCurrency"
            [isSuppressed]="isSuppressed"
            [displayInclusiveExtrasAsTaxes]="displayInclusiveExtrasAsTaxes"
        ></ibe-total-summary>

        <div class="ibe-city-tax-estimate" *ngIf="showCityTaxEstimateEnabled">
            <span class="city-tax-message">
                {{ 'checkout.city_tax_estimate' | translate }}:
            </span>
            <span class="city-tax">
                {{ cityTaxEstimate | currency:bookingCurrency:'symbol':'1.2-2' }}
            </span>
        </div>
    </div>
</div>
