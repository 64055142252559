<h2 mat-dialog-title class="ibe-modal-title">{{ data.availabilityResultRate.name }}</h2>
<mat-dialog-content>
    <div class="ibe-rateplan-description">{{ data.availabilityResultRate.description }}</div>

    <div *ngIf="data.availabilityResultRate.cancellationPolicy" class="ibe-cancellation-policy">
        <h3 class="ibe-modal-subtitle">{{ 'dialog.cancellation_policy_title' | translate }}</h3>
        <p>
            {{ data.availabilityResultRate.cancellationPolicy.name }}
            -
            {{ data.availabilityResultRate.cancellationPolicy.description }}
        </p>
    </div>

    <div *ngIf="data.availabilityResultRate.depositPolicy" class="ibe-deposit-policy">
        <h3 class="ibe-modal-subtitle">{{ 'dialog.deposit_policy_title' | translate }}</h3>
        <p>
            {{ data.availabilityResultRate.depositPolicy.name }}
            -
            {{ data.availabilityResultRate.depositPolicy.pmsDescription }}
        </p>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="ibe-btn ibe-primary-btn" mat-dialog-close>{{ 'dialog.close' | translate }}</button>
</mat-dialog-actions>
