import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PersonsQtyData } from 'app/fixme-inline-types';
import * as _ from 'lodash';
import { asString } from '../../../helpers/type.helper';
import { IbeConfigService } from '../../../services/ibe-config.service';

@Component({
    selector: 'ibe-persons-qty-popup',
    templateUrl: './persons-qty-popup.component.html',
    styleUrls: ['./persons-qty-popup.component.scss'],
})
export class PersonsQtyPopupComponent implements OnInit {
    @Input('personsQtyData') public personsQtyData: PersonsQtyData;
    @Output('onValueChange') public onValueChange: EventEmitter<PersonsQtyData> = new EventEmitter();
    @Output('closePopup') public closePopup: EventEmitter<undefined> = new EventEmitter();

    private childrenQty: number[] = [];
    public get getChildAges(): number[] { return this.childrenQty; }
    public get maxNumberOfAdults(): number { return this.config.settings.maxNumberOfAdults; }
    public get maxNumberOfChildren(): number {return this.config.settings.maxNumberOfChildren; }
    public get isChildrenEnabled(): boolean { return this.config.isChildrenEnabled; }

    constructor(
        private readonly config: IbeConfigService,
        private readonly elementRef: ElementRef,
    ) {}

    public ngOnInit(): void {
        if (this.isChildrenEnabled && this.personsQtyData.childrenAges.length) {
            this.childrenQty = _.times(
                this.personsQtyData.childrenAges.length,
                (number): number => number,
            );
        }
    }

    public onAdultQtyChange(qty: number): void {
        this.personsQtyData.adults = qty;
        this.onValueChange.emit(this.personsQtyData);
    }

    public onChildrenQtyChange(qty: number): void {
        if (qty < this.childrenQty.length) {
            this.childrenQty.pop();
            this.personsQtyData.childrenAges.pop();
        }

        if (qty > this.childrenQty.length) {
            this.childrenQty.push(0);
            this.personsQtyData.childrenAges.push(0);
        }

        this.onValueChange.emit(this.personsQtyData);
    }

    public onChildrenAgeChange(index: number, value: string): void {
        this.personsQtyData.childrenAges[index] = Number(value);
        this.onValueChange.emit(this.personsQtyData);
    }

    public generateChildrenAgeOptions() {
        const maxChildrenAge = 17;
        return _.times(maxChildrenAge, (number) => {
            return number + 1;
        });
    }

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (targetElement) {
            const parentId = asString(targetElement?.parentElement?.id, '');
            const targetId = asString(targetElement?.id, '');

            if ((targetId !== 'personsQtyField') && (parentId !== 'personsQtyField')) {

                const clickedInside = this.elementRef.nativeElement.contains(targetElement);
                if (!clickedInside) {
                    this.closePopup.emit();
                }
            }
        }
    }
}
