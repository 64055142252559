import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Property } from 'up-ibe-types';
import { getVariationsInCurrentLanguage } from '../helpers/language.helper';
import { IbeConfigService } from './ibe-config.service';

@Injectable({
    providedIn: 'root',
})
export class FilterPropertiesService {
    private properties: Property[] = this.config.properties;
    private currentLanguage = this.translate.currentLang || 'en';
    private filteredProperties: Property[] = this.properties;
    private subject = new Subject();

    constructor(
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
    ) {
    }

    private _filterProperties(filterType: 'regions' | 'categories', filterValue: string) {
        if (filterValue !== 'All') {
            return this.properties.filter(property => {
                const attributesOfProperty = getVariationsInCurrentLanguage(property, filterType, this.currentLanguage);

                return attributesOfProperty.includes(filterValue);
            });
        } else {
            return this.properties;
        }
    }

    public setFilteredProperties(filterType: 'regions' | 'categories', filterValue: string) {
        this.filteredProperties = this._filterProperties(filterType, filterValue);
    }

    public getFilteredProperties(): Property[] {
        return this.filteredProperties;
    }

    public sendSelectionHasChanged() {
        this.subject.next();
    }

    public getSelectionHasChanged(): Observable<unknown> {
        return this.subject.asObservable();
    }

    public getFilteredPropertyIds = (): string[] => {
        return this.getFilteredProperties().map((property: Property) => property.pmsId);
    };
}
