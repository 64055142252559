<div class="ibe-rate-options" [ngClass]="{'has-special-rates': hasSpecialRates}">
    <span class="ibe-special-rate-title" *ngIf="hasSpecialRates">SPECIAL RATE</span>

    <ng-container *ngIf="hasSpecialRates">
        <div class="ibe-special-rates">
            <ng-container *ngFor="let rate of availabilityResult.rates">
                <div
                    class="ibe-rate-option ibe-special-rate"
                    *ngIf="isSpecialRate(rate)"
                    [ngClass]="{selected: isRateSelected(rate.id)}"
                    (click)="selectRate(rate)"
                >
                    <div class="ibe-rate-option-left">
                        <div class="ibe-radio-outer">
                            <div class="ibe-radio-inner"></div>
                        </div>
                        <div class="ibe-rate-title">
                            <span *ngIf="!rate.suppressed && !useBasePrices">
                                {{
                                rate.totalGrossAmount?.amount
                                    | currency: rate.totalGrossAmount.currency: 'symbol': '1.2-2'
                                }}
                                |
                            </span>
                            <span *ngIf="!rate.suppressed && useBasePrices && !rateHasAdditionalIncludedExtras(rate)">
                                {{
                                rate.totalGrossAmount?.amount
                                    | currency: rate.totalGrossAmount.currency:'symbol':'1.2-2'
                                }}
                                |
                            </span>
                            <span *ngIf="!rate.suppressed && useBasePrices && rateHasAdditionalIncludedExtras(rate)">
                                {{
                                rate.totalBaseAmount?.amount
                                    | currency: rate.totalBaseAmount.currency:'symbol':'1.2-2'
                                }}
                                |
                            </span>

                            {{ rate.name }}

                            <div class="ibe-rate-info-btn" (click)="openRateInfoModal(rate)">i</div>
                        </div>
                    </div>
                    <div class="ibe-rate-option-right" *ngIf="isRateSelected(rate.id)">
                        <div class="ibe-desktop-selector ibe-rate-option-right">
                            <button
                                type="button"
                                class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-left"
                                (click)="decreaseQty()"
                                [disabled]="selectedUnitQty === 1"
                            >
                                <span class="inner-text sr-only">Less</span>
                            </button>
                            <span class="ibe-unit-qty-display" *ngIf="isRateSelected(rate.id)">
                                {{ selectedUnitQty }}
                            </span>
                            <button
                                type="button"
                                class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-right"
                                (click)="increaseQty(rate.availableUnits)"
                                [disabled]="
                                        selectedUnitQty === rate.availableUnits
                                        || selectedUnitQty === config.settings.roomQtySelectorLimit
                                    "
                            >
                                <span class="inner-text sr-only">More</span>
                            </button>

                            <button class="ibe-btn ibe-primary-btn" (click)="addToCart($event, rate)">
                                {{ 'room_results.add' | translate }}
                            </button>
                        </div>
                        <div class="ibe-mobile-selector ibe-rate-option-right">
                            <select
                                [(ngModel)]="selectedUnitQty"
                                aria-label="Choose number of rooms"
                                class="ibe-custom-select ibe-unit-qty-select"
                                name="unitQty"
                            >
                                <option
                                    value="{{ unitQty }}"
                                    *ngFor="let unitQty of generateUnitQtyArray(rate.availableUnits)"
                                >
                                    {{ unitQty }}
                                </option>
                            </select>
                            <button
                                class="ibe-btn ibe-primary-btn"
                                (click)="addToCart($event, rate)"
                            >
                                {{ 'room_results.add' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="!config.settings.onlyShowPromoCodeRatesEnabled || !hasSpecialRates">
        <ng-container *ngFor="let rate of availabilityResult.rates">
            <div
                class="ibe-rate-option"
                *ngIf="!isSpecialRate(rate)"
                [ngClass]="{selected: isRateSelected(rate.id)}"
                (click)="selectRate(rate)"
            >
                <div class="ibe-rate-option-left">
                    <div class="ibe-radio-outer">
                        <div class="ibe-radio-inner"></div>
                    </div>
                    <div class="ibe-rate-title">
                        <span *ngIf="!rate.suppressed && !useBasePrices">
                            {{
                            rate.totalGrossAmount?.amount
                                | currency: rate.totalGrossAmount.currency:'symbol':'1.2-2'
                            }}
                            |
                        </span>
                        <span *ngIf="!rate.suppressed && useBasePrices && !rateHasAdditionalIncludedExtras(rate)">
                            {{
                            rate.totalGrossAmount?.amount
                                | currency: rate.totalGrossAmount.currency:'symbol':'1.2-2'
                            }}
                            |
                        </span>
                        <span *ngIf="!rate.suppressed && useBasePrices && rateHasAdditionalIncludedExtras(rate)">
                            {{
                            rate.totalBaseAmount?.amount
                                | currency: rate.totalBaseAmount.currency:'symbol':'1.2-2'
                            }}
                            |
                        </span>
                        {{ rate.name }}
                        <div class="ibe-rate-info-btn" (click)="openRateInfoModal(rate)">i</div>
                    </div>
                </div>
                <div class="ibe-option-right-wrapper" *ngIf="isRateSelected(rate.id)">
                    <div class="ibe-desktop-selector ibe-rate-option-right">
                        <button
                            type="button"
                            class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-left"
                            (click)="decreaseQty()"
                            [disabled]="selectedUnitQty === 1"
                        >
                            <span class="inner-text sr-only">Less</span>
                        </button>
                        <span class="ibe-unit-qty-display" *ngIf="isRateSelected(rate.id)">
                            {{ selectedUnitQty }}
                        </span>
                        <button
                            type="button"
                            class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-right"
                            (click)="increaseQty(rate.availableUnits)"
                            [disabled]="
                                selectedUnitQty === rate.availableUnits
                                || selectedUnitQty === config.settings.roomQtySelectorLimit
                            "
                        >
                            <span class="inner-text sr-only">More</span>
                        </button>
                        <button class="ibe-btn ibe-primary-btn" (click)="addToCart($event, rate)">
                            {{ 'room_results.add' | translate }}
                        </button>
                    </div>
                    <div class="ibe-mobile-selector ibe-rate-option-right">
                        <select
                            [(ngModel)]="selectedUnitQty"
                            aria-label="Choose number of rooms"
                            class="ibe-custom-select ibe-unit-qty-select"
                            name="unitQty"
                        >
                            <option
                                value="{{ unitQty }}"
                                *ngFor="let unitQty of generateUnitQtyArray(rate.availableUnits)"
                            >
                                {{ unitQty }}
                            </option>
                        </select>
                        <button class="ibe-btn ibe-primary-btn" (click)="addToCart($event, rate)">
                            {{ 'room_results.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
