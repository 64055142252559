<h1 class="ibe-login-title">{{ 'guest_management_auth.login' | translate }}</h1>
<form class="ibe-guest-login" #guestLoginFormDirective="ngForm" [formGroup]="guestLoginForm">
    <div class="ibe-form-group">
        <label for="usernameInput">{{ 'checkout_details.email' | translate }}</label>
        <input
            type="text"
            formControlName="username"
            id="usernameInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestLoginForm.controls.username)}"
            placeholder="{{ 'checkout_details.email' | translate }}"
        >
        <ibe-form-validation-message
            [form]="guestLoginFormDirective"
            [control]="guestLoginForm.controls.username"
        ></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group">
        <label for="passwordInput">{{ 'guest_management_auth.password' | translate }}</label>
        <input
            type="password"
            formControlName="password"
            id="passwordInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestLoginForm.controls.password)}"
            placeholder="{{ 'guest_management_auth.password' | translate }}"
        >
        <ibe-form-validation-message
            [form]="guestLoginFormDirective"
            [control]="guestLoginForm.controls.password"
        ></ibe-form-validation-message>
    </div>

    <ibe-loading-bar [isLoading]="loading"></ibe-loading-bar>
    <button class="ibe-btn ibe-primary-btn" (click)="loginGuest()">
        Login
    </button>

    <p class="ibe-forgotten-password-link">
        <a (click)="resetPassword()">{{ 'guest_management_auth.forgotten_password' | translate }}</a>
    </p>
    <p class="ibe-no-account-link">{{ 'guest_management_auth.no_account' | translate }}
        <a (click)="createAccount()">{{ 'guest_management_auth.create_account' | translate }}</a>
    </p>
    <p class="ibe-manage-booking-link">{{ 'global.or' | translate }}
        <a (click)="manageBooking()">{{ 'guest_management_auth.manage_booking' | translate }}</a>
    </p>
</form>
