import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ibe-persons-qty-selector',
    templateUrl: './persons-qty-selector.component.html',
    styleUrls: ['./persons-qty-selector.component.scss'],
})
export class PersonsQtySelectorComponent implements OnInit {
    @Input('minimumValue') public minimumValue = 0;
    @Input('maximumValue') public maximumValue: number;
    @Input('initialValue') public initialValue = 0;
    @Output('onValueChange') public onValueChange: EventEmitter<Number> = new EventEmitter();

    private _value = 0;
    get value(): number {
        return this._value;
    }

    public ngOnInit(): void {
        this._value = this.initialValue;
    }

    public increment(): void {
        if (this._value < this.maximumValue) {
            this._value = this._value + 1;
            this.onValueChange.emit(this._value);
        }
    }

    public decrement(): void {
        if (this._value > (this.minimumValue)) {
            this._value = this._value - 1;
            this.onValueChange.emit(this._value);
        }
    }
}
