import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { captureException, Scope } from '@sentry/browser';
import { ErrorSource } from 'app/error-dialog/error-mapping';
import { PaymentDialogData, PaymentSetupData, PaymentSetupRequestData } from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { Subscription } from 'rxjs';
import { IbeServerApiResponseModel } from 'up-ibe-types';
import { environment } from '../../../environments/environment';
import { getPaymentProviderScriptUrl } from '../../helpers/payment.helper';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { ErrorHandlerService, tryInitializeSentry } from '../../services/error-handler.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { ScriptLoaderService } from '../../services/script-loader.service';

@Component({
    selector: 'ibe-booking-payment-dialog',
    templateUrl: './booking-payment-dialog.component.html',
    styleUrls: ['./booking-payment-dialog.component.scss'],
})
export class BookingPaymentDialogComponent implements OnInit {
    // FIXME DO NOT USE PUBLIC properties
    public isLoading = true;
    public hasPaymentScriptLoaded = false;
    public paymentSetupData: PaymentSetupData;
    public bookingRequestId: string;
    public paymentRedirected = false;
    private readonly hasSentry: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
        private readonly http: HttpClient,
        private readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
        private readonly errorDialogService: ErrorDialogService,
        private readonly dialogRef: MatDialogRef<BookingPaymentDialogComponent>,
        private readonly scriptLoader: ScriptLoaderService,
    ) {
        this.hasSentry = tryInitializeSentry();
    }

    public ngOnInit() {
        this.paymentRedirected = this.data.paymentRedirected;
        this.config.setCurrentProperty(this.data.propertyId);

        const providerName: string = this.config.getPaymentProvider();
        const testApiMode: boolean = this.config.isPaymentInTestMode();

        const scriptUrl = getPaymentProviderScriptUrl(providerName, testApiMode);
        if (scriptUrl) {
            this.scriptLoader
                .loadScript(scriptUrl)
                .then(() => {
                    this.hasPaymentScriptLoaded = true;
                    this._getPaymentSetupData();
                })
                .catch((error: Error) => {
                    this.handleError(error, 'http', 'ibe-booking-payment-dialog.ngOnInit', {
                        'scriptUrl': scriptUrl,
                    });
                });
        } else {
            this._getPaymentSetupData();
        }
    }

    // tslint:disable-next-line:no-any
    public completeBooking(paymentData: any) {
        if (this.config.getPaymentProvider() === 'adyen-dropin' && !this.paymentRedirected) {
            this.toasterService.showSuccess(
                this.translate.instant('global.success'),
                this.translate.instant('manage_booking.payment_method_added_successfully'),
            );
            return this.dialogRef.close({
                paymentSuccess: true,
            });
        } else {
            this.isLoading = true;
            return this.http
                .post(
                    `${environment.serverUrl}/api/ibe/complete-payment`,
                    {
                        ...this.data,
                        paymentData,
                        bookingRequestId: this.bookingRequestId,
                    })
                .subscribe({
                    next: (response: IbeServerApiResponseModel) => {
                        if (response.success) {
                            this.toasterService.showSuccess(
                                this.translate.instant('global.success'),
                                this.translate.instant('manage_booking.payment_method_added_successfully'),
                            );
                            this.dialogRef.close({
                                paymentSuccess: true,
                            });
                        }
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.isLoading = false;
                        if (error.error && !error.error.success && error.error.errorCode) {
                            this.errorDialogService.errorDialog(error.error, error.error.source);
                        } else {
                            const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
                            this.errorDialogService.errorDialog('Payment Process Failed', errorSource);
                        }
                    },
                });
        }
    }

    public showPaymentComponent(paymentProvider: string) {
        return (
            this.paymentSetupData &&
            this.config.getPaymentProvider() === paymentProvider
        );
    }

    private _getPaymentSetupData(): Subscription {
        this.isLoading = true;
        const ibeUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        const requestData: PaymentSetupRequestData = {
            ...this.data,
            ibeLanguage: this.config.language,
            ibeUrl,
        };

        const setupRequest = { ...requestData };

        return this.http
            .post(`${environment.serverUrl}/api/ibe/setup-payment`, setupRequest)
            .subscribe(this.handleSetupPaymentResponse, this.handleSetupPaymentError);
    }

    // tslint:disable-next-line:no-any
    private handleSetupPaymentError = (error: any) => {
        const ibeUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        this.handleError(error, 'http', 'ibe-booking-payment-dialog._getPaymentSetupData', {
            'ibeUrl': ibeUrl,
        });
    };

    // tslint:disable-next-line:no-any
    private handleSetupPaymentResponse = (response: any /* { result: PaymentSetupData } */) => {
        // FIXME: random any junk - this is an myIBE API call and should be a known complete shared type
        //      - of course '{ result: PaymentSetupData }' is a lie, the type is invalid/incomplete junk
        const someRandomData = response.result;
        // FIXME: [TSC] This expression is unnecessarily compared to a boolean. Just use it directly
        //      - >>> data is unknown and therefore hideDirectPaymentMethods - the TSC rule is WRONG
        if (this.data?.hideDirectPaymentMethods as unknown === true) {
            // FIXME: this is a fast HACK 'scheme' is adyen for 'CreditCard',
            //      - maybe we can allow more paymentMethods, this is reduced to the minimum
            //      - other payment provider are not handled
            if (someRandomData?.paymentMethods?.length > 0) {
                someRandomData.paymentMethods = someRandomData.paymentMethods
                    // tslint:disable-next-line:no-any
                    .filter((maybePaymentMethod: any) => (maybePaymentMethod?.type === 'scheme'));
            }
        }

        this.paymentSetupData = someRandomData;
        this.paymentSetupData.bookingOrReservationId = this.data.bookingOrReservationId;
        this.bookingRequestId = response.result.bookingRequestId;
        this.isLoading = false;
    };

    // tslint:disable-next-line:no-any
    private handleError(error: unknown, type: string, category: string, details: { [key: string]: any; }): void {
        if (this.hasSentry) {
            const err = ErrorHandlerService.toError(error);
            details.error = error;
            captureException(err, (scope: Scope) =>
                scope.addBreadcrumb({
                    type,
                    level: 'fatal',
                    message: `${err.message}`,
                    category,
                    data: details,
                }),
            );
        } else {
            console.error('handleError', error);
        }

        const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
        this.errorDialogService.errorDialog('Payment Process Failed', errorSource);
    }
}
