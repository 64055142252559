// tslint:disable:max-file-line-count
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { captureException, Scope } from '@sentry/browser';
import {
    BookingError,
    BookingErrorDetails,
    ComponentCanDeactivate,
    ErrorDialogData,
    ErrorDialogProps,
} from 'app/fixme-inline-types';
import { Observable } from 'rxjs';
import { CreateBookingRequestResponseModel, CreateBookingResponseModel } from 'up-ibe-types';
import { GuaranteeTypeEnum, HttpStatus } from '../../../enums';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { LogGuestMovementGuard } from '../../guards/log-guest-movement-guard';
import { getPaymentProviderScriptUrl } from '../../helpers/payment.helper';
import { AnalyticsService } from '../../services/analytics.service';
import { BookingService } from '../../services/booking.service';
import { ErrorHandlerService, tryInitializeSentry } from '../../services/error-handler.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { JourneyService } from '../../services/journey.service';
import { ScriptLoaderService } from '../../services/script-loader.service';

@Component({
    selector: 'ibe-checkout-payment',
    templateUrl: './checkout-payment.component.html',
    styleUrls: ['./checkout-payment.component.scss'],
    animations: [
        fadeInOnEnterAnimation,
        scaleUpAnimation,
    ],
})
export class CheckoutPaymentComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
    // FIXME: DO NOT use public properties
    public bookingRequestId: string;
    public paymentRequired: boolean;
    // tslint:disable-next-line:no-any
    public paymentSetupData: any;
    public paymentRedirected = false;
    public hasPaymentScriptLoaded = false;
    public prePaymentAmount = 0;
    public bookingCurrency: string;
    public isLoading = true;
    public inPaymentFlow = false;

    private bypassCreation = false;
    private hasPayload = false;
    private readonly hasSentry: boolean = false;

    constructor(
        // FIXME: DO NOT use public properties
        public readonly bookingService: BookingService,
        public readonly dialog: MatDialog,
        public readonly config: IbeConfigService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly analyticsService: AnalyticsService,
        private readonly scriptLoader: ScriptLoaderService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly logGuestMovementGuard: LogGuestMovementGuard,
        private readonly journeyService: JourneyService,
    ) {
        this.hasSentry = tryInitializeSentry();
    }

    @HostListener('window:beforeunload', ['$event'])
    public unloadHandler(event: Event) {
        event.preventDefault();
        return this.logGuestMovementGuard.canDeactivate(this);
    }

    public canDeactivate(): Observable<boolean> | boolean {
        return !this.inPaymentFlow;
    }

    public ngOnInit() {
        const reservations = this.bookingService.getReservations();
        if (reservations.length > 0) {
            this.pageSetupOrRedirect();
        }
    }

    private pageSetupOrRedirect() {
        this.route
            .queryParamMap
            .subscribe({
                next: (queryParams: ParamMap) => {
                    if (this._pageHasPayload(queryParams)) {
                        this.hasPayload = true;
                        const bookingRequestId = this.bookingService.getBookingRequestId();
                        if (bookingRequestId) {
                            this.bookingRequestId = bookingRequestId;
                        } else {
                            this.router
                                .navigate(['checkout/payment'], { queryParams: {} })
                                .catch(console.error);
                        }
                        this.paymentRedirected = true;
                    } else {
                        // bypassCreation is used for when the query parameters change and you
                        // don't want to create a new booking request.
                        if (!this.bypassCreation) {
                            this.createBookingRequest();
                            this._createCheckoutPaymentEvent();
                        }
                    }
                },
            });
    }

    private _pageHasPayload(queryParams: ParamMap): boolean {
        return queryParams.has('payload');
    }

    public ngAfterViewInit(): void {
        this.initExternalScripts();
    }

    public createBookingRequest() {
        this.bookingService
            .createBookingRequest()
            .subscribe(
                this.onCreateBookingRequestResponse,
                this.onCreateBookingRequestError,
            );
    }

    private onCreateBookingRequestResponse = (response: CreateBookingRequestResponseModel) => {
        this.paymentRequired = response.paymentRequired;
        if (!this.paymentRequired) {
            this.isLoading = false;
        }
        this.paymentSetupData = (response.paymentSetupData && Object.keys(response.paymentSetupData).length)
            ? response.paymentSetupData
            : undefined;

        this.bookingRequestId = response.bookingRequestId;
        this.prePaymentAmount = response.prePaymentAmount;
        this.bookingCurrency = response.currency;
        this.bookingService.saveBookingRequestIdToLocalStorage(response);
    };

    // tslint:disable-next-line:no-any
    private onCreateBookingRequestError = (error: any) => {
        console.error('createBookingRequest', error);
        const data: ErrorDialogProps = {
            title: this.translate.instant(`dialog_error_codes.${BookingError.BookingProblem}`),
            details: [],
            allowRetry: false,
        };

        if (error.status === HttpStatus.Unprocessable_Entity) {
            data.details = this._getBookingErrors([BookingError.NoAvailability]);
        } else if (error.status === HttpStatus.Bad_Request) {
            data.details = this._getBookingErrors(
                [BookingError.InvalidPmsData],
                this.bookingService.populateBookingErrorMessage(error.error.context),
            );
            data.allowRetry = true;
        } else {
            data.details = this._getBookingErrors([BookingError.BookingFailed]);
        }

        this.dialog
            .open(ErrorDialogComponent, { data })
            .afterClosed()
            .subscribe(
                this.onErrorDialogClosed(error),
                // FIXME: onError
            );
    };

    // tslint:disable-next-line:no-any
    private onErrorDialogClosed = (error: any) => (response: any): void => {
        console.error('onErrorDialogClosed', error, response);
        if (response) {
            // WTF ??? merge reservation with error - sure why not.
            this.bookingService.mergeReservationsWithPmsData(error.error.context);
            this.reloadPageOnError();
        } else {
            this.bookingService.removeAllReservations();
            const queryParams = this.bookingService.getLastSearchedStayCriteria();

            this.router
                .navigate(['/booking/results'], { queryParams })
                .catch(console.error);
        }
    };

    /**
     * Be careful: Only leave paymentData undefined if you want
     * to make a booking with no payment. e.g. When the IBE
     * has payments turned off
     */
    // tslint:disable-next-line:no-any
    public completeBooking(paymentData?: any) {
        this.isLoading = true;
        if (this.config.getPaymentProvider() === 'saferpay') {
            this.showConfirmationOnCompletionSaferpay(paymentData);
        } else {
            this.bookingService
                .completeBooking(paymentData, this.bookingRequestId)
                .subscribe(this.onCompleteBookingResponse, this.onCompleteBookingError);
        }
    }

    private onCompleteBookingResponse = (bookingResponse: CreateBookingResponseModel): void => {
        this.onBookingSuccess(bookingResponse);
        this.journeyService.finaliseJourney();
    };

    private showConfirmationOnCompletionSaferpay(paymentComplete: CreateBookingResponseModel & BookingErrorDetails): void {
        if (paymentComplete.success) {
            this.onBookingSuccess(paymentComplete);
        } else {
            this.onCompleteBookingError(paymentComplete);
        }
    }

    private onBookingSuccess(bookingResponse: CreateBookingResponseModel): void {
        this.inPaymentFlow = false;
        if (bookingResponse.success) {
            const booking = this.bookingService.getBooking();
            this.analyticsService.createAnalyticsTransactionEvent(
                this.bookingRequestId,
                booking,
                bookingResponse.pmsBookingIds,
            );
            this.isLoading = false;
            const confirmationPath = `${window.location.origin}${window.location.pathname}#/booking/confirmation`;
            const params = `?bookingRequestId=${bookingResponse.bookingRequestId}`;
            window.location.href = confirmationPath + params;
        }
    }

    private onCompleteBookingError = (error: BookingErrorDetails): void => {
        console.error('onCompleteBookingError', error);

        this.inPaymentFlow = false;
        this.bookingService.clearBookingRequestInProgress();

        const data = (error.error && !error.error.success && error.error.errorCodes)
            ? {
                title: this.translate.instant(`dialog_error_codes.${BookingError.BookingProblem}`),
                details: this._getBookingErrors(error.error.errorCodes),
            }
            : {
                title: this.translate.instant(`dialog_error_codes.${BookingError.BookingFailed}.title`),
                message: this.translate.instant(`dialog_error_codes.${BookingError.BookingFailed}.message`),
            };

        this.dialog
            .open(ErrorDialogComponent, { data })
            .afterClosed()
            .subscribe(
                this.reloadPageOnError,
                // FIXME: onError
            );
    };

    public onRedirectComplete(bookingRequestId: string) {
        this.isLoading = false;
        this.bookingService.clearBookingRequestInProgress();
        // We force location to booking confirmation to remove intrusive saferpay query params
        const confirmationPath = `${window.location.origin}${window.location.pathname}#/booking/confirmation`;
        const params = `?bookingRequestId=${bookingRequestId}`;
        window.location.href = confirmationPath + params;
    }

    private reloadPageOnError(): void {
        // We include a conditional because clients often use weird path names that affects the angular route hashing
        // and may redirect them to a non ibe page.
        // It is safer to use window.location.href = paymentPath, to redirect clients in that case
        const paymentPath = `${window.location.origin}${window.location.pathname}#/checkout/payment`;
        // If the page has a payload but needs to refresh then bypass the creation of a new booking
        // Remove all query parameters and reload.
        if (this.hasPayload) {
            this.bypassCreation = true;
        }
        if (window.location.href === paymentPath) {
            window.location.reload();
        } else {
            window.location.href = paymentPath;
        }
    }

    private _getBookingErrors(errorCodes: BookingError[], errorParams?: ErrorDialogData) {
        return errorCodes.map((code: BookingError) => ({
            title: this.translate.instant(`dialog_error_codes.${code}.title`),
            message: this.translate.instant(`dialog_error_codes.${code}.message`, errorParams),
            description: errorParams ? errorParams.description : undefined,
        }));
    }

    private _createCheckoutPaymentEvent() {
        const step = 3;
        const reservations = this.bookingService.getReservations();
        this.analyticsService.createCheckoutEvent(reservations, step);
    }

    public paymentButtonLabel(): string {
        const requirePrepayment = (this.paymentRequired && this.prePaymentAmount > 0);
        const buttonLabel = requirePrepayment ? 'checkout_payment.pay' : 'checkout_payment.confirm';

        return this.translate.instant(buttonLabel);
    }

    public showPaymentComponent(paymentProvider: string) {
        const show = this.bookingRequestId && this.config.getPaymentProvider() === paymentProvider;
        return this.paymentRedirected ? show : this.paymentSetupData && show;
    }

    public showFlexibleGuaranteeMessage() {
        const reservations = this.bookingService.getReservations();
        const guaranteeTypes = reservations.map((x) => x.guaranteeType);
        return !(
            guaranteeTypes.includes(undefined) || guaranteeTypes.includes(GuaranteeTypeEnum.Prepayment)
        );
    }

    public toggleIsLoading(value: boolean) {
        this.isLoading = value;
        this.changeDetectorRef.detectChanges();
    }

    public togglePaymentRedirected(value: boolean) {
        this.paymentRedirected = value;
        this.logGuestMovementGuard.logGuestReturn();
        this.changeDetectorRef.detectChanges();
    }

    public toggleInPaymentFlow(value: boolean) {
        this.inPaymentFlow = value;
        if (!value) {
            this.logGuestMovementGuard.logGuestRedirect(this.config.getPaymentProvider());
        }
    }

    private initExternalScripts(): void {
        const scriptUrl = getPaymentProviderScriptUrl(
            this.config.getPaymentProvider(),
            this.config.isPaymentInTestMode(),
        );

        if (scriptUrl) {
            this.scriptLoader
                .loadScript(scriptUrl)
                .then(() => { this.hasPaymentScriptLoaded = true; })
                .catch((error: Error) => {
                    this.handleError(error, 'http', 'ibe-checkout-payment.loadScript', {
                        'scriptUrl': scriptUrl,
                    });
                });
        }
    }

    // tslint:disable-next-line:no-any
    private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
        if (this.hasSentry) {
            const err = ErrorHandlerService.toError(error);
            details.error = error;
            captureException(err, (scope: Scope) =>
                scope.addBreadcrumb({
                    type,
                    level: 'fatal',
                    message: `${err.message}`,
                    category,
                    data: details,
                }),
            );
        } else {
            console.error('handleError', error);
        }

        const data = {
            title: 'Error',
            message: `${error}`,
        };
        this.dialog
            .open(ErrorDialogComponent, { data })
            .afterClosed()
            .subscribe(() => {
                this.reloadPageOnError();
            });
    }
}
