import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'ibe-guest-account-auth',
    templateUrl: './guest-account-auth.component.html',
    styleUrls: ['./guest-account-auth.component.scss'],
})
export class GuestAccountAuthComponent implements OnInit {
    private isAuthenticated: boolean = false;
    get isAccountAuthenticated(): boolean { return this.isAuthenticated; }

    private authenticationError = false;
    get noError(): boolean { return !this.authenticationError; }

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly route: ActivatedRoute,
    ) { }

    private handleAuthenticated = () => {
        this.isAuthenticated = true;
        this.authenticationError = false;
    };
    private handleError = (error: unknown) => {
        console.error('GuestAccountAuth.handleError', error);
        this.authenticationError = true;
    };

    public ngOnInit(): void {
        const guestAuthId: string | null = this.route.snapshot.queryParamMap.get('guestAuthId');
        // TODO: guestAuthId === '' || guestAuthId === null
        this.http
            .post(`${environment.serverUrl}/api/ibe/guest/authenticate`, { guestAuthId })
            .subscribe(this.handleAuthenticated, this.handleError);
    }

    public backToSearch() {
        this.router
            .navigate(['booking/search'])
            .catch((navError) => console.error('navigation failed: /booking/search', navError));
    }
}
