import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ibe-cart-removal-modal',
    templateUrl: './removal-modal.component.html',
    styleUrls: ['./removal-modal.component.scss'],
})
export class RemovalModalComponent {
    constructor(private dialog: MatDialogRef<RemovalModalComponent>) { }

    public close(action: string) {
        this.dialog.close(action);
    }
}
