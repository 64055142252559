/* tslint:disable:max-file-line-count */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BookingManagementParams,
    GuestDetailsDialogResponse,
    NOT_FOUND_TEXT,
    PaymentDialogData,
} from 'app/fixme-inline-types';
import { ToasterService } from 'app/services/toaster.services';
import { BookerModel, BookingModel, IbeServerApiResponseModel, ReservationModel } from 'up-ibe-types';
import { BookingOrReservationEnum, GuaranteeTypeEnum, ReservationStatusEnum } from '../../enums';
import { environment } from '../../environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { GuestDetailsDialogComponent } from '../guest-details-dialog/guest-details-dialog.component';
import {
    AddExtrasDialogComponent,
} from '../guest-management/modify-reservation/add-extras-dialog/add-extras-dialog.component';
import { serializeQueryParams } from '../helpers/booking.helper';
import { isFormControlInvalid } from '../helpers/form.helper';
import { isGuestDataValid } from '../helpers/payment.helper';
import { asString } from '../helpers/type.helper';
import { GlobalWindowService } from '../services/global-window.service';
import { IbeConfigService } from '../services/ibe-config.service';
import { LocalStorageService } from '../services/local-storage.service';
import {
    BookingCancellationDialogComponent,
} from './booking-cancellation-dialog/booking-cancellation-dialog.component';
import { BookingPaymentDialogComponent } from './booking-payment-dialog/booking-payment-dialog.component';
import { GuestCreditCard } from './credit-card/credit-card.component';

@Component({
    selector: 'ibe-booking-management',
    templateUrl: './booking-management.component.html',
    styleUrls: ['./booking-management.component.scss'],
})
export class BookingManagementComponent implements OnInit {
    @ViewChild('bookingLoginFormDirective', { static: true }) public bookingLoginFormDirective: FormGroupDirective;
    // FIXME DO NOT USE PUBLIC properties
    public isBooking: boolean;
    public isReservation: boolean;
    public bookingOrReservation: string;
    public bookingLoginForm: FormGroup;
    public booking: BookingModel | undefined;
    public reservation: ReservationModel | undefined;
    public reservations: ReservationModel[];
    public isLoading = false;
    public reservationStatus = ReservationStatusEnum.Confirmed;
    public bookingManagementParams: BookingManagementParams;
    public reservationConfirmed = false;
    public filteredReservations: ReservationModel[] = [];
    public showEditButtons = false;
    private paymentRedirected = false;

    constructor(
        // FIXME DO NOT USE PUBLIC properties
        public readonly config: IbeConfigService,
        public readonly dialog: MatDialog,
        public readonly localStorageService: LocalStorageService,
        private readonly http: HttpClient,
        private readonly globalWindowService: GlobalWindowService,
        private readonly formBuilder: FormBuilder,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {
        this.bookingLoginForm = this.formBuilder.group({
            'propertyId': ['', [Validators.required]],
            'bookingOrReservationId': ['', [Validators.required]],
            'lastName': ['', [Validators.required]],
        });
    }

    public ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            const maybeString = params.get('bookingOrReservation');
            // FIXME: handle (this.bookingOrReservation === '') ???
            // convert maybe type to string
            this.bookingOrReservation = (maybeString ? `${maybeString}` : '');
        });

        this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
            const propertyId = asString(queryParams.get('propertyId'), '');

            this.bookingManagementParams = {
                id: queryParams.get('id'),
                lastName: queryParams.get('lastName'),
                propertyId,
                showExtras: queryParams.get('showExtras'),
            };
            this.config.setCurrentProperty(propertyId);

            if (this.config.redirectPath) {
                const serializedParams = serializeQueryParams(this.bookingManagementParams);
                const url = `${this.config.redirectPath}#/${this.bookingOrReservation}/manage?${serializedParams}`;
                this.globalWindowService.getWindowLocationAssign(url);
            } else {
                if (this.bookingOrReservation === BookingOrReservationEnum.Reservation) {
                    this.router
                        .navigate(['/booking/manage'], { queryParams: this.bookingManagementParams })
                        .catch((navError) => console.error('navigation failed: /booking/manage', navError));
                }

                this._setFormsValues(this.bookingManagementParams);
            }
        });
    }

    private _setupBookingOrReservation(bookingOrReservation: string): void {
        this.isBooking = (bookingOrReservation === BookingOrReservationEnum.Booking);
        this.isReservation = (bookingOrReservation === BookingOrReservationEnum.Reservation);

        if (!this.isBooking && !this.isReservation) {
            // Invalid param - booking or reservation required
            this.router
                .navigate(['/404'])
                .catch((navError) => console.error('navigation failed: /404', navError));
        }
    }

    private _setFormsValues(params: BookingManagementParams): void {
        if (params.id) {
            this.bookingLoginForm.controls['bookingOrReservationId'].setValue(params.id);
        }
        if (params.lastName) {
            this.bookingLoginForm.controls['lastName'].setValue(params.lastName);
        }
        if (params.propertyId) {
            this.bookingLoginForm.controls['propertyId'].setValue(params.propertyId);
        }
        if (this.bookingLoginForm.valid) {
            this._setAsBookingOrReservation(this.bookingLoginForm.controls['bookingOrReservationId'].value);
            this.localStorageService.setBookingLoginCredentials(this.bookingLoginForm.value);
            this._fetchBookingOrReservationData();
        }
    }

    public get firstProperty() {
        return this.config.properties[0];
    }

    public clearBookingOrReservation() {
        this.booking = undefined;
        this.reservation = undefined;
        this.bookingLoginForm.reset();

        return this.router
            .navigate(['/booking/manage'], { queryParams: {} })
            .catch((navError) => console.error('navigation failed: /booking/manage', navError));
    }

    public get reservationStatusOptions() {
        return [
            {
                value: 'Confirmed',
                label: this.translate.instant(`manage_booking.reservation_statuses.confirmed`),
            },
            {
                value: 'Canceled',
                label: this.translate.instant(`manage_booking.reservation_statuses.canceled`),
            },
            {
                value: 'CheckedOut',
                label: this.translate.instant(`manage_booking.reservation_statuses.checked_out`),
            },
            {
                value: 'NoShow',
                label: this.translate.instant(`manage_booking.reservation_statuses.no_show`),
            },
            {
                value: 'InHouse',
                label: this.translate.instant(`manage_booking.reservation_statuses.in_house`),
            },
        ];
    }

    public onFormSubmit(event: Event) {
        event.preventDefault();
        if (this.config.properties.length === 1) {
            this.bookingLoginForm.controls['propertyId'].setValue(this.config.properties[0].pmsId);
        }
        if (this.bookingLoginForm.valid) {
            this._setAsBookingOrReservation(this.bookingLoginForm.controls['bookingOrReservationId'].value);
            this.localStorageService.setBookingLoginCredentials(this.bookingLoginForm.value);
            this._fetchBookingOrReservationData();
            this.router
                .navigate(
                    [],
                    {
                        relativeTo: this.route,
                        queryParams: {
                            propertyId: this.bookingLoginForm.controls['propertyId'].value,
                            id: this.bookingLoginForm.controls['bookingOrReservationId'].value,
                            lastName: this.bookingLoginForm.controls['lastName'].value,
                        },
                        queryParamsHandling: 'merge',
                    },
                )
                .catch((navError) => console.error('navigation failed: []', navError));
        }
    }

    public _setAsBookingOrReservation(id: string): void {
        this.bookingOrReservation = this._isIdReservationOrBooking(id);
        this._setupBookingOrReservation(this.bookingOrReservation);
    }

    private _isIdReservationOrBooking(id: string): BookingOrReservationEnum {
        const isBookingId: boolean = (this.config.isPmsApaleo() && !id.includes('-'));

        return isBookingId
            ? BookingOrReservationEnum.Booking
            : BookingOrReservationEnum.Reservation;
    }

    public cancelReservation(reservation: ReservationModel): void {
        this.dialog
            .open(BookingCancellationDialogComponent, { data: reservation })
            .afterClosed()
            .subscribe((dialogResponse: boolean) => {
                if (dialogResponse) {
                    this.apiCancelReservation(reservation);
                }
            });
    }

    public isFormControlInvalid(formControl: AbstractControl) {
        return isFormControlInvalid(formControl, this.bookingLoginFormDirective);
    }

    public reservationHasConfirmedStatus(reservation: ReservationModel): boolean {
        return (reservation.status === ReservationStatusEnum.Confirmed);
    }

    public openGuestDetailsDialog(): void {
        const credentials = this.localStorageService.getBookingLoginCredentials();
        const guestDetails = this.prepareGuestDetails();

        const dialogGuestDetailsData = {
            bookingOrReservation: this.bookingOrReservation,
            bookingOrReservationId: credentials.bookingOrReservationId,
            guestDetails,
            propertyId: this.bookingLoginForm.controls['propertyId'].value,
            guestTitleFieldEnabled: this.config.settings.checkoutFields.details.title,
        };

        this.dialog
            .open(GuestDetailsDialogComponent, { data: dialogGuestDetailsData })
            .afterClosed()
            .subscribe(this.onGuestDetailDialogClosed);
    }

    private onGuestDetailDialogClosed = (response: GuestDetailsDialogResponse): void => {
        if (response.updateSuccess) {
            this.toasterService.showSuccess(
                this.translate.instant('global.success'),
                this.translate.instant('manage_booking.guest_updated_successfully'),
            );

            if (this.isBooking && this.booking) {
                this.booking.booker = {
                    ...response.guestDetails,
                };
            }

            if (this.isReservation && this.reservation) {
                this.reservation.primaryGuest = {
                    ...response.guestDetails,
                };
            }
        } else {
            this.toasterService.showError(
                this.translate.instant('manage_booking.guest_update'),
                this.translate.instant('manage_booking.guest_update_failed'),
            );
        }
    };

    public openPaymentDialog() {
        const guestData = this.prepareGuestDetails();
        const pmsIsApaleo = this.config.pmsProvider === 'APALEO';

        // TODO why do we skip isGuestDataValid for apaleo
        if (pmsIsApaleo || isGuestDataValid(guestData)) {
            const propertyId = this.preparePropertyId();
            const bookingOrReservationId = this.prepareBookingOrReservationId();

            // what if propertyId == '' || bookingOrReservationId == ''
            this.showPaymentDialog(propertyId, bookingOrReservationId);
        } else {
            this.openGuestDetailsDialog();
        }
    }

    private showPaymentDialog(propertyId: string, bookingOrReservationId: string): void {
        // remove 'maybe' types
        const bookingOrReservation = (this.isBooking)
            ? BookingOrReservationEnum.Booking
            : BookingOrReservationEnum.Reservation;

        const paymentDialogConfig: PaymentDialogData = {
            bookingOrReservation,
            bookingOrReservationId,
            propertyId,
            bookingRequestId: '',
            paymentRedirected: this.paymentRedirected,
            hideDirectPaymentMethods: true,
        };

        this.dialog.open(BookingPaymentDialogComponent, { data: paymentDialogConfig })
            .afterClosed()
            .subscribe((response) => {
                    if (response && response.paymentSuccess) {
                        this._fetchBookingOrReservationData();
                    }
                    if (this.paymentRedirected) {
                        this.paymentRedirected = false;
                        this.router
                            .navigate(
                                [],
                                {
                                    relativeTo: this.route,
                                    queryParams: this.bookingManagementParams,
                                },
                            )
                            .catch((navError) => console.error('navigation failed: []', navError));
                    }
                },
            );
    }

    public login() {
        this.router
            .navigate(['guest/manage'])
            .catch((navError) => console.error('navigation failed: /guest/manage', navError));
    }

    public openAddExtrasDialog(reservation: ReservationModel) {
        this.dialog
            .open(AddExtrasDialogComponent, { data: reservation })
            .afterClosed()
            .subscribe(
                (success): void => {
                    if (success) {
                        this._fetchBookingOrReservationData();
                    }
                },
                (error): void => { console.error('openAddExtrasDialog', error); },
            );
    }

    private _maybeShowExtrasOnOpen(reservations: ReservationModel[] | undefined) {
        const stringTrue = 'true';
        if (this.bookingManagementParams.showExtras &&
            this.bookingManagementParams.showExtras.toLowerCase() === stringTrue
            && reservations && reservations.length) {
            this.openAddExtrasDialog(reservations[0]);
        }
    }

    public isReservationFlexibleAndEditable(reservation: ReservationModel) {
        return this.isReservationModifiable(reservation)
            && this._isReservationFlexible(reservation);
    }

    public isReservationModifiable(reservation: ReservationModel) {
        const unmodifiableStatuses = [
            ReservationStatusEnum.Canceled,
            ReservationStatusEnum.InHouse,
            ReservationStatusEnum.NoShow,
            ReservationStatusEnum.DueIn,
            ReservationStatusEnum.CheckedOut,
            ReservationStatusEnum.DueOut,
        ];
        return !unmodifiableStatuses.includes(reservation.status as ReservationStatusEnum)
            && reservation.channel === 'Ibe';
    }

    private _isReservationFlexible(reservation: ReservationModel) {
        return reservation.guaranteeType
            && (reservation.guaranteeType !== GuaranteeTypeEnum.Prepayment);
    }

    public bookingId() {
        return (this.booking) ? this.booking.id : NOT_FOUND_TEXT;
    }

    public bookingReference() {
        return (this.reservation) ? this.reservation.bookingReference : NOT_FOUND_TEXT;
    }

    public booker() {
        return (this.booking) ? this.booking.booker : NOT_FOUND_TEXT;
    }

    public primaryGuest() {
        return (this.reservation) ? this.reservation.primaryGuest : NOT_FOUND_TEXT;
    }

    // FIXME return TYPE should only be 'GuestCreditCard | null'
    public paymentAccount(): GuestCreditCard | undefined | null | void {
        let guestCreditCard = null;
        if (this.booking?.paymentAccount) {
            guestCreditCard = this.booking.paymentAccount;
        } else if (this.booking) {
            guestCreditCard = this.booking.reservations.find(reservation => reservation.paymentAccount)?.paymentAccount;
        } else if (this.reservation) {
            guestCreditCard = this.reservation.paymentAccount;
        }

        // FIXME: invalid Typecast (data is not validated);
        return guestCreditCard as GuestCreditCard | null;
    }

    private _getFilteredReservations(): ReservationModel[] {
        // FIXME: why would this function be called without required data >>> fix component state management
        return (this.booking && this.booking.reservations)
            ? this.booking.reservations.filter((reservation) => {
                this.reservationConfirmed = (reservation.status === 'Confirmed' as ReservationStatusEnum);
                return (reservation.status === this.reservationStatus);
            })
            : [];
    }

    private _fetchBookingOrReservationData() {
        const credentials = this.localStorageService.getBookingLoginCredentials();
        let httpParams = new HttpParams()
            .set('id', credentials.bookingOrReservationId)
            .set('lastName', credentials.lastName);

        if (this.bookingLoginForm.controls['propertyId'].value) {
            httpParams = httpParams.append('propertyId', this.bookingLoginForm.controls['propertyId'].value);
        }

        this.isLoading = true;
        return this.http
            .get(`${environment.serverUrl}/api/ibe/${this.bookingOrReservation}`, { params: httpParams })
            .subscribe(
                // tslint:disable-next-line:no-any
                (response: any) => {
                    this.isLoading = false;
                    const allowDirectBookings = this.config?.accountFeatureWhitelist?.allowEditingOfDirectBookings;

                    if (this.isBooking) {
                        this.booking = response;
                        if (this.booking && this.booking.reservations) {
                            this.reservations = this.booking.reservations;
                            this.showEditButtons = (
                                    allowDirectBookings
                                    && !!(this.reservations.find(reservation => reservation.channel === 'Direct'))
                                )
                                || !this.reservations.find(reservation => reservation.channel !== 'Ibe');
                            this._maybeShowExtrasOnOpen(this.reservations);
                        }
                    }

                    if (this.isReservation) {
                        this.reservation = response;
                        const channel = this.reservation?.channel;

                        this.showEditButtons = (channel === 'Ibe') || ((channel === 'Direct') && allowDirectBookings);
                        this._maybeShowExtrasOnOpen([this.reservation as ReservationModel]);
                    }
                    this.filteredReservations = this._getFilteredReservations();

                    if (this.route.snapshot.queryParamMap.has('payload')) {
                        this.paymentRedirected = true;
                        this.openPaymentDialog();
                    }
                },
                (error) => {
                    console.error(error);
                    this.isLoading = false;
                    this.dialog.open(ErrorDialogComponent, {
                        data: {
                            title: this.translate.instant('dialog_error_codes.booking_not_found.title'),
                            message: this.translate.instant('dialog_error_codes.booking_not_found.message'),
                        },
                    });
                },
            );
    }

    public changeReservationStatus = (value: ReservationStatusEnum) => {
        this.reservationStatus = value;
        if (value) {
            this.filteredReservations = this.reservations.filter((reservation) => reservation.status === this.reservationStatus);
        } else {
            this.filteredReservations = this.reservations;
        }
    };

    private apiCancelReservation = (reservation: ReservationModel) => {
        const reservationId = (this.config.isPmsStayntouch())
            ? reservation.bookingReference
            : reservation.id;

        // FIXME: MUST BE typed in 'my-ibe-types' - our API should have a defined interface!!!
        const cancellationRequest = {
            propertyId: reservation.property.id,
            bookingReference: reservation.bookingReference,
            reservationId,
        };

        this.http
            .post(`${environment.serverUrl}/api/ibe/cancel-reservation`, cancellationRequest)
            .subscribe(
                (apiResponse: IbeServerApiResponseModel) => {
                    if (apiResponse.success) {
                        this._fetchBookingOrReservationData();
                        const title = this.translate.instant('manage_booking.reservation_cancelled');
                        const body = this.translate.instant('manage_booking.reservation_successfully_cancelled');
                        this.toasterService.showSuccess(title, body);
                    }
                },
                (error) => {
                    console.error('cancel-reservation', error);
                    const title = this.translate.instant('dialog_error_codes.booking_cancel_error.title');
                    const message = this.translate.instant('dialog_error_codes.booking_cancel_error.message');
                    this.dialog.open(ErrorDialogComponent, { data: { title, message } });
                },
            );
    };

    private prepareGuestDetails(): BookerModel {
        const guestDetails = (this.isBooking && this.booking)
            ? this.booking.booker
            : ((this.isReservation && this.reservation)
                    ? this.reservation.primaryGuest
                    : {}
            );

        // FIXME: we shuffle some random data together and pray that it is something useful
        return guestDetails as BookerModel;
    }

    private prepareBookingOrReservationId(): string {
        const bookingOrReservationId = (this.isBooking && this.booking)
            ? this.booking.id
            : ((this.isReservation && this.reservation)
                    ? this.reservation.bookingReference
                    : ''
            );

        // FIXME: we shuffle some random data together and pray that it is something useful
        return bookingOrReservationId as string;
    }

    private preparePropertyId(): string {
        const propertyId = (this.isBooking && this.booking)
            ? this.booking.reservations[0].property.id
            : ((this.isReservation && this.reservation)
                    ? this.reservation.property.id
                    : ''
            );

        // FIXME: we shuffle some random data together and pray that it is something useful
        return propertyId as string;
    }
}
