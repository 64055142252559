import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalendarStayDateSelectionEvent, PersonsQtyData, StayCriteriaModel } from 'app/fixme-inline-types';
import { QueryParamsToSearchValuesService } from 'app/services/booking/query-params-to-search-values.service';
import { ToasterService } from 'app/services/toaster.services';
import { range } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Property } from 'up-ibe-types';
import { bookingSearchPopUpAnimation } from '../../animations';
import { serializeQueryParams } from '../../helpers/booking.helper';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { BookingService } from '../../services/booking.service';
import { GlobalWindowService } from '../../services/global-window.service';
import { IbeConfigService } from '../../services/ibe-config.service';

// FIXME: no random junk data >>> define type
const customDatePickerFormats = {
    parse: { dateInput: 'LL' },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const adultsControlOptionCount = 4;

@Component({
    selector: 'ibe-booking-search',
    templateUrl: './booking-search.component.html',
    styleUrls: ['./booking-search.component.scss', './ibe-form-control.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: customDatePickerFormats },
    ],
    animations: [bookingSearchPopUpAnimation],
})
export class BookingSearchComponent implements OnInit {
    @ViewChild('searchFormDirective', { static: true }) public searchFormDirective: FormGroupDirective;
    @ViewChild('ibeDateInputRef', { static: true }) public ibeDateInputRef: ElementRef;

    @Input() public isLoading: boolean;
    @Output('onSearchFormChange') public onSearchFormChange: EventEmitter<Number> = new EventEmitter();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public searchForm: FormGroup;
    public personsQtyData: PersonsQtyData = { adults: 1, childrenAges: [] };
    public properties: Property[];
    public isPersonsQtyPopupOpen = false;
    public readonly momentToday: Moment = moment();
    private readonly tomorrowsDate: Moment = moment().add(1, 'day');

    private _stayCriteriaParams: StayCriteriaModel;
    get stayCriteriaParams(): StayCriteriaModel { return this._stayCriteriaParams; }

    get ibeConfig(): IbeConfigService { return this.config; }

    private selectedRegion: string;
    private readonly adultSelectControlOptions = range(1, adultsControlOptionCount + 1)
        .map(i => ({ value: i, label: i.toString() }));

    constructor(
        private readonly config: IbeConfigService,
        private readonly globalWindowService: GlobalWindowService,
        private readonly queryParamsToSearchValues: QueryParamsToSearchValuesService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly bookingService: BookingService,
        private readonly formBuilder: FormBuilder,
        private readonly dateAdapter: DateAdapter<Moment>,
        private readonly translate: TranslateService,
        private readonly toasterService: ToasterService,
    ) {
        this.momentToday = moment(this.config.getStartDate());
        this.tomorrowsDate = moment(this.momentToday).add(1, 'day');
        this.searchForm = this.formBuilder.group({
            'propertyId': ['', [Validators.required]],
            'arrival': [this.momentToday, [Validators.required]],
            'departure': [this.tomorrowsDate, [Validators.required]],
            'adults': [this.adultSelectControlOptions[0].value, [Validators.required]],
            'promoCode': undefined,
        });

        this._onFormChanges();
        this.setInitialStayCriteria();
    }

    public ngOnInit(): void {
        this.dateAdapter.setLocale(this.translate.currentLang ? this.translate.currentLang : 'en');

        // FIXME remove local 'copy' use this.config.properties directly
        this.properties = this.config.properties;
        this.properties = this._sortByName(this.properties);

        this._setDefaultProperty();
        this._presetSelectedRegionAndPropertyIfRegionEnabled();
    }

    public onPropertyChange(): void {
        this.config.setCurrentProperty(this.searchForm.controls['propertyId'].value);
    }

    public viewShowAdvancedPropertySelector(): boolean {
        // the advanced PropertySelector need's CityNames as Select OptionGroup and autocomplete search filter
        return this.ibeConfig.settings.advancedPropertySelectorEnabled
            && this.propertiesHaveCities();
    }

    // TODO: wording ... this function does not set a default property
    //     - it tries to pre-select a property, preferable from the search params or from a default in the IBE config
    public _setDefaultProperty(): void {
        this.route.queryParams.subscribe(params => {
            const propertyId: string = (params.propertyId) ? params.propertyId : this.config.defaultPropertyId;
            if (propertyId) {
                const testId: string = propertyId.toLowerCase();
                const propertyWithPmsId = this.properties
                    .find((property) => (property) && (property.pmsId.toLowerCase() === testId));

                if (propertyWithPmsId && propertyWithPmsId.pmsId) {
                    this.onPropertySelection(propertyWithPmsId.pmsId);
                }
            }
        });
    }

    private maybeSetEmptyPropertyIdToAll(): void {
        if (this.config.settings.displayPropertiesByRegionEnabled &&
            this.searchForm.controls['propertyId'].value === '') {
            this.searchForm.controls['propertyId'].setValue('All');
        }
    }

    private _presetSelectedRegionAndPropertyIfRegionEnabled(): void {
        if (this.config.settings.displayPropertiesByRegionEnabled && this.propertiesHaveCities()) {
            if (!this.selectedRegion) {
                this.selectedRegion = 'All';
            }

            this.maybeSetEmptyPropertyIdToAll();
        }
    }

    public get firstProperty(): Property {
        // FIXME this expects properties to have a property at index 0
        return this.properties[0];
    }

    public onPropertySelection($event: string): void {
        // FIXME this is a PropertySelector Event where we set the value in the propertySelector = stupid event handling
        this.searchForm.controls['propertyId'].setValue($event);
        this.maybeSetEmptyPropertyIdToAll();
    }

    public onCityAndPropertySelection($event: { region: string, propertyId: string }): void {
        this.onPropertySelection($event.propertyId);
        this.selectedRegion = $event.region;
    }

    public onStayDateSelection($event: CalendarStayDateSelectionEvent): void {
        this.searchForm.controls['arrival'].setValue($event.arrivalDate);
        this.searchForm.controls['departure'].setValue($event.departureDate);
    }

    private _isDepartureSameOrBeforeArrival(arrival: Date, departure: Date): boolean {
        return moment(departure).isSameOrBefore(arrival, 'day');
    }

    private _isArrivalOrDepartureBeforeStartDate(arrival: Date, departure: Date): boolean {
        return (
            moment(departure).isBefore(this.config.getStartDate(), 'day')
            ||
            moment(arrival).isBefore(this.config.getStartDate(), 'day')
        );
    }

    private _sortByName(properties: Property[]) {
        return properties.sort((a, b): number => {
            const valA = a.name.toUpperCase();
            const valB = b.name.toUpperCase();
            return (valA < valB) ? -1 : ((valA > valB) ? 1 : 0);
        });
    }

    private setInitialStayCriteria(): void {
        this.queryParamsToSearchValues
            .params$(this.momentToday)
            .subscribe((queryParams): void => {
                this.searchForm.patchValue(queryParams);
                this.selectedRegion = queryParams.region;

                if (this._isArrivalOrDepartureBeforeStartDate(queryParams.arrival, queryParams.departure)) {
                    this.toasterService.showError(
                        this.translate.instant('booking_search.date_error'),
                        this.translate.instant('booking_search.date_change'),
                    );
                }

                this.personsQtyData.adults = queryParams.adults;
                this.personsQtyData.childrenAges = queryParams.childrenAges;
            });
    }

    public submitSearch(event: Event): void {
        event.preventDefault();

        if (this.properties.length === 1) {
            this.searchForm.controls['propertyId'].setValue(this.properties[0].pmsId);
        }

        if (this.searchForm.valid) {
            let arrival = this.searchForm.controls['arrival'].value;
            let departure = this.searchForm.controls['departure'].value;

            if (moment(arrival).isBefore(moment())) {
                // If somehow the arrival is in the past, set it to the current day.
                arrival = moment().toDate();
            }

            if (this._isDepartureSameOrBeforeArrival(arrival, departure)) {
                departure = moment(arrival).add(1, 'days').toDate();
            }

            this._stayCriteriaParams = this.searchForm.getRawValue();
            if (this.selectedRegion) {
                this._stayCriteriaParams.region = this.selectedRegion;
            }
            this._stayCriteriaParams.arrival = moment(arrival).format('YYYY-MM-DD');
            this._stayCriteriaParams.departure = moment(departure).format('YYYY-MM-DD');
            this._stayCriteriaParams.adults = this.personsQtyData.adults;
            this._stayCriteriaParams.childrenAges = this.personsQtyData.childrenAges.filter(age => age > 0);

            this.bookingService.saveLastSearchedStayCriteria(this._stayCriteriaParams);

            this.maybeSetEmptyPropertyIdToAll();

            this._navigate();
        }
    }

    private _navigate(): void {
        if (this.config.redirectPath) {
            const serializedParams = serializeQueryParams(this._stayCriteriaParams);
            const url = `${this.config.redirectPath}#/booking/results?${serializedParams}`;
            this.globalWindowService.getWindowLocationAssign(url);
        } else {
            this.router
                .navigate(['booking/results'], { queryParams: this._stayCriteriaParams })
                .catch((navError) => console.error('navigation failed: /booking/results', navError));
        }
    }

    private _onFormChanges(): void {
        this.searchForm
            .valueChanges
            .subscribe((values) => {this.onSearchFormChange.emit(values); });
    }

    public isFormControlInvalid(formControl: AbstractControl): boolean {
        return isFormControlInvalid(formControl, this.searchFormDirective);
    }

    public togglePersonsQtyPopup(): void {
        this.isPersonsQtyPopupOpen = !this.isPersonsQtyPopupOpen;
    }

    public handleKeypress($event: KeyboardEvent): void {
        if ($event.key === 'Enter') {
            this.togglePersonsQtyPopup();
        }
    }

    public onPersonsQtyChange(data: PersonsQtyData): void {
        this.personsQtyData = data;
    }

    public get personsQtyFieldAdultsLabel(): string {
        // TODO: plural forms are not this simple
        // TODO: why do we change the label between adults / persons, instead of having one const wording?
        const plural = 2;
        const labelAdults = (this.personsQtyData.adults < plural) ? 'booking_search.adult' : 'booking_search.adults';
        const labelPersons = (this.personsQtyData.adults < plural) ? 'booking_search.person' : 'booking_search.persons';
        const label = (this.config.isChildrenEnabled) ? labelAdults : labelPersons;

        return this.translate.instant(label);
    }

    public propertiesHaveCities(): boolean {
        const propertyWithoutCity = this.properties.find((property: Property) => (!property?.location?.city));

        return (propertyWithoutCity === undefined);
    }
}
