import { enableProdMode, LOCALE_ID, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// for @ngu-carousel
// tslint:disable-next-line: no-import-side-effect
import 'hammerjs';

import { AppModule } from './app/app.module';
import { IbeConfigService } from './app/services/ibe-config.service';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

declare global {
    interface Window {
        up: {
            bootstrapEngine(): void;
            destroyEngine(): void;
        },
        upIbe: {
            loaded: boolean;
            setLanguage(language: string): void;
            bootstrapEngine(): void;
            destroyEngine(): void;
        }
    }
}

const oldTag = document.querySelector('ibe-apaleo');

if (oldTag) {
    oldTag.outerHTML = oldTag.outerHTML.replace(/ibe-apaleo/g, 'ibe-up');
}

let _maybePlatformRef = maybeBootstrap();

function maybeBootstrap(): Promise<NgModuleRef<AppModule>> | undefined {
    window.upIbe = window.upIbe || { loaded: false };
    if (window?.upIbe?.loaded) {
        return _maybePlatformRef;
    } else if (document.querySelector('ibe-up')) {
        window.upIbe.loaded = true;
        return platformBrowserDynamic()
            .bootstrapModule(AppModule, {
                providers: [
                    {
                        provide: LOCALE_ID,
                        deps: [ IbeConfigService ],
                        useFactory: (ibeConfigService: IbeConfigService) => ibeConfigService.language,
                    },
                ],
            });
    } else {
        console.warn('It looks like ibe.min.js is included on a page where the IBE is not used. For performance reasons you should avoid doing this.');
        return undefined;
    }
}

const bootstraps = {
    bootstrapEngine() {
        _maybePlatformRef = maybeBootstrap();
    },
    destroyEngine() {
        if (_maybePlatformRef) {
            window.upIbe.loaded = false;
            _maybePlatformRef.then(ref => ref.destroy());
        }
    },
};

window.upIbe = window.upIbe || {};
Object.assign(window.upIbe, bootstraps);

// tslint:disable-next-line:no-console
console.log(`IBE Version: ${environment.version}`);
