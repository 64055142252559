import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateRestrictions } from 'app/fixme-inline-types';

@Component({
    selector: 'ibe-availability-restrictions-dialog',
    templateUrl: './availability-restrictions-dialog.component.html',
    styleUrls: ['./availability-restrictions-dialog.component.scss'],
})
export class AvailabilityRestrictionsDialogComponent implements OnInit {
    public minimumLength: { minLengthOfStay: number };
    public maximumLength: { maxLengthOfStay: number };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DateRestrictions,
        private readonly dialogRef: MatDialogRef<AvailabilityRestrictionsDialogComponent>,
    ) {
        this.dialogRef.disableClose = true;
    }

    public ngOnInit(): void {
        this.minimumLength = {
            minLengthOfStay: this.data.minLengthOfStay,
        };
        this.maximumLength = {
            maxLengthOfStay: this.data.maxLengthOfStay,
        };
    }

    public onContinueClick(event: Event): void {
        event.preventDefault();
        this.dialogRef.close(true);
    }
}
