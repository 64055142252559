import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogProps } from 'app/fixme-inline-types';

@Component({
    selector: 'ibe-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ErrorDialogProps,
        private dialogRef: MatDialogRef<ErrorDialogComponent>,
    ) { }

    public onRetry() {
        this.dialogRef.close(true);
    }
}
