import { Injectable } from '@angular/core';
import { FixmeIbeSettings, Language } from 'app/fixme-inline-types';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config, FeatureWhitelist, Property, PropertyPaymentProviderSettings } from 'up-ibe-types';
import { asString } from '../helpers/type.helper';

// @ts-ignore
const emptyIbeSettings: FixmeIbeSettings = {
    forceCommercialPurpose: false,
    defaultAddressCountryCode: '',
    defaultLanguage: 'en',
    paymentStepEnabled: true,
    termsAndConditionsUrls: {
        en: '',
        de: '',
        fr: '',
        ru: '',
        sv: '',
        fi: '',
        nl: '',
        es: '',
    },
    primaryColour: '',
    advancedPropertySelectorEnabled: false,
    usePropertyAvailabilityCalendar: false,
    displayPropertiesByRegionEnabled: false,
    displayPropertiesByCategoryEnabled: false,
    childrenOptionEnabled: false,
    availabilityCalendarEnabled: false,
    onlyShowPromoCodeRatesEnabled: false,
    maxNumberOfAdults: 10,
    maxNumberOfChildren: 10,
    numberOfAdultsDefault: 1,
    showCityTaxEstimateEnabled: false,
    showServiceChargeEnabled: true,
    startDateEnabled: false,
    startDate: new Date(),
    roomQtySelectorLimit: 10,
    guestManagementEnabled: false,
    sendGuestEmailsEnabled: false,
    checkoutFields: {
        details: {
            title: true,
            firstName: true,
            lastName: true,
            phone: true,
            email: true,
            password: true,
            travelPurpose: true,
            companyName: true,
            companyTaxId: true,
            guestComment: true,
        },
        address: {
            street: true,
            city: true,
            postalCode: true,
            countryCode: true,
            state: false,
            marketingConsent: true,
        },
    },
    autoscrollEnabled: true,
    availabilityCalendarRestrictionsModalEnabled: true,
    predefinedCommentBubbles: {
        en: [],
        de: [],
        es: [],
        fi: [],
        fr: [],
        it: [],
        nl: [],
        ru: [],
        sv: [],
    },
};

@Injectable()
export class IbeConfigService {
    get defaultPropertyId(): string {
        return this._defaultPropertyId;
    }

    // FIXME - this service has an initial UNDEFINED state AND is unable to handle it
    //       - this broken state leads to invalid data through out the whole application

    // FIXME - configs MUST NOT be public
    // FIXME - configs MUST NOT be writeable from anywhere
    // TODO  - replace all access with read only 'getter'
    public ibeKey: string;
    public language: Language = 'en';
    public redirectPath?: string; // FIXME: define use case - this seems to be an attribute on the myIBE 'HTML'Tag
    public pmsProvider: string;
    public accountPaymentProvider: string;
    public settings: FixmeIbeSettings = emptyIbeSettings;
    public accountAmexEnabled = false;
    public accountTestModeEnabled: boolean = false;
    public accountFeatureWhitelist: FeatureWhitelist;

    // _defaultPropertyId: Customers can set an attribute <my-ibe default-property-id="PMS-Property-ID" />
    //                     to preselect a property in the booking search bar
    private _defaultPropertyId: string = '';
    private _properties: Property[];
    private _currentProperty: Property | undefined;

    /**
     * This was introduced so that different components can keep track of the current property
     * when other components change it. It should always be the same value as the original
     * _currentProperty property as the setCurrentProperty() method handles both. Going
     * forward, we should be using the getCurrentPropertySubscribable() method to access the
     * current property, and we should refactor code that uses the original getter to use the
     * new one where possible. This should result in us being able to remove all usage of
     * _currentProperty eventually.
     */
    private _currentPropertySubscribable: BehaviorSubject<Property | undefined> = new BehaviorSubject<Property | undefined>(undefined);

    public isLongStayProperty(): boolean {
        return (this._currentProperty?.isLongStay === true);
    }

    get properties(): Property[] {
        return this._properties;
    }

    set properties(properties: Property[]) {
        this._properties = properties;
    }

    public setConfig(config: Config) {
        this.settings = { ...this.settings, ...config.settings };
        this.pmsProvider = config.pmsProvider;
        this.properties = config.properties;

        this.accountPaymentProvider = config.accountPaymentProvider;
        this.accountAmexEnabled = config.accountAmexEnabled || false;
        this.accountTestModeEnabled = config.accountTestModeEnabled;
        this.accountFeatureWhitelist = config.accountFeatureWhitelist || {};

        // FIXME: incompatible feature / settings
        this.fixInvalidSettings();
    }

    private fixInvalidSettings = () => {
        const isCommentBubbleActive = (this.accountFeatureWhitelist?.predefinedCommentBubbles === true);
        const isLicensePlateFieldActive = (this.accountFeatureWhitelist?.licensePlateField === true);
        if (isCommentBubbleActive && isLicensePlateFieldActive) {
            this.accountFeatureWhitelist.licensePlateField = false;
            console.error(
                'myIBE incompatible config',
                'feature.predefinedCommentBubbles && feature.licensePlateField',
                'feature.licensePlateField will be disabled',
            );
        }
    };

    public get isChildrenEnabled() {
        return this.settings.childrenOptionEnabled;
    }

    public initializeCurrentProperty(): void {
        const property = this.findPropertyById(this._defaultPropertyId);
        if (property !== undefined) {
            this._currentPropertySubscribable = new BehaviorSubject<Property>(property);
        }
    }

    public isPmsApaleo() {
        return (this.pmsProvider === 'APALEO');
    }

    public isPmsStayntouch() {
        return (this.pmsProvider === 'STAYNTOUCH');
    }

    public findPropertyById(propertyPmsId: string): Property | undefined {
        return this.properties.find((property) => (property.pmsId === propertyPmsId));
    }

    public getStartDate(): Date {
        // startDate is enabled & set & in the future
        const useStartDate = this.settings.startDateEnabled
            && this.settings.startDate
            && moment(this.settings.startDate).isAfter(moment());

        // FIXME: the shared type IBE Settings is invalid
        //      - start date is not a Date it is 'string | unknown'
        // @ts-ignore
        return useStartDate ? new Date(this.settings.startDate) : new Date();
    }

    public setCurrentProperty(propertyPmsId: string) {
        const property = this.findPropertyById(propertyPmsId);
        this._currentProperty = property;

        try {
            // FIXME: use LocalStorageService
            localStorage.setItem(`upibe_${this.ibeKey}_property`, JSON.stringify(property));
        } catch (error) {
            // FIXME log and throw: sentry is currently not able to build an useful stack trace (its minified)
            console.error('IbeConfigService.setCurrentProperty:', error);
            throw  error;
        }

        if (property !== undefined) {
            this._currentPropertySubscribable.next(property);
        }
    }

    public getCurrentPropertySubscribable(): Observable<Property | undefined> {
        return this._currentPropertySubscribable.asObservable();
    }

    public getPaymentProvider(): string {
        const accountPaymentNotSet = (this.accountPaymentProvider === 'none' || !this.accountPaymentProvider);
        const provider = (accountPaymentNotSet && this._currentProperty)
            ? this._currentProperty.paymentProvider
            : this.accountPaymentProvider;

        return asString(provider, '');
    }

    public isPaymentInTestMode = (): boolean => {
        return this.accountTestModeEnabled
            || (this._currentProperty?.paymentProviderSettings?.testModeEnabled as unknown === true);
    };

    public setDefaultPropertyId(defaultPropertyId: string) {
        this._defaultPropertyId = defaultPropertyId;
    }

    public getPropertyPaymentSettings(): PropertyPaymentProviderSettings {
        return {
            merchantAccount: '',
            subMerchantId: '',
            cashierNumber: '',
            workstationId: '',
            cardCodes: undefined,
            additionalAdyenShopperDetailsEnabled: false,
            paymentCurrency: '',
            riskProfileReference: '',
        };
    }
}
