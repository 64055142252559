<h4 class="ibe-guest-details-dialog-title">
    {{ 'manage_booking.edit_details' | translate }}
</h4>

<form
    [formGroup]="guestDetailsForm"
    #guestDetailsFormDirective="ngForm"
    (submit)="onFormSubmit($event)"
>
    <ibe-details-form
        (formReady)="addFormControl('details', $event)"
        [showCreateAccountBox]="false"
        [submitObservable]="submit.asObservable()"
    ></ibe-details-form>
    <ibe-address-form
        [submitObservable]="submit.asObservable()"
        (formReady)="addFormControl('address', $event)"
    ></ibe-address-form>

    <button type="submit" class="ibe-btn ibe-primary-btn">
        {{ 'global.continue' | translate }}
    </button>

    <button
        type="button"
        class="ibe-btn ibe-cancel-btn"
        (click)="onCancel($event)"
    >
        {{ 'global.cancel' | translate }}
    </button>
</form>
