import { Injectable } from '@angular/core';

// tslint:disable-next-line:no-any
declare var document: any;

@Injectable()
export class ScriptLoaderService {
    public loadScript(url: string) {
        return new Promise((resolve) => {
            // load script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            if (script.readyState) { // IE
                script.onreadystatechange = () => {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = undefined;
                        resolve({ script: url, loaded: true, status: 'Loaded' });
                    }
                };
            } else { // others
                script.onload = () => {
                    resolve({ script: url, loaded: true, status: 'Loaded' });
                };
            }
            script.onerror = (error: Error) => {
                console.error('loadScript', error);
                resolve({ script: url, loaded: false, status: 'Loaded' });
            };
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }
}
