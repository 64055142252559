<div class="mat-dialog-header">
    <h1 mat-dialog-title>{{ 'extras.add_extras' | translate }}</h1>
    <button type="button" class="ibe-skip-extras-btn" (click)="skipExtrasStep()">
        {{ 'extras.skip_extras' | translate }}
        <i class="ibe-icon ibe-icon-chevron-right"></i>
    </button>
</div>
<ibe-extras-template
    [extras]="extras"
    [selectedExtras]="selectedExtras"
    [isLoading]="isLoading"
    (onSelectedExtrasUpdate)="onSelectedExtrasUpdate($event)"
>
</ibe-extras-template>
<mat-dialog-actions align="end">
    <button
        class="ibe-btn ibe-primary-btn ibe-complete-extras-btn"
        (click)="completeExtrasStep()"
        *ngIf="data.extras"
    >
        {{ 'extras.next' | translate }}
    </button>
</mat-dialog-actions>
