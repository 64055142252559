import { Component, OnInit } from '@angular/core';
import { ReservationModel } from 'up-ibe-types';
import { fadeInOnEnterAnimation, scaleDownOnLeaveAnimation } from '../../animations';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'ibe-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    animations: [
        fadeInOnEnterAnimation,
        scaleDownOnLeaveAnimation,
    ],
})
export class CartComponent implements OnInit {
    private closeCardTimeout = 2000;
    private isOpen = true;

    constructor(
        private readonly bookingService: BookingService,
    ) { }

    public get isCardOpen(): boolean { return this.isOpen; }
    public get reservations(): ReservationModel[] { return this.bookingService.getReservations(); }
    public get reservationCount() { return this.reservations.length; }
    public get hasReservations() { return !!(this.reservations.length); }

    public ngOnInit() { this.closeCartAfterPeriodOfTime(); }
    public toggleCart = () => { this.isOpen = !this.isOpen; };

    private closeCartAfterPeriodOfTime() {
        setTimeout(
            () => {this.isOpen = false; },
            this.closeCardTimeout,
        );
    }
}
