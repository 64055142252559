<div class="ibe-please-wait-message" *ngIf="showMessage">
    <div>
        <i class="ibe-icon ibe-icon-warning-circle"></i>
    </div>
    <div class="message-text">
        {{ 'checkout_payment.you_will_be_redirected' | translate }}
        <br>
        {{ 'checkout_payment.please_wait' | translate }}
    </div>
</div>
<div #dropin class="dropin-container"></div>
