import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HttpStatus } from 'enums';
import { environment } from 'environments/environment';
import { iif, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, map, retryWhen, shareReplay, switchMap } from 'rxjs/operators';
import { AvailabilityResultByRegionModel, AvailabilityResultModel } from 'up-ibe-types';
import { hasProperty, isObject } from '../helpers/type.helper';
import { AvailabilitySearchParams, INVALID_PARAMS } from './room-results/search-params.service';
import { arrayIfEmpty } from './store/common';

const timeout = 5000;
const maxRetries = 3;

const shouldCancelRequest = (error: unknown, index: number) => () => {
    const invalidParams = (error === INVALID_PARAMS);
    const badRequest = isObject(error)
        && hasProperty(error, 'status')
        && (error.status === HttpStatus.Bad_Request);
    const maxRetriesReached = (index >= maxRetries);

    return invalidParams
        || badRequest
        || maxRetriesReached
        ;
};

@Injectable({
    providedIn: 'root',
})
export class AvailabilityStoreService {
    constructor(
        private readonly http: HttpClient,
    ) { }

    public availability$(validParams$: Observable<Params>) {

        return validParams$.pipe(
            map((searchParams: AvailabilitySearchParams) => searchParams.toHttpParams()),
            switchMap((httpParams) => {
                return this.http.get<AvailabilityResultModel[]>(
                    `${environment.serverUrl}/api/ibe/availability`,
                    { params: httpParams },
                );
            }),
            arrayIfEmpty(),
            shareReplay(),
            retryWhen(
                (errors) => errors.pipe(
                    concatMap(
                        (error, index) => {
                            console.error(`api/ibe/availability`, { error, retry: `${+index}/${maxRetries}` });
                            return iif(
                                // Cancel when 'shouldCancelRequest' returns true
                                shouldCancelRequest(error, index),
                                // condition = true (cancel) throw exception
                                throwError(error),
                                // condition = false (retry) retry the call after a short delay
                                of(error).pipe(delay(timeout)),
                            );
                        },
                    ),
                ),
            ),
        );
    }

    public availabilityForRegion$(validParams$: Observable<Params>) {
        return validParams$.pipe(
            map((searchParams: AvailabilitySearchParams) => searchParams.toHttpParams()),
            switchMap((httpParams) => {
                return this.http
                    .get<AvailabilityResultByRegionModel[]>(
                        `${environment.serverUrl}/api/ibe/region-availability`,
                        { params: httpParams },
                    );
            }),
            arrayIfEmpty(),
            shareReplay(),
        );
    }
}
