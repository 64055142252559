import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { ImagesService } from 'app/services/images.service';
import * as objectFitImagesPollyFill from 'object-fit-images';
import { UnitTypeModel } from 'up-ibe-types';

export type GalleryDialogData = {
    propertyId: string,
    unitType: UnitTypeModel
}

@Component({
    selector: 'ibe-gallery-dialog',
    templateUrl: './gallery-dialog.component.html',
    styleUrls: ['./gallery-dialog.component.scss'],
})
export class GalleryDialogComponent implements OnInit, AfterViewChecked {
    private firstImageLoaded = false;

    private unitTypeImageUrls: string[];
    public get imagesUrls(): string[] { return this.unitTypeImageUrls; }

    public get imageCarousel(): NguCarouselConfig {
        return {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            slide: 1,
            speed: 400,
            interval: { timing: 4000 },
            point: { visible: true },
            touch: true,
            loop: false,
        };
    };

    public get unitType() { return this.data.unitType; }

    constructor(
        private readonly imagesService: ImagesService,
        @Inject(MAT_DIALOG_DATA) private readonly data: GalleryDialogData,
    ) { }

    public ngOnInit() {
        this.unitTypeImageUrls = this.imagesService.getUnitTypeImagesUrls(this.data.propertyId, this.data.unitType.id);
        objectFitImagesPollyFill();
    }

    public ngAfterViewChecked() {
        if (!this.firstImageLoaded) {
            const carousel = document.getElementById('carousel');
            if (carousel) {
                const image = carousel.getElementsByTagName('img')[0];
                if (image && image.height > 0) {
                    carousel.style.height = `${image.height.toString()}px`;
                    this.firstImageLoaded = true;
                }
            }
        }
    }

    public onImageChange(event: NguCarouselStore) {
        const carousel = document.getElementById('carousel');
        if (carousel) {
            const image = carousel.getElementsByTagName('img')[event.currentSlide];
            carousel.style.height = `${image.height.toString()}px`;
        }
    }
}
