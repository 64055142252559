/* tslint:disable:max-file-line-count */
import RedirectElement from '@adyen/adyen-web/dist/types/components/Redirect';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Scope } from '@sentry/browser';
import {
    AdyenDropinComponent,
    AdyenDropinSetupData,
    AdyenDropInState,
    AdyenPaymentPayload,
    AdyenResponse,
    ErrorDialogProps,
    WebhookResponse,
} from 'app/fixme-inline-types';
import { ErrorDialogService } from 'app/services/error-dialog.service';
import { environment } from 'environments/environment';
import { interval, Observable, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { ErrorSource } from '../../../error-dialog/error-mapping';
import { AdyenFactory } from '../../../factories/adyen.factory';
import { asString, isArray } from '../../../helpers/type.helper';
import { AdyenService } from '../../../services/adyen.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { IbeConfigService } from '../../../services/ibe-config.service';

@Component({
    selector: 'ibe-adyen-dropin-payment',
    templateUrl: './adyen-dropin-payment.component.html',
    styleUrls: ['./adyen-dropin-payment.component.scss'],
})
export class AdyenDropinPaymentComponent implements OnInit, OnDestroy {
    @ViewChild('dropin', { static: true }) public dropin: ElementRef;
    @Input('bookingRequestId') public bookingRequestId: string;
    @Input('paymentSetupData') public paymentSetupData: AdyenDropinSetupData;
    @Input('isLoading') public isLoading: boolean;
    @Input('paymentRedirected') public paymentRedirected = false;
    @Input('paymentButtonLabel') public paymentButtonLabel = 'Pay';
    @Output('onComplete') public onComplete: EventEmitter<AdyenPaymentPayload> = new EventEmitter();
    @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
    @Output('toggleInPaymentFlow') public toggleInPaymentFlow: EventEmitter<boolean> = new EventEmitter();
    @Output('togglePaymentRedirected') public togglePaymentRedirected: EventEmitter<boolean> = new EventEmitter();

    private showMessageSubscription: Subscription;
    // FIXME DO NOT USE PUBLIC PROPERTIES
    public showMessage = false;
    public instance: RedirectElement;

    private readonly environment: string;

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly adyenService: AdyenService,
        private readonly errorDialogService: ErrorDialogService,
        private readonly http: HttpClient,
        private readonly translate: TranslateService,
        private readonly config: IbeConfigService,
        private readonly route: ActivatedRoute,
        private readonly adyenFactory: AdyenFactory,
        private readonly errorHandlerService: ErrorHandlerService,
    ) {
        this.environment = this.config.isPaymentInTestMode() ? 'test' : 'live';
    }

    public ngOnInit(): void {
        if (this.paymentRedirected && this.hasPayload()) {
            this.togglePaymentRedirected.emit(true);
            this.toggleInPaymentFlow.emit(true);

            let redirectResult = this.route.snapshot.queryParams['redirectResult'];

            // Putting this here because a weird issue cropped up where it was being pulled out as an array
            // which is invalid and breaks the flow
            if (isArray(redirectResult)) {
                redirectResult = redirectResult[0];
            }

            this.onComplete.emit({
                redirectResult,
                bookingReference: this.bookingRequestId,
            });
        } else {
            this.setupAdyenWidget()
                .then(() => {
                    this.showMessageSubscription = this.adyenFactory.showMessage
                        .subscribe({
                            next: response => this.showMessage = response,
                            error: (error) => this.handleError(error, 'http', 'ibe-adyen-dropin-payment.adyenFactory', {}),
                        });
                })
                .catch(error => this.handleError(error, 'http', 'ibe-adyen-dropin-payment.setupAdyenWidget', {}));
        }
    }

    public ngOnDestroy() {
        if (this.showMessageSubscription) {
            this.showMessageSubscription.unsubscribe();
        }
    }

    public async setupAdyenWidget(): Promise<void> {
        const locale = this.mapLanguageCode(this.config.language);
        const translations = { [locale]: { 'payButton': this.paymentButtonLabel } };

        const paymentMethodsConfiguration = this.createPaymentMethodsConfiguration();

        const configuration: CoreOptions = {
            paymentMethodsResponse: { paymentMethods: this.paymentSetupData.paymentMethods },
            clientKey: this.paymentSetupData.clientKey,
            locale,
            translations,
            environment: this.environment,
            showPayButton: true,
            paymentMethodsConfiguration,
            // Events
            onReady: this.onDropinReady,
            onSubmit: this.onDropinSubmit,
            onAdditionalDetails: this.onDropinAdditionalDetails,
            onError: this.onDropinError,
        };

        this.instance = await this.adyenFactory.create(
            configuration,
            'dropin',
            this.dropin.nativeElement,
        );

        this.toggleIsLoading.emit(false);
    }

    private createPaymentMethodsConfiguration() /* : PaymentMethodsConfiguration */ {
        const card = {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: this.translate.instant('checkout_payment.method_card.name'),
        };

        const paypal = {
            environment: this.environment,
            countryCode: this.paymentSetupData.countryCode,
            amount: {
                currency: this.paymentSetupData.currency,
                // tslint:disable-next-line:no-magic-numbers
                value: this.paymentSetupData.amount * 100,
            },
            onError: this.onPaypalError,
            // tslint:disable-next-line:no-any
            onCancel: (data: any, dropin: any) => {
                dropin.setStatus('ready');
                this.toggleIsLoading.emit(false);
            },
        };

        return {
            card,
            paypal,
            threeDS2: { challengeWindowSize: '05' },
        };
    }

    private onDropinError = (error: unknown) => {
        console.error('onDropinError', error);
        this.handleError(error, 'http', 'ibe-adyen-dropin-payment.onError', {});
    };
    private onPaypalError = (error: unknown) => {
        console.error('onPaypalError', error);
        this.handleError(error, 'http', 'ibe-adyen-dropin-payment.onError', {});
    };

    private onDropinReady = (): void => {
        this.toggleIsLoading.emit(false);
    };

    private onDropinSubmit = (state: AdyenDropInState, dropin: AdyenDropinComponent): void => {
        this.toggleIsLoading.emit(true);
        this.toggleInPaymentFlow.emit(true);

        if (state.isValid) {
            // FIXME: remove IP address request ( https://myibe.com/mip/ )
            // - the IP is not required for the payment
            // - this is a bad hack and should be removed
            // - the server must read the ip (client data can be spoofed)
            this.adyenService
                .getIpAddress()
                .then(response => this.initiatePayment(state, dropin, response.ip))
                .catch(() => this.initiatePayment(state, dropin));
        }
    };

    private onDropinAdditionalDetails = (state: AdyenDropInState, dropin: AdyenDropinComponent): void => {
        this.toggleIsLoading.emit(true);
        this.submitAdditionalDetails(state, dropin);
    };

    private hasPayload = (): boolean => !!this.route.snapshot.queryParams.payload;

    public submitAdditionalDetails(state: AdyenDropInState, dropin: AdyenDropinComponent) {
        const data = {
            ...state.data,
            target: asString(this.paymentSetupData.bookingOrReservationId, ''),
        };
        this.adyenService
            .submitAdditionalDetails(this.bookingRequestId, data)
            .subscribe({
                next: (response): void => { this.handlePaymentResponse(response, dropin); },
                error: (error): void => {
                    console.error('submitAdditionalDetails', error);
                    this.errorDialogService
                        .errorDialog(error.statusText ?? 'Unexpected Error', ErrorSource.ADYENDROPIN);
                },
            });
    }

    private getJournalForPspReference(response: WebhookResponse | null, pspReference: string) {
        // FIXME: journal is not an array OR `WebhookResponse` is invalid typed
        return response?.journal.find((journal) => {
            return (journal.eventCode === 'AUTHORISATION' && journal.pspReference === pspReference);
        });
    }

    public isSuccessfulWebhookResponse(response: WebhookResponse | null, pspReference: string): boolean {
        return !!this.getJournalForPspReference(response, pspReference);
    }

    public pollBookingsPspReference(pspReference: string): Observable<unknown> {
        return this.http.get(
            `${environment.serverUrl}/api/webhooks/bookings/${this.bookingRequestId}/payment-transactions`,
            { params: { pspReference } },
        );
    }

    public handlePaymentResponse(response: AdyenResponse, dropin?: AdyenDropinComponent): void {
        if (response.action && dropin) {
            this.toggleInPaymentFlow.emit(false);
            dropin.handleAction(response.action);
            this.toggleIsLoading.emit(false);
            return;
        }

        const isPspReferenceValid: boolean = Boolean(response?.pspReference && (response.pspReference !== ''));

        if (isPspReferenceValid && (response.resultCode === 'Pending' || response.resultCode === 'Received')) {
            const pspReference: string = String(response.pspReference);
            const INTERVAL = 5000;
            interval(INTERVAL)
                .pipe(
                    switchMap(() => this.pollBookingsPspReference(response.pspReference as string)),
                    takeWhile((webhookResponse: WebhookResponse | null): boolean => {
                        return !this.isSuccessfulWebhookResponse(webhookResponse, pspReference);
                    }, true),
                )
                .subscribe({
                    next: this.handleApaleoWebhookResponse(pspReference, response.reason),
                    error: (error) => {
                        console.error('WebhookResponse.error', error);
                        this.handleError(error, 'http', 'ibe-adyen-dropin-payment.setupAdyenWidget', {});
                    },
                });

            return;
        }

        this.isLoading = false;
        this.toggleIsLoading.emit(false);

        if (response.resultCode === 'Authorised') {
            return this.onAuthorisation();
        }

        this.toggleInPaymentFlow.emit(false);
        if (response.resultCode === 'Refused') {
            this.handleRefused(response);
        } else {
            console.error('handlePaymentResponse.unhandled', response);
            this.errorDialogService.errorDialog(response.reason, ErrorSource.ADYENDROPIN);
        }
    }

    private handleRefused(response: AdyenResponse) {
        const title = this.translate.instant(`dialog_error_codes.declined.title`);
        const message = this.translate.instant(`dialog_error_codes.declined.message`);
        const description = this.refusalReason(response);

        const config: ErrorDialogProps = { allowRetry: false, title, message, description, details: [] };
        this.errorDialogService.showErrorDialog(config, false);
    }

    public onAuthorisation(): void {
        if (!this.paymentSetupData) {
            return this.onComplete.emit();
        }

        return this.onComplete.emit({
            propertyPaymentProviderSettings: {
                merchantAccount: this.paymentSetupData.merchantAccount,
                paymentCurrency: this.paymentSetupData.paymentCurrency,
            },
        });
    }

    public initiatePayment(state: AdyenDropInState, dropin: AdyenDropinComponent, ipAddress?: string) {
        this.isLoading = true;
        this.toggleIsLoading.emit(true);
        this.toggleInPaymentFlow.emit(true);

        const payload = {
            data: state.data,
            bookingRequestId: this.bookingRequestId,
            merchantAccount: this.paymentSetupData.merchantAccount,
            bookingOrReservationId: this.paymentSetupData.bookingOrReservationId,
            ipAddress,
        };

        this.adyenService.initiatePayment(payload)
            .subscribe({
                next: (response): void => {
                    this.handlePaymentResponse(response, dropin);
                },
                error: (error) => {
                    this.handleError(error, 'http', 'ibe-adyen-dropin-payment.initiatePayment', {});
                },
            });
    }

    public mapLanguageCode(languageCode: string) {
        if (languageCode === 'en') {
            return 'en_GB';
        } else if (languageCode === 'de') {
            return 'de_DE';
        } else if (languageCode === 'fi') {
            return 'fi_FI';
        } else if (languageCode === 'se') {
            return 'se_SE';
        } else if (languageCode === 'ru') {
            return 'ru_RU';
        } else if (languageCode === 'fr') {
            return 'fr_FR';
        } else {
            return 'en_GB';
        }
    }

    // FIXME: replace 'any' with 'unknown'
    // tslint:disable-next-line:no-any
    private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
        // cast any, maybe, random junk to string
        const errorCode = (error?.errorCode && (typeof error.errorCode === 'string')) ? `${error?.errorCode}` : '';
        const message = (error?.message && (typeof error.message === 'string')) ? `${error?.message}` : '';
        if ((errorCode === 'timeout') && (message !== '')) {
            const props: ErrorDialogProps = {
                title: this.translate.instant(`dialog_error_codes.declined.title`),
                message: this.translate.instant(`dialog_error_codes.declined.message`),
                description: `${errorCode}: ${message}`,
                allowRetry: false,
            };
            this.errorDialogService.showErrorDialog(props, false);
        }

        details.error = error;
        this.errorHandlerService.logErrorWithContext(error, (scope: Scope) => {
            scope.addBreadcrumb({
                type,
                level: 'fatal',
                message: `${error.message}`,
                category,
                data: details,
            });
            return scope;
        });

        this.errorDialogService.errorDialog('Payment Process Failed', ErrorSource.ADYENDROPIN);
    }

    private refusalReason = (response: AdyenResponse): string => {
        // @ts-ignore - adyen types are junk
        return response?.refusalReason ?? '';
        /*
            {
                "refusalReason": "3D Not Authenticated",
                "resultCode": "Refused",
                "refusalReasonCode": "11",
                "threeDS2Result": {
                    "challengeCancel": "01",
                    "transStatus": "U",
                    "transStatusReason": "01"
                }
            }
        */
    };

    private handleApaleoWebhookResponse = (pspReference: string, reason: string) => (webhook: WebhookResponse): void => {
        this.isLoading = false;
        this.toggleIsLoading.emit(false);

        const journal = this.getJournalForPspReference(webhook, pspReference);
        if (journal && journal.isSuccess && journal.reason === 'Authorised') {
            this.onAuthorisation();
        } else {
            this.errorHandlerService.logErrorWithContext(
                'adyen-dropin: unexpected apaleo webhook response',
                (scope) => {
                    scope.setContext('response', {
                        'app.pspReference': pspReference,
                        'app.reason': reason,
                        'apaleo.response': webhook,
                    });
                    return scope;
                });
            if (journal && journal.reason) {
                this.errorDialogService.errorDialog(journal.reason, ErrorSource.ADYENDROPIN);
            } else {
                this.errorDialogService.errorDialog(reason, ErrorSource.ADYENDROPIN);
            }
        }
    };
}
