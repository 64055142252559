import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { PropertiesByCity } from 'app/fixme-inline-types';
import { Property } from 'up-ibe-types';
import { sortPropertiesByName } from '../../../helpers/utils.helper';

@Component({
    selector: 'ibe-property-selector',
    templateUrl: './property-selector.component.html',
    styleUrls: ['./property-selector.component.scss'],
})
export class PropertySelectorComponent implements OnInit {
    // tslint:disable-next-line:no-any
    @Input('invalidClass') public invalidClass: any; // un-exported junk types
    @Input('properties') public properties: Property[] = [];
    @Input('selectedPropertyId') public selectedPropertyId: string = '';

    @Output('onPropertySelection') public onPropertySelection: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatAutocompleteTrigger, { static: true }) public matAutoComplete: MatAutocompleteTrigger;

    // filterValue AutoComplete Search string
    private filterValue: string = '';

    // _propertiesGroupedByCity pre-build group of properties (group by city)
    private _propertiesGroupedByCity: PropertiesByCity[] = [];

    // _propertySelectOptions filtered (_propertiesGroupedByCity by filterValue)
    private _propertySelectOptions: PropertiesByCity[];
    get propertySelectOptions(): PropertiesByCity[] {
        return this._propertySelectOptions;
    }

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public propertyId = new FormControl();

    constructor(
        private readonly translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._setInitialPropertyId();
        this.groupPropertiesByCity();

        this.attachOnPropertyChangeHandler();
    }

    public onSearchSelection(property: Property) {
        this.propertyId.setValue(property.name);
        this.onPropertySelection.emit(property.pmsId);
    }

    public onPropertyIdInputClick() {
        this.propertyId.setValue('');
        this.matAutoComplete.openPanel();
    }

    public placeHolderOrSelectedProperty = (): string => {
        return this.displayName() || this.translate.instant('booking_search.select_property');
    };

    public displayName(): string {
        // FIXME for some reason 'this.properties' can run into a state were the value is undefined
        const selected = this.properties?.find((property) => property.pmsId === this.selectedPropertyId);

        return selected ? selected.name : '';
    }

    private _setInitialPropertyId() {
        if (this.selectedPropertyId) {
            const selectedProperty = this.properties.find(property => (property.pmsId === this.selectedPropertyId));
            if (selectedProperty) {
                this.propertyId.setValue(selectedProperty.name);
            }
        }
    }

    private groupPropertiesByCity(): void {
        this._propertiesGroupedByCity = [];

        const sortedProperties: Property[] = this.properties.sort(sortPropertiesByName);
        sortedProperties.forEach((property) => {
            const propertyCity: string = property.location?.city ? property.location.city.trim() : '';
            const cityGroup = this._propertiesGroupedByCity.find((group) => (group.city === propertyCity));
            if (cityGroup) {
                cityGroup.properties.push(property);
            } else {
                this._propertiesGroupedByCity.push({ city: propertyCity, properties: [property] });
            }
        });
    }

    private attachOnPropertyChangeHandler(): void {
        // FIXME: define events - create a clear event chain - NOT: maybe this, maybe that happened - or maybe not !?
        // FIXME: a UI select triggers three different events - of course all with different types for query
        this.propertyId
            .valueChanges
            .subscribe(
                (query: string | Property | undefined | unknown  /* FIXME types!?!? */) => {
                    // @ts-ignore
                    this.filterValue = (typeof query === 'string') ? query.toLowerCase() : query?.name?.toLowerCase();

                    this.filterMappedProperties();
                },
            );
    }

    private filterMappedProperties(): void {
        const isFiltered = this.filterValue?.trim() !== '';

        // clone and filter properties - the grouped this.propertiesGroupedByCity does not change and can be reused
        const filteredProperties = this._propertiesGroupedByCity
            .map((cityGroup) => {
                // Filter Properties - return all or filtered by search term
                const properties = cityGroup.properties.filter((property) => {
                    const inName = isFiltered && property.name.toLowerCase().includes(this.filterValue);
                    const inCity = isFiltered && property.location?.city?.toLowerCase().includes(this.filterValue);

                    return (!isFiltered || inName || inCity);
                });
                return { city: cityGroup.city, properties };
            });

        // finally filter 'empty' cities
        this._propertySelectOptions = filteredProperties.filter((cityGroup) => cityGroup.properties.length > 0);
    }
}
