import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'app/services/toaster.services';
import { environment } from '../../../environments/environment';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { FormValidationService } from '../../services/form-validation.service';

@Component({
    selector: 'ibe-guest-password-reset',
    templateUrl: './guest-password-reset.component.html',
    styleUrls: ['./guest-password-reset.component.scss'],
})
export class GuestPasswordResetComponent implements OnInit {
    @ViewChild('passwordResetFormDirective') public passwordResetFormDirective: FormGroupDirective;
    @ViewChild('completePasswordResetFormDirective') public completePasswordResetFormDirective: FormGroupDirective;

    // FIXME: DO NOT use public properties
    public passwordResetForm: FormGroup;
    public completePasswordResetForm: FormGroup;
    public resetToken: string;
    public isFormControlInvalid = isFormControlInvalid;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly http: HttpClient,
        private readonly currentRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly toasterService: ToasterService,
        private readonly translate: TranslateService,
        private readonly formValidationService: FormValidationService,
    ) {
    }

    public ngOnInit() {
        this.passwordResetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });

        this.completePasswordResetForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
        }, { validator: this.formValidationService.passwordMatchValidator });

        this.currentRoute.params.subscribe((params) => {
            if (params.resetToken) {
                this.resetToken = params.resetToken;
            }
        });
    }

    public resetPassword() {
        if (this.passwordResetForm.valid) {
            this.http
                .post(
                    `${environment.serverUrl}/api/ibe/guest/reset-password`,
                    {
                        email: this.passwordResetForm.controls['email'].value,
                        ibeUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                    },
                )
                .subscribe(
                    (response: { success: boolean }) => {
                        if (response.success) {
                            this.toasterService.showSuccess(
                                this.translate.instant('guest_management_auth.password_reset_email_sent'),
                                '',
                            );
                        }
                    },
                    (error) => {
                        this.toasterService.showError(
                            this.translate.instant('guest_management_auth.password_reset_failed_due_to_no_account'),
                            '',
                        );
                    },
                );
        }
    }

    public completeResetPassword() {
        if (this.completePasswordResetForm.valid) {
            this.http.post(
                    `${environment.serverUrl}/api/ibe/guest/complete-reset-password/${this.resetToken}`,
                    this.completePasswordResetForm.value,
                )
                .subscribe(
                    (response: { success: boolean }) => {
                        if (response.success) {
                            this.toasterService.showSuccess(
                                this.translate.instant('guest_management_auth.password_reset_successful'),
                                '',
                            );

                            this.goToGuestManagement();
                        }
                    },
                    (response) => {
                        this.toasterService.showError(
                            this.translate.instant('guest_management_auth.password_reset_failed'),
                            response.error.message,
                        );
                    },
                );
        }
    }

    public goToGuestManagement() {
        this.router
            .navigate(['guest/manage'])
            .catch((navError) => console.error('navigation failed: /guest/manage', navError));
    }
}
