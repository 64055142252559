<h2 mat-dialog-title class="mat-dialog-title">
    {{ 'manage_booking.cancel_booking_dialog_title' | translate }}
</h2>

<mat-dialog-content *ngIf="reservation.isNotEmpty()">
    <div class="ibe-cancellation-reservation-header">
        <p>{{ 'manage_booking.reservation_id' | translate }}:
            <span class="reservation-id">
                {{ reservation.bookingReference() }}
            </span>
        </p>
        <p>{{ 'manage_booking.cancel_booking_dialog_text' | translate }}</p>
    </div>

    <div *ngIf="cancellationFee.isNotEmpty()">
        <p class="ibe-cancellation-policy-title">{{ 'manage_booking.cancellation_policy' | translate }}</p>
        <p>{{ cancellationFee.description() }}</p>
        <p *ngIf="!cancellationFee.isPayable() && cancellationFee.amountDue() > 0">
            <span
                [innerHTML]="'manage_booking.cancellation_fee_text' | translate:{
                cancellationFee: cancellationFee.amount() | currency:cancellationFee.currency():'symbol':'1.2-2'
                }"
            ></span>
            <span
                *ngIf="cancellationFee.dueDateTime()"
                [innerHTML]="'manage_booking.cancellation_fee_date_text' | translate:{
                    payableDate: cancellationFee.formattedDueDate(language)
                }"
            ></span>
        </p>

        <div *ngIf="isCancellationFeePayable">
            <p class="ibe-cancellation-policy-title">{{ 'manage_booking.cancellation_fee' | translate }}</p>
            <p class="ibe-cancellation-fee">
                {{ cancellationFee.amount() | currency:cancellationFee.currency():'symbol':'1.2-2' }}
            </p>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="ibe-btn ibe-close-btn" [mat-dialog-close]="false">
        {{ 'dialog.close' | translate }}
    </button>

    <button class="ibe-btn ibe-cancel-btn" [mat-dialog-close]="true">
        {{ 'manage_booking.cancel_reservation' | translate }}
    </button>
</mat-dialog-actions>
