import AdyenCheckout from '@adyen/adyen-web';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { Injectable } from '@angular/core';
import { AdyenDropinComponent } from 'app/fixme-inline-types';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdyenFactory {
    private showMessageSource = new BehaviorSubject<boolean>(false);
    public showMessage = this.showMessageSource.asObservable();

    public async create(configuration: CoreOptions, component: string, element: HTMLElement) {
        const widget = await AdyenCheckout(configuration);
        const onSelect = (dropin: AdyenDropinComponent) => {
            if (dropin.props.type !== 'card') {
                this.showMessageSource.next(true);
            } else {
                this.showMessageSource.next(false);
            }
        };

        return widget
            .create(component, { onSelect })
            .mount(element);
    }
}
