import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnAddToCartEvent, SelectedRateId } from 'app/fixme-inline-types';
import { asNumber } from 'app/helpers/type.helper';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { orderBy } from 'lodash';
import { AvailabilityResultModel, AvailabilityResultRateModel, Property, UnitType } from 'up-ibe-types';
import { RateInfoModalComponent } from '../rate-info-modal/rate-info-modal.component';

@Component({
    selector: 'ibe-rate-selector',
    templateUrl: './rate-selector.component.html',
    styleUrls: [ './rate-selector.component.scss' ],
})
export class RateSelectorComponent implements OnInit {
    @Input('availabilityResult') public availabilityResult: AvailabilityResultModel;
    @Input('pmsPropertyId') public pmsPropertyId: string;
    @Input('propertyName') public propertyName: string;
    @Input('useBasePrices') public useBasePrices: boolean;

    @Output('onAddToCart') public onAddToCart: EventEmitter<OnAddToCartEvent> = new EventEmitter();

    // FIXME DO NOT USE PUBLIC PROPERTIES
    public selectedUnitQty = 1;
    public hasSpecialRates = false;

    private selectedRate: SelectedRateId;

    constructor(
        // FIXME DO NOT USE PUBLIC PROPERTIES
        public readonly config: IbeConfigService,
        private readonly dialog: MatDialog,
    ) { }

    public ngOnInit() {
        // FIXME remove lodash orderBy >> replace it with Array.sort !
        const sortedRates = orderBy(
            this.availabilityResult.rates,
            (rate) => this.isSpecialRate(rate),
            [ 'desc' ],
        );

        this.availabilityResult = { ...this.availabilityResult, rates: sortedRates };
        this.hasSpecialRates = this.hasSpecialRate(this.availabilityResult.rates);
        this.preselectDefaultOffer();
    }

    public selectRate(rate: AvailabilityResultRateModel): void {
        this.selectedRate = { rateId: rate.id };
    }

    public isRateSelected(rateId: string): boolean {
        return (this.selectedRate?.rateId === rateId);
    }

    public isSpecialRate(rate: AvailabilityResultRateModel): boolean {
        return (rate.isPromoRate === true)
            || (rate.isCorporateRate === true);
    }

    public rateHasAdditionalIncludedExtras(rate: AvailabilityResultRateModel): boolean {
        if (rate.includedExtras === undefined || !rate.includedExtras.length) {
            return false;
        }

        const additionalIncludedExtras = rate.includedExtras.filter((extra) => {
            return extra.pricingMode === 'Additional';
        });

        return additionalIncludedExtras.length > 0;
    }

    private hasSpecialRate(rates: AvailabilityResultRateModel[]): boolean {
        const specialRate = rates.find((rate) => this.isSpecialRate(rate));

        return (specialRate !== undefined);
    }

    public generateUnitQtyArray(availableUnits: number): number[] {
        const maxRoomsNumber = this.config.settings.roomQtySelectorLimit;

        return Array.from(Array(1 + availableUnits).keys()).slice(1, maxRoomsNumber + 1);
    }

    public openRateInfoModal(availabilityResultRate: AvailabilityResultRateModel): void {
        this.dialog.open(RateInfoModalComponent, {
            data: {
                availabilityResultRate,
            },
            panelClass: 'ibe-rate-info-dialog',
        });
    }

    public addToCart(event: MouseEvent, availabilityResultRate: AvailabilityResultRateModel): void {
        const data: OnAddToCartEvent = {
            availabilityResult: this.availabilityResult,
            availabilityResultRate,
            selectedUnitQty: asNumber(this.selectedUnitQty, 0),
        };

        this.onAddToCart.emit(data);
    }

    public decreaseQty(): void {
        if (this.selectedUnitQty > 1) {
            this.selectedUnitQty = this.selectedUnitQty - 1;
        }
    }

    public increaseQty(availableUnits: number): void {
        if (this.selectedUnitQty < this.config.settings.roomQtySelectorLimit && this.selectedUnitQty < availableUnits) {
            this.selectedUnitQty = this.selectedUnitQty + 1;
        }
    }

    private preselectDefaultOffer(): void {
        const specialRate = this.availabilityResult.rates.find(rate => rate.isPromoRate);

        if (this.hasSpecialRates && specialRate) {
            this.selectedRate = { rateId: specialRate.id };
        } else {
            const defaultRatePlanPmsId = this.getDefaultRatePlanIdFromConfig();

            if (defaultRatePlanPmsId) {
                this.availabilityResult.rates.forEach((rate) => {
                    if (rate.id === defaultRatePlanPmsId) {
                        this.selectedRate = {
                            rateId: rate.id,
                        };
                    }
                });
            }

            if (!this.selectedRate) {
                this.selectedRate = {
                    rateId: this.availabilityResult.rates[0].id,
                };
            }
        }
    }

    private getDefaultRatePlanIdFromConfig(): string {
        const pmsUnitTypeId = this.availabilityResult.unitType.id;
        const property = this.config.properties
            .find((_property: Property) => (_property.pmsId === this.pmsPropertyId));

        const unitType = (property)
            ? property.unitTypes.find((_unitType: UnitType) => (_unitType.pmsId === pmsUnitTypeId))
            : null;

        return (unitType) ? unitType.defaultRatePlanPmsId : '';
    }
}
